import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  createTeamMember,
  acceptTeamMemberRequest,
  deleteTeamMember,
  getTeamMembers,
  getTeamIncomingJoinRequests,
  getTeamMemberById,
  resetTeamMembers,
  getTeamOwnersByUserId
} from 'src/actions/teamActions';

export const teamEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

export const incomingRequestsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = teamEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  team: null,
  error: null,
  teamOwners: [],
  incomingRequests: incomingRequestsAdapter.getInitialState({ error: null })
});

const teamReducer = createReducer(initialState, {
  [createTeamMember.pending]: (state, _) => {
    state.loading = true;
  },
  [createTeamMember.rejected]: (state, _) => {
    state.loading = false;
  },
  [createTeamMember.fulfilled]: (state, action) => {
    teamEntityAdapter.addOne(state, action.payload);
    state.team = action.payload;
    state.loading = false;
  },
  [acceptTeamMemberRequest.pending]: (state, _) => {
    state.loading = true;
  },
  [acceptTeamMemberRequest.fulfilled]: (state, { payload }) => {
    state.loading = false;
    incomingRequestsAdapter.updateOne(state.incomingRequests, payload);
  },
  [acceptTeamMemberRequest.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getTeamMembers.pending]: (state, _) => {
    state.loading = true;
  },
  [getTeamMembers.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getTeamMembers.fulfilled]: (state, action) => {
    state.loading = false;
    state.loaded = true;
    teamEntityAdapter.addMany(state, action.payload);
  },

  [getTeamIncomingJoinRequests.pending]: (state, _) => {
    state.loading = true;
  },
  [getTeamIncomingJoinRequests.rejected]: (state, { error }) => {
    state.loading = false;
    state.incomingRequests.error = error;
  },

  [getTeamIncomingJoinRequests.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    incomingRequestsAdapter.upsertMany(state.incomingRequests, payload);
  },

  [getTeamMemberById.pending]: (state, _) => {
    state.loading = true;
  },
  [getTeamMemberById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    teamEntityAdapter.upsertOne(state, payload);
  },
  [getTeamMemberById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },

  [deleteTeamMember.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteTeamMember.fulfilled]: (state, action) => {
    state.loading = false;
    teamEntityAdapter.removeOne(state, action.payload);
  },
  [deleteTeamMember.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getTeamOwnersByUserId.fulfilled]: (state, { payload }) => {
    state.teamOwners = payload;
  },
  [resetTeamMembers.type]: (_state, _) => {
    return initialState;
  }
});

export default teamReducer;
