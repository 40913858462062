import {
  addLetter,
  saveLetter,
  getLetter,
  resetLetter
} from 'src/actions/letterActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const letterEntityAdapter = createEntityAdapter();

const initialState = letterEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const letterReducer = createReducer(initialState, {
  [addLetter.pending]: (state, _) => {
    state.loading = true;
  },
  [addLetter.fulfilled]: (state, { payload }) => {
    state.loading = false;
    letterEntityAdapter.upsertOne(state, payload);
    state.loaded = true;
  },
  [addLetter.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [saveLetter.pending]: (state, _) => {
    state.loading = true;
  },
  [saveLetter.fulfilled]: (state, { payload }) => {
    state.loading = false;
    letterEntityAdapter.upsertOne(state, payload);
    state.loaded = true;
  },
  [saveLetter.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getLetter.pending]: (state, _) => {
    state.loading = true;
  },
  [getLetter.fulfilled]: (state, { payload }) => {
    state.loading = false;
    letterEntityAdapter.upsertOne(state, payload);
    state.loaded = true;
  },
  [getLetter.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [resetLetter.type]: (_, __) => {
    return initialState;
  }
});

export default letterReducer;
