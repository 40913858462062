import Axios from 'axios';

export const getQueryParameter = elem => {
  elem = elem.replace('/', '');
  const urlParts = window.location.pathname.split('/');
  const index = urlParts.indexOf(elem);
  return index > 0 ? urlParts[index + 1] : '';
};

export const getUrlParam = name => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const getHost = urlString => {
  const url = new URL(urlString);
  return url.host;
};

/**
 *
 * @param {string} _url Currently supported: Youtube, Vimeo
 */
export const convertToEmbedded = _url => {
  try {
    const url = new URL(_url);
    // set autoplay true by default
    url.searchParams.set('autoplay', 'true');
    // set loop true by default
    url.searchParams.set('loop', 'true');

    const host = url.host;
    if (url.href.includes(SUPPORTED_PLAYERS.YOUTUBE) || url.href.includes(SUPPORTED_PLAYERS.YOUTU_BE)) {
      if (url.href.includes(SUPPORTED_PLAYERS.YOUTUBE_EMBEDDED)) {
        url.searchParams.set('playlist', url.pathname.split('/').pop());
        return url.toString();
      }
      if (url.href.includes(SUPPORTED_PLAYERS.YOUTU_BE)) {
        url.searchParams.set('playlist', url.pathname.split('/').pop());
        return url.toString().replace('youtu.be/', 'youtube.com/embed/');
      }
      const videoId = url.searchParams.get('v');
      url.searchParams.delete('v');
      const newUrl = new URL(`https://${host}/embed/${videoId}`);
      url.searchParams.forEach((value, key) => {
        newUrl.searchParams.append(key, value);
      });
      newUrl.searchParams.set('playlist', videoId);
      return newUrl.toString();
    }
    if (url.href.includes(SUPPORTED_PLAYERS.VIMEO)) {
      if (url.href.includes(SUPPORTED_PLAYERS.VIMEO_EMBEDDED)) {
        return url.toString();
      }
      const videoId = url.pathname.split('/')[1];
      const newUrl = new URL(`https://player.${host}/video/${videoId}`);
      url.searchParams.forEach((value, key) => {
        newUrl.searchParams.append(key, value);
      });
      return newUrl.toString();
    }
    return url.toString();
  } catch {
    return _url;
  }
};

export const SUPPORTED_PLAYERS = {
  YOUTUBE: 'youtube',
  YOUTU_BE: 'youtu.be',
  YOUTUBE_EMBEDDED: 'embed',
  VIMEO: 'vimeo',
  VIMEO_EMBEDDED: 'player'
};

//https://www.youtube.com/watch?v=3OP-q55hOUI
//https://www.youtube.com/embed/v4xZUr0BEfE
//https://youtu.be/LPSK5QlCCgE?autoplay=true

//https://vimeo.com/336812660?autoplay=1&loop=1&title=0&byline=0&portrait=0
//https://player.vimeo.com/video/336812660?autoplay=1&loop=1&title=0&byline=0&portrait=0

/**
 *
 * @param {string} _url Currently supported: Youtube, Vimeo
 */
export async function retrieveDefaultThumbnail(_url) {
  if (_url.includes(SUPPORTED_PLAYERS.YOUTU_BE)) {
    _url = _url.replace('youtu.be/', 'youtube.com/embed/');
  }

  try {
    const inputUrl = new URL(_url);
    /* YOUTUBE */
    if (inputUrl.href.includes(SUPPORTED_PLAYERS.YOUTUBE)) {
      const videoId = inputUrl.href.includes(SUPPORTED_PLAYERS.YOUTUBE_EMBEDDED)
        ? inputUrl.pathname.split('/').pop()
        : inputUrl.searchParams.get('v');

      const outputHost = `img.${inputUrl.host.replace('www.', '')}`;
      const outputUrl = new URL(`https://${outputHost}/vi/${videoId}/0.jpg`);
      return outputUrl.toString();
    }

    /* VIMEO */
    if (inputUrl.href.includes(SUPPORTED_PLAYERS.VIMEO)) {
      const videoId = inputUrl.pathname.split('/').pop();
      return await Axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`)
        .then(response => {
          return response.data && response.data[0] ? response.data[0].thumbnail_large : '';
        })
        .catch(() => {
          return '';
        });
    }

    //return inputUrl.toString();
  } catch {
    return '';
  }
}
