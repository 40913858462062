import {
  createTestimonial,
  updateTestimonial,
  updateTestimonialsSort,
  deleteTestimonial,
  getTestimonialById,
  getTestimonials,
  resetTestimonials,
} from 'src/actions/testimonialActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const testimonialEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = testimonialEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const testimonialReducer = createReducer(initialState, {
  [createTestimonial.pending]: (state, _) => {
    state.loading = true;
  },
  [createTestimonial.fulfilled]: (state, { payload }) => {
    state.loading = false;
    testimonialEntityAdapter.upsertOne(state, payload);
  },
  [createTestimonial.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateTestimonial.pending]: (state, _) => {
    state.loading = true;
  },
  [updateTestimonial.fulfilled]: (state, { payload }) => {
    state.loading = false;
    testimonialEntityAdapter.updateOne(state, payload);
  },
  [updateTestimonial.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateTestimonialsSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateTestimonialsSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    testimonialEntityAdapter.upsertMany(state, payload);
  },
  [updateTestimonialsSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteTestimonial.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteTestimonial.fulfilled]: (state, { payload }) => {
    state.loading = false;
    testimonialEntityAdapter.removeOne(state, payload);
  },
  [deleteTestimonial.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getTestimonialById.pending]: (state, _) => {
    state.loading = true;
  },
  [getTestimonialById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    testimonialEntityAdapter.upsertOne(state, payload);
  },
  [getTestimonialById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getTestimonials.pending]: (state, _) => {
    state.loading = true;
  },
  [getTestimonials.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    testimonialEntityAdapter.upsertMany(state, payload);
  },
  [getTestimonials.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetTestimonials.type]: (_, __) => {
    return initialState;
  }
});

export default testimonialReducer;
