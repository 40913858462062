import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  getCustomRegistrationForms,
  updateCustomRegistrationFormsSort,
  createCustomRegistrationForm,
  updateCustomRegistrationForm,
  deleteCustomRegistrationForm,
  getCustomRegistrationFormById,
  resetCustomRegistrationForms
} from 'src/actions/customRegistrationFormsActions';

export const customRegistrationFormsEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = customRegistrationFormsEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const customRegistrationFormReducer = createReducer(initialState, {
  [getCustomRegistrationForms.pending]: (state, _) => {
    state.loading = true;
  },
  [getCustomRegistrationForms.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    customRegistrationFormsEntityAdapter.upsertMany(state, payload);
  },
  [getCustomRegistrationForms.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateCustomRegistrationFormsSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateCustomRegistrationFormsSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    customRegistrationFormsEntityAdapter.upsertMany(state, payload);
  },
  [updateCustomRegistrationFormsSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [createCustomRegistrationForm.pending]: (state, _) => {
    state.loading = true;
  },
  [createCustomRegistrationForm.fulfilled]: (state, { payload }) => {
    state.loading = false;
    customRegistrationFormsEntityAdapter.addOne(state, payload);
  },
  [createCustomRegistrationForm.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateCustomRegistrationForm.pending]: (state, _) => {
    state.loading = true;
  },
  [updateCustomRegistrationForm.fulfilled]: (state, { payload }) => {
    state.loading = false;
    customRegistrationFormsEntityAdapter.updateOne(state, payload);
  },
  [updateCustomRegistrationForm.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteCustomRegistrationForm.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteCustomRegistrationForm.fulfilled]: (state, { payload }) => {
    state.loading = false;
    customRegistrationFormsEntityAdapter.removeOne(state, payload);
  },
  [deleteCustomRegistrationForm.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getCustomRegistrationFormById.pending]: state => {
    state.loading = true;
  },
  [getCustomRegistrationFormById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    customRegistrationFormsEntityAdapter.upsertOne(state, payload);
  },
  [getCustomRegistrationFormById.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetCustomRegistrationForms.type]: (_, __) => {
    return initialState;
  }
});

export default customRegistrationFormReducer;
