import settingsService from 'src/services/settingsService';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const GET_TERMS = '@settings/get-terms';
export const GET_DEFAULT_BILLING = '@settings/billing';
export const GET_BILLING_BY_PACKAGE = '@settings/billing-by-package';
export const GET_COUNTRY_TAXES = '@settings/get-country-taxes';

export const getTermsAndConditions = createAsyncThunk(GET_TERMS, async () => {
  const terms = await settingsService.getTerms();
  return terms;
});

export const getBillingFeesByPackage = createAsyncThunk(
  GET_BILLING_BY_PACKAGE,
  async packageId => {
    const fees = await settingsService.getBillingFeesByPackage(packageId);
    return fees;
  }
);

export const getDefaultBillingFees = createAsyncThunk(
  GET_DEFAULT_BILLING,
  async () => {
    const fees = await settingsService.getDefaultBillingFees();
    return fees;
  }
);

export const getCountryTaxes = createAsyncThunk(GET_COUNTRY_TAXES, async () => {
  const taxes = await settingsService.getCountryTaxes();
  return taxes;
});
