import * as routes from 'src/routeConstants';
import authService from 'src/services/authService';
import { translate } from 'src/translations/i18n';
import { getQueryParameter } from 'src/utils/queryParameter';
import {
  attachNewDomain,
  createDomain,
  deleteDomain,
  getAttachableDomains,
  getDomainById,
  getEventDomains,
  registerDomain,
  updateDomain,
  verifyDomain
} from '../core/api/DomainStore';

class DomainService {
  createDomain = domain =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createDomain(domain, userId, eventId)
        .then(newDomain => {
          resolve(newDomain);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteDomain = (domain, accessToken, eventId) =>
    new Promise((resolve, reject) => {
      deleteDomain(domain, accessToken, eventId)
        .then(stage => {
          resolve(stage);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  verifyDomain = (domain, accessToken) =>
    new Promise((resolve, reject) => {
      verifyDomain(domain, accessToken)
        .then(updatedDomain => {
          resolve(updatedDomain);
        })
        .catch(() => {
          reject(false);
        });
    });

  registerDomain = (domain, accessToken) =>
    new Promise((resolve, reject) => {
      registerDomain(domain, accessToken)
        .then(updatedDomain => {
          resolve(updatedDomain);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventDomainsById = eventId =>
    new Promise((resolve, reject) => {
      getEventDomains(eventId)
        .then(domains => {
          if (domains !== null) {
            resolve(domains);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventDomains = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventDomains(eventId)
        .then(domains => {
          if (domains !== null) {
            resolve(domains);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateDomain = changes =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      updateDomain(changes, userId)
        .then(change => {
          resolve(change);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getDomainById = domainId =>
    new Promise((resolve, reject) => {
      getDomainById(domainId)
        .then(domain => {
          if (domain != null) {
            resolve(domain);
          } else {
            reject(translate('domains.errors.noDomainWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getAttachableDomains = () =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      getAttachableDomains(userId)
        .then(domains => {
          if (domains !== []) {
            resolve(domains);
          } else {
            reject(translate('domains.errors.noAvailableDomains'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  attachDomain = (domainId, eventId) =>
    new Promise((resolve, reject) => {
      attachNewDomain(domainId, eventId)
        .then(events => {
          if (events) {
            resolve(events);
          } else {
            reject(translate('domains.errors.domainAttachmentFailed'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new DomainService();
