import * as routes from 'src/routeConstants';
import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import {
  createChecklist,
  deleteChecklist,
  getEventChecklist,
  updateChecklist,
  updateChecklistSort
} from '../core/api/ChecklistStore';

class ChecklistService {
  createChecklist = checklist =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createChecklist(checklist, userId, eventId)
        .then(newChecklist => {
          resolve(newChecklist);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateChecklist = checklist =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateChecklist(checklist, eventId)
        .then(newChecklist => {
          resolve(newChecklist);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateChecklistSort = checklist =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateChecklistSort(checklist, eventId)
        .then(newChecklist => {
          resolve(newChecklist);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteChecklist = checklistId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteChecklist(checklistId, eventId)
        .then(checklist => {
          resolve(checklist);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventChecklist = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventChecklist(eventId)
        .then(checklist => {
          if (checklist != null) {
            resolve(this.sortChecklist(checklist));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortChecklist = checklist => {
    let sortedChecklist = checklist.sort((a, b) =>
      a.hasOwnProperty('sort') && b.hasOwnProperty('sort')
        ? a.sort - b.sort
        : a.createdDate - b.createdDate
    );
    let newChecklist = sortedChecklist.map(
      (s, index) => (s = { ...s, sort: index })
    );
    return newChecklist;
  };
}

export default new ChecklistService();
