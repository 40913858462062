import * as routes from 'src/routeConstants';
import authService from 'src/services/authService';
import { translate } from 'src/translations/i18n';
import { getQueryParameter } from 'src/utils/queryParameter';
import {
  createFeed,
  createStage,
  deleteFeed,
  deleteStage,
  getEventStages,
  getStageById,
  listenStageStatusById,
  updateFeed,
  updateStage,
  updateStagesSort,
  addInvitedSpeaker
} from './../core/api/StageStore';
import packageService from './packageService';
import { STAGE_TYPE } from 'src/constants/packagesConstants';

class StageService {
  createStage = (stage, accessToken) =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);
      packageService
        .validatePackage(STAGE_TYPE, {})
        .then(() => {
          createStage(stage, userId, eventId, accessToken)
            .then(newStage => {
              resolve(newStage);
            })
            .catch(error => {
              reject(error.message);
            });
        })
        .catch(err => reject(err));
    });

  updateStage = stage =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateStage(stage, eventId)
        .then(newStage => {
          resolve(newStage);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateStagesSort = stages =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateStagesSort(stages, eventId)
        .then(newStages => {
          resolve(newStages);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteStage = (stageId, accessToken) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteStage(stageId, eventId, accessToken)
        .then(stage => {
          resolve(stage);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  createFeed = (stageId, feed, accessToken) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      createFeed(stageId, feed, eventId, accessToken)
        .then(changes => {
          if (changes) {
            resolve(changes);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  updateFeed = (stageId, feed) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      updateFeed(stageId, feed, eventId)
        .then(changes => {
          if (changes) {
            resolve(changes);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  deleteFeed = (stageId, feedId, accessToken) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      deleteFeed(stageId, feedId, eventId, accessToken)
        .then(changes => {
          if (changes) {
            resolve(changes);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getStageById = stageId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getStageById(eventId, stageId)
        .then(stage => {
          if (stage != null) {
            resolve(stage);
          } else {
            reject(translate('stages.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventStages = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventStages(eventId)
        .then(stages => {
          if (stages != null) {
            resolve(this.sortStages(stages));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortStages = stages => {
    let sortedStages = stages.sort((a, b) =>
      a.hasOwnProperty('sort') && b.hasOwnProperty('sort')
        ? a.sort - b.sort
        : a.createdDate - b.createdDate
    );
    let newStages = sortedStages.map((s, index) => (s = { ...s, sort: index }));
    return newStages;
  };

  listenStageStatusById = (dispatch, stageId) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      listenStageStatusById(dispatch, stageId, eventId)
        .then(unsubscribe => {
          resolve(unsubscribe);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  addInvitedSpeaker = (stageId, speakerId) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      addInvitedSpeaker(stageId, eventId, speakerId)
        .then(changes => {
          if (changes) {
            resolve(changes);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}

export default new StageService();
