import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import customRegistrationFormsService from 'src/services/customRegistrationFormsService';
export const GET_CUSTOM_REGISTRATION_FORMS =
  '@custom-registration-forms/get-custom-registration-forms';
export const UPDATE_CUSTOM_REGISTRATION_FORMS =
  '@custom-registration-forms/update-custom-registration-forms';
export const DELETE_CUSTOM_REGISTRATION_FORM =
  '@custom-registration-forms/delete-custom-registration-form';
export const CREATE_CUSTOM_REGISTRATION_FORM =
  '@custom-registration-forms/create-custom-registration-form';
export const UPDATE_CUSTOM_REGISTRATION_FORM =
  '@custom-registration-forms/update-custom-registration-form';
export const GET_CUSTOM_REGISTRATION_FORM_BY_ID =
  '@custom-registration-forms/get-custom-registration-form-by-id';
export const RESET_CUSTOM_REGISTRATION_FORMS =
  '@custom-registration-forms/reset-custom-registration-forms';

export const getCustomRegistrationForms = createAsyncThunk(
  GET_CUSTOM_REGISTRATION_FORMS,
  async () => {
    const list = await customRegistrationFormsService.getEventCustomRegistrationForms();
    return list;
  }
);

export const getCustomRegistrationFormById = createAsyncThunk(
  GET_CUSTOM_REGISTRATION_FORM_BY_ID,
  async formId =>
    await customRegistrationFormsService.getEventCustomRegistrationFormById(
      formId
    )
);

export const updateCustomRegistrationFormsSort = createAsyncThunk(
  UPDATE_CUSTOM_REGISTRATION_FORMS,
  async changes => {
    const change = await customRegistrationFormsService.updateCustomRegistrationFormsSort(
      changes
    );
    return change;
  }
);

export const deleteCustomRegistrationForm = createAsyncThunk(
  DELETE_CUSTOM_REGISTRATION_FORM,
  async id => {
    await customRegistrationFormsService.deleteCustomRegistrationForm(id);
    return id;
  }
);

export const createCustomRegistrationForm = createAsyncThunk(
  CREATE_CUSTOM_REGISTRATION_FORM,
  async data => {
    const registrationForm = await customRegistrationFormsService.createCustomRegistrationForm(
      data
    );
    return registrationForm;
  }
);

export const updateCustomRegistrationForm = createAsyncThunk(
  UPDATE_CUSTOM_REGISTRATION_FORM,
  async changes => {
    const registrationForm = await customRegistrationFormsService.updateCustomRegistrationForm(
      changes
    );
    return registrationForm;
  }
);

export const resetCustomRegistrationForms = createAction(RESET_CUSTOM_REGISTRATION_FORMS);
