import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';

const useStyles = makeStyles(() => ({
  root: {}
}));

const definedRoutes = {
  [routes.EVENT]: 'dashboard',
  [routes.TICKETS]: 'tickets',
  [routes.PROMO_CODES]: 'promoCodes',
  [routes.CUSTOM_REGISTRATION_FORMS]: 'customRegistrationForms',
  [routes.NEW_CUSTOM_REGISTRATION_FORMS]: 'newCustomRegistrationForms',
  [routes.ADD_ONS]: 'addOns',
  [routes.CREATE]: 'create',
  [routes.EDIT]: 'edit'
};

const pageNames = {
  [routes.EVENT]: 'dashboard',
  [routes.TICKETS]: 'tickets',
  [routes.PROMO_CODES]: 'promoCodes',
  [routes.ADD_ONS]: 'ticketAddOns',
  [routes.CUSTOM_REGISTRATION_FORMS]: 'customRegistrationForms',
  [routes.NEW_CUSTOM_REGISTRATION_FORMS]: 'newCustomRegistrationForms'
};

function Header({ className, ...rest }) {
  const classes = useStyles();
  const location = useLocation();

  const paths = location.pathname.split('/').slice(1);

  const breadCrumbs = paths
    .map((path, index, paths) => {
      const nextPath = paths[index + 1];
      const name = definedRoutes[`/${path}`];
      if (!name) return null;
      if (!nextPath) return { name };
      return {
        name,
        to: paths.reduce((to, _path, _index, _paths) => {
          if (_index <= index) {
            if (name === definedRoutes[`${routes.EVENT}`]) {
              return `${to}/${_path}/${nextPath}`;
            }
            return `${to}/${_path}`;
          } else {
            return to;
          }
        }, '')
      };
    })
    .filter(value => value);

  const pageName = paths.reverse().reduce((pageName, name) => {
    if (pageNames[`/${name}`] && !pageName) {
      return pageNames[`/${name}`];
    } else {
      return pageName;
    }
  }, null);

  return (
    <Grid
      alignItems="center"
      container
      justify="space-between"
      spacing={3}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadCrumbs.map(breadCrumb =>
            breadCrumb.to ? (
              <Link
                key={breadCrumb.name}
                variant="body1"
                color="inherit"
                to={breadCrumb.to}
                component={RouterLink}
              >
                {translate(`breadcrumbs.${breadCrumb.name}`)}
              </Link>
            ) : (
              <Typography
                key={breadCrumb.name}
                variant="body1"
                color="textPrimary"
              >
                {translate(`breadcrumbs.${breadCrumb.name}`)}
              </Typography>
            )
          )}
        </Breadcrumbs>
        <Typography variant="h3" color="textPrimary" className="pageTitleStyle">
          {translate(`${pageName}.pageName`)}
        </Typography>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
