import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLegalInformations } from 'src/actions/legalInformationActions';
import {
  selectLegalInformation,
  selectLegalInformationLoaded
} from 'src/selectors/legalInformationSelectors';
import NotificationBubble from 'src/views/event/components/NotificationBubble';
import {
  PRIVACY_POLICY,
  TERMS_CONDITIONS
} from 'src/constants/legal-information-constants';
import { Box } from '@material-ui/core';

export function LegalInformationNotification() {
  const dispatch = useDispatch();

  const legalInformationList = useSelector(selectLegalInformation);
  const loaded = useSelector(selectLegalInformationLoaded);

  useEffect(() => {
    dispatch(getLegalInformations());
  }, [dispatch]);

  return (
    <div>
      {loaded &&
        (legalInformationList.length === 0 ||
          !legalInformationList.some(s => s.type === TERMS_CONDITIONS) ||
          !legalInformationList.some(s => s.type === PRIVACY_POLICY)) && (
          <Box mx={1}>
            <NotificationBubble />
          </Box>
        )}
    </div>
  );
}
