import React from 'react';

function AppStore() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }}
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g fill="#fff" stroke="currentColor" strokeWidth="1">
        <rect width="8" height="8" rx="3" stroke="none"></rect>
        <rect x="0.5" y="0.5" width="7" height="7" rx="2.5" fill="none"></rect>
      </g>
      <g
        transform="translate(10 10)"
        fill="#fff"
        stroke="currentColor"
        strokeWidth="1"
      >
        <rect width="8" height="8" rx="3" stroke="none"></rect>
        <rect x="0.5" y="0.5" width="7" height="7" rx="2.5" fill="none"></rect>
      </g>
      <g
        transform="translate(0 10)"
        fill="#fff"
        stroke="currentColor"
        strokeWidth="1"
      >
        <rect width="8" height="8" rx="3" stroke="none"></rect>
        <rect x="0.5" y="0.5" width="7" height="7" rx="2.5" fill="none"></rect>
      </g>
      <line
        y2="6"
        transform="translate(13.5 1.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1"
      ></line>
      <line
        x2="6"
        transform="translate(10.5 4.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1"
      ></line>
    </svg>
  );
}

export default AppStore;
