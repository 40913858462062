import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import legalInformationService from 'src/services/legalInformationService';

export const CREATE_LEGAL_INFORMATION = '@legacies/create-legal-information'
export const UPDATE_LEGAL_INFORMATION = '@legacies/update-legal-information';
export const GET_LEGAL_INFORMATIONS = '@legacies/get-legal-informations';
export const RESET_LEGAL_INFORMATIONS = '@legacies/reset-legal_informations';

export const createLegalInformation = createAsyncThunk(CREATE_LEGAL_INFORMATION, async changes => {
  const newInfo = await legalInformationService.createLegalInformation(changes);
  return newInfo;
});

export const updateLegalInformation = createAsyncThunk(UPDATE_LEGAL_INFORMATION, async changes => {
  const change = await legalInformationService.updateLegalInformation(changes);
  return change;
});

export const getLegalInformations = createAsyncThunk(GET_LEGAL_INFORMATIONS, async () => {
  const info = await legalInformationService.getLegalInformations();
  return info;
});

export const resetLegalInformations = createAction(RESET_LEGAL_INFORMATIONS);
