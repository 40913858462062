export const LECTURE = 'LECTURE';
export const NETWORKING = 'NETWORKING';
export const BREAK = 'BREAK';
export const NETWORKING_AREA = 'Networking area';
export const videoTypesTranslateKeys = {
  [LECTURE]: 'session.videoType.lecture',
  [NETWORKING]: 'session.videoType.networking',
  [BREAK]: 'session.videoType.break'
};

export const UPLOADED_VIDEO_TYPES = {
  LIVE_RECORDED: 'LiveRecorded',
  PRESENTATION: 'Presentation',
  PRE_RECORDED: 'PreRecorded'
};
