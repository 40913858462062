import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import addonService from 'src/services/addonService';

export const GET_ADDONS = '@addons/get-addons';
export const RESET_ADDONS = '@addons/reset-addons';
export const CREATE_ADDON = '@addons/create-addon';
export const UPDATE_ADDON = '@addons/update-addon';
export const UPDATE_ADDONS = '@addons/update-addons';
export const DELETE_ADDON = '@addons/delete-addon';

export const getAddons = createAsyncThunk(GET_ADDONS, async eventId => {
  const { addons } = await addonService.getAddons(eventId);
  return { addons };
});

export const createAddon = createAsyncThunk(
  CREATE_ADDON,
  async ({ addon, eventId }) => {
    const newAddon = await addonService.createAddon(addon, eventId);
    return newAddon;
  }
);

export const updateAddon = createAsyncThunk(
  UPDATE_ADDON,
  async ({ addon, eventId }) => {
    const updatedAddon = await addonService.updateAddon(addon, eventId);
    return updatedAddon;
  }
);

export const updateAddonsSort = createAsyncThunk(
  UPDATE_ADDONS,
  async ({ addons, eventId }) => {
    const change = await addonService.updateAddonsSort(addons, eventId);
    return change;
  }
);

export const deleteAddon = createAsyncThunk(
  DELETE_ADDON,
  async ({ addonId, eventId }) => {
    return await addonService.deleteAddon(addonId, eventId);
  }
);

export const resetAddons = createAction(RESET_ADDONS);
