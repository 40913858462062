import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  queryAttendees,
  resetAttendees,
  activateUsers,
  deleteAttendees,
  getAttendeesStats,
  setEventFilter,
  setOrganizationUserFilter,
  setStatusFilter,
  setTicketFilter,
  exportAttendees
} from 'src/actions/attendeesActions';

export const attendeesEntityAdapter = createEntityAdapter();

const initialState = attendeesEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  stats: {
    totalPendingCount: 0,
    totalAttendeeCount: 0,
    totalActiveCount: 0
  },
  eventFilter: null,
  organizationUserFilter: null,
  ticketFilter: null,
  exportLoading: false,
  statusFilter: null,
  latestAttendees: []
});

const attendeesReducer = createReducer(initialState, {
  [queryAttendees.pending]: (state, _) => {
    state.loading = true;
  },
  [queryAttendees.fulfilled]: (state, { payload, meta: { arg } }) => {
    state.loading = false;
    state.loaded = true;
    if (arg.isNewQuery) {
      attendeesEntityAdapter.setAll(state, payload.data);
    } else {
      attendeesEntityAdapter.upsertMany(state, payload.data);
    }
  },
  [queryAttendees.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetAttendees.type]: (_, __) => {
    return initialState;
  },
  [activateUsers.pending]: (state, _) => {
    state.loading = true;
  },
  [activateUsers.fulfilled]: (state, { payload }) => {
    state.loading = false;
    attendeesEntityAdapter.upsertMany(state, payload);
  },
  [activateUsers.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteAttendees.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteAttendees.fulfilled]: (state, { payload }) => {
    state.loading = false;
    attendeesEntityAdapter.removeMany(state, payload);
  },
  [deleteAttendees.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getAttendeesStats.fulfilled]: (state, { payload }) => {
    state.stats = payload;
  },
  [setEventFilter.type]: (state, { payload }) => {
    state.eventFilter = payload;
  },
  [setOrganizationUserFilter.type]: (state, { payload }) => {
    state.organizationUserFilter = payload;
  },
  [setTicketFilter.type]: (state, { payload }) => {
    state.ticketFilter = payload;
  },
  [setStatusFilter.type]: (state, { payload }) => {
    state.statusFilter = payload;
  },
  [exportAttendees.pending]: state => {
    state.exportLoading = true;
  },
  [exportAttendees.rejected]: (state, { error }) => {
    state.error = error;
    state.exportLoading = false;
  },
  [exportAttendees.fulfilled]: state => {
    state.exportLoading = false;
  }
});

export default attendeesReducer;
