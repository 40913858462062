import speakerService from 'src/services/speakerService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const CREATE_SPEAKER = '@speaker/create-speaker';
export const UPDATE_SPEAKER = '@speaker/update-speaker';
export const UPDATE_SPEAKERS = '@speaker/update-speakers';
export const DELETE_SPEAKER = '@speaker/delete-speaker';
export const GET_SPEAKER = '@speaker/get-speaker';
export const GET_SPEAKERS = '@speaker/get-speakers';
export const RESET_SPEAKERS = '@speaker/reset-speakers';
export const LISTEN_SPEAKER_STATUS_BY_ID = '@speaker/listen-status-by-id';
export const SPEAKER_STATUS_CHANGED = '@speaker/status-changed';
export const INVITE_SPEAKER = '@speaker/invite-speaker';
export const GENERATE_SPEAKER_LOGIN_LINK =
  '@speaker/generate-speaker-login-link';

export const createSpeaker = createAsyncThunk(
  CREATE_SPEAKER,
  async ({ speaker, cleanupId }) => {
    const newSpeaker = await speakerService.createSpeaker(speaker, cleanupId);
    return newSpeaker;
  }
);

export const updateSpeaker = createAsyncThunk(UPDATE_SPEAKER, async changes => {
  const change = await speakerService.updateSpeaker(changes);
  return change;
});

export const updateSpeakersSort = createAsyncThunk(
  UPDATE_SPEAKERS,
  async changes => {
    const change = await speakerService.updateSpeakersSort(changes);
    return change;
  }
);

export const deleteSpeaker = createAsyncThunk(
  DELETE_SPEAKER,
  async speakerId => {
    const deletedSpeakerId = await speakerService.deleteSpeaker(speakerId);
    return deletedSpeakerId;
  }
);

export const getSpeakerById = createAsyncThunk(GET_SPEAKER, async speakerId => {
  const speaker = await speakerService.getSpeakerById(speakerId);
  return speaker;
});

export const getSpeakers = createAsyncThunk(GET_SPEAKERS, async () => {
  const speakers = await speakerService.getEventSpeakers();
  return speakers;
});

export const inviteSpeaker = createAsyncThunk(
  INVITE_SPEAKER,
  async ({ speaker, stageId }) => {
    const response = await speakerService.inviteSpeaker(speaker, stageId);
    return response;
  }
);

export const generateSpeakerLoginLink = createAsyncThunk(
  GENERATE_SPEAKER_LOGIN_LINK,
  async ({ speaker, stageId }) => {
    const response = await speakerService.generateSpeakerLoginLink(
      speaker,
      stageId
    );
    return response;
  }
);

export const resetSpeakers = createAction(RESET_SPEAKERS);
