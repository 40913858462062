import axios from 'axios';
import firebase from 'firebase';
import {
  VIDEO_BY_ID_STATUS_CHANGED,
  VIDEO_STATUS_CHANGED,
  VIDEO_UPLOAD_CANCELED,
  VIDEO_UPLOAD_FAILED,
  VIDEO_UPLOAD_FINISHING,
  VIDEO_UPLOAD_IN_PROGRESS,
  VIDEO_UPLOAD_MUX_PREPARING,
  VIDEO_UPLOAD_PAUSED,
  VIDEO_UPLOAD_STATUS
} from 'src/actions/videoAction-types';
import { UPLOADED_VIDEO_TYPES } from 'src/constants/video-types';
import { getDifferenceInMinute } from 'src/utils/dateFormatter';
import { storage, store } from './firebase';

const EVENTS_COLLECTION = 'events';
const VIDEOS_COLLECTION = 'videos';
const SESSIONS_COLLECTION = 'sessions';
const VIDEO_CATEGORIES_COLLECTION = 'video-categories';

export async function getEventVideos(eventId) {
  const videoDocs = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(VIDEOS_COLLECTION)
    .get();

  return videoDocs.docs.map(video => ({
    id: video.id,
    ...video.data()
  }));
}

export async function getVideoById(eventId, videoId) {
  const videoDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}`)
    .doc(videoId);
  const video = await videoDoc.get();
  if (!video.exists) return null;
  return { id: video.id, ...video.data() };
}

export async function listeningToCompletedVideos(dispatch, eventId) {
  const unsubscribe = store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(VIDEOS_COLLECTION)
    .where('uploadStatus', '==', VIDEO_UPLOAD_STATUS.COMPLETED)
    .onSnapshot(
      querySnapshot => {
        dispatch({
          type: VIDEO_STATUS_CHANGED,
          payload: querySnapshot.docs.map(video => ({
            id: video.id,
            changes: { ...video.data() }
          }))
        });
      },
      error => {
        throw Error(error.message);
      }
    );

  return () => unsubscribe();
}

export async function listenVideoStatusById(dispatch, eventId, videoId) {
  const unsubscribe = store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${videoId}`)
    .onSnapshot(
      querySnapshot => {
        dispatch({
          type: VIDEO_BY_ID_STATUS_CHANGED,
          payload: {
            id: querySnapshot.id,
            changes: { ...querySnapshot.data() }
          }
        });
      },
      error => {
        throw Error(error.message);
      }
    );
  return () => unsubscribe();
}

export async function assignVideoToSession(eventId, changes) {
  const { video, sessionId } = changes;
  // remove old assignment
  if (video.session && video.session.id) {
    const sessionDoc = await store
      .doc(
        `${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}/${video.session.id}`
      )
      .get();
    if (sessionDoc.exists) {
      sessionDoc.ref.update({
        videoId: null,
        thumbnailUrl: null,
        liveVideoUrl: null,
        delay: 0,
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
      });
    }
  }
  if (sessionId) {
    const currentSession = await store
      .doc(
        `${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}/${sessionId}`
      )
      .get();
    const delay = getDifferenceInMinute(
      currentSession.data().start || currentSession.data().startDateTime,
      currentSession.data().end || currentSession.data().endDateTime
    );
    currentSession.ref.update({
      videoId: video.id,
      thumbnailUrl: `https://image.mux.com/${video.assetAccessId}/animated.gif`,
      liveVideoUrl: `https://stream.mux.com/${video.assetAccessId}.m3u8`,
      delay: Math.max(delay && video.duration / 60 - delay, 0),
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
    await store
      .doc(`${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${video.id}`)
      .update({
        session: {
          id: sessionId,
          title: currentSession.data().title,
          startDateTime: currentSession.data().start,
          endDateTime: currentSession.data().end
        },
        stageId: currentSession.data().resourceId,
        type: UPLOADED_VIDEO_TYPES.PRE_RECORDED,
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
      });
  } else {
    await store
      .doc(`${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${video.id}`)
      .update({
        session: null,
        stageId: firebase.firestore.FieldValue.delete()
      });
  }
  return true;
}

/**
 * @note Update a single video
 * @param {*} video
 * @param {*} eventId
 * @returns
 */
export async function updateVideo(video, eventId) {
  const videoDoc = await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${video.id}`)
    .get();

  if (!videoDoc.exists) return null;

  /* Remove the "uploadTask" field, because it is frontend-related only. Shouldn't arrive into the DB. */
  const { uploadTask, ...rest } = video;

  await videoDoc.ref.update({
    ...rest,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });
  return { id: videoDoc.id, changes: { ...video } };
}

/**
 * If a new lobby-assigned video emerged, let's update the rest of the videos (remove the lobby assignment).
 * @param {*} eventId
 * @returns
 */
export async function removeLobbyAssignmentFromVideos(eventId) {
  const changes = [];
  const previousLobbyVideos = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}`)
    .where('displayOnLobby', '==', true)
    .get();
  const batch = store.batch();

  previousLobbyVideos.docs.map(previousLobbyVideo => {
    batch.update(previousLobbyVideo.ref, {
      ...previousLobbyVideo.data(),
      displayOnLobby: false
    });
    changes.push({
      id: previousLobbyVideo.id,
      changes: {
        displayOnLobby: false
      }
    });
  });

  await batch.commit();
  return changes;
}

/**
 * @note Delete a video
 * @param {*} video
 * @param {*} eventId
 * @returns
 */
export async function deleteVideo(video, eventId) {
  await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${video.id}`)
    .delete();

  if (video.downloadUrl && video.storageUrl) {
    await storage
      .ref()
      .child(video.storageUrl)
      .delete();
  }
  if (video.session) {
    const sessionDoc = await store
      .doc(
        `${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}/${video.session.id}`
      )
      .get();
    if (sessionDoc.exists) {
      await sessionDoc.ref.set(
        {
          videoId: null,
          thumbnailUrl: null,
          liveVideoUrl: null,
          delay: 0
        },
        { merge: true }
      );
    }
  }

  return true;
}

export async function uploadVideo(
  data,
  dispatch,
  eventId,
  userId,
  accessToken,
  videoType,
  videoName,
  noCategory,
  displayOnLobby,
  loopVideo
) {
  const { type, size, file, duration, videoId } = data;
  let { name } = data;
  const metadata = {
    contentType: type
  };

  if (videoName) name = videoName;
  //create or/and set video document in firebase
  let videoRef = null;
  if (videoId) {
    videoRef = store
      .collection(`${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}`)
      .doc(videoId);
    await videoRef.update({
      uploadStatus: VIDEO_UPLOAD_STATUS.STARTED,
      name,
      size,
      duration,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      type: videoType,
      noCategory: noCategory,
      displayOnLobby: displayOnLobby,
      loopVideo: loopVideo
    });
  } else {
    videoRef = store
      .collection(`${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}`)
      .doc();
    await videoRef.set({
      uploadStatus: VIDEO_UPLOAD_STATUS.STARTED,
      name,
      size,
      duration,
      createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: userId,
      type: videoType,
      noCategory: noCategory,
      displayOnLobby: displayOnLobby,
      loopVideo: loopVideo
    });
  }

  const videoDoc = await videoRef.get();
  // need this video id on client side.
  await videoDoc.ref.update({
    id: videoDoc.id
  });
  const uploadTask = storage
    .ref()
    .child(
      `${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${videoDoc.id}-${name}`
    )
    .put(file, metadata);
  // subscribe to the upload task, and pass down the dispatch for the UI updating
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    async snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          await videoRef.update({
            uploadStatus: VIDEO_UPLOAD_STATUS.PAUSED
          });
          dispatch({
            type: VIDEO_UPLOAD_PAUSED,
            payload: {
              id: videoDoc.id,
              changes: {
                progress,
                uploadStatus: VIDEO_UPLOAD_STATUS.PAUSED
              }
            }
          });
          break;
        case firebase.storage.TaskState.RUNNING:
          await videoRef.update({
            uploadStatus: VIDEO_UPLOAD_STATUS.UPLOADING
          });
          dispatch({
            type: VIDEO_UPLOAD_IN_PROGRESS,
            payload: {
              id: videoDoc.id,
              changes: {
                progress,
                uploadStatus: VIDEO_UPLOAD_STATUS.UPLOADING
              }
            }
          });
          break;
        case firebase.storage.TaskState.CANCELED:
          await videoRef.update({
            uploadStatus: VIDEO_UPLOAD_STATUS.CANCELED
          });
          dispatch({
            type: VIDEO_UPLOAD_CANCELED,
            payload: {
              id: videoDoc.id,
              changes: {
                progress: 0,
                uploadStatus: VIDEO_UPLOAD_STATUS.CANCELED
              }
            }
          });
          break;
        default:
          break;
      }
    },
    async error => {
      // firebase video upload error
      await videoRef.update({
        uploadStatus: VIDEO_UPLOAD_STATUS.FAILED
      });
      dispatch({
        type: VIDEO_UPLOAD_FAILED,
        payload: {
          id: videoDoc.id,
          changes: {
            progress: 0,
            uploadStatus: VIDEO_UPLOAD_STATUS.FAILED,
            error
          }
        }
      });
    },
    async () => {
      // get firebase download url, and start mux asset creation with firebase function
      const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();

      await videoRef.update({
        downloadUrl,
        storageUrl: `${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${videoDoc.id}-${name}`,
        uploadStatus: VIDEO_UPLOAD_STATUS.FINISHING
      });
      dispatch({
        type: VIDEO_UPLOAD_FINISHING,
        payload: {
          id: videoDoc.id,
          changes: {
            progress: 100,
            uploadStatus: VIDEO_UPLOAD_STATUS.FINISHING,
            downloadUrl: downloadUrl || ''
          }
        }
      });
      try {
        if (videoType === UPLOADED_VIDEO_TYPES.LIVE_RECORDED) {
          return {
            id: videoDoc.id,
            ...videoDoc.data(),
            uploadTask: {
              pause: () => uploadTask.pause(),
              resume: () => uploadTask.resume(),
              cancel: () => uploadTask.cancel()
            }
          };
        }
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        };
        const { data: response } = await axios({
          headers: headers,
          method: 'post',
          url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/videos-createVideoAsset`,
          data: {
            input: [{ url: downloadUrl }],
            videoDocUrl: `${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${videoDoc.id}`
          }
        });
        if (
          response.status &&
          response.status === VIDEO_UPLOAD_STATUS.COMPLETED &&
          response.data
        ) {
          // save the mux response in the firebase video doc if the asset was created successfully
          await videoDoc.ref.update({
            uploadStatus: VIDEO_UPLOAD_STATUS.MUX_PREPARING,
            muxAssets: response.data,
            assetAccessId:
              response.data.playback_ids.find(play => play.id).id ||
              'NO_MUX_ACCESS_ID'
          });

          dispatch({
            type: VIDEO_UPLOAD_MUX_PREPARING,
            payload: {
              id: videoDoc.id,
              changes: {
                progress: 100,
                uploadStatus: VIDEO_UPLOAD_STATUS.MUX_PREPARING,
                data: response.data
              }
            }
          });
        } else if (
          response.status &&
          response.status === VIDEO_UPLOAD_STATUS.FAILED
        ) {
          await videoRef.update({
            uploadStatus: VIDEO_UPLOAD_STATUS.FAILED
          });
          await uploadTask.snapshot.ref.delete();
          dispatch({
            type: VIDEO_UPLOAD_FAILED,
            payload: {
              id: videoDoc.id,
              changes: {
                progress: 0,
                uploadStatus: VIDEO_UPLOAD_STATUS.FAILED,
                error: response.error
              }
            }
          });
        }
      } catch (e) {
        await videoRef.update({
          uploadStatus: VIDEO_UPLOAD_STATUS.FAILED
        });
        await uploadTask.snapshot.ref.delete();
        dispatch({
          type: VIDEO_UPLOAD_FAILED,
          payload: {
            id: videoDoc.id,
            changes: {
              progress: 0,
              uploadStatus: VIDEO_UPLOAD_STATUS.FAILED,
              error: e.message
            }
          }
        });
      }
    }
  );
  return {
    id: videoDoc.id,
    ...videoDoc.data(),
    uploadTask: {
      pause: () => uploadTask.pause(),
      resume: () => uploadTask.resume(),
      cancel: () => uploadTask.cancel()
    }
  };
}

export async function getEventVideoCategories(eventId) {
  const documentReference = store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(VIDEO_CATEGORIES_COLLECTION);
  let videoCategories = [];
  videoCategories = documentReference.get().then(snap => {
    const videoCategoriesList = [];
    snap.forEach(doc => {
      if (doc.exists) {
        const data = doc.data();
        const item = {
          id: doc.id,
          name: data.name,
          createdDate: data.createdDate
        };
        videoCategoriesList.push(item);
      }
    });
    return videoCategoriesList;
  });
  return videoCategories;
}

export async function createVideoCategory(category, userId, eventId) {
  const videoCategoryCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${VIDEO_CATEGORIES_COLLECTION}`
  );
  let newCategory = {
    name: category.name,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  };

  const addedCategory = await videoCategoryCollection.add(newCategory);
  return { ...newCategory, id: addedCategory.id };
}
