/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ArrowLeft as ArrowLeftIcon, Home as HomeIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { resetBenefits } from 'src/actions/benefitActions';
import { resetChecklist } from 'src/actions/checklistActions';
import { resetSections } from 'src/actions/landingPageSectionActions';
import { resetLegalInformations } from 'src/actions/legalInformationActions';
import { resetLetter } from 'src/actions/letterActions';
import { resetSpeakers } from 'src/actions/speakerActions';
import { resetSponsors } from 'src/actions/sponsorActions';
import { resetCategories } from 'src/actions/sponsorCategoryActions';
import { resetBooths } from 'src/actions/boothActions';
import { resetBilling } from 'src/actions/billingActions';
import { resetStages } from 'src/actions/stageActions';
import { resetTestimonials } from 'src/actions/testimonialActions';
import { resetTickets } from 'src/actions/ticketActions';
import { resetSessions } from 'src/actions/sessionActions';
import { resetCustomForm } from 'src/actions/customFormFieldsActions';
import { resetTeamMembers } from 'src/actions/teamActions';
import { resetEventSettings } from 'src/actions/eventSettingsActions';
import { resetEvents } from 'src/actions/eventActions';
import { resetNetworks } from 'src/actions/networkActions';
import { resetEmailTemplates } from 'src/actions/emailTemplateActions';
import Logo from 'src/components/Logo';
import Ticket from 'src/components/Ticket';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';
import { getQueryParameter } from 'src/utils/queryParameter';
import NavItem from './NavItem';
import Display from 'src/components/custom_icons/Display';
import Brush from 'src/components/custom_icons/Brush';
import Fold from 'src/components/custom_icons/Fold';
import Globe from 'src/components/custom_icons/Globe';
import Mic from 'src/components/custom_icons/Mic';
import Pen from 'src/components/custom_icons/Pen';
import { ReactComponent as Reports } from 'src/components/custom_icons/Reports.svg';
import Users from 'src/components/custom_icons/Users';
import Settings from 'src/components/custom_icons/Settings';
import Calendar from 'src/components/custom_icons/Calendar';
import Camera from 'src/components/custom_icons/Camera';
import Footer from '../Footer';
import { launchPreview } from 'src/utils/previewLink';
import { selectDomainList } from 'src/selectors/domainSelectors';
import { selectEventInfo, selectEventSlug } from 'src/selectors/eventSelector';
import { LegalInformationNotification } from 'src/views/event/components/LegalInformationNotification';
import AppStore from 'src/components/custom_icons/AppStore';
import Preview from 'src/components/custom_icons/Preview';
import { resetAddons } from 'src/actions/addonActions';
import { resetCustomRegistrationForms } from 'src/actions/customRegistrationFormsActions';

function EventName() {
  const classes = useStyles();
  const eventName = useSelector(state =>
    state.event.event != null ? state.event.event.name : ''
  );

  const event = useSelector(selectEventInfo);
  const eventSlug = useSelector(selectEventSlug);
  const domains = useSelector(selectDomainList);

  return (
    <div className={classes.titleSidebarContainer}>
      <Box mr={1} className={classes.titleSidebar}>
        {eventName}
      </Box>
      <IconButton
        onClick={() => launchPreview(event, eventSlug, domains)}
        className={classes.previewBtn}
      >
        <Preview />
      </IconButton>
    </div>
  );
}

const navConfig = [
  {
    subheader: '',
    items: [
      {
        title: translate('navBar.backToEvents'),
        icon: ArrowLeftIcon,
        href: () => routes.HOME
      }
    ]
  },
  {
    subheader: <EventName />,
    items: [
      {
        title: translate('navBar.overview'),
        icon: HomeIcon,
        href: id => `${routes.EVENT}/${id}`
      },
      {
        title: translate('navBar.keyDetails'),
        icon: Pen,
        href: id => `${routes.EVENT}/${id}${routes.KEY_DETAILS}`
      },
      {
        title: translate('navBar.reports'),
        icon: Reports,
        href: id => `${routes.EVENT}/${id}${routes.REPORTS}`
      }
    ]
  },
  {
    subheader: translate('navBar.information'),
    items: [
      {
        title: translate('navBar.speakers'),
        icon: Mic,
        href: id => `${routes.EVENT}/${id}${routes.SPEAKERS}`
      },
      {
        title: translate('navBar.liveStages'),
        icon: Camera,
        href: id => `${routes.EVENT}/${id}${routes.STAGES}`
      },
      {
        title: translate('navBar.schedule'),
        icon: Calendar,
        href: id => `${routes.EVENT}/${id}${routes.SCHEDULE}`
      },
      {
        title: translate('navBar.tickets'),
        icon: Ticket,
        href: id => `${routes.EVENT}/${id}${routes.TICKETS}`
      },
      {
        title: translate('navBar.videos'),
        icon: Display,
        href: id => `${routes.EVENT}/${id}${routes.VIDEO}`
      },
      {
        title: translate('navBar.sponsors'),
        icon: Fold,
        href: id => `${routes.EVENT}/${id}${routes.SPONSORS}`
      },
      {
        title: translate('navBar.networking'),
        icon: Users,
        href: id => `${routes.EVENT}/${id}${routes.NETWORKING}`
      }
    ]
  },
  {
    subheader: translate('navBar.customization'),
    items: [
      {
        title: translate('navBar.theme'),
        icon: Brush,
        href: id => `${routes.EVENT}/${id}${routes.THEME}`,
        notification: <LegalInformationNotification />
      },
      {
        title: translate('navBar.domain'),
        icon: Globe,
        href: id => `${routes.EVENT}/${id}${routes.DOMAIN}`
      },
      {
        title: translate('navBar.settings'),
        icon: Settings,
        href: id => `${routes.EVENT}/${id}${routes.SETTINGS}`
      },
      {
        title: translate('navBar.appStore'),
        icon: AppStore,
        href: id => `${routes.EVENT}/${id}${routes.APP_STORE}`
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0, id }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href(id),
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        notification={item.notification}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href(id)}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        notification={item.notification}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: {
    backgroundColor: theme.palette.background.main,
    padding: '20px 0'
  },
  titleSidebarContainer: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '28px',
    color: '#000',
    paddingBottom: 6,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  titleSidebar: {
    maxWidth: 160,
    wordBreak: 'break-word'
  },
  subheader: {
    paddingLeft: 22,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: 11,
    lineHeight: '20px',
    paddingTop: 10
  },
  previewBtn: {
    backgroundColor: '#F5F5F5',
    borderRadius: 8,
    padding: 6
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const event = useSelector(selectEventInfo);
  const eventSlug = useSelector(selectEventSlug);
  const domains = useSelector(selectDomainList);
  const id = getQueryParameter(routes.EVENT);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    dispatch(resetEvents());
    dispatch(resetSpeakers());
    dispatch(resetStages());
    dispatch(resetTickets());
    dispatch(resetSessions());
    dispatch(resetSponsors());
    dispatch(resetCategories());
    dispatch(resetBooths());
    dispatch(resetBilling());
    dispatch(resetSections());
    dispatch(resetLetter());
    dispatch(resetChecklist());
    dispatch(resetTestimonials());
    dispatch(resetBenefits());
    dispatch(resetLegalInformations());
    dispatch(resetCustomForm());
    dispatch(resetTeamMembers());
    dispatch(resetEventSettings());
    dispatch(resetAddons());
    dispatch(resetCustomRegistrationForms());
    dispatch(resetNetworks());
    dispatch(resetEmailTemplates());
    // eslint-disable-next-line
  }, [id]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={1}
            display="flex"
            justifyContent="center"
            className={classes.logo}
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box px={2} pt={2}>
          {navConfig.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader
                  className={classes.subheader}
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
                id
              })}
            </List>
          ))}
        </Box>
        <Box px={4} py={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => launchPreview(event, eventSlug, domains)}
          >
            {translate('overview.viewEvent')}
          </Button>
        </Box>
        <Box px={2}>
          <Footer />
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
