import firebase from 'firebase';
import { NETWORKING_AREA } from 'src/constants/video-types';
import { storage, store } from './firebase';

const EVENTS_COLLECTION = 'events';
const SESSIONS_COLLECTION = 'sessions';
const NETWORKS_COLLECTION = 'networking-tables';

export async function createNetworks(networks, userId, eventId) {
  const networksCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${NETWORKS_COLLECTION}`
  );

  const { docs } = await networksCollection
    .orderBy('sort', 'desc')
    .limit(1)
    .get();

  let sort = docs.reduce((accumulator, doc) => {
    if (doc.exists) return doc.data().sort + 1;
    return accumulator;
  }, 0);
  const batch = store.batch();
  networks.forEach(async network => {
    const createdDate = firebase.firestore.FieldValue.serverTimestamp();
    let doc = networksCollection.doc();
    let data = {
      name: network.name,
      maxParticipants: network.maxParticipants,
      sort,
      createdBy: userId,
      createdDate: createdDate
    };
    batch.set(doc, data, { merge: true });
    sort = sort + 1;
  });
  await batch.commit();
  return await getEventNetworks(eventId);
}

export async function getEventNetworks(eventId) {
  const networkDocs = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(NETWORKS_COLLECTION)
    .get();

  return networkDocs.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
    createdDate: doc.data().createdDate.toDate()
  }));
}

export async function deleteNetworks(networkIds, eventId) {
  const batch = store.batch();
  await Promise.all(
    networkIds.map(async id => {
      const query = await store
        .collection(`${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}`)
        .where('networkTable', '==', id)
        .get();
      query.docs.forEach(doc => {
        batch.update(doc.ref, { networkTable: NETWORKING_AREA });
      });
      batch.delete(
        store.doc(
          `${EVENTS_COLLECTION}/${eventId}/${NETWORKS_COLLECTION}/${id}`
        )
      );
    })
  );
  await batch.commit();
  return networkIds;
}

export async function updateNetwork(network, eventId) {
  const networkDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${NETWORKS_COLLECTION}`)
    .doc(network.id);

  let updatedNetwork = {
    id: network.id,
    name: network.name,
    maxParticipants: network.maxParticipants,
    logoUrl: network.logoUrl
  };

  await networkDoc.update({
    ...updatedNetwork,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  if (network.logoRaw) {
    const imageName = `events/${eventId}/network-tables/${network.id}`;
    const uploadTask = await storage.ref(imageName).put(network.logoRaw);
    const downloadUrl = await uploadTask.ref.getDownloadURL();
    updatedNetwork = {
      ...updatedNetwork,
      logoUrl: downloadUrl
    };
    await networkDoc.update({
      logoUrl: downloadUrl,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  return updatedNetwork;
}

export async function updateNetworksSort(changesList, eventId) {
  const batch = store.batch();

  changesList.forEach(changes => {
    const networkRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${NETWORKS_COLLECTION}/${changes.id}`
    );

    batch.update(networkRef, {
      id: changes.id,
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  });

  await batch.commit();

  return changesList;
}

export async function getNetworkById(eventId, networkId) {
  const networkDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${NETWORKS_COLLECTION}`)
    .doc(networkId);
  const network = await networkDoc.get();
  if (!network.exists) return null;
  return { id: network.id, ...network.data() };
}
