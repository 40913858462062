import firebase from 'firebase';
import { DEFAULT_ID } from 'src/constants/custom-registration-constants';
import {
  createCustomRegistrationFormWithCustomId,
  checkEventCustomRegistrationFormDocumentExistsById
} from './CustomRegistrationFormsStore';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const CUSTOM_REGISTRATION_FORMS_COLLECTION = 'custom-registration-forms';
const CUSTOM_FORM_FIELDS_COLLECTION = 'custom-form-fields';

export async function createCustomFormField(
  customFormField,
  userId,
  formId,
  eventId
) {
  const customFormFieldsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}/${formId}/${CUSTOM_FORM_FIELDS_COLLECTION}`
  );

  const { size } = await customFormFieldsCollection.get();

  const customFormFieldRef = await customFormFieldsCollection.add({
    label: customFormField.label,
    buttonText: customFormField.buttonText,
    selectedCustomFieldType: customFormField.selectedCustomFieldType,
    dropdownValues: customFormField.dropdownValues,
    multiSelectValues: customFormField.multiSelectValues,
    isMandatory: customFormField.isMandatory,
    sort: size,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  });

  await customFormFieldRef.update({
    id: customFormFieldRef.id
  });

  //Create default registration form if not exists
  if (formId === DEFAULT_ID) {
    const defaultExists = await checkEventCustomRegistrationFormDocumentExistsById(
      eventId,
      formId
    );
    if (!defaultExists) {
      await createCustomRegistrationFormWithCustomId(
        { name: 'Default' },
        eventId,
        formId
      );
    }
  }

  return {
    ...(await customFormFieldRef.get()).data(),
    id: customFormFieldRef.id
  };
}

export async function updateCustomFormField(customFormField, eventId, formId) {
  const customFormFieldRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}/${formId}/${CUSTOM_FORM_FIELDS_COLLECTION}/${customFormField.id}`
  );

  await customFormFieldRef.update({
    id: customFormField.id,
    label: customFormField.label,
    buttonText: customFormField.buttonText,
    selectedCustomFieldType: customFormField.selectedCustomFieldType,
    dropdownValues: customFormField.dropdownValues,
    multiSelectValues: customFormField.multiSelectValues,
    isMandatory: customFormField.isMandatory,
    sort: customFormField.sort,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  const customFormDoc = await customFormFieldRef.get();

  return {
    id: customFormDoc.id,
    changes: {
      ...customFormDoc.data()
    }
  };
}

export async function updateCustomFormFieldSort(changesList, eventId, formId) {
  const batch = store.batch();

  changesList.forEach(async changes => {
    const customFormFieldRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}/${formId}/${CUSTOM_FORM_FIELDS_COLLECTION}/${changes.id}`
    );

    batch.update(customFormFieldRef, {
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  });

  batch.commit();

  return changesList;
}

export async function deleteCustomFormField(
  customFormFieldId,
  eventId,
  formId
) {
  const customFormFieldCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}/${formId}/${CUSTOM_FORM_FIELDS_COLLECTION}`
  );
  const customFormFieldDoc = customFormFieldCollection.doc(customFormFieldId);

  await customFormFieldDoc.delete();

  return customFormFieldId;
}

export async function getCustomFormFieldsByFormId(eventId, formId) {
  const customFormFieldsCollection = await store
    .collection(
      `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}/${formId}/${CUSTOM_FORM_FIELDS_COLLECTION}`
    )
    .get();

  return customFormFieldsCollection.docs.map(doc => ({
    id: doc.id,
    formId,
    ...doc.data()
  }));
}
