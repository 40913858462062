import { Tabs } from '@material-ui/core';
import React from 'react';
import LinkTab from './LinkTab';

function LinkTabs({ paths, currentPath, children }) {
  const [active, setActive] = React.useState(0);
  React.useEffect(() => {
    Object.entries(paths).map(([path, rest]) => {
      if (rest.exact && path === currentPath) {
        setActive(rest.index);
      }
      else if (currentPath.includes(path)) {
        setActive(rest.index);
      }
    });
  }, [currentPath, setActive, paths]);

  return (
    <Tabs
      value={active}
      onChange={(_, index) => setActive(index)}
      scrollButtons="auto"
      variant="scrollable"
      textColor="primary"
      indicatorColor="primary"
    >
      {Object.entries(paths).map(([path, { label }], index) => (
        <LinkTab key={index} label={label} to={path} />
      ))}
    </Tabs>
  );
}

export default LinkTabs;
