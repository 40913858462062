import { Link, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'src/translations/i18n';
import * as routes from 'src/routeConstants';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexWrap: 'wrap',
    padding: '0 22px 10px'
  },
  text: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.5)'
  },
  link: {
    marginRight: '10px'
  }
}));

function Footer({ className, ...rest }) {
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Typography variant="body2" className={classes.text}>
        {`${translate('footer.copyrightSymbol')} ${year} ${translate(
          'footer.soundmix'
        )}`}
      </Typography>
      <Typography variant="body2" className={classes.text}>
        <Link
          className={classes.link}
          color="inherit"
          underline="always"
          href={routes.TERMS_CONDITIONS}
          target="_blank"
        >
          {translate('footer.terms')}
        </Link>
        <Link
          className={classes.link}
          color="inherit"
          underline="always"
          href={routes.PRIVACY_POLICY}
          target="_blank"
        >
          {translate('footer.privacy')}
        </Link>
        <Link
          color="inherit"
          underline="always"
          href="#"
          onClick={() => {
            window.open(`https://support.eventmix.live`, '_blank');
          }}
        >
          {translate('footer.support')}
        </Link>
      </Typography>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
