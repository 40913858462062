import firebase from 'firebase';
import { ERRORS } from 'src/constants/error-constants';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const CUSTOM_REGISTRATION_FORMS_COLLECTION = 'custom-registration-forms';

export async function getEventCustomRegistrationForms(eventId) {
  const customRegistrationCollection = await store
    .collection(
      `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}`
    )
    .get();
  return customRegistrationCollection.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }));
}

export async function getEventCustomRegistrationFormById(eventId, formId) {
  const doc = await store
    .doc(
      `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}/${formId}`
    )
    .get();

  if (!doc.exists) throw Error(ERRORS.NOT_FOUND);

  return { ...doc.data(), id: doc.id };
}

export async function checkEventCustomRegistrationFormDocumentExistsById(
  eventId,
  formId
) {
  const doc = await store
    .doc(
      `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}/${formId}`
    )
    .get();

  return doc.exists;
}

export async function updateCustomRegistrationFormsSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  changesList.forEach(async changes => {
    const ref = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}/${changes.id}`
    );

    batch.update(ref, {
      id: changes.id,
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    let result = {
      ...changes
    };

    resultList.push(result);
  });

  batch.commit();

  return resultList;
}

export async function createCustomRegistrationForm(data, eventId) {
  const registrationFormsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}`
  );

  const docRef = await registrationFormsCollection.add({
    ...data,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  return {
    id: docRef.id,
    ...data
  };
}

export async function createCustomRegistrationFormWithCustomId(
  data,
  eventId,
  customId
) {
  if (!customId) {
    throw Error('Invalid customer id');
  }
  const registrationFormsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}`
  );

  await registrationFormsCollection.doc(customId).set({
    ...data,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  return {
    id: customId,
    ...data
  };
}

export async function updateCustomRegistrationForm(
  { id, ...changes },
  eventId
) {
  const docRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}/${id}`
  );

  await docRef.set({
    ...changes,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  return {
    id,
    changes
  };
}

export async function deleteCustomRegistrationForm(id, eventId) {
  const collection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${CUSTOM_REGISTRATION_FORMS_COLLECTION}`
  );
  const doc = collection.doc(id);

  await doc.delete();

  return id;
}
