import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { snakeCase } from 'lodash';
import analytics from 'src/utils/analytics';

export default function AnalyticsScreenTracking() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname) {
      // build a unique event based on this screen name
      analytics.logEvent(`page_view_${snakeCase(location.pathname)}`);
    }
  }, [location]);
  return null;
}
