import {
  createSponsor,
  updateSponsor,
  updateSponsorsSort,
  deleteSponsor,
  getSponsorById,
  getSponsors,
  resetSponsors,
} from 'src/actions/sponsorActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const sponsorEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = sponsorEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const sponsorReducer = createReducer(initialState, {
  [createSponsor.pending]: (state, _) => {
    state.loading = true;
  },
  [createSponsor.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorEntityAdapter.upsertOne(state, payload);
  },
  [createSponsor.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateSponsor.pending]: (state, _) => {
    state.loading = true;
  },
  [updateSponsor.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorEntityAdapter.updateOne(state, payload);
  },
  [updateSponsor.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateSponsorsSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateSponsorsSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorEntityAdapter.upsertMany(state, payload);
  },
  [updateSponsorsSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteSponsor.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteSponsor.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorEntityAdapter.removeOne(state, payload);
  },
  [deleteSponsor.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getSponsorById.pending]: (state, _) => {
    state.loading = true;
  },
  [getSponsorById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorEntityAdapter.upsertOne(state, payload);
  },
  [getSponsorById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getSponsors.pending]: (state, _) => {
    state.loading = true;
  },
  [getSponsors.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    sponsorEntityAdapter.upsertMany(state, payload);
  },
  [getSponsors.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetSponsors.type]: (_, __) => {
    return initialState;
  }
});

export default sponsorReducer;
