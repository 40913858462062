import * as routes from 'src/routeConstants';
import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import {
  createCustomFormField,
  deleteCustomFormField,
  getCustomFormFieldsByFormId,
  updateCustomFormField,
  updateCustomFormFieldSort
} from '../core/api/CustomFormFieldsStore';

class CustomFormFieldsService {
  createCustomFormField = (customForm, formId) =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createCustomFormField(customForm, userId, formId, eventId)
        .then(newCustomForm => {
          resolve(newCustomForm);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateCustomFormField = (customForm, formId) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateCustomFormField(customForm, eventId, formId)
        .then(newCustomForm => {
          resolve(newCustomForm);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateCustomFormFieldsSort = (customForm, formId) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateCustomFormFieldSort(customForm, eventId, formId)
        .then(newCustomForm => {
          resolve(newCustomForm);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteCustomFormField = (customFormId, formId) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteCustomFormField(customFormId, eventId, formId)
        .then(customForm => {
          resolve(customForm);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getCustomFormFieldsByFormId = formId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      getCustomFormFieldsByFormId(eventId, formId)
        .then(resolve)
        .catch(error => reject(error.message));
    });
}

export default new CustomFormFieldsService();
