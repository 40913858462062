import firebase from 'firebase';
import moment from 'moment';
import { translate } from 'src/translations/i18n';
import { store } from './firebase';
import { getUserPackage } from './PackageStore';
import { getUserEvents } from './EventStore';
const EVENTS_COLLECTION = 'events';
const TICKETS_COLLECTION = 'tickets';

export async function createTicket(ticket, userId, eventId, timezone) {
  const ticketsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${TICKETS_COLLECTION}`
  );

  moment.tz.setDefault(timezone);
  const startDate = moment(ticket.startDate)
    .startOf('day')
    .toDate();
  const endDate = moment(ticket.endDate)
    .endOf('day')
    .toDate();

  let collectionSize = 0;
  await ticketsCollection.get().then(s => (collectionSize = s.size));

  const ticketRef = await ticketsCollection.add({
    name: ticket.name,
    type: ticket.type,
    description: ticket.description,
    eventAccess: ticket.eventAccess,
    startDate: startDate,
    endDate: endDate,
    price: ticket.price,
    quantity: ticket.quantity,
    available: ticket.quantity,
    selectedDays: ticket.selectedDays,
    selectedStages: ticket.selectedStages,
    selectedAddons: ticket.selectedAddons,
    selectedAllDays: ticket.selectedAllDays,
    selectedAllStages: ticket.selectedAllStages,
    selectedAllAddons: ticket.selectedAllAddons,
    isHidden: ticket.isHidden,
    generateQRCode: ticket.generateQRCode,
    displayRemainingTicketsLabel: ticket.displayRemainingTicketsLabel,
    displaySoldOutLabel: ticket.displaySoldOutLabel,
    isArchived: false,
    isPublished: true,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    showAllRecordedSessions: ticket.showAllRecordedSessions,
    sort: collectionSize,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId,
    status: getStatus(startDate, endDate, ticket.quantity),
    externalPaymentLink: ticket.externalPaymentLink || '',
    useExternalPaymentLink: ticket.useExternalPaymentLink || false,
    ticketPurchaseMessage: ticket.ticketPurchaseMessage || '',
    isPreApproved: ticket.isPreApproved || false,
    showFreeTicketLabel: ticket.showFreeTicketLabel,
    unlimitedSellingTime: ticket.unlimitedSellingTime,
    registrationFormId: ticket.registrationFormId || null
  });
  const ticketDoc = await ticketRef.get();

  await ticketDoc.ref.update({
    id: ticketDoc.id
  });

  return {
    ...ticketDoc.data(),
    id: ticketDoc.id,
    startDate: ticket.startDate.toISOString(),
    endDate: ticket.endDate.toISOString()
  };
}

export async function updateTicket(changes, eventId, timezone) {
  const ticketRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${TICKETS_COLLECTION}/${changes.id}`
  );

  moment.tz.setDefault(timezone);
  const startDate = moment(changes.startDate)
    .startOf('day')
    .toDate();
  const endDate = moment(changes.endDate)
    .endOf('day')
    .toDate();

  await ticketRef.update({
    ...changes,
    quantity: changes.quantity,
    available: changes.quantity,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    startDate: startDate,
    endDate: endDate
  });

  const ticketDoc = await ticketRef.get();

  return {
    id: ticketDoc.id,
    changes: {
      ...ticketDoc.data(),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      status: getStatus(changes.startDate, changes.endDate, changes.quantity)
    }
  };
}

export async function updateTicketsSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  changesList.forEach(async changes => {
    const ticketRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${TICKETS_COLLECTION}/${changes.id}`
    );

    batch.update(ticketRef, {
      ...changes,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      startDate: new Date(changes.startDate),
      endDate: new Date(changes.endDate)
    });

    let result = {
      ...changes,
      startDate: new Date(changes.startDate),
      endDate: new Date(changes.endDate)
    };

    resultList.push(result);
  });

  batch.commit();

  return resultList;
}

export async function updateTicketIsArchived(ticketId, eventId, actionValue) {
  const ticketRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${TICKETS_COLLECTION}/${ticketId}`
  );

  if (actionValue === true) {
    await ticketRef.update({
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: actionValue,
      isPublished: false
    });
  } else {
    await ticketRef.update({
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: actionValue
    });
  }

  const ticketDoc = await ticketRef.get();
  const changes = ticketDoc.data();
  const startDate = getTimestamp(changes.startDate);
  const endDate = getTimestamp(changes.endDate);
  const status = getStatus(startDate, endDate, changes.quantity, actionValue);

  return {
    id: ticketDoc.id,
    changes: {
      ...changes,
      startDate: startDate,
      endDate: endDate,
      status: status
    }
  };
}

export async function updateTicketIsPublished(ticketId, eventId, actionValue) {
  const ticketRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${TICKETS_COLLECTION}/${ticketId}`
  );

  await ticketRef.update({
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    isPublished: actionValue
  });

  const ticketDoc = await ticketRef.get();
  const changes = ticketDoc.data();
  const startDate = getTimestamp(changes.startDate);
  const endDate = getTimestamp(changes.endDate);
  const status = getStatus(startDate, endDate, changes.quantity);

  return {
    id: ticketDoc.id,
    changes: {
      ...changes,
      startDate: startDate,
      endDate: endDate,
      status: status
    }
  };
}

export async function getTicketById(eventId, ticketId) {
  const documentReference = store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(TICKETS_COLLECTION)
    .doc(ticketId);
  let ticket = null;
  ticket = documentReference.get().then(doc => {
    if (doc.exists) {
      const ticket = doc.data();
      if (ticket != null) {
        const startDate = getTimestamp(ticket.startDate);
        const endDate = getTimestamp(ticket.endDate);
        let newTicket = {
          id: ticketId,
          name: ticket.name,
          type: ticket.type,
          description: ticket.description,
          eventAccess: ticket.eventAccess,
          startDate,
          endDate,
          price: ticket.price,
          generateQRCode: ticket.generateQRCode,
          displayRemainingTicketsLabel: ticket.displayRemainingTicketsLabel,
          displaySoldOutLabel: ticket.displaySoldOutLabel,
          quantity: ticket.quantity,
          available: ticket.available,
          selectedDays: ticket.selectedDays,
          selectedStages: ticket.selectedStages,
          selectedAddons: ticket.selectedAddons || [],
          selectedAllDays: ticket.selectedAllDays,
          selectedAllStages: ticket.selectedAllStages,
          selectedAllAddons: ticket.selectedAllAddons,
          showAllRecordedSessions: ticket.showAllRecordedSessions,
          isHidden: ticket.isHidden,
          isArchived: ticket.isArchived,
          isPublished: ticket.isPublished,
          status: getStatus(
            startDate,
            endDate,
            ticket.available,
            ticket.isArchived
          ),
          sort: ticket.sort,
          useExternalPaymentLink: ticket.useExternalPaymentLink || false,
          externalPaymentLink: ticket.externalPaymentLink || '',
          ticketPurchaseMessage: ticket.ticketPurchaseMessage || '',
          isPreApproved: ticket.isPreApproved || false,
          showFreeTicketLabel: ticket.showFreeTicketLabel,
          unlimitedSellingTime: ticket.unlimitedSellingTime || false,
          registrationFormId: ticket.registrationFormId || null
        };
        return newTicket;
      }
    }
  });

  return ticket;
}

export async function deleteTicket(ticketId, eventId) {
  const ticketsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${TICKETS_COLLECTION}`
  );
  const ticketDoc = ticketsCollection.doc(ticketId);

  await ticketDoc.delete();
  return ticketId;
}

export async function getEventTickets(eventId) {
  const documentReference = store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(TICKETS_COLLECTION);
  let tickets = [];
  tickets = documentReference.get().then(snap => {
    const ticketsList = [];
    snap.forEach(doc => {
      if (doc.exists) {
        const data = doc.data();
        const startDate = getTimestamp(data.startDate);
        const endDate = getTimestamp(data.endDate);
        let newTicket = {
          id: doc.id,
          name: data.name,
          type: data.type,
          description: data.description,
          startDate,
          endDate,
          price: data.price,
          quantity: data.quantity,
          eventAccess: data.eventAccess,
          generateQRCode: data.generateQRCode,
          displayRemainingTicketsLabel: data.displayRemainingTicketsLabel,
          displaySoldOutLabel: data.displaySoldOutLabel,
          available: data.available,
          selectedDays: data.selectedDays,
          selectedStages: data.selectedStages,
          selectedAllDays: data.selectedAllDays,
          selectedAllStages: data.selectedAllStages,
          showAllRecordedSessions: data.showAllRecordedSessions,
          isHidden: data.isHidden,
          isArchived: data.isArchived || false,
          isPublished: data.isPublished || false,
          isDisabled: data.isDisabled || false,
          createdDate: data.createdDate,
          status: getStatus(
            startDate,
            endDate,
            data.available,
            data.isArchived,
            data.isDisabled
          ),
          sort: data.sort,
          externalPaymentLink: data.externalPaymentLink || '',
          showFreeTicketLabel: data.showFreeTicketLabel,
          unlimitedSellingTime: data.unlimitedSellingTime || false,
          registrationFormId: data.registrationFormId || ''
        };
        ticketsList.push(newTicket);
      }
    });
    return ticketsList;
  });

  return tickets;
}

export async function getSessionTickets(
  eventId,
  oldStageId,
  newStageId,
  currentTab
) {
  const ticketsSnap = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(TICKETS_COLLECTION)
    .get();

  let tickets = ticketsSnap.docs.map(s => ({
    id: s.data().id,
    name: s.data().name,
    selectedStages: s.data().selectedStages,
    selectedDays: s.data().selectedDays,
    isPublished: s.data().isPublished,
    status: s.data().status
  }));

  let publishedTickets = tickets.filter(s => s.isPublished);
  let filteredTicketsByDay = publishedTickets.filter(
    s => s.selectedDays && s.selectedDays.some(a => a.name === currentTab)
  );
  let filteredTicketsByStage = filteredTicketsByDay.filter(
    s =>
      s.selectedStages &&
      s.selectedStages.some(a => a.id === oldStageId) &&
      s.selectedStages.some(a => a.id !== newStageId)
  );
  let filteredTickets = newStageId
    ? filteredTicketsByStage.filter(
        s =>
          s.selectedStages && s.selectedStages.every(a => a.id !== newStageId)
      )
    : filteredTicketsByStage;

  let filteredTicketIds = filteredTickets.map(s => ({ id: s.id }));
  let ticketsCount = filteredTicketIds.length;
  return ticketsCount;
}

const getTimestamp = date => {
  const d = date;
  date = new firebase.firestore.Timestamp(d.seconds, d.nanoseconds).toDate();
  return date;
};

/**
 * @note  Getting the status of the event tickets.
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} available
 * @param {*} isArchived
 */
const getStatus = (
  startDate,
  endDate,
  available,
  isArchived = false,
  isDisabled = false
) => {
  if (isArchived) return { id: 4, name: translate('tickets.list.isArchived') };

  moment.tz.setDefault('UTC');
  startDate = moment(startDate).tz('UTC', true);
  endDate = moment(endDate).tz('UTC', true);

  const currentDate = moment();
  const startDifference = startDate.diff(currentDate, 'minutes');
  const endDifference = endDate.diff(currentDate, 'minutes');

  if (isDisabled) return { id: 4, name: translate('tickets.list.disabled') };

  if (available === 0)
    return { id: 0, name: translate('tickets.list.soldOut') };
  let status;
  if (startDifference <= 0 && endDifference >= 0)
    status = { id: 1, name: translate('tickets.list.selling') };
  else if (startDifference > 0 && endDifference > 0)
    status = { id: 2, name: translate('tickets.list.upcoming') };
  else if (startDifference < 0 && endDifference < 0)
    status = { id: 3, name: translate('tickets.list.expired') };
  return status;
};
