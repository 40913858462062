import teamService from 'src/services/teamService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const CREATE_TEAM = '@team/create-team';
export const UPDATE_TEAM = '@team/update-team';
export const DELETE_TEAM = '@team/delete-team';
export const GET_TEAMS = '@team/get-teams';
export const GET_JOIN_REQUESTS = `@team/get-join-requests`;
export const GET_TEAM_MEMBER = '@team/get-team-member';
export const RESEND_EMAIL = '@team/resend-email';
export const RESET_TEAMS = '@team/reset-teams';
export const GET_TEAM_OWNERS_BY_USER_ID = '@team/get-team-owners-by-user-id';

export const createTeamMember = createAsyncThunk(CREATE_TEAM, async team => {
  const newTeamMember = await teamService.createTeamMember(team);
  return newTeamMember;
});

export const acceptTeamMemberRequest = createAsyncThunk(
  UPDATE_TEAM,
  async teamId => {
    const updated = await teamService.updateTeamMemberStatus(teamId);
    return updated;
  }
);

export const deleteTeamMember = createAsyncThunk(DELETE_TEAM, async teamId => {
  await teamService.deleteTeamMember(teamId);
  return teamId;
});

export const getTeamMembers = createAsyncThunk(GET_TEAMS, async () => {
  const teams = await teamService.getTeamMembers();
  return teams;
});

export const getTeamMemberById = createAsyncThunk(
  GET_TEAM_MEMBER,
  async teamMemberId => {
    const team = await teamService.getTeamMemberById(teamMemberId);
    return team;
  }
);

export const getTeamIncomingJoinRequests = createAsyncThunk(
  GET_JOIN_REQUESTS,
  async () => {
    const teams = await teamService.getTeamIncomingJoinRequests();
    return teams;
  }
);

export const resendEmail = createAsyncThunk(RESEND_EMAIL, async team => {
  return await teamService.resendEmail(team);
});

export const getTeamOwnersByUserId = createAsyncThunk(
  GET_TEAM_OWNERS_BY_USER_ID,
  async userId => {
    return await teamService.getTeamOwnersByUserId(userId);
  }
);

export const resetTeamMembers = createAction(RESET_TEAMS);
