import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';
import {  useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  noBanner: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  hasBanner: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 88,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 108,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 128,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    minHeight: '100%',
    overflow: 'auto'
  },
  menuButtom: {
    color: theme.palette.primary.contrastText
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const account = useSelector(state => state.account);

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={
            account.user !== null && account.user.email_verified
              ? classes.noBanner
              : classes.hasBanner
          }>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
