import React from 'react';

function Settings() {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
    <path d="M22.51,10.25l-2.41-.4a8,8,0,0,0-.85-2l1.42-2a.58.58,0,0,0-.06-.75L18.94,3.39a.56.56,0,0,0-.74-.06l-2,1.42a8,8,0,0,0-2-.85l-.4-2.41A.59.59,0,0,0,13.18,1H10.82a.58.58,0,0,0-.57.49L9.85,3.9a8,8,0,0,0-2,.85l-2-1.42a.58.58,0,0,0-.75.06L3.39,5.05a.58.58,0,0,0-.06.75l1.42,2A8,8,0,0,0,3.9,9.85l-2.41.4a.58.58,0,0,0-.49.57v2.36a.58.58,0,0,0,.49.57l2.41.41a8.09,8.09,0,0,0,.85,2l-1.42,2a.56.56,0,0,0,.06.74l1.67,1.67a.58.58,0,0,0,.75.06l2-1.42a8,8,0,0,0,2.05.85l.4,2.41a.58.58,0,0,0,.57.49h2.36a.59.59,0,0,0,.57-.49l.4-2.41a8,8,0,0,0,2-.85l2,1.42a.56.56,0,0,0,.74-.06l1.67-1.67a.56.56,0,0,0,.06-.74l-1.42-2a8.09,8.09,0,0,0,.85-2l2.41-.41a.58.58,0,0,0,.49-.57V10.82A.58.58,0,0,0,22.51,10.25Zm-.67,2.44-2.31.38a.59.59,0,0,0-.47.45,7.17,7.17,0,0,1-1,2.39.59.59,0,0,0,0,.66l1.36,1.9-1,1-1.9-1.36a.59.59,0,0,0-.66,0,7.25,7.25,0,0,1-2.39,1,.59.59,0,0,0-.45.47l-.38,2.31H11.31l-.38-2.31a.59.59,0,0,0-.45-.47,7.25,7.25,0,0,1-2.39-1,.59.59,0,0,0-.66,0l-1.9,1.36-1-1,1.36-1.9a.59.59,0,0,0,0-.66,7.17,7.17,0,0,1-1-2.39.59.59,0,0,0-.47-.45l-2.31-.38V11.31l2.31-.38a.59.59,0,0,0,.47-.45,7.17,7.17,0,0,1,1-2.39.59.59,0,0,0,0-.66L4.56,5.53l1-1,1.9,1.36a.59.59,0,0,0,.66,0,7.17,7.17,0,0,1,2.39-1,.59.59,0,0,0,.45-.47l.38-2.31h1.38l.38,2.31a.59.59,0,0,0,.45.47,7.17,7.17,0,0,1,2.39,1,.59.59,0,0,0,.66,0l1.9-1.36,1,1-1.36,1.9a.59.59,0,0,0,0,.66,7.17,7.17,0,0,1,1,2.39.59.59,0,0,0,.47.45l2.31.38Z" fill="currentColor"/>
    <path d="M12,7.93A4.07,4.07,0,1,0,16.07,12,4.07,4.07,0,0,0,12,7.93Zm0,7A2.91,2.91,0,1,1,14.91,12,2.92,2.92,0,0,1,12,14.91Z" fill="currentColor"/>
  </svg>
  );
}

export default Settings;