import {
  Breadcrumbs,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as routes from 'src/routeConstants';
import { getPageNameById } from 'src/selectors/landingPageSectionSelectors';
import { translate } from 'src/translations/i18n';

const useStyles = makeStyles(() => ({
  root: {}
}));

const definedRoutes = {
  [`${routes.EVENT}`]: 'dashboard',
  [`${routes.THEME}`]: 'theme',
  [`${routes.LANDING_PAGE}`]: 'landingPage'
};

function Header({ className, ...rest }) {
  const classes = useStyles();
  const location = useLocation();
  const paths = location.pathname.split('/').slice(1);

  const lastUrlFragment = paths[paths.length - 1];
  const page = useSelector(state => getPageNameById(state, lastUrlFragment));

  const breadCrumbs = paths
    .map((path, index, paths) => {
      const nextPath = paths[index + 1];
      let name = definedRoutes[`/${path}`];
      if (!name && index === paths.length - 1 && page) {
        name = page.type;
      }
      if (!name) return null;
      if (!nextPath) return { name };

      return {
        name,
        to: paths.reduce((to, _path, _index, _paths) => {
          if (_index <= index) {
            if (name === definedRoutes[`${routes.EVENT}`]) {
              return `${to}/${_path}/${nextPath}`;
            }
            return `${to}/${_path}`;
          } else {
            return to;
          }
        }, '')
      };
    })
    .filter(value => value);

  return (
    <Grid
      alignItems="center"
      container
      justify="space-between"
      spacing={3}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadCrumbs.map(breadCrumb =>
            breadCrumb.to ? (
              <Link
                key={breadCrumb.name}
                variant="body1"
                color="inherit"
                to={breadCrumb.to}
                component={RouterLink}
              >
                {translate(`breadcrumbs.${breadCrumb.name}`)}
              </Link>
            ) : (
              <Typography
                key={breadCrumb.name}
                variant="body1"
                color="textPrimary"
              >
                {translate(`breadcrumbs.${breadCrumb.name}`)}
              </Typography>
            )
          )}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
