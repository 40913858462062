import { combineReducers } from 'redux';
import accountReducer from 'src/reducers/accountReducer';
import attendeesReducer from 'src/reducers/attendeesReducer';
import benefitReducer from 'src/reducers/benefitReducer';
import checklistReducer from 'src/reducers/checklistReducer';
import dialogReducer from 'src/reducers/dialogReducer';
import domainReducer from 'src/reducers/domainReducer';
import eventReducer from 'src/reducers/eventReducer';
import landingPageSectionReducer from 'src/reducers/landingPageSectionReducer';
import legalInformationReducer from 'src/reducers/legalInformationReducer';
import integrationsReducer from 'src/reducers/integrationsReducer';
import billingInformationReducer from 'src/reducers/billingReducer';
import letterReducer from 'src/reducers/letterReducer';
import promoCodeReducer from 'src/reducers/promoCodeReducer';
import sessionReducer from 'src/reducers/sessionReducer';
import speakerReducer from 'src/reducers/speakerReducer';
import settingsReducer from 'src/reducers/settingsReducer';
import sponsorCategoryReducer from 'src/reducers/sponsorCategoryReducer';
import boothReducer from 'src/reducers/boothReducer';
import sponsorReducer from 'src/reducers/sponsorReducer';
import networkReducer from 'src/reducers/networkReducer';
import stageReducer from 'src/reducers/stageReducer';
import testimonialReducer from 'src/reducers/testimonialReducer';
import ticketReducer from 'src/reducers/ticketReducer';
import videoReducer from 'src/reducers/videoReducer';
import customFormFieldsReducer from 'src/reducers/customFormFieldsReducer';
import statsReducer from 'src/reducers/statsReducer';
import teamReducer from 'src/reducers/teamReducer';
import eventSettingsReducer from 'src/reducers/eventSettingsReducer';
import addonReducer from 'src/reducers/addonReducer';
import customRegistrationFormsReducer from 'src/reducers/customRegistrationFormsReducer';
import packageReducer from 'src/reducers/packageReducer';
import emailTemplateReducer from 'src/reducers/emailTemplateReducer';
export const initialRootState = {};

const rootReducer = combineReducers({
  account: accountReducer,
  addons: addonReducer,
  attendees: attendeesReducer,
  benefit: benefitReducer,
  billingInformation: billingInformationReducer,
  booth: boothReducer,
  checklist: checklistReducer,
  customFormFields: customFormFieldsReducer,
  customRegistrationForms: customRegistrationFormsReducer,
  dialog: dialogReducer,
  domain: domainReducer,
  emailTemplates: emailTemplateReducer,
  event: eventReducer,
  eventSettings: eventSettingsReducer,
  integrationTrackingCodes: integrationsReducer,
  legalInformation: legalInformationReducer,
  letter: letterReducer,
  network: networkReducer,
  package: packageReducer,
  promoCode: promoCodeReducer,
  section: landingPageSectionReducer,
  session: sessionReducer,
  settings: settingsReducer,
  speaker: speakerReducer,
  sponsor: sponsorReducer,
  sponsorCategory: sponsorCategoryReducer,
  stage: stageReducer,
  stats: statsReducer,
  team: teamReducer,
  testimonial: testimonialReducer,
  ticket: ticketReducer,
  video: videoReducer
});

export default rootReducer;
