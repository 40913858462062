import { createReducer } from '@reduxjs/toolkit';
import {
  createBillingInformation,
  getBillingInformation,
  updateBillingInformation,
  updatePaymentInformation,
  getUserBillingFlags,
  resetBilling
} from 'src/actions/billingActions';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  userBillingFlagsLoading: false,
  userBillingFlagsLoading: false,
  userBillingFlagsError: null,
  billingInformation: {},
  userBillingFlags: null
};

const billingInformationSectionReducer = createReducer(initialState, {
  [createBillingInformation.pending]: (state, _) => {
    state.loading = true;
  },
  [createBillingInformation.fulfilled]: (state, { payload }) => {
    state.loading = false;

    state.billingInformation = {
      ...payload
    };
  },
  [createBillingInformation.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getBillingInformation.pending]: state => {
    state.loading = true;
  },
  [getBillingInformation.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.billingInformation = {
      ...payload
    };
    state.loaded = true;
  },
  [getBillingInformation.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [updateBillingInformation.pending]: (state, _) => {
    state.loading = true;
  },
  [updateBillingInformation.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.billingInformation = {
      ...payload
    };
  },
  [updateBillingInformation.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },

  [updatePaymentInformation.pending]: (state, _) => {
    state.loading = true;
  },
  [updatePaymentInformation.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.billingInformation = {
      ...state.billingInformation,
      ...payload
    };
  },
  [updatePaymentInformation.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getUserBillingFlags.pending]: (state, _) => {
    state.userBillingFlagsLoading = true;
  },
  [getUserBillingFlags.fulfilled]: (state, { payload }) => {
    state.userBillingFlagsLoading = false;
    state.userBillingFlags = payload;
  },
  [getUserBillingFlags.rejected]: (state, { error }) => {
    state.userBillingFlagsLoading = false;
    state.userBillingFlagsError = error;
  },
  [resetBilling.type]: (_, __) => {
    return initialState;
  }
});

export default billingInformationSectionReducer;
