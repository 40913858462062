import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import checklistService from 'src/services/checklistService';

export const GET_CHECKLIST = '@checklist/get-checklist';
export const RESET_CHECKLIST = '@checklist/reset-checklist';
export const CREATE_CHECKLIST = '@checklist/create-checklist';
export const UPDATE_CHECKLIST = '@checklist/update-checklist';
export const DELETE_CHECKLIST = '@checklist/delete-checklist';
export const UPDATE_CHECKLIST_SORT = '@checklist/update-checklist-sort';
export const UPDATE_CHECKLIST_TITLE = '@checklist/update-checklist-title';
export const GET_CHECKLIST_TITLE = '@checklist/get-checklist-title';

export const createChecklist = createAsyncThunk(
  CREATE_CHECKLIST,
  async checklist => {
    const newChecklist = await checklistService.createChecklist(checklist);
    return newChecklist;
  }
);

export const updateChecklist = createAsyncThunk(
  UPDATE_CHECKLIST,
  async changes => {
    const change = await checklistService.updateChecklist(changes);
    return change;
  }
);

export const updateChecklistSort = createAsyncThunk(
  UPDATE_CHECKLIST_SORT,
  async changes => {
    const change = await checklistService.updateChecklistSort(changes);
    return change;
  }
);

export const deleteChecklist = createAsyncThunk(
  DELETE_CHECKLIST,
  async checklistId => {
    await checklistService.deleteChecklist(checklistId);
    return checklistId;
  }
);

export const getChecklist = createAsyncThunk(GET_CHECKLIST, async () => {
  const checklist = await checklistService.getEventChecklist();
  return { checklist: checklist };
});

export const resetChecklist = createAction(RESET_CHECKLIST);
