import { createAsyncThunk } from '@reduxjs/toolkit';
import { ALREADY_REGISTERED_DOMAIN } from 'src/core/api/DomainStore';
import { functions } from 'src/core/api/firebase';
import authService from 'src/services/authService';
import domainService from 'src/services/domainService';
import { translate } from 'src/translations/i18n';
import { updateEventPartial } from './eventActions';

export const CREATE_DOMAIN = '@domain/create-domain';
export const ATTACH_DOMAIN = '@domain/attach-domain';
export const CREATE_DOMAIN_START = '@domain/create-domain-start';
export const CREATE_DOMAIN_FAILURE = '@domain/create-domain-failure';

export const UPDATE_DOMAIN = '@domain/update-domain';
export const UPDATE_DOMAIN_START = '@domain/update-domain-start';
export const UPDATE_DOMAIN_FAILURE = '@domain/update-domain-failure';

export const DELETE_DOMAIN = '@domain/delete-domain';
export const DELETE_DOMAIN_START = '@domain/delete-domain-start';
export const DELETE_DOMAIN_FAILURE = '@domain/delete-domain-failure';

export const GET_DOMAIN = '@domain/get-domain';
export const GET_DOMAIN_START = '@domain/get-domain-start';
export const GET_DOMAIN_FAILURE = '@domain/get-domain-failure';

export const GET_DOMAINS = '@domain/get-domains';
export const GET_DOMAINS_START = '@domain/get-domains-start';
export const GET_DOMAINS_FAILURE = '@domain/get-domains-failure';

export const VERIFY_DOMAIN = '@domain/verify-domain';
export const VERIFY_DOMAIN_START = '@domain/verify-domain-start';
export const VERIFY_DOMAIN_FAILURE = '@domain/verify-domain-failure';

export const REGISTER_DOMAIN = '@domain/register-domain';
export const REGISTER_DOMAIN_START = '@domain/register-domain-start';
export const REGISTER_DOMAIN_FAILURE = '@domain/register-domain-failure';

export const GET_ATTACHABLE_DOMAINS = '@domain/get_attachable_domains';
export const GET_ATTACHABLE_DOMAINS_START =
  '@domain/get_attachable_domains-start';
export const GET_ATTACHABLE_DOMAINS_FAILURE =
  '@domain/get_attachable_domains-failure';

export const RENEW_DOMAIN_START = '@domain/renew-domain-start';
export const RENEW_DOMAIN_END = '@domain/renew-domain-end';
export const RENEW_DOMAIN_FAILURE = '@domain/renew-domain-failure';

export const DOMAIN_ASSOCIATION_STATUS = {
  DEFAULT: 'default',
  SETTINGS_REQUIRED: 'settings_required',
  VERIFIED: 'verified',
  SSL_CERTIFIED: 'ssl_certified',
  SSL_CERTIFICATION_FAILED: 'ssl_certification_failed'
};

export function createDomain(newDomain, eventId) {
  return async dispatch => {
    dispatch({
      type: CREATE_DOMAIN_START
    });
    try {
      const saved = await domainService.createDomain(newDomain);
      if (typeof saved === 'string' && saved === ALREADY_REGISTERED_DOMAIN) {
        throw translate(`domains.errors.${ALREADY_REGISTERED_DOMAIN}`);
      } else {
        dispatch({
          type: CREATE_DOMAIN,
          payload: saved
        });
        if (newDomain.primaryDomain) {
          dispatch(
            updateEventPartial({
              id: eventId,
              changes: { defaultCustomDomain: newDomain.name }
            })
          );
        }
      }
    } catch (error) {
      dispatch({ type: CREATE_DOMAIN_FAILURE, message: error.message });
      throw error;
    }
  };
}

export function deleteDomain(domain, isPrimaryDomain, eventId) {
  return async dispatch => {
    dispatch({
      type: DELETE_DOMAIN_START
    });
    try {
      const accessToken = authService.getAccessToken();
      await domainService.deleteDomain(domain, accessToken, eventId);
      if (isPrimaryDomain) {
        dispatch(
          updateEventPartial({
            id: eventId,
            changes: {
              defaultCustomDomain: domain.primaryDomain ? domain.name : ''
            }
          })
        );
      }
      dispatch({
        type: DELETE_DOMAIN,
        payload: domain.id
      });
      return Promise.resolve();
    } catch (error) {
      dispatch({ type: DELETE_DOMAIN_FAILURE });
      throw error;
    }
  };
}

export const updateDomainPartial = createAsyncThunk(
  UPDATE_DOMAIN,
  async changes => {
    const change = await domainService.updateDomain(changes);
    return change;
  }
);

export function verifyDomain(domain) {
  return async dispatch => {
    dispatch({
      type: VERIFY_DOMAIN_START
    });
    try {
      const accessToken = authService.getAccessToken();
      const verifiedObj = await domainService.verifyDomain(domain, accessToken);
      if (verifiedObj) {
        dispatch({
          type: VERIFY_DOMAIN,
          //{ ...domain, status: DOMAIN_ASSOCIATION_STATUS.VERIFIED }
          payload: verifiedObj
        });
      } else {
        dispatch({
          type: VERIFY_DOMAIN_FAILURE
        });
      }
      return Promise.resolve(verifiedObj);
    } catch (error) {
      dispatch({ type: VERIFY_DOMAIN_FAILURE });
      throw error;
    }
  };
}

export function registerDomain(domain) {
  return async dispatch => {
    dispatch({
      type: REGISTER_DOMAIN_START
    });
    try {
      const accessToken = authService.getAccessToken();
      const registeredObj = await domainService.registerDomain(
        domain,
        accessToken
      );
      dispatch({
        type: REGISTER_DOMAIN,
        //payload: { ...domain, status: DOMAIN_ASSOCIATION_STATUS.SSL_CERTIFIED }
        payload: registeredObj
      });
      return Promise.resolve(registeredObj);
    } catch (error) {
      dispatch({ type: REGISTER_DOMAIN_FAILURE });
      throw error;
    }
  };
}

export function getEventDomains() {
  return async dispatch => {
    dispatch({
      type: GET_DOMAINS_START
    });
    try {
      const response = await domainService.getEventDomains();
      dispatch({
        type: GET_DOMAINS,
        payload: response
      });
    } catch (error) {
      dispatch({ type: GET_DOMAINS_FAILURE });
      throw error;
    }
  };
}

export function getDomainById(domainId) {
  return async dispatch => {
    dispatch({
      type: GET_DOMAIN_START
    });
    try {
      const domain = await domainService.getDomainById(domainId);
      dispatch({
        type: GET_DOMAIN,
        payload: domain
      });
      return Promise.resolve();
    } catch (error) {
      dispatch({ type: GET_DOMAIN_FAILURE });
      throw error;
    }
  };
}

export function getAttachableDomains() {
  return async dispatch => {
    dispatch({
      type: GET_ATTACHABLE_DOMAINS_START
    });
    try {
      const attachableDomains = await domainService.getAttachableDomains();
      dispatch({
        type: GET_ATTACHABLE_DOMAINS,
        payload: attachableDomains
      });
    } catch (error) {
      dispatch({ type: GET_ATTACHABLE_DOMAINS_FAILURE });
      throw error;
    }
  };
}

export function attachExistingDomain(newDomain, eventId) {
  const { domainName, domainId, primaryDomain } = newDomain;
  return async dispatch => {
    dispatch({
      type: CREATE_DOMAIN_START
    });
    try {
      const attached = await domainService.attachDomain(domainId, eventId);
      dispatch({
        type: ATTACH_DOMAIN,
        payload: attached
      });
      if (primaryDomain) {
        dispatch(
          updateEventPartial({
            id: eventId,
            changes: { defaultCustomDomain: domainName }
          })
        );
      }
    } catch (error) {
      dispatch({ type: CREATE_DOMAIN_FAILURE, message: error.message });
      throw error;
    }
  };
}

export function renewDomainCertificate(domain) {
  return async dispatch => {
    dispatch({
      type: RENEW_DOMAIN_START
    });
    try {
      const renewed = await functions.httpsCallable(
        'domains-renewDomainCertificate'
      )({
        domain: domain.name
      });
      dispatch({
        type: RENEW_DOMAIN_END,
        payload: domain.id
      });
      return Promise.resolve(renewed);
    } catch (error) {
      dispatch({ type: RENEW_DOMAIN_FAILURE });
      throw error;
    }
  };
}
