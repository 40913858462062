import { Typography, Link, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { translate } from 'src/translations/i18n';
import { requestEmailVerification } from 'src/actions/accountActions';

const useStyles = makeStyles(() => ({
  root: {
    color: 'rgb(255, 255, 255)',
    textAlign: 'center',
    textTransform: 'none',
    wordBreak: 'break-word',
    backgroundColor: 'rgb(255, 130, 9)',
    padding: '2px 16px',
    position: 'relative',
    zIndex: '2000'
  }
}));

function EmailVerificationBar({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);
  const [sentEmail, setSentEmail] = useState(false);

  const handleResendEmailVerification = async e => {
    e.preventDefault();
    dispatch(requestEmailVerification())
      .then(() => setSentEmail(true))
      .catch(() => setSentEmail(false));
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Typography variant="body2" color="inherit">
        {`${translate('topBar.verifyEmail')} '${account.user.email}'. `}
        {!sentEmail && (
          <>
            {`${translate('topBar.havingTrouble')} `}
            <Link
              color="inherit"
              underline="always"
              href="#"
              onClick={handleResendEmailVerification}
              target="_blank"
            >
              {translate('topBar.resentEmail')}
            </Link>
          </>
        )}
      </Typography>
    </div>
  );
}

EmailVerificationBar.propTypes = {
  className: PropTypes.string
};

export default EmailVerificationBar;
