import { DOMAIN_ASSOCIATION_STATUS } from 'src/actions/domainActions';

/**
 * @note Launching an event preview - application-wide solution
 * Takes into account: defaultCustomDomain, eventId/custom slug and additional url/url-fragment;
 * @param {*} event
 * @param {*} eventSlug
 * @param {*} domains
 * @param {*} additionalLink
 */
export const launchPreview = (event, eventSlug, domains, additionalLink) => {
  let newWindow = '';

  if (!!additionalLink && additionalLink.includes('https://')) {
    newWindow = additionalLink;
  } else {
    const subPage = !!additionalLink ? additionalLink : '';
    newWindow = `${process.env.REACT_APP_CLIENT_URL}/${eventSlug}/${subPage}`;

    if (event && event.defaultCustomDomain) {
      domains.map(domain => {
        if (
          domain.name === event.defaultCustomDomain &&
          domain.status === DOMAIN_ASSOCIATION_STATUS.SSL_CERTIFIED
        ) {
          newWindow = `https://${event.defaultCustomDomain}/${eventSlug}/${subPage}`;
        }
      });
    }
  }

  window.open(newWindow, '_blank');
};
