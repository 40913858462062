import React from 'react';

function Calendar() {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
    <path d="M17.62,3.43H17V1.57A.57.57,0,0,0,16.43,1a.58.58,0,0,0-.58.57V3.43H8.15V1.57A.58.58,0,0,0,7.57,1,.57.57,0,0,0,7,1.57V3.43H6.38A4.17,4.17,0,0,0,2.21,7.59V18.83A4.17,4.17,0,0,0,6.38,23H17.62a4.17,4.17,0,0,0,4.17-4.17V7.59A4.17,4.17,0,0,0,17.62,3.43Zm3,15.4a3,3,0,0,1-3,3H6.38a3,3,0,0,1-3-3V7.59a3,3,0,0,1,3-3H7V6.52a.57.57,0,0,0,.57.57.58.58,0,0,0,.58-.57V4.57h7.7V6.52a.58.58,0,0,0,.58.57A.57.57,0,0,0,17,6.52V4.57h.62a3,3,0,0,1,3,3Z" fill="currentColor"/>
  </svg>
  );
}

export default Calendar;
