import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  updateEventChatSettings,
  updateEventQASettings,
  updateEventPeopleSettings,
  getEventSettings,
  resetEventSettings
} from 'src/actions/eventSettingsActions';

export const eventSettingsEntityAdapter = createEntityAdapter();
const initialState = eventSettingsEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const eventSettingsReducer = createReducer(initialState, {
  [updateEventChatSettings.pending]: (state, _) => {
    state.loading = true;
  },
  [updateEventChatSettings.fulfilled]: (state, { payload }) => {
    state.loading = false;
    eventSettingsEntityAdapter.updateOne(state, payload);
  },
  [updateEventChatSettings.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },

  [updateEventQASettings.pending]: (state, _) => {
    state.loading = true;
  },
  [updateEventQASettings.fulfilled]: (state, { payload }) => {
    state.loading = false;
    eventSettingsEntityAdapter.updateOne(state, payload);
  },
  [updateEventQASettings.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },

  [updateEventPeopleSettings.pending]: (state, _) => {
    state.loading = true;
  },
  [updateEventPeopleSettings.fulfilled]: (state, { payload }) => {
    state.loading = false;
    eventSettingsEntityAdapter.updateOne(state, payload);
  },
  [updateEventPeopleSettings.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },

  [getEventSettings.pending]: (state, _) => {
    state.loading = true;
  },
  [getEventSettings.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    eventSettingsEntityAdapter.upsertMany(state, payload);
  },
  [getEventSettings.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetEventSettings.type]: (_, __) => {
    return initialState;
  }
});

export default eventSettingsReducer;
