import firebase from 'firebase';
import { store, storage } from './firebase';

const EVENTS_COLLECTION = 'events';
const BENEFITS_COLLECTION = 'benefits';

export async function createBenefit(
  { photoUrl, photoRaw, title, description },
  userId,
  eventId
) {
  const benefitsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${BENEFITS_COLLECTION}`
  );

  let collectionSize = 0;
  await benefitsCollection.get().then(s => (collectionSize = s.size));

  let newBenefit = {
    title,
    description,
    photoUrl: '',
    id: '',
    sort: collectionSize,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  };

  await benefitsCollection
    .add(newBenefit)
    .then(s => (newBenefit = { ...newBenefit, id: s.id }));
  const benefitDoc = benefitsCollection.doc(newBenefit.id);

  if (photoUrl !== '') {
    const imageName = `events/${eventId}/benefits/${title}`;
    const uploadTask = await storage.ref(imageName).put(photoRaw);

    const downloadUrl = await uploadTask.ref.getDownloadURL();
    newBenefit = { ...newBenefit, photoUrl: downloadUrl };
    await benefitDoc.update({
      photoUrl: downloadUrl,
      id: newBenefit.id,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
    return newBenefit;
  }
  await benefitDoc.update({
    id: newBenefit.id,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });
  return newBenefit;
}

export async function getEventBenefits(eventId) {
  const benefitDocs = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(BENEFITS_COLLECTION)
    .get();

  return benefitDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export async function deleteBenefit(benefitId, eventId) {
  const benefitsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${BENEFITS_COLLECTION}`
  );
  const benefitDoc = benefitsCollection.doc(benefitId);

  await benefitDoc.delete();

  return benefitId;
}

export async function getBenefitById(eventId, benefitId) {
  const benefitDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${BENEFITS_COLLECTION}`)
    .doc(benefitId);
  const benefit = await benefitDoc.get();
  if (!benefit.exists) return null;
  return { id: benefit.id, ...benefit.data() };
}

export async function updateBenefit(
  { id, photoRaw, title, description, photoUrl },
  eventId
) {
  const benefitDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${BENEFITS_COLLECTION}`)
    .doc(id);

  await benefitDoc.update({
    title,
    description,
    photoUrl,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  let updatedBenefit = {
    id,
    title,
    description
  };
  if (photoRaw !== '') {
    const imageName = `events/${eventId}/benefits/${title}`;
    const uploadTask = await storage.ref(imageName).put(photoRaw);
    const downloadUrl = await uploadTask.ref.getDownloadURL();
    updatedBenefit = {
      ...updatedBenefit,
      photoUrl: downloadUrl
    };
    await benefitDoc.update({
      photoUrl: downloadUrl,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  return updatedBenefit;
}

export async function updateBenefitsSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  changesList.forEach(async changes => {
    const benefitRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${BENEFITS_COLLECTION}/${changes.id}`
    );

    batch.update(benefitRef, {
      id: changes.id,
      title: changes.title,
      description: changes.description,
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    let result = {
      ...changes
    };

    resultList.push(result);
  });

  batch.commit();

  return resultList;
}
