import React from 'react';

function Globe() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
      <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm9.75,10.39H16.4c-.08-3.46-.79-7-2.09-8.88A9.78,9.78,0,0,1,21.75,11.39ZM12,21.78c-1.45,0-3.06-3.88-3.18-9.17h6.36C15.06,17.9,13.45,21.78,12,21.78ZM8.82,11.39C8.94,6.1,10.55,2.22,12,2.22s3.06,3.88,3.18,9.17Zm.87-8.88c-1.3,1.92-2,5.42-2.09,8.88H2.25A9.78,9.78,0,0,1,9.69,2.51ZM2.25,12.61H7.6c.08,3.46.79,7,2.09,8.88A9.78,9.78,0,0,1,2.25,12.61Zm12.06,8.88c1.3-1.92,2-5.42,2.09-8.88h5.35A9.78,9.78,0,0,1,14.31,21.49Z" fill="currentColor"/>
    </svg>
  );
}

export default Globe;
