import { translate } from 'src/translations/i18n';
import {
  getTermsAndConditions,
  getDefaultBillingFees,
  getBillingFeesByPackage,
  getCountryTaxes
} from '../core/api/SettingsStore';

class SettingsService {
  getTerms = () =>
    new Promise((resolve, reject) => {
      getTermsAndConditions()
        .then(terms => {
          if (terms != null) {
            resolve(terms);
          } else {
            reject(translate('termsConditions.error'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getDefaultBillingFees = () =>
    new Promise((resolve, reject) => {
      getDefaultBillingFees()
        .then(fees => {
          resolve(fees);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getBillingFeesByPackage = packageId =>
    new Promise((resolve, reject) => {
      getBillingFeesByPackage(packageId)
        .then(fees => {
          resolve(fees);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getCountryTaxes = () =>
    new Promise((resolve, reject) => {
      getCountryTaxes()
        .then(taxes => {
          resolve(taxes);
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new SettingsService();
