import { Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSections } from 'src/actions/landingPageSectionActions';
import Page from 'src/components/Page';
import { translate } from 'src/translations/i18n';
import BillingDetailsVerificationBar from '../TicketsLayout/BillingDetailsVerificationBar';
import Header from './Header';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function LandingPageLayout({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSections());
  }, []);

  return (
    <>
      <BillingDetailsVerificationBar />
      <Page className={classes.root} title={translate('pageTitle.landingPage')}>
        <Container maxWidth="lg">
          <Header />
          {children}
        </Container>
      </Page>
    </>
  );
}

LandingPageLayout.propTypes = {
  children: PropTypes.any
};

export default LandingPageLayout;
