import firebase from 'firebase';
import { functions, store } from './firebase';
import { asyncForEach } from 'src/utils/asyncUtils';
import _ from 'lodash';

const USERS_COLLECTION = 'users';
const ATTENDEES_COLLECTION = 'attendees';
const TICKETS_COLLECTION = 'tickets';
const TEAMS_COLLECTION = 'teams';
const EVENTS = 'events';
const ATTENDEES_STATS_COLLECTION = 'attendeesStats';

export async function getAttendees(userId, sharedEvents = true) {
  const teamsDocumentReference = await store
    .collection(TEAMS_COLLECTION)
    .where('userId', '==', userId)
    .get();

  let teamOwnerIds = [];
  teamsDocumentReference.docs.map(doc => {
    const membership = doc.data();

    if (membership.status && membership.status.name === 'Accepted') {
      teamOwnerIds.push(membership.teamOwnerId);
    }
  });

  let attendees = [];
  let events = [];

  try {
    const userDocumentReference = store
      .collection(USERS_COLLECTION)
      .doc(userId);
    const userDetails = await userDocumentReference.get();
    let eventsForThisUser = userDetails.get(EVENTS) || [];
    if (sharedEvents) {
      if (teamOwnerIds.length !== 0) {
        await asyncForEach(teamOwnerIds, async ownerUserId => {
          const userDocRef = store
            .collection(USERS_COLLECTION)
            .doc(ownerUserId);
          const user = await userDocRef.get();
          const sharedEvents = user.get(EVENTS);
          eventsForThisUser = eventsForThisUser.concat(sharedEvents);
        });
      }
    }
    if (eventsForThisUser) {
      await asyncForEach(eventsForThisUser, async event => {
        const tickets = [];
        const attendeesCollection = await store
          .collection(ATTENDEES_COLLECTION)
          .where('eventId', '==', event.eventId)
          .get();

        attendeesCollection.docs.map(doc => {
          const newAttendee = {
            id: doc.id,
            ...doc.data()
          };
          attendees.push(newAttendee);
          tickets.push({
            name: newAttendee.ticketName,
            id: newAttendee.ticketId,
            price: newAttendee.ticketPrice,
            basePrice: newAttendee.basePrice,
            purchaseDate: newAttendee.purchaseDate
          });
        });
        if (attendeesCollection.docs.length) {
          events.push({ ...event, tickets });
        }
      });
    }
    return { events, attendees };
  } catch (error) {
    throw new Error(error);
  }
}

export async function getAttendeeById(attendeeId) {
  const attendeeDoc = store.collection(ATTENDEES_COLLECTION).doc(attendeeId);
  const attendee = await attendeeDoc.get();
  if (!attendee.exists) return null;
  return { id: attendee.id, ...attendee.data() };
}
export async function activateUserTicket(attendee) {
  try {
    const userTicketRef = store.doc(
      `${USERS_COLLECTION}/${attendee.attendeeId}/${TICKETS_COLLECTION}/${attendee.attendeeTicketId}`
    );
    if (userTicketRef) {
      await userTicketRef.update({
        isPending: false
      });
    }

    const attendeeRef = store.doc(`${ATTENDEES_COLLECTION}/${attendee.id}`);
    await attendeeRef.update({
      ticketIsPending: false
    });
  } catch (error) {
    throw error;
  }

  return {
    id: attendee.id,
    changes: {
      ticketIsPending: false
    }
  };
}

export async function activateUsers(attendees) {
  await Promise.all(
    attendees.map(async attendee => {
      const batch = store.batch();
      try {
        batch.set(
          store.doc(
            `${USERS_COLLECTION}/${attendee.attendeeId}/${TICKETS_COLLECTION}/${attendee.attendeeTicketId}`
          ),
          {
            isPending: false
          },
          { merge: true }
        );

        batch.set(
          store.doc(`${ATTENDEES_COLLECTION}/${attendee.id}`),
          {
            ticketIsPending: false
          },
          { merge: true }
        );
        const { eventId, ticketId, organizationUserId } = attendee;
        batch.set(
          store.collection(ATTENDEES_STATS_COLLECTION).doc(organizationUserId),
          {
            totalActiveCount: firebase.firestore.FieldValue.increment(1),
            totalPendingCount: firebase.firestore.FieldValue.increment(-1),
            [eventId]: {
              totalActiveCount: firebase.firestore.FieldValue.increment(1),
              totalPendingCount: firebase.firestore.FieldValue.increment(-1),
              [ticketId]: {
                totalActiveCount: firebase.firestore.FieldValue.increment(1),
                totalPendingCount: firebase.firestore.FieldValue.increment(-1)
              }
            }
          },
          { merge: true }
        );
      } catch (error) {
        throw error;
      }
      await batch.commit();
    })
  );

  return attendees.map(s => ({ ...s, ticketIsPending: false }));
}
export async function getEventAttendees(eventId) {
  const attendeesCollection = await store
    .collection(ATTENDEES_COLLECTION)
    .where('eventId', '==', eventId)
    .get();

  return attendeesCollection.docs.map(doc => {
    return {
      id: doc.id,
      ...doc.data()
    };
  });
}

export async function deleteAttendees(attendees) {
  await Promise.all(
    attendees.map(
      async ({
        id,
        eventId,
        ticketId,
        ticketIsPending,
        organizationUserId
      }) => {
        const batch = store.batch();

        const fieldKey = ticketIsPending
          ? 'totalPendingCount'
          : 'totalActiveCount';
        batch.delete(store.collection(ATTENDEES_COLLECTION).doc(id));
        batch.set(
          store.collection(ATTENDEES_STATS_COLLECTION).doc(organizationUserId),
          {
            totalAttendeeCount: firebase.firestore.FieldValue.increment(-1),
            [fieldKey]: firebase.firestore.FieldValue.increment(-1),
            [eventId]: {
              totalAttendeeCount: firebase.firestore.FieldValue.increment(-1),
              [fieldKey]: firebase.firestore.FieldValue.increment(-1),
              [ticketId]: {
                totalAttendeeCount: firebase.firestore.FieldValue.increment(-1),
                [fieldKey]: firebase.firestore.FieldValue.increment(-1)
              }
            }
          },
          { merge: true }
        );

        await batch.commit();
      }
    )
  );
}

export async function queryAttendees({
  groupBy,
  orderBy,
  pageSize = 10,
  startAfter,
  endBefore
}) {
  let query = store.collection(ATTENDEES_COLLECTION);
  if (groupBy.length) {
    groupBy.forEach(([groupByKey, groupByValue]) => {
      query = query.where(groupByKey, '==', groupByValue);
    });
  }
  if (orderBy) {
    query = query.orderBy(orderBy, 'desc');
  }
  if (startAfter) {
    query = query.startAfter(startAfter).limit(pageSize);
  } else if (endBefore) {
    query = query.endBefore(endBefore).limitToLast(pageSize);
  } else {
    query = query.limit(pageSize);
  }
  const result = await query.get();
  return {
    data: result.docs.map(doc => ({ ...doc.data(), id: doc.id })),
    lastElement: [...result.docs].pop(),
    firstElement: [...result.docs].shift()
  };
}

export async function getAttendeeStats(id) {
  const doc = await store
    .collection(ATTENDEES_STATS_COLLECTION)
    .doc(id)
    .get();
  if (!doc.exists) {
    return null;
  }
  return {
    id: doc.id,
    ...doc.data()
  };
}

export async function exportAttendees({
  eventId,
  ticketId,
  status,
  organizationUserId
}) {
  const { data } = await functions.httpsCallable('attendees-report-allInfo')({
    eventId,
    ticketId,
    status,
    organizationUserId
  });
  return data;
}
