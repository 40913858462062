import letterService from 'src/services/letterService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const ADD_LETTER = '@letter/add-letter';
export const SAVE_LETTER = '@letter/update-letter';
export const GET_LETTER = '@letter/get-letter';
export const RESET_LETTER = '@speaker/reset-letter';

export const addLetter = createAsyncThunk(ADD_LETTER, async changes => {
  const change = await letterService.addLetter(changes);
  return change;
});

export const saveLetter = createAsyncThunk(SAVE_LETTER, async changes => {
  const change = await letterService.saveLetter(changes);
  return change;
});

export const getLetter = createAsyncThunk(GET_LETTER, async () => {
  const letter = await letterService.getLetter();
  return letter;
});

export const resetLetter = createAction(RESET_LETTER);