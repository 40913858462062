import produce from 'immer';
import _ from 'lodash';
import {
  CREATE_PROMO_CODE,
  GET_PROMO_CODES,
  DELETE_PROMO_CODE
} from 'src/actions/promoCodeActions';

const initialState = {
  promoCodeList: []
};

const promoCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROMO_CODE: {
      const promoCode = action.payload;

      return produce(state, draft => {
        draft.promoCodeList.push(promoCode);
      });
    }
    case GET_PROMO_CODES: {
      const promoCodes = action.payload;

      return produce(state, draft => {
        draft.promoCodeList = promoCodes;
      });
    }

    case DELETE_PROMO_CODE: {
      const promoCodeId = action.payload;

      return produce(state, draft => {
        draft.promoCodeList = _.reject(draft.promoCodeList, { promoCodeId });
      });
    }

    default: {
      return state;
    }
  }
};

export default promoCodeReducer;
