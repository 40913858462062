import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';
import {
  createTicket,
  getTicketById,
  updateTicket,
  updateTicketsSort,
  deleteTicket,
  updateTicketIsArchived,
  updateTicketIsPublished,
  getEventTickets,
  getSessionTickets
} from '../core/api/TicketStore';

class TicketService {
  createTicket = (ticket, timezone) =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createTicket(ticket, userId, eventId, timezone)
        .then(newTicket => {
          resolve(newTicket);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateTicket = (ticket, timezone) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateTicket(ticket, eventId, timezone)
        .then(newTicket => {
          resolve(newTicket);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateTicketsSort = tickets =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateTicketsSort(tickets, eventId)
        .then(newTickets => {
          resolve(newTickets);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateTicketIsArchived = (ticketId, actionValue) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateTicketIsArchived(ticketId, eventId, actionValue)
        .then(ticket => {
          resolve(ticket);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateTicketIsPublished = (ticketId, actionValue) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateTicketIsPublished(ticketId, eventId, actionValue)
        .then(ticket => {
          resolve(ticket);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteTicket = ticketId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteTicket(ticketId, eventId)
        .then(ticket => {
          resolve(ticket);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getTicketById = ticketId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getTicketById(eventId, ticketId)
        .then(ticket => {
          if (ticket != null) {
            resolve(ticket);
          } else {
            reject(translate('tickets.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventTickets = theEventId =>
    new Promise((resolve, reject) => {
      const eventId = theEventId || getQueryParameter(routes.EVENT);
      getEventTickets(eventId)
        .then(tickets => {
          if (tickets != null) {
            resolve(this.sortTickets(tickets));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getSessionTickets = (oldStageId, newStageId, currentTab) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getSessionTickets(eventId, oldStageId, newStageId, currentTab)
        .then(count => {
          resolve(count);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortTickets = tickets => {
    let sortedTickets = tickets.sort((a, b) =>
      a.hasOwnProperty('sort') && b.hasOwnProperty('sort')
        ? a.sort - b.sort
        : a.createdDate - b.createdDate
    );
    let newTickets = sortedTickets.map(
      (s, index) => (s = { ...s, sort: index })
    );
    return newTickets;
  };
}

export default new TicketService();
