import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import {
  createSponsor,
  getEventSponsors,
  deleteSponsor,
  getSponsorById,
  updateSponsor,
  updateSponsorsSort
} from '../core/api/SponsorStore';
import { translate } from 'i18n-js';

class SponsorService {
  createSponsor = sponsor =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createSponsor(sponsor, userId, eventId)
        .then(newSponsor => {
          resolve(newSponsor);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventSponsors = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventSponsors(eventId)
        .then(sponsors => {
          if (sponsors != null) {
            resolve(this.sortList(sponsors));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteSponsor = sponsorId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteSponsor(sponsorId, eventId)
        .then(sponsor => {
          resolve(sponsor);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getSponsorById = sponsorId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getSponsorById(eventId, sponsorId)
        .then(sponsor => {
          if (sponsor != null) {
            resolve(sponsor);
          } else {
            reject(translate('sponsors.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateSponsor = sponsor =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateSponsor(sponsor, eventId)
        .then(newSponsor => {
          resolve(newSponsor);
        })
        .catch(error => {
          reject(error.message);
        });
    });


  updateSponsorsSort = (sponsors) => new Promise((resolve, reject) => {
    const eventId = getQueryParameter(routes.EVENT);

    updateSponsorsSort(sponsors, eventId)
      .then((newSponsors) => {
        resolve(newSponsors);
      })
      .catch((error) => {
        reject(error.message);
      });
  });

  sortList = (list) => {
    let sorted = list.sort((a, b) => (a.hasOwnProperty('sort') && b.hasOwnProperty('sort')) ? a.sort - b.sort : a.createdDate - b.createdDate);
    let result = sorted.map((s, index) => (s = { ...s, sort: index }));
    return result;
  }
}

export default new SponsorService();
