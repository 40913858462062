import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { selectBillingInformation } from 'src/selectors/billingInformationSelectors';
import { useSelector, useDispatch } from 'react-redux';
import CustomLoader from 'src/components/CustomLoader';
import { getBillingFeesByPackage } from 'src/actions/settingsAction';
import {
  selectPackageById,
  selectSettingsLoading
} from 'src/selectors/settingsSelectors';
import { getBillingInformation } from 'src/actions/billingActions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  yellowIcon: {
    color: '#ff8209',
    width: '10px',
    marginRight: '5px'
  }
}));

function BillingNotificationBubble({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const billingInformation = useSelector(selectBillingInformation);
  const packageFees = useSelector(selectPackageById);
  const settingsLoading = useSelector(selectSettingsLoading);

  const packageId = billingInformation[0]?.packageId;

  useEffect(() => {
    dispatch(getBillingInformation());
    if (packageId) {
      dispatch(getBillingFeesByPackage(packageId));
    }
  }, [dispatch, packageId]);

  const hasFreePackage = packageFees && packageFees.type === 'free';

  if (settingsLoading || (packageId && !packageFees)) {
    return null;
  }

  return (
    <>
      {!billingInformation?.paymentCard && !hasFreePackage && (
        <div className={clsx(classes.root, className)} {...rest}>
          <FiberManualRecordIcon className={classes.yellowIcon} />
        </div>
      )}
    </>
  );
}

BillingNotificationBubble.propTypes = {
  className: PropTypes.string
};

export default BillingNotificationBubble;
