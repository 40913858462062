import benefitService from 'src/services/benefitService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const CREATE_BENEFIT = '@benefit/create-benefit';
export const UPDATE_BENEFIT = '@benefit/update-benefit';
export const UPDATE_BENEFITS = '@benefit/update-benefits';
export const DELETE_BENEFIT = '@benefit/delete-benefit';
export const GET_BENEFIT = '@benefit/get-benefit';
export const GET_BENEFITS = '@benefit/get-benefits';
export const RESET_BENEFITS = '@benefit/reset-benefits';
export const LISTEN_BENEFIT_STATUS_BY_ID = '@benefit/listen-status-by-id';
export const BENEFIT_STATUS_CHANGED = '@benefit/status-changed';

export const createBenefit = createAsyncThunk(CREATE_BENEFIT, async benefit => {
  const newBenefit = await benefitService.createBenefit(benefit);
  return newBenefit;
});

export const updateBenefit = createAsyncThunk(UPDATE_BENEFIT, async changes => {
  const change = await benefitService.updateBenefit(changes);
  return change;
});

export const updateBenefitsSort = createAsyncThunk(
  UPDATE_BENEFITS,
  async changes => {
    const change = await benefitService.updateBenefitsSort(changes);
    return change;
  }
);

export const deleteBenefit = createAsyncThunk(
  DELETE_BENEFIT,
  async benefitId => {
    await benefitService.deleteBenefit(benefitId);
    return benefitId;
  }
);

export const getBenefitById = createAsyncThunk(GET_BENEFIT, async benefitId => {
  const benefit = await benefitService.getBenefitById(benefitId);
  return benefit;
});

export const getBenefits = createAsyncThunk(GET_BENEFITS, async () => {
  const benefits = await benefitService.getEventBenefits();
  return benefits;
});

export const resetBenefits = createAction(RESET_BENEFITS);
