import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  yellowIcon: {
    color: '#ff8209',
    width: '10px',
    marginRight: '5px'
  }
}));

function EventStatus({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <FiberManualRecordIcon className={classes.yellowIcon} />
    </div>
  );
}

EventStatus.propTypes = {
  className: PropTypes.string
};

export default EventStatus;
