import React from 'react';

function Camera() {
  return (
 <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
   <path d="M22.67,6.74a.58.58,0,0,0-.62,0l-5.67,4.05V5.55a.77.77,0,0,0-.77-.77H1.77A.78.78,0,0,0,1,5.55v12.9a.78.78,0,0,0,.77.77H15.61a.77.77,0,0,0,.77-.77V13.17l5.67,4a.61.61,0,0,0,.35.11.52.52,0,0,0,.27-.07.6.6,0,0,0,.33-.53V7.27A.6.6,0,0,0,22.67,6.74ZM15.18,18h-13V6h13Zm6.61-2.46-5-3.55,5-3.55Z" fill="currentColor"/>
  </svg>
  );
}

export default Camera;
