import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { getEvents } from 'src/actions/eventActions';
import { pushOffSessions, updateSession } from 'src/actions/sessionActions';
import {
  VIDEO_BY_ID_STATUS_CHANGED,
  VIDEO_STATUS_CHANGED,
  VIDEO_UPLOAD_CANCELED,
  VIDEO_UPLOAD_COMPLETED,
  VIDEO_UPLOAD_FAILED,
  VIDEO_UPLOAD_FINISHING,
  VIDEO_UPLOAD_IN_PROGRESS,
  VIDEO_UPLOAD_MUX_PREPARING,
  VIDEO_UPLOAD_PAUSED
} from 'src/actions/videoAction-types';
import {
  deleteVideo,
  getVideoById,
  getVideos,
  uploadVideo,
  getVideoCategories,
  createVideoCategory,
  removeLobbyAssignmentFromVideos,
  updateVideo
} from 'src/actions/videoActions';
import { isNullishCoalesce } from 'typescript';

export const videoEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    (a.createdDate || a.modifiedDate)?.toDate() -
    (b.createdDate || b.modifiedDate)?.toDate()
});

export const videoCategoryEntityAdapter = createEntityAdapter();

const initialState = videoEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  categories: videoCategoryEntityAdapter.getInitialState({
    error: null
  })
});

const videoReducer = createReducer(initialState, {
  [uploadVideo.fulfilled]: (state, { payload }) => {
    videoEntityAdapter.addOne(state, payload);
  },
  [VIDEO_UPLOAD_IN_PROGRESS]: (state, { payload }) => {
    videoEntityAdapter.updateOne(state, {
      id: payload.id,
      changes: payload.changes
    });
  },
  [removeLobbyAssignmentFromVideos.fulfilled]: (state, { payload }) => {
    videoEntityAdapter.updateMany(state, payload);
  },
  [VIDEO_UPLOAD_CANCELED]: (state, { payload }) => {
    videoEntityAdapter.updateOne(state, {
      id: payload.id,
      changes: payload.changes
    });
  },
  [VIDEO_UPLOAD_FAILED]: (state, { payload }) => {
    videoEntityAdapter.updateOne(state, {
      id: payload.id,
      changes: payload.changes
    });
  },
  [VIDEO_UPLOAD_PAUSED]: (state, { payload }) => {
    videoEntityAdapter.updateOne(state, {
      id: payload.id,
      changes: payload.changes
    });
  },
  [VIDEO_UPLOAD_FINISHING]: (state, { payload }) => {
    videoEntityAdapter.updateOne(state, {
      id: payload.id,
      changes: payload.changes
    });
  },
  [VIDEO_UPLOAD_COMPLETED]: (state, { payload }) => {
    videoEntityAdapter.updateOne(state, {
      id: payload.id,
      changes: payload.changes
    });
  },
  [VIDEO_UPLOAD_MUX_PREPARING]: (state, { payload }) => {
    videoEntityAdapter.updateOne(state, {
      id: payload.id,
      changes: payload.changes
    });
  },
  [updateVideo.pending]: (state, { payload }) => {
    state.loading = true;
    state.error = isNullishCoalesce;
  },
  [updateVideo.fulfilled]: (state, { payload }) => {
    videoEntityAdapter.updateOne(state, {
      id: payload.id,
      changes: payload.changes
    });
  },
  [updateVideo.rejected]: (state, { error }) => {
    state.error = error?.message;
    state.loading = true;
  },
  [getVideos.pending]: state => {
    state.loading = true;
  },
  [getVideos.fulfilled]: (state, { payload }) => {
    videoEntityAdapter.addMany(state, payload);
    state.loaded = true;
    state.loading = false;
  },
  [getVideos.rejected]: state => {
    state.loading = false;
  },
  [VIDEO_STATUS_CHANGED]: (state, { payload }) => {
    videoEntityAdapter.updateMany(state, payload);
  },
  [deleteVideo.fulfilled]: (state, { payload }) => {
    videoEntityAdapter.removeOne(state, payload);
  },
  [getVideoById.pending]: state => {
    state.loading = true;
  },
  [getVideoById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    videoEntityAdapter.addOne(state, payload);
  },
  [getVideoById.rejected]: (state, { error }) => {
    state.loading = false;
  },
  [VIDEO_BY_ID_STATUS_CHANGED]: (state, { payload }) => {
    videoEntityAdapter.updateOne(state, payload);
  },
  [getEvents.fulfilled]: state => {
    videoEntityAdapter.removeAll(state);
    state.loaded = false;
  },
  [getVideoCategories.fulfilled]: (state, { payload }) => {
    videoCategoryEntityAdapter.upsertMany(state.categories, payload);
  },
  [getVideoCategories.rejected]: (state, { error }) => {
    state.categories.error = error;
  },
  [createVideoCategory.fulfilled]: (state, { payload }) => {
    videoCategoryEntityAdapter.upsertOne(state.categories, payload);
  },
  [createVideoCategory.rejected]: (state, { error }) => {
    state.categories.error = error;
  },
  [pushOffSessions.pending]: (state, _) => {
    state.loading = true;
  },
  [pushOffSessions.fulfilled]: (state, { payload }) => {
    state.loading = false;
    if (payload.videoChanges) {
      videoEntityAdapter.updateMany(state, payload.videoChanges);
    }
  },
  [pushOffSessions.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateSession.pending]: (state, _) => {
    state.loading = true;
  },
  [updateSession.fulfilled]: (state, { payload }) => {
    state.loading = false;
    if (payload.videoChanges) {
      videoEntityAdapter.updateOne(state, payload.videoChanges);
    }
  },
  [updateSession.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  }
});

export default videoReducer;
