import {
  createLegalInformation,
  getLegalInformations,
  updateLegalInformation
} from 'src/core/api/LegalInformationStore';
import * as routes from 'src/routeConstants';
import { getQueryParameter } from 'src/utils/queryParameter';
import authService from './authService';

class LandingPageService {
  createLegalInformation = info =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      const userId = authService.getUserUid();
      createLegalInformation(eventId, userId, info)
        .then(newSection => {
          resolve(newSection);
        })
        .catch(error => {
          reject(error);
        });
    });

  updateLegalInformation = change =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      const userId = authService.getUserUid();
      updateLegalInformation(eventId, userId, change)
        .then(changes => {
          resolve(changes);
        })
        .catch(error => {
          reject(error);
        });
    });

  getLegalInformations = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      getLegalInformations(eventId)
        .then(legacies => {
          // if (legacies !== null)
          resolve(legacies);
        })
        .catch(error => {
          reject(error);
        });
    });
}

export default new LandingPageService();
