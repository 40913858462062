import {
  getAttendees,
  activateUserTicket,
  activateUsers,
  queryAttendees,
  deleteAttendees,
  getAttendeeStats,
  exportAttendees
} from '../core/api/AttendeesStore';
import authService from './authService';

class AttendeesService {
  getAttendees = () =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      getAttendees(userId)
        .then(payload => {
          resolve(this.sortList(payload));
        })
        .catch(error => {
          reject(error.message);
        });
    });

  activateUserTicket = attendee =>
    new Promise((resolve, reject) => {
      activateUserTicket(attendee)
        .then(payload => {
          resolve(payload);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  activateUsers = attendees =>
    new Promise((resolve, reject) => {
      activateUsers(attendees)
        .then(payload => {
          resolve(payload);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortList = list => {
    let sortedAttendees = list.attendees.sort(
      (a, b) => b.purchaseDate.seconds - a.purchaseDate.seconds
    );
    return { events: list.events, attendees: sortedAttendees };
  };

  deleteAttendees = attendees =>
    new Promise((resolve, reject) => {
      deleteAttendees(attendees)
        .then(resolve)
        .catch(error => {
          reject(error.message);
        });
    });

  queryAttendees = query =>
    new Promise((resolve, reject) =>
      queryAttendees(query)
        .then(resolve)
        .catch(error => reject(error.message))
    );

  getAttendeeStats = statId => {
    return new Promise((resolve, reject) =>
      getAttendeeStats(statId)
        .then(resolve)
        .catch(error => reject(error.message))
    );
  };

  exportAttendees = params =>
    new Promise((resolve, reject) =>
      exportAttendees(params)
        .then(resolve)
        .catch(error => reject(error))
    );
}

export default new AttendeesService();
