import { createSelector } from '@reduxjs/toolkit';
import {
  teamEntityAdapter,
  incomingRequestsAdapter
} from '../reducers/teamReducer';

export const selectTeamState = state => state.team;
export const selectIncomingRequestsState = createSelector(
  selectTeamState,
  state => state.incomingRequests
);

export const selectTeamList = teamEntityAdapter.getSelectors(selectTeamState)
  .selectAll;

export const selectTeamLoading = createSelector(
  selectTeamState,
  state => state.loading
);

export const selectTeamLoaded = createSelector(
  selectTeamState,
  state => state.loaded
);

export const selectTeamMemberById = teamEntityAdapter.getSelectors(
  selectTeamState
).selectById;

export const selectIncomingJoinRequests = incomingRequestsAdapter.getSelectors(
  selectIncomingRequestsState
).selectAll;

export const selectTeamOwners = createSelector(
  selectTeamState,
  state => state.teamOwners
);
