import { store } from './firebase';
import { isNumber } from 'lodash';
import firebase from 'firebase';
import uuidv4 from 'src/utils/uid';

const EVENTS_COLLECTION = 'events';
const ADDONS_COLLECTION = 'addons';

export async function getAddons(eventId) {
  const documentReference = store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(ADDONS_COLLECTION);

  let ticketAddons = [];
  ticketAddons = documentReference.get().then(snap => {
    const addonList = [];
    snap.forEach(doc => {
      if (doc.exists) {
        const data = doc.data();
        const newAddon = {
          id: doc.id,
          ...data
        };
        addonList.push(newAddon);
      }
    });
    return addonList;
  });

  return ticketAddons;
}

export async function deleteAddon(addonId, eventId) {
  const addonDoc = store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(ADDONS_COLLECTION)
    .doc(addonId);
  await addonDoc.delete();
  return addonId;
}

export async function createAddon(addon, userId, eventId) {
  const addonsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${ADDONS_COLLECTION}`
  );

  let variations = {};
  addon.variations.map(variation => {
    const variationId = uuidv4();
    variations[variationId] = {
      id: variationId,
      name: variation.name,
      description: variation.description,
      price: variation.price,
      sessionId: variation.session ? variation.session.id : null,
      createdDate: firebase.firestore.FieldValue.serverTimestamp()
    };
  });

  let newAddon = {
    name: addon.name,
    selectMultiple: addon.selectMultiple,
    eventId,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId,
    variations: variations
  };

  let addonId = '';
  try {
    await addonsCollection.add(newAddon).then(s => (addonId = s.id));
  } catch (error) {
    console.log(error);
  }

  newAddon.id = addonId;
  return newAddon;
}

export async function updateAddon(addon, userId, eventId) {
  const addonsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${ADDONS_COLLECTION}`
  );

  const theFirebaseAddon = (await addonsCollection.doc(addon.id).get()).data();

  let variations = {};
  addon.variations.map(variation => {
    const variationId = variation.id || uuidv4();
    variations[variationId] = {
      id: variationId,
      name: variation.name,
      description: variation.description,
      price: variation.price,
      sort: isNumber(variation.sort) ? variation.sort : 99,
      sessionId: variation.session ? variation.session.id : null,
      createdDate: firebase.firestore.FieldValue.serverTimestamp()
    };
  });

  let updatedAddon = {
    ...theFirebaseAddon,
    id: addon.id,
    name: addon.name,
    selectMultiple: addon.selectMultiple,
    eventId,
    variations: variations,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedBy: userId,
    sort: isNumber(addon.sort) ? addon.sort : 99
  };

  try {
    await addonsCollection.doc(addon.id).set(updatedAddon);
  } catch (error) {
    console.log(error);
  }

  return updatedAddon;
}

export async function updateAddonSort(changesList, userId, eventId) {
  const batch = store.batch();
  changesList.forEach(async changes => {
    const addonRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${ADDONS_COLLECTION}/${changes.id}`
    );

    batch.update(addonRef, {
      ...changes,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy: userId
    });
  });

  batch.commit();

  return changesList;
}
