import * as jwt_decode from 'jwt-decode';
import {
  createUser,
  signInUser,
  sendPasswordResetEmail,
  signOut,
  getUserIdToken,
  getUserById,
  sendEmailVerificationRequest,
  createUsers,
  updateUserOrganization,
  updateUserPhoneNumber,
  updateUserWebsite,
  setRegistrationSurvey,
  deleteFullAccount,
  requestDeleteFullAccount,
  updateProfile,
  saveEmailVerifiedFlag,
  createUserUsingProvider
} from '../core/api/UserStore';
import authService from 'src/services/authService';
import { functions } from 'src/core/api/firebase';

class AuthService {
  registerUser = newUser =>
    new Promise((resolve, reject) => {
      createUser(newUser)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error.message);
        });
    });

  registerUserWithProvider = async newUser => {
    try {
      const userExists = !!(await getUserById(newUser?.uid));
      if (!userExists) {
        await createUserUsingProvider(newUser);
        const current_user = await this.loginInWithToken();
        return current_user;
      }
      throw new Error('[PROVIDER REGISTER] User already exists');
    } catch (error) {
      console.warn(error.message);
      return null;
    }
  };

  bulkRegisterUsers = (users, eventId, ticketId) =>
    new Promise((resolve, reject) => {
      createUsers(users, eventId, ticketId)
        .then(resolve)
        .catch(reject);
    });

  loginWithEmailAndPassword = user =>
    new Promise(async (resolve, reject) => {
      try {
        let token = await signInUser(user);
        this.setSession(token);
        const current_user = await this.getCustomTokenResponse(token);
        resolve(current_user);
      } catch (error) {
        reject(error.message);
      }
    });

  resetPassword = email =>
    new Promise((resolve, reject) => {
      const accessToken = authService.getAccessToken();
      sendPasswordResetEmail(email, accessToken)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error.message);
        });
    });

  logout = () =>
    new Promise((resolve, reject) => {
      signOut()
        .then(() => {
          this.setSession(null);
          resolve();
        })
        .catch(error => {
          reject(error.message);
        });
    });

  loginInWithToken = () =>
    new Promise(async (resolve, reject) => {
      const token = this.getAccessToken();
      if (!token) reject('Could not retrieve token');
      else {
        const current_user = await this.getCustomTokenResponse(token);
        resolve(current_user);
      }
    });

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
    }
  };

  getUserIdToken = () =>
    new Promise((resolve, reject) => {
      getUserIdToken()
        .then(token => {
          resolve(token);
          this.setSession(token);
        })
        .catch(error => reject(error));
    });

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwt_decode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();

  getUserUid = () => {
    const token = this.getAccessToken();
    const decoded = jwt_decode(token);
    return decoded.user_id;
  };

  getUserInfo = () => {
    const token = this.getAccessToken();
    const decoded = jwt_decode(token);
    return decoded;
  };

  getConnectedAccountId = () => {
    const token = this.getAccessToken();
    const decoded = jwt_decode(token);
    return decoded.connectedAccountId;
  };

  getCustomTokenResponse = async token => {
    let user = jwt_decode(token);
    await getUserById(user.user_id).then(details => {
      if (details) {
        user = {
          ...user,
          fullName: details.fullName,
          organization: details.organization,
          phoneNumber: details.phoneNumber,
          website: details.website,
          registrationSurvey: details?.registrationSurvey || null,
          photo: details.photo
        };
      }
    });

    return user;
  };

  requestEmailVerification = async () =>
    new Promise((resolve, reject) => {
      return sendEmailVerificationRequest()
        .then(() => {
          resolve({ success: true, msg: '' });
        })
        .catch(error => {
          reject({ success: false, msg: error });
        });
    });

  deleteUserAccount = async userId => {
    const deleteAccount = functions.httpsCallable(
      'auth-account-deleteUserAccount'
    );

    const response = await deleteAccount({
      userId: userId
    });

    return response;
  };

  updateAuthEmail = async (userId, email) => {
    const updateEmail = functions.httpsCallable('auth-account-updateAuthEmail');

    const response = await updateEmail({
      userId: userId,
      email: email
    });

    return response;
  };

  createUserAccount = async (username, email, password, eventId) => {
    const registration = functions.httpsCallable(
      'auth-account-createSpeakerUser'
    );

    const response = await registration({
      email: email,
      username: username,
      password: password
    });

    return response.data.id;
  };

  updateProfile = async profileData => {
    const response = await updateProfile(profileData);

    return response;
  };

  updateUserOrganization = async userData => {
    const response = updateUserOrganization(userData);

    return response;
  };
  updateUserPhoneNumber = async userData => {
    const response = await updateUserPhoneNumber(userData);

    return response;
  };
  updateUserWebsite = async userData => {
    const response = await updateUserWebsite(userData);

    return response;
  };
  setRegistrationSurvey = async userData => {
    const response = await setRegistrationSurvey(userData);

    return response;
  };

  deleteFullAccount = () =>
    new Promise((resolve, reject) => {
      deleteFullAccount()
        .then(status => {
          resolve(status);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  requestDeleteFullAccount = () =>
    new Promise((resolve, reject) => {
      requestDeleteFullAccount()
        .then(status => {
          resolve(status);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  saveEmailVerifiedFlag = () =>
    new Promise((resolve, reject) => {
      saveEmailVerifiedFlag()
        .then(status => {
          resolve(status);
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new AuthService();
