import { createSelector } from '@reduxjs/toolkit';
import { legalEntityAdapter } from '../reducers/legalInformationReducer';

export const selectLegalState = state => state.legalInformation;

export const selectLegalInformation = legalEntityAdapter.getSelectors(
  selectLegalState
).selectAll;

export const selectLegalInformationLoading = createSelector(
  selectLegalState,
  state => state.loading
);

export const selectLegalInformationLoaded = createSelector(
  selectLegalState,
  state => state.loaded
);
