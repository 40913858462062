import firebase from 'firebase';
import { functions, storage, store } from './firebase';
import { asyncForEach } from 'src/utils/asyncUtils';
import { v4 as uuid } from 'uuid';

const EVENTS_COLLECTION = 'events';
const SPEAKERS_COLLECTION = 'speakers';
const SESSIONS_COLLECTION = 'sessions';

/**
 * @note Create the speaker with a cloud function
 * @param {*} speaker
 * @param {*} eventId
 * @returns
 */
export async function createSpeaker(speaker, eventId, cleanupId) {
  const { data } = await functions.httpsCallable('speakers-createSpeaker')({
    eventId,
    speaker,
    cleanupId
  });

  return data;
}

/**
 * @note Update the speaker with a cloud function
 * @param {*} speaker
 * @param {*} eventId
 * @returns
 */
export async function updateSpeaker(speaker, eventId) {
  await functions.httpsCallable('speakers-updateSpeaker')({
    eventId,
    speaker,
    speakerId: speaker.id,
    cleanupId: speaker.cleanupId
  });

  return { id: speaker.id, changes: speaker };
}

/**
 * @note Delete the speaker with the help of a cloud function
 * @param {*} speakerId
 * @param {*} eventId
 * @returns
 */
export async function deleteSpeaker(speakerId, eventId) {
  await functions.httpsCallable('speakers-deleteSpeaker')({
    eventId,
    speakerId
  });

  return speakerId;
}


export async function updateSpeakersSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  for (const changes of changesList) {
    const speakerRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${SPEAKERS_COLLECTION}/${changes.id}`
    );
    const oldSpeaker = await speakerRef.get();

    let filteredObject = {};

    Object.keys(changes).forEach(key => {
      if (oldSpeaker.data()[key] !== undefined) {
        filteredObject[key] = changes[key];
      }
    });
    filteredObject.sort = changes.sort;

    batch.update(speakerRef, {
      ...filteredObject,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    let result = {
      ...filteredObject
    };

    resultList.push(result);
  }

  batch.commit();

  return resultList;
}

export async function getSpeakerById(eventId, speakerId) {
  const documentReference = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(SPEAKERS_COLLECTION)
    .doc(speakerId);
  let speaker = null;
  speaker = documentReference.get().then(doc => {
    if (doc.exists) {
      const data = doc.data();
      if (data != null) {
        const newSpeaker = {
          id: speakerId,
          ...data
        };
        return newSpeaker;
      }
    }
  });

  return speaker;
}

export async function getEventSpeakers(eventId) {
  const speakersCollection = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${SPEAKERS_COLLECTION}`)
    .get();

  const sessionCollection = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}`)
    .get();

  let speakers = [];
  const speakerDoc = speakersCollection.docs;
  if (speakerDoc && speakerDoc.length) {
    await asyncForEach(speakerDoc, async doc => {
      const data = doc.data();
      const allSessions = sessionCollection.docs.map(s => ({
        id: s.data().id,
        title: s.data().title,
        selectedSpeakers: s.data().selectedSpeakers,
        resourceId: s.data().resourceId,
        start: s.data().start,
        end: s.data().end
      }));

      let speakerSessions = allSessions
        .filter(
          s =>
            s.selectedSpeakers && s.selectedSpeakers.some(a => a.id === doc.id)
        )
        .sort((a, b) => new Date(a.start) - new Date(b.start));

      const stageIds = getStageIdsFromSpeakerSessions(speakerSessions);

      const newSpeaker = {
        id: doc.id,
        stageIds: stageIds,
        sessions: speakerSessions,
        ...data
      };

      speakers.push(newSpeaker);
    });
  }

  return speakers;
}

function getStageIdsFromSpeakerSessions(sessions) {
  return sessions.reduce((accum, session) => {
    if (!accum.includes(session.resourceId)) {
      accum.push(session.resourceId);
    }
    return accum;
  }, []);
}
