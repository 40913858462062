import firebase from 'firebase';
import { asyncForEach } from 'src/utils/asyncUtils';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const CHECKLIST_COLLECTION = 'checklist';

export async function createChecklist(checklist, userId, eventId) {
  const checklistCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${CHECKLIST_COLLECTION}`
  );

  let collectionSize = 0;
  await checklistCollection.get().then(s => (collectionSize = s.size));

  const checklistRef = await checklistCollection.add({
    description: checklist.description,
    sort: collectionSize,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  });

  const checklistDoc = await checklistRef.get();
  await checklistDoc.ref.update({
    id: checklistDoc.id
  });

  return {
    ...checklistDoc.data(),
    id: checklistDoc.id
  };
}

export async function updateChecklist(checklist, eventId) {
  const checklistRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${CHECKLIST_COLLECTION}/${checklist.id}`
  );

  await checklistRef.update({
    id: checklist.id,
    description: checklist.description,
    sort: checklist.sort,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  const checklistDoc = await checklistRef.get();

  return {
    id: checklistDoc.id,
    changes: {
      ...checklistDoc.data()
    }
  };
}

export async function updateChecklistSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  changesList.forEach(async changes => {
    const checklistRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${CHECKLIST_COLLECTION}/${changes.id}`
    );

    batch.update(checklistRef, {
      id: changes.id,
      description: changes.description,
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    let result = {
      ...changes
    };

    resultList.push(result);
  });

  batch.commit();

  return resultList;
}

export async function deleteChecklist(checklistId, eventId) {
  const checklistCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${CHECKLIST_COLLECTION}`
  );
  const checklistDoc = checklistCollection.doc(checklistId);

  await checklistDoc.delete();

  return checklistId;
}

export async function getEventChecklist(eventId) {
  const checklistCollection = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${CHECKLIST_COLLECTION}`)
    .get();

  let checklist = [];
  const checklistDoc = checklistCollection.docs;
  if (checklistDoc && checklistDoc.length) {
    await asyncForEach(checklistDoc, async doc => {
      let data = doc.data();

      const newChecklist = {
        id: doc.id,
        description: data.description,
        sort: data.sort,
        createdDate: data.createdDate
      };

      checklist.push(newChecklist);
    });
  }

  return checklist;
}
