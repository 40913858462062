import firebase from 'firebase';
import { DEFAULTS } from 'src/constants/landing-page-constants';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const LANDING_PAGE_COLLECTION = 'landingPage';

export async function createSection(eventId, userId, section) {
  const sectionsCollectionRef = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${LANDING_PAGE_COLLECTION}`
  );
  const sectionDoc = await sectionsCollectionRef.add(section);
  await sectionDoc.update({
    id: sectionDoc.id,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  });
  return { id: sectionDoc.id, ...section };
}

export async function updateSection(eventId, userId, changes) {
  const sectionDoc = await store
    .doc(
      `${EVENTS_COLLECTION}/${eventId}/${LANDING_PAGE_COLLECTION}/${changes.id}`
    )
    .get();
  if (sectionDoc.exists) {
    await sectionDoc.ref.update({
      ...changes,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedBy: userId
    });
  } else if (DEFAULTS[changes.id]) {
    await sectionDoc.ref.set({
      ...changes,
      createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: userId
    });
  } else {
    return null;
  }
  return { id: sectionDoc.id, changes: { ...changes } };
}

export async function getSections(eventId) {
  const sectionsCollection = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${LANDING_PAGE_COLLECTION}`)
    .get();
  if (sectionsCollection.size === 0) {
    return Object.entries(DEFAULTS).map(([section, config]) => ({
      id: section,
      ...config
    }));
  }

  const defaultSections = Object.keys(DEFAULTS);
  return sectionsCollection.docs.reduce((acc, doc, index, { length }) => {
    const sectionIndex = defaultSections.indexOf(doc.get('type'));
    acc.push({ id: doc.id, ...doc.data() });
    if (sectionIndex > -1) {
      defaultSections.splice(sectionIndex, 1);
    }
    if (index + 1 === length) {
      acc.push(
        ...defaultSections.map(section => ({
          id: section,
          ...DEFAULTS[section]
        }))
      );
    }
    return acc;
  }, []);
}

export async function updateSectionsSort(eventId, userId, changesList) {
  const batch = store.batch();
  changesList.forEach(changes => {
    const sectionRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${LANDING_PAGE_COLLECTION}/${changes.id}`
    );
    batch.update(sectionRef, {
      ...changes,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedBy: userId
    });
  });
  await batch.commit();
  return changesList;
}

export async function deleteSection(eventId, sectionId) {
  await store
    .doc(
      `${EVENTS_COLLECTION}/${eventId}/${LANDING_PAGE_COLLECTION}/${sectionId}`
    )
    .delete();
  return sectionId;
}

export async function generateDefaultLandingPageSections(eventId) {
  const sectionsCollectionRef = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${LANDING_PAGE_COLLECTION}`
  );
  Object.keys(DEFAULTS).forEach(async section => {
    await sectionsCollectionRef.add(DEFAULTS[section]);
  });
}

export async function getSectionById(eventId, sectionId) {
  const sectionsDoc = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${LANDING_PAGE_COLLECTION}`)
    .doc(sectionId);
  const section = await sectionsDoc.get();
  if (!section.exists) return null;
  return { id: section.id, ...section.data() };
}
