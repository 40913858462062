import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  createSection,
  getSectionById,
  getSections,
  resetSections,
  updateSection,
  updateSectionsSort
} from 'src/actions/landingPageSectionActions';

export const sectionEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = sectionEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const landingPageSectionReducer = createReducer(initialState, {
  [createSection.pending]: (state, _) => {
    state.loading = true;
  },
  [createSection.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sectionEntityAdapter.upsertOne(state, payload);
  },
  [createSection.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getSections.pending]: state => {
    state.loading = true;
    state.error = null;
  },
  [getSections.fulfilled]: (state, { payload }) => {
    sectionEntityAdapter.addMany(state, payload);
    state.loaded = true;
    state.loading = false;
  },
  [getSections.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [updateSectionsSort.pending]: (state, { meta }) => {
    sectionEntityAdapter.upsertMany(state, meta.arg);
    state.loading = true;
  },
  [updateSectionsSort.fulfilled]: state => {
    state.loading = false;
    state.loaded = true;
  },
  [updateSectionsSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateSection.pending]: (state, _) => {
    state.loading = true;
  },
  [updateSection.fulfilled]: (state, { payload }) => {
    sectionEntityAdapter.updateOne(state, payload);
    state.loading = false;
    state.loaded = true;
  },
  [updateSection.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getSectionById.pending]: (state, _) => {
    state.loading = true;
  },
  [getSectionById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sectionEntityAdapter.upsertOne(state, payload);
  },
  [getSectionById.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetSections.type]: (_, __) => {
    return initialState;
  }
});

export default landingPageSectionReducer;
