import moment from 'moment-timezone';

export const fullDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const dateFormat = 'DD MMM YYYY';
export const dayDateFormat = 'ddd DD/MM/YYYY';
export const longDayDateFormat = 'dddd | D MMMM YYYY';
export const dateTimeFormat = 'D MMMM YYYY - HH:mm';
export const dateTimeFormat2 = 'D MMMM YYYY - hh:mm a';
export const dateTimeFormat3 = 'D MMMM YYYY, hh:mm A';
export const fullDateFormat = (date, timezone) =>
  timezone
    ? moment(date)
        .tz(timezone)
        .format(dateFormat)
    : moment(date).format(dateFormat);

export const secondsToDuration = seconds => {
  const d = Number(seconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  return `${h.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}:${m.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}:${s.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}`;
};

export const getDifferenceInMinute = (start, end) => {
  if (!start || !end) {
    return 0;
  }
  const startDate = moment.unix(start.seconds);
  const endDate = moment.unix(end.seconds);
  const duration = moment.duration(endDate.diff(startDate));

  return duration.asMinutes();
};

export const getDifferenceInSecond = (start, end) => {
  if (!start || !end) {
    return 0;
  }
  const startDate = moment(start.seconds);
  const endDate = moment(end.seconds);
  const duration = moment.duration(endDate.diff(startDate));

  return duration.asSeconds();
};

export const convertSecondsToMinutesAndRoundUp = seconds => {
  return seconds % 60 ? (seconds + 60 - (seconds % 60)) / 60 : seconds / 60;
};

export const printMinuteDifference = (start, end) => {
  const startDate = moment.unix(start);
  const endDate = moment.unix(end);
  const startDay = startDate.date();
  const startMonth = startDate.month();
  const endMonth = endDate.month();
  const endDay = endDate.date();
  const startHours = startDate.hours();
  const endHours = endDate.hours();
  const startMinutes = startDate.minutes();
  const endMinutes = endDate.minutes();
  if (startMonth !== endMonth) {
    return `${startDay} ${startDate.format(
      'MMMM'
    )} - ${endDay} ${endDate.format(
      'MMMM'
    )} ${startDate.year()} ${startHours}:${startMinutes.toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2,
        useGrouping: false
      }
    )}-${endHours}:${endMinutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })}`;
  } else if (startDay !== endDay) {
    return `${startDay}-${endDay} ${startDate.format(
      'MMMM'
    )} ${startDate.year()} ${startHours}:${startMinutes.toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2,
        useGrouping: false
      }
    )}-${endHours}:${endMinutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })}`;
  } else {
    return `${startDay} ${startDate.format(
      'MMMM'
    )} ${startDate.year()} ${startHours}:${startMinutes.toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2,
        useGrouping: false
      }
    )}-${endHours}:${endMinutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })}`;
  }
};

/**
 * @note  Get the list/array of the event days
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} timezone
 */
export const getEventDaysArray = (startDate, endDate, timezone) => {
  let dateArray = [];
  let start = moment(startDate);
  let end = moment(endDate);
  if (start && end) {
    while (start.isSameOrBefore(end, 'day')) {
      dateArray.push(fullDateFormat(start, timezone));
      start = moment(start).add(1, 'days');
    }
  }
  return dateArray;
};

/**
 * @note Converting a date string to fit an event timezone
 * @param {*} incomingDate,
 * @param {*} timezone, the timezone of the event, saved at the key-details
 * @return {*} utcDate, the date converted to the event's timezone, Ex: 2020-10-26T10:30:00Z
 */
export const upgradeDateWithEventTimeZone = (incomingDate, timezone) => {
  const momentDate = moment(incomingDate).format(fullDateTimeFormat);
  const dateWithTimezone = moment.tz(momentDate, timezone);
  return dateWithTimezone.utc().format();
};

/**
 * @note Strip the date from event-timezone
 * @param {*} date,
 * @param {*} timezone,
 * @return {*} utcDate
 */
export const stripDateFromEventTimeZone = (date, timezone) => {
  const timezoneOffset = getTimezoneOffset(timezone, date);
  if (timezoneOffset > 0)
    return moment(date).subtract(Math.abs(timezoneOffset), 'hours');
  return moment(date).add(timezoneOffset, 'hours');
};

/**
 * @note Force to local timezone
 * @param {*} date,
 * @param {*} timezone,
 * @return {*} utcDate
 */
export const forceCalendarTimeZone = (date, timezone) => {
  const timezoneOffset = getTimezoneOffset(timezone, date);
  return moment(date).subtract(timezoneOffset, 'hours');
};

/**
 * Get the offset of a given timezone from utc
 * @param {*} timezone
 * @param {*} date
 */
export const getTimezoneOffset = (timezone, date = null) => {
  // get the current time so we know which offset to take
  const now = moment(date ?? new Date()).utc();
  // get the zone offsets for this time, in minutes
  return moment.tz.zone(timezone).utcOffset(now) / 60;
};

export const printTime = time => {
  const seconds = time / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const weeks = days / 7;
  const months = weeks / 31;
  const years = months / 12;

  if (Math.trunc(years) !== 0) {
    return `${years.toFixed(0)}y`;
  }

  if (Math.trunc(months) !== 0) {
    return `${months.toFixed(0)}m`;
  }

  if (Math.trunc(weeks) !== 0) {
    return `${weeks.toFixed(0)}w`;
  }

  if (Math.trunc(days) !== 0) {
    return `${days.toFixed(0)}d`;
  }

  if (Math.trunc(hours) !== 0) {
    return `${hours.toFixed(0)}h`;
  }

  return `${minutes.toFixed(0)}min`;
};

export function momentTimeRange(from, to) {
  if (from.format('YYYY') !== to.format('YYYY'))
    return `${from.format('D MMM YYYY')} - ${to.format('D MMM YYYY')}`;
  if (from.format('M') !== to.format('M'))
    return `${from.format('D MMM')} - ${to.format('D MMM YYYY')}`;
  if (from.format('D') !== to.format('D'))
    return `${from.format('D')} - ${to.format('D MMM YYYY')}`;
  return to.format('D MMM YYYY');
}
