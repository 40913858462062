import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  createLegalInformation,
  getLegalInformations,
  resetLegalInformations,
  updateLegalInformation
} from 'src/actions/legalInformationActions';

export const legalEntityAdapter = createEntityAdapter();

const initialState = legalEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const legalInformationSectionReducer = createReducer(initialState, {
  [createLegalInformation.pending]: (state, _) => {
    state.loading = true;
  },
  [createLegalInformation.fulfilled]: (state, { payload }) => {
    state.loading = false;
    legalEntityAdapter.upsertOne(state, payload);
  },
  [createLegalInformation.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getLegalInformations.pending]: state => {
    state.loading = true;
  },
  [getLegalInformations.fulfilled]: (state, { payload }) => {
    state.loaded = true;
    state.loading = false;
    legalEntityAdapter.upsertMany(state, payload);
  },
  [getLegalInformations.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [updateLegalInformation.pending]: (state, _) => {
    state.loading = true;
  },
  [updateLegalInformation.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    legalEntityAdapter.updateOne(state, payload);
  },
  [updateLegalInformation.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetLegalInformations.type]: (_, __) => {
    return initialState;
  }
});

export default legalInformationSectionReducer;
