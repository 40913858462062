import {
  createCategory,
  updateCategory,
  updateCategoriesSort,
  deleteCategory,
  getCategoryById,
  getCategories,
  resetCategories,
} from 'src/actions/sponsorCategoryActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const sponsorCategoryEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = sponsorCategoryEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const categoryReducer = createReducer(initialState, {
  [createCategory.pending]: (state, _) => {
    state.loading = true;
  },
  [createCategory.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorCategoryEntityAdapter.upsertOne(state, payload);
  },
  [createCategory.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateCategory.pending]: (state, _) => {
    state.loading = true;
  },
  [updateCategory.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorCategoryEntityAdapter.updateOne(state, payload);
  },
  [updateCategory.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateCategoriesSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateCategoriesSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorCategoryEntityAdapter.upsertMany(state, payload);
  },
  [updateCategoriesSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteCategory.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteCategory.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorCategoryEntityAdapter.removeOne(state, payload);
  },
  [deleteCategory.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getCategoryById.pending]: (state, _) => {
    state.loading = true;
  },
  [getCategoryById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sponsorCategoryEntityAdapter.upsertOne(state, payload);
  },
  [getCategoryById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getCategories.pending]: (state, _) => {
    state.loading = true;
  },
  [getCategories.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    sponsorCategoryEntityAdapter.upsertMany(state, payload);
  },
  [getCategories.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetCategories.type]: (_, __) => {
    return initialState;
  }
});

export default categoryReducer;
