import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as routes from 'src/routeConstants';
import { CLEAR_USER, REBUILD_PROVIDER_INFO } from 'src/actions/accountActions';

function AuthGuard({ children }) {
  const account = useSelector(state => state.account);
  const dispatch = useDispatch();
  if (!account.user) {
    return <Redirect to={routes.LOGIN} />;
  }

  const undefinedMagicString = '--not-defined--';
  const orgNotDefined = account.user.organization === undefinedMagicString;

  if (orgNotDefined) {
    const providerInfo = {
      fullName: account.user.fullName,
      email: account.user.email,
      uid: account.user.user_id
    };
    // TODO: find a better way to redirect, this is a bit dirty
    dispatch({ type: REBUILD_PROVIDER_INFO, payload: providerInfo });
    dispatch({ type: CLEAR_USER });
    return <Redirect to={routes.REGISTER} />;
  }

  if (account.user && !account.user.email_verified) {
    return <Redirect to={routes.VERIFY_EMAIL} />;
  }

  if (!account.user?.registrationSurvey) {
    return <Redirect to={routes.THANK_YOU_PAGE} />;
  }
  
  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
