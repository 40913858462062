import React from 'react';

function Brush() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
      <path d="M19.59,1H4.41a.58.58,0,0,0-.58.58V13.29a.58.58,0,0,0,.58.58H9.32l-.63,5.37a3.34,3.34,0,1,0,6.63,0l-.64-5.37h4.91a.58.58,0,0,0,.58-.58V1.58A.58.58,0,0,0,19.59,1ZM5,2.16H7.16V4.84a.58.58,0,1,0,1.16,0V2.16H14V5.38a.58.58,0,0,0,1.16,0V2.16H19v6H5ZM19,12.71H14a.58.58,0,0,0-.58.65l.71,6a2.14,2.14,0,0,1-.53,1.71,2.24,2.24,0,0,1-3.26,0,2.17,2.17,0,0,1-.53-1.72l.71-6a.58.58,0,0,0-.57-.65H5V9.34H19Z" fill="currentColor"/>
    </svg>
  );
}

export default Brush;
