import statsService from 'src/services/statsService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { PERIODS } from 'src/constants/stats';
import moment from 'moment-timezone';

export const GET_STATS_TOTAL = '@stats/get-stats-total';
export const GET_STATS_MONTHLY = '@stats/get-stats-monthly';
export const GET_STAT = '@stats/get-stat';
export const CHANGE_STAT_PERIOD = '@stats/change-stat-period';

export const getStatsTotal = createAsyncThunk(GET_STATS_TOTAL, async () => {
  const totalStat = await statsService.getStatsTotal();
  return totalStat;
});

export const getStatsMonthly = createAsyncThunk(
  GET_STATS_MONTHLY,
  async date => {
    const monthlyStats = await statsService.getStatsMonthly(date);
    return monthlyStats;
  }
);

export const getStat = createAsyncThunk(GET_STAT, async ({ date, period }) => {
  const stat = await statsService.getStat(date, period);
  return stat;
});

export const changeStatPeriod = createAction(
  CHANGE_STAT_PERIOD,
  ({ period, date }) => {
    if (!moment.isMoment(date)) {
      throw Error(
        `ERROR: unsupported date: ${date}, use a moment.js date object'`
      );
    }
    if (!PERIODS[period])
      throw Error(
        `ERROR: unsupported stat period: ${period}, use one of from the following values: ${Object.values(
          PERIODS
        ).join(',')}!`
      );
    return {
      payload: {
        period,
        date
      }
    };
  }
);
