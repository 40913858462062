import {
  createSpeaker,
  updateSpeaker,
  updateSpeakersSort,
  deleteSpeaker,
  getSpeakerById,
  getSpeakers,
  resetSpeakers,
  inviteSpeaker,
  generateSpeakerLoginLink
} from 'src/actions/speakerActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const speakerEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = speakerEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const speakerReducer = createReducer(initialState, {
  [createSpeaker.pending]: (state, _) => {
    state.loading = true;
  },
  [createSpeaker.fulfilled]: (state, { payload }) => {
    state.loading = false;
    speakerEntityAdapter.upsertOne(state, payload);
  },
  [createSpeaker.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateSpeaker.pending]: (state, _) => {
    state.loading = true;
  },
  [updateSpeaker.fulfilled]: (state, { payload }) => {
    state.loading = false;
    speakerEntityAdapter.updateOne(state, payload);
  },
  [updateSpeaker.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateSpeakersSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateSpeakersSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    speakerEntityAdapter.upsertMany(state, payload);
  },
  [updateSpeakersSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteSpeaker.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteSpeaker.fulfilled]: (state, { payload }) => {
    state.loading = false;
    speakerEntityAdapter.removeOne(state, payload);
  },
  [deleteSpeaker.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getSpeakerById.pending]: (state, _) => {
    state.loading = true;
  },
  [getSpeakerById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    speakerEntityAdapter.upsertOne(state, payload);
  },
  [getSpeakerById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getSpeakers.pending]: (state, _) => {
    state.loading = true;
  },
  [getSpeakers.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    speakerEntityAdapter.upsertMany(state, payload);
  },
  [getSpeakers.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetSpeakers.type]: (_, __) => {
    return initialState;
  },
  [inviteSpeaker.pending]: (state, _) => {
    state.loading = true;
  },
  [inviteSpeaker.fulfilled]: (state, _) => {
    state.loading = false;
  },
  [inviteSpeaker.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [generateSpeakerLoginLink.pending]: (state, _) => {
    state.loading = true;
  },
  [generateSpeakerLoginLink.fulfilled]: (state, _) => {
    state.loading = false;
  },
  [generateSpeakerLoginLink.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  }
});

export default speakerReducer;
