import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import {
  createCategory,
  getEventCategories,
  deleteCategory,
  getCategoryById,
  updateCategory,
  updateCategoriesSort
} from '../core/api/SponsorCategoryStore';
import { translate } from 'i18n-js';

class CategoryService {
  createCategory = category =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createCategory(category, userId, eventId)
        .then(newCategory => {
          resolve(newCategory);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventCategories = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventCategories(eventId)
        .then(categories => {
          if (categories != null) {
            resolve(this.sortList(categories));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteCategory = categoryId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteCategory(categoryId, eventId)
        .then(category => {
          resolve(category);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getCategoryById = categoryId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getCategoryById(eventId, categoryId)
        .then(category => {
          if (category != null) {
            resolve(category);
          } else {
            reject(translate('categories.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateCategory = category =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateCategory(category, eventId)
        .then(newCategory => {
          resolve(newCategory);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateCategoriesSort = (categories) => new Promise((resolve, reject) => {
    const eventId = getQueryParameter(routes.EVENT);

    updateCategoriesSort(categories, eventId)
      .then((newCategories) => {
        resolve(newCategories);
      })
      .catch((error) => {
        reject(error.message);
      });
  });

  sortList = (list) => {
    let sorted = list.sort((a, b) => (a.hasOwnProperty('sort') && b.hasOwnProperty('sort')) ? a.sort - b.sort : a.createdDate - b.createdDate);
    let result = sorted.map((s, index) => (s = { ...s, sort: index }));
    return result;
  }
}

export default new CategoryService();
