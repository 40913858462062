import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  deleteAddon,
  getAddons,
  resetAddons,
  updateAddon,
  updateAddonsSort
} from 'src/actions/addonActions';

export const addonsEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = addonsEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  addons: []
});

const addonReducer = createReducer(initialState, {
  [getAddons.pending]: (state, _) => {
    state.loading = true;
  },
  [getAddons.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    addonsEntityAdapter.upsertMany(state, payload.addons);
  },
  [getAddons.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetAddons.type]: (_, __) => {
    return initialState;
  },
  [updateAddonsSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateAddonsSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    addonsEntityAdapter.upsertMany(state, payload);
  },
  [updateAddonsSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteAddon.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteAddon.fulfilled]: (state, { payload }) => {
    state.loading = false;
    addonsEntityAdapter.removeOne(state, payload);
  },
  [deleteAddon.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateAddon.pending]: (state, _) => {
    state.loading = true;
  },
  [updateAddon.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    addonsEntityAdapter.upsertOne(state, payload);
  },
  [updateAddon.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  }
});

export default addonReducer;
