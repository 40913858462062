import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  createChecklist,
  deleteChecklist,
  getChecklist,
  resetChecklist,
  updateChecklist,
  updateChecklistSort
} from 'src/actions/checklistActions';

export const checklistEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = checklistEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const checklistReducer = createReducer(initialState, {
  [createChecklist.pending]: (state, _) => {
    state.loading = true;
  },
  [createChecklist.fulfilled]: (state, { payload }) => {
    state.loading = false;
    checklistEntityAdapter.upsertOne(state, payload);
  },
  [createChecklist.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateChecklist.pending]: (state, _) => {
    state.loading = true;
  },
  [updateChecklist.fulfilled]: (state, { payload }) => {
    state.loading = false;
    checklistEntityAdapter.updateOne(state, payload);
  },
  [updateChecklist.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateChecklistSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateChecklistSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    checklistEntityAdapter.upsertMany(state, payload);
  },
  [updateChecklistSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteChecklist.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteChecklist.fulfilled]: (state, { payload }) => {
    state.loading = false;
    checklistEntityAdapter.removeOne(state, payload);
  },
  [deleteChecklist.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getChecklist.pending]: (state, _) => {
    state.loading = true;
  },
  [getChecklist.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    checklistEntityAdapter.upsertMany(state, payload.checklist);
  },
  [getChecklist.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetChecklist.type]: (_, __) => {
    return initialState;
  }
});

export default checklistReducer;
