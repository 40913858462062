import firebase from 'firebase';
import videoService from 'src/services/videoService';
import {
  getDifferenceInMinute,
  upgradeDateWithEventTimeZone
} from 'src/utils/dateFormatter';
import { store } from './firebase';
import { getVideoById } from './VideoStore';
import {
  LIVE_SESSION_TYPES,
  PRE_RECORDED_SESSION_TYPE,
  LIVE_BACKSTAGE_SESSION_TYPE
} from 'src/constants/session-types';
import { LECTURE, NETWORKING_AREA } from 'src/constants/video-types';
const EVENTS_COLLECTION = 'events';
const SESSIONS_COLLECTION = 'sessions';
const VIDEOS_COLLECTION = 'videos';
const SESSION_CATEGORIES_COLLECTION = 'session-categories';

export async function createSession(session, userId, eventId) {
  const sessionsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}`
  );
  const timestampStart = new firebase.firestore.Timestamp(
    session.start.unix(),
    0
  );
  const timestampEnd = new firebase.firestore.Timestamp(session.end.unix(), 0);
  try {
    const sessionRef = await sessionsCollection.add({
      ...session,
      start: timestampStart,
      end: timestampEnd,
      createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: userId
    });
    const sessionDoc = await sessionRef.get();
    await sessionDoc.ref.update({
      id: sessionDoc.id
    });
    return {
      ...sessionDoc.data(),
      id: sessionDoc.id,
      start: timestampStart,
      end: timestampEnd
    };
  } catch (error) {
    console.error(`[SESSION_CREATION_ERROR]: ${error}`);
  }
}

export async function updateSession(session, eventId) {
  const sessionRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}/${session.id}`
  );
  let videoChanges = undefined;
  const timestampStart = new firebase.firestore.Timestamp(
    session.start.unix(),
    0
  );
  const timestampEnd = new firebase.firestore.Timestamp(session.end.unix(), 0);
  let updatedSession = {
    id: session.id,
    title: session.title,
    resourceId: session.resourceId,
    sessionType: session.sessionType,
    videoType: session.videoType,
    networkTable: session.networkTable,
    selectedSpeakers: session.selectedSpeakers,
    hosts: session.hosts || null,
    videoId: session.videoId || null,
    start: timestampStart,
    end: timestampEnd,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    category: session.category || '',
    description: session.description || ''
  };
  // If session type changed from pre-recorded to live stream, then the video must be detached
  const oldSession = await getSessionById(eventId, session.id);
  if (
    oldSession.sessionType === PRE_RECORDED_SESSION_TYPE &&
    LIVE_SESSION_TYPES.includes(session.sessionType)
  ) {
    //update video
    const videoDoc = await store
      .doc(
        `${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${oldSession.videoId}`
      )
      .get();

    if (videoDoc.exists) {
      await videoDoc.ref.update({
        session: firebase.firestore.FieldValue.delete(),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
      });

      videoChanges = {
        id: session.videoId,
        changes: {
          session: null,
          modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
        }
      };

      //update session with
      updatedSession = {
        ...updatedSession,
        videoId: null,
        liveVideoUrl: null,
        thumbnailUrl: null
      };
    }
  } else if (session.videoId) {
    const videoDoc = await store
      .doc(
        `${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}/${session.videoId}`
      )
      .get();

    const delay = getDifferenceInMinute(timestampStart, timestampEnd);
    updatedSession.delay = Math.max(
      delay && videoDoc.data().duration / 60 - delay,
      0
    );

    const changesOnVideo = {
      title: session.title,
      startDateTime: timestampStart,
      endDateTime: timestampEnd,
      id: session.id
    };

    videoChanges = {
      id: session.id,
      changes: changesOnVideo
    };

    await videoDoc.ref.update({
      id: session.videoId,
      session: changesOnVideo,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  await sessionRef.update(updatedSession);
  const sessionDoc = await sessionRef.get();

  return {
    sessionChanges: {
      id: sessionDoc.id,
      changes: {
        ...sessionDoc.data(),
        start: timestampStart,
        end: timestampEnd
      }
    },
    videoChanges: videoChanges
  };
}

export async function pushOffSessions(sessions, eventId) {
  const batch = store.batch();
  const sessionChanges = [];
  const videoChanges = [];
  const sessionCollectionRef = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}`
  );
  const videoCollectionRef = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${VIDEOS_COLLECTION}`
  );

  const promises = sessions.map(async session => {
    const pushOffStart = new firebase.firestore.Timestamp(
      session.pushOffStart ? session.pushOffStart : session.start.seconds,
      0
    );
    const pushOffEnd = new firebase.firestore.Timestamp(
      session.pushOffEnd ? session.pushOffEnd : session.end.seconds,
      0
    );

    const changes = {
      start: pushOffStart,
      end: pushOffEnd,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    };

    batch.set(sessionCollectionRef.doc(session.id), changes, {
      merge: true
    });

    sessionChanges.push({
      id: session.id,
      changes
    });

    //Update video assigned with the session
    if (session.videoId) {
      const video = await getVideoById(eventId, session.videoId);
      if (video) {
        const changes = {
          session: {
            startDateTime: pushOffStart,
            endDateTime: pushOffEnd
          },
          modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
        };

        batch.set(videoCollectionRef.doc(video.id), changes, {
          merge: true
        });

        videoChanges.push({
          id: video.id,
          changes
        });
      }
    }
  });

  await Promise.all(promises);

  await batch.commit();

  return {
    sessionChanges,
    videoChanges
  };
}

export async function getSessionById(eventId, sessionId) {
  const documentReference = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(SESSIONS_COLLECTION)
    .doc(sessionId);
  let session = null;
  session = documentReference.get().then(doc => {
    if (doc.exists) {
      const data = doc.data();
      if (data != null) {
        let newSession = {
          id: sessionId,
          title: data.title,
          resourceId: data.resourceId,
          videoType: data.videoType || LECTURE,
          networkTable: data.networkTable || NETWORKING_AREA,
          sessionType: data.sessionType || LIVE_BACKSTAGE_SESSION_TYPE,
          videoId: data.videoId,
          selectedSpeakers: data.selectedSpeakers || [],
          hosts: data.hosts || [],
          start: data.start,
          end: data.end,
          category: data.category,
          description: data.description
        };
        return newSession;
      }
    }
  });

  return session;
}

export async function deleteSession(sessionId, eventId) {
  const sessionsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}`
  );
  const sessionDoc = sessionsCollection.doc(sessionId);
  const sessionData = await sessionDoc.get();
  const session = sessionData.data();
  if (session.videoId) {
    await videoService.updateVideo({
      id: session.videoId,
      session: null
    });
  }

  await sessionDoc.delete();
  return sessionId;
}

export async function getEventSessions(eventId) {
  const documentReference = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(SESSIONS_COLLECTION);
  let sessions = [];
  sessions = documentReference.get().then(snap => {
    const sessionsList = [];
    snap.forEach(doc => {
      if (doc.exists) {
        const data = doc.data();
        let newSession = {
          id: doc.id,
          title: data.title,
          resourceId: data.resourceId,
          videoType: data.videoType || LECTURE,
          networkTable: data.networkTable || NETWORKING_AREA,
          sessionType: data.sessionType || LIVE_BACKSTAGE_SESSION_TYPE,
          videoId: data.videoId,
          selectedSpeakers: data.selectedSpeakers || [],
          hosts: data.hosts || [],
          start: data.start,
          end: data.end,
          createdDate: data.createdDate,
          category: data.category,
          description: data.description
        };
        sessionsList.push(newSession);
      }
    });
    return sessionsList;
  });

  return sessions;
}

export async function getAvailableSessions(eventId) {
  const sessionsDocs = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}`)
    .where('videoId', '==', null)
    .get();

  return sessionsDocs.docs.map(session => ({
    id: session.id,
    ...session.data()
  }));
}

export async function createSessionCategory(category, userId, eventId) {
  const sessionCategoryCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${SESSION_CATEGORIES_COLLECTION}`
  );
  let newCategory = {
    name: category.name,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  };

  const addedCategory = await sessionCategoryCollection.add(newCategory);
  return { ...newCategory, id: addedCategory.id };
}

export async function getEventSessionCategories(eventId) {
  const documentReference = store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(SESSION_CATEGORIES_COLLECTION);
  let sessionCategories = [];
  sessionCategories = documentReference.get().then(snap => {
    const sessionCategoriesList = [];
    snap.forEach(doc => {
      if (doc.exists) {
        const data = doc.data();
        const item = {
          id: doc.id,
          name: data.name,
          createdDate: data.createdDate
        };
        sessionCategoriesList.push(item);
      }
    });
    return sessionCategoriesList;
  });
  return sessionCategories;
}

export async function removeTheOutlawSessions(eventId, sessions) {
  const batch = store.batch();

  /* Remove the video associations */
  sessions.map(async session => {
    if (session.videoId) {
      await videoService.updateVideo({
        id: session.videoId,
        session: null
      });
    }
  });

  /* Remove the sessions */
  sessions.forEach(session => {
    batch.delete(
      store.doc(
        `${EVENTS_COLLECTION}/${eventId}/${SESSIONS_COLLECTION}/${session.id}`
      )
    );
  });
  await batch.commit();
  return sessions.map(session => session.id);
}
