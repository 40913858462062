import networkService from 'src/services/networkService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const CREATE_NETWORK = '@network/create-network';
export const UPDATE_NETWORK = '@network/update-network';
export const UPDATE_NETWORKS = '@network/update-networks';
export const DELETE_NETWORKS = '@network/delete-networks';
export const GET_NETWORK = '@network/get-network';
export const GET_NETWORKS = '@network/get-networks';
export const RESET_NETWORKS = '@network/reset-networks';

export const createNetworks = createAsyncThunk(
  CREATE_NETWORK,
  async networks => {
    const newNetwork = await networkService.createNetworks(networks);
    return newNetwork;
  }
);

export const updateNetwork = createAsyncThunk(UPDATE_NETWORK, async changes => {
  const change = await networkService.updateNetwork(changes);
  return change;
});

export const updateNetworksSort = createAsyncThunk(
  UPDATE_NETWORKS,
  async changes => {
    const change = await networkService.updateNetworksSort(changes);
    return change;
  }
);

export const deleteNetworks = createAsyncThunk(
  DELETE_NETWORKS,
  async networkIds => {
    await networkService.deleteNetworks(networkIds);
    return networkIds;
  }
);

export const getNetworkById = createAsyncThunk(GET_NETWORK, async networkId => {
  const network = await networkService.getNetworkById(networkId);
  return network;
});

export const getNetworks = createAsyncThunk(GET_NETWORKS, async () => {
  const networks = await networkService.getEventNetworks();
  return networks;
});

export const resetNetworks = createAction(RESET_NETWORKS);
