import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import billingService from 'src/services/billingService';

export const CREATE_BILLING_INFORMATION =
  '@billing-information/create-billing-information';

export const GET_BILLING_INFORMATION =
  '@billing-information/get-billing-information';
export const GET_USER_BILLING_FLAGS =
  '@billing-information/get-user-billing-flags';
export const UPDATE_BILLING_INFORMATION =
  '@billing-information/update-billing-information';

export const UPDATE_PAYMENT_INFORMATION =
  '@billing-information/update-payment-information';

export const VERIFY_VAT_NUMBER = '@billing-information/verify-vat-number';
export const RESET_BILLING = '@billing-information/reset-billing';

export const createBillingInformation = createAsyncThunk(
  CREATE_BILLING_INFORMATION,
  async changes => {
    const newInfo = await billingService.createBillingInformation(changes);
    return newInfo;
  }
);

export const updateBillingInformation = createAsyncThunk(
  UPDATE_BILLING_INFORMATION,
  async changes => {
    const change = await billingService.updateBillingInformation(changes);
    return change;
  }
);

export const updatePaymentInformation = createAsyncThunk(
  UPDATE_PAYMENT_INFORMATION,
  async changes => {
    const change = await billingService.updatePaymentInformation(changes);
    return change;
  }
);

export async function updateBillingInformationOrganization(organization) {
  const result = await billingService.updateBillingInformationOrganization(
    organization
  );
  return result;
}

export const getBillingInformation = createAsyncThunk(
  GET_BILLING_INFORMATION,
  async () => {
    const info = await billingService.getBillingInformation();
    return info;
  }
);

export const getUserBillingFlags = createAsyncThunk(
  GET_USER_BILLING_FLAGS,
  async userId => {
    const info = await billingService.getUserBillingFlags(userId);
    return info;
  }
);
export const getUserBillingFlagsFromEvent = createAsyncThunk(
  GET_USER_BILLING_FLAGS,
  async eventId => {
    const info = await billingService.getUserBillingFlagsFromEvent(eventId);
    return info;
  }
);

export const verifyVatNumber = createAsyncThunk(
  VERIFY_VAT_NUMBER,
  async ({ countryCode, vatNumber }) => {
    const validationInfo = await billingService.verifyVatNumber(
      vatNumber,
      countryCode
    );
    return validationInfo;
  }
);

export const resetBilling = createAction(RESET_BILLING);
