import React from 'react';

function Fold() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
      <path d="M21.25,1H9.4a.58.58,0,0,0-.58.58V7.14H2.75a.58.58,0,0,0-.58.58v14.7a.58.58,0,0,0,.58.58H15.28a.64.64,0,0,0,.24-.05h0a.58.58,0,0,0,.2-.18l0-.06a.55.55,0,0,0,.09-.28V17.54h5.38a.58.58,0,0,0,.58-.58V1.58A.58.58,0,0,0,21.25,1ZM3.33,8.31H8.82V17s0,.05,0,.08a.6.6,0,0,0,.18.52l4.76,4.28H3.33ZM14.7,21.11l-4-3.57h4Zm6-4.73H10V2.16H20.67Z" fill="currentColor"/>
    </svg>
  );
}

export default Fold;
