import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import { auth } from 'src/core/api/firebase';
import { generateHubspotToken } from 'src/core/api/UserStore';
import initHelpHero from 'helphero';
function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        const user = await authService.loginInWithToken();
        await dispatch(setUserData(user));
      }
      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  useEffect(() => {
    const unlisten = auth.onIdTokenChanged(async authUser => {
      if (authUser) {
        await authService.getUserIdToken();
        if (authUser.emailVerified) {
          const helpHero = initHelpHero('JLQ1l0NWwj');
          window.helpHero = helpHero;
          helpHero.identify(authUser.uid);
          helpHero.startTour('ckz75txed001s3e6117rodrz1');
        }
        const token = await generateHubspotToken(
          authUser.email,
          authUser.displayName
        );
        window.hsConversationsSettings = {
          identificationEmail: authUser.email,
          identificationToken: token
        };
        window.HubSpotConversations.widget.remove();
        window.HubSpotConversations.widget.load();
      } else {
        window.hsConversationsSettings = {};
        window.HubSpotConversations.widget.remove();
        window.HubSpotConversations.widget.load();
      }
      if (typeof window.fpr === 'function') {
        window.fpr('init', { cid: '5uwus8gu' });
        window.fpr('click');
      }
    });
    return () => {
      unlisten();
    };
  });

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
