import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  getEmailTemplates,
  updateEmailTemplate,
  resetEmailTemplates,
  GET_PREVIEW_STARTED,
  GET_PREVIEW_FINISHED,
  GET_PREVIEW_FAILED
} from 'src/actions/emailTemplateActions';

export const emailTemplateEntityAdapter = createEntityAdapter();

const initialState = emailTemplateEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  previewLoading: false
});

const emailTemplateReducer = createReducer(initialState, {
  [getEmailTemplates.pending]: state => {
    state.loading = true;
  },
  [getEmailTemplates.fulfilled]: (state, { payload }) => {
    state.loaded = true;
    state.loading = false;
    emailTemplateEntityAdapter.upsertMany(state, payload);
  },
  [getEmailTemplates.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [updateEmailTemplate.pending]: (state, _) => {
    state.loading = true;
  },
  [updateEmailTemplate.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    emailTemplateEntityAdapter.updateOne(state, payload);
  },
  [updateEmailTemplate.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [GET_PREVIEW_STARTED]: state => {
    state.previewLoading = true;
  },
  [GET_PREVIEW_FINISHED]: state => {
    state.previewLoading = false;
  },
  [GET_PREVIEW_FAILED]: state => {
    state.previewLoading = false;
  },
  [resetEmailTemplates.type]: (_, __) => {
    return initialState;
  }
});

export default emailTemplateReducer;
