import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { functions } from 'src/core/api/firebase';
import emailTemplateService from 'src/services/emailTemplateService';

export const UPDATE_EMAIL_TEMPLATES = '@email-templates/update-email-templates';
export const GET_EMAIL_TEMPLATES = '@email-templates/get-email-templates';
export const RESET_EMAIL_TEMPLATES = '@email-templates/reset-email-templates';

export const GET_PREVIEW_STARTED = '@email-templates/get-preview-started';
export const GET_PREVIEW_FINISHED = '@email-templates/get-preview-finished';
export const GET_PREVIEW_FAILED = '@email-templates/get-preview-failed';

export const updateEmailTemplate = createAsyncThunk(
  UPDATE_EMAIL_TEMPLATES,
  async changes => {
    const change = await emailTemplateService.updateEmailTemplate(changes);
    return change;
  }
);

export const getEmailTemplates = createAsyncThunk(
  GET_EMAIL_TEMPLATES,
  async () => {
    const info = await emailTemplateService.getEmailTemplates();
    return info;
  }
);

export function getCustomEmailPreview(eventId, template) {
  return async dispatch => {
    dispatch({
      type: GET_PREVIEW_STARTED
    });
    try {
      const preview = await functions.httpsCallable(
        'emails-getCustomEmailPreview'
      )({
        eventId,
        template
      });
      dispatch({
        type: GET_PREVIEW_FINISHED
      });
      return Promise.resolve(preview);
    } catch (error) {
      dispatch({ type: GET_PREVIEW_FAILED });
      throw error;
    }
  };
}

export const resetEmailTemplates = createAction(RESET_EMAIL_TEMPLATES);
