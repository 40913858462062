import {
  getEmailTemplates,
  updateEmailTemplate
} from 'src/core/api/EmailTemplateStore';
import * as routes from 'src/routeConstants';
import { getQueryParameter } from 'src/utils/queryParameter';
import authService from './authService';

class EmailTemplateService {
  updateEmailTemplate = change =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      const userId = authService.getUserUid();
      updateEmailTemplate(eventId, userId, change)
        .then(changes => {
          resolve(changes);
        })
        .catch(error => {
          reject(error);
        });
    });

  getEmailTemplates = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      getEmailTemplates(eventId)
        .then(items => {
          if (items !== null) resolve(items);
        })
        .catch(error => {
          reject(error);
        });
    });
}

export default new EmailTemplateService();
