import firebase from 'firebase';
import moment from 'moment';
import { AM_PM } from 'src/constants';
import { translate } from 'src/translations/i18n';
import { functions, storage, store } from './firebase';
import { generateDefaultLandingPageSections } from './LandingPageSectionStore';

const EVENTS_COLLECTION = 'events';
const USERS_COLLECTION = 'users';
const TICKETS_COLLECTION = 'tickets';
const EVENT_SLUGS_COLLECTION = 'eventSlugs';

export async function createEvent(
  event,
  userId,
  // Need a predefined value because of the firebase doesn't allows undefined values
  connectedAccountId = ''
) {
  const eventsCollection = store.collection(EVENTS_COLLECTION);

  moment.tz.setDefault(event.timezone);
  const startDate = moment(event.startDate)
    .tz(event.timezone, true)
    .toDate();
  const endDate = moment(event.endDate)
    .tz(event.timezone, true)
    .toDate();

  const newEvent = {
    name: event.name,
    headline: event.name, // Requested in EMX-1455
    timezone: event.timezone,
    startDate,
    endDate,
    dateFormat: event.dateFormat,
    currency: event.currency || 'USD',
    published: false,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId,
    connectedAccountId,
    footer: {
      copyrightText: `<strong>© ${moment().get('year')}</strong>`,
      showPoweredByEventMix: true,
      showPrivacyPolicy: true,
      showTermsAndConditions: true
    }
  };

  let eventId = event.eventUrl;
  const createdEvent = await eventsCollection.add(newEvent);
  eventId = createdEvent?.id;
  // Adding the default sections for the landing page.
  await generateDefaultLandingPageSections(eventId);
  // add defaults for header + speaker + session
  // add roles for the users
  // update hubspot
  const addEventDetails = functions.httpsCallable('events-addEventDetails');
  await addEventDetails({ eventId });
  return {
    name: event.name,
    startDate: event.startDate.toISOString(),
    endDate: event.endDate.toISOString(),
    status: getStatus(event.startDate, event.endDate),
    published: false,
    timezone: event.timezone,
    eventId,
    createdBy: userId
  };
}

export async function getEventById(eventId) {
  const documentReference = store.collection(EVENTS_COLLECTION).doc(eventId);
  let event = null;
  event = documentReference.get().then(doc => {
    if (doc.exists) {
      const event = doc.data();
      if (event != null && !event.isArchived) {
        const startDate = getTimestamp(event.startDate);
        const endDate = getTimestamp(event.endDate);
        const userEvent = {
          id: eventId,
          name: event.name,
          headline: event.headline,
          description: event.description,
          about: event.about,
          timezone: event.timezone,
          startDate,
          endDate,
          dateFormat: event.dateFormat,
          currency: event.currency,
          published: event.published || false,
          status: getStatus(startDate, endDate),
          checkoutBlock: event.checkoutBlock,
          footer: event.footer || {
            copyrightText: '',
            showPoweredByEventMix: true,
            showPrivacyPolicy: true,
            showTermsAndConditions: true
          },
          bannerUrl: event.bannerUrl,
          showHeaderOverlay:
            typeof event.showHeaderOverlay === 'undefined' ||
            event.showHeaderOverlay,
          faviconUrl: event.faviconUrl,
          logoUrl: event.logoUrl,
          customization: event.customization,
          customEmails: event.customEmails ? event.customEmails : '',
          defaultCustomDomain: event.defaultCustomDomain
            ? event.defaultCustomDomain
            : '',
          languageId: event.languageId,
          hideUsersLiveCount: event.hideUsersLiveCount || false,
          createdBy: event.createdBy,
          passwordLessRegistration: event?.passwordLessRegistration || false
        };
        return userEvent;
      }
    }
  });

  return event;
}

export async function getEventSlugById(eventId) {
  let eventSlug = eventId;
  const slugSnapshot = await firebase
    .firestore()
    .collection(EVENT_SLUGS_COLLECTION)
    .where('eventId', '==', eventId)
    .limit(1)
    .get();

  if (slugSnapshot) {
    slugSnapshot.forEach(function(doc) {
      eventSlug = doc.id;
    });
  }

  return eventSlug;
}

export async function saveSlug(eventId, newSlug) {
  const oldSlugs = [];
  const slugSnapshot = await firebase
    .firestore()
    .collection(EVENT_SLUGS_COLLECTION)
    .where('eventId', '==', eventId)
    .limit(1)
    .get();

  if (slugSnapshot) {
    slugSnapshot.forEach(function(doc) {
      oldSlugs.push(doc.id);
    });
  }

  if (!oldSlugs.includes(newSlug)) {
    const batch = store.batch();

    oldSlugs.map(oldSlug => {
      batch.delete(
        firebase
          .firestore()
          .collection(EVENT_SLUGS_COLLECTION)
          .doc(oldSlug)
      );
    });

    batch.set(
      firebase
        .firestore()
        .collection(EVENT_SLUGS_COLLECTION)
        .doc(newSlug),
      { eventId: eventId }
    );

    await batch.commit();
  }
  return newSlug;
}

export async function verifySlug(newSlug) {
  const doc = await firebase
    .firestore()
    .collection(EVENT_SLUGS_COLLECTION)
    .doc(newSlug)
    .get();

  return doc.exists ? false : true;
}

export async function updateEventPartial({ id, changes }, userId) {
  const eventDoc = await store.doc(`${EVENTS_COLLECTION}/${id}`).get();
  await eventDoc.ref.set(
    {
      ...changes,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedBy: userId
    },
    { merge: true }
  );
  const { startDate, endDate, ...rest } = eventDoc.data();
  return {
    id,
    changes: {
      ...rest,
      startDate: startDate.toDate ? startDate.toDate() : startDate,
      endDate: endDate.toDate ? endDate.toDate() : endDate,
      ...changes,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedBy: userId
    }
  };
}

export async function updateEvent(event, userId) {
  const eventsCollection = store.collection(EVENTS_COLLECTION);
  const eventDoc = eventsCollection.doc(event.id);

  moment.tz.setDefault(event.timezone);
  const startDate = moment(event.startDate)
    .tz(event.timezone, true)
    .toDate();
  const endDate = moment(event.endDate)
    .tz(event.timezone, true)
    .toDate();

  let theUpdatedEvent = {
    name: event.name,
    headline: event.headline || '',
    about: event.about || '',
    description: event.description || '',
    timezone: event.timezone,
    startDate,
    endDate,
    dateFormat: event.dateFormat || AM_PM,
    published: event.published,
    currency: event.currency,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedBy: userId,
    footer: event.footer || '',
    hideUsersLiveCount: event.hideUsersLiveCount || false,
    showHeaderOverlay: event.showHeaderOverlay
  };

  if (
    event.checkoutBlock &&
    event.checkoutBlock.poster &&
    typeof event.checkoutBlock.poster === 'object'
  ) {
    theUpdatedEvent.checkoutBlock = {
      title: event.checkoutBlock.title || '',
      description: event.checkoutBlock.description || '',
      video: event.checkoutBlock.video || '',
      defaultVideoThumbnail: event.checkoutBlock.defaultVideoThumbnail || '',
      poster:
        event.checkoutBlock.poster.raw === ''
          ? event.checkoutBlock.poster.url
          : '',
      registerFreeButtonLabel: event.checkoutBlock.registerFreeButtonLabel,
      buyTicketButtonLabel: event.checkoutBlock.buyTicketButtonLabel
    };
  }

  await eventDoc.update(theUpdatedEvent);

  theUpdatedEvent = {
    ...theUpdatedEvent,
    status: getStatus(startDate, endDate),
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    eventId: event.id
  };

  let eventLogo = event.logoUrl?.url || event.logoUrl || '';
  if (event.logoUrl && event.logoUrl?.raw) {
    const imageName = `events/${event.id}/assets/${event.name}-logo`;
    const uploadTask = await storage.ref(imageName).put(event.logoUrl.raw);
    eventLogo = await uploadTask.ref.getDownloadURL();
  }

  let eventBanner = event.bannerUrl?.url || event.bannerUrl || '';
  if (event.bannerUrl && event.bannerUrl?.raw) {
    const imageName = `events/${event.id}/assets/${event.name}-banner`;
    const uploadTask = await storage.ref(imageName).put(event.bannerUrl.raw);
    eventBanner = await uploadTask.ref.getDownloadURL();
  }

  let eventMetaImage = event.metaImageUrl?.url || event.metaImageUrl || '';
  if (event.metaImageUrl && event.metaImageUrl?.raw) {
    const imageName = `events/${event.id}/assets/${event.name}-metaImage`;
    const uploadTask = await storage.ref(imageName).put(event.metaImageUrl.raw);
    eventMetaImage = await uploadTask.ref.getDownloadURL();
  }

  let eventFavicon = event.faviconUrl?.url || event.faviconUrl || '';
  if (event.faviconUrl && event.faviconUrl?.raw) {
    const imageName = `events/${event.id}/assets/${event.name}-favicon`;
    const uploadTask = await storage.ref(imageName).put(event.faviconUrl.raw);
    eventFavicon = await uploadTask.ref.getDownloadURL();
  }

  theUpdatedEvent = {
    ...theUpdatedEvent,
    logoUrl: eventLogo,
    bannerUrl: eventBanner,
    metaImageUrl: eventMetaImage,
    faviconUrl: eventFavicon
  };

  await eventDoc.update({
    logoUrl: eventLogo,
    bannerUrl: eventBanner,
    metaImageUrl: eventMetaImage,
    faviconUrl: eventFavicon,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  if (
    event.checkoutBlock &&
    event.checkoutBlock.poster &&
    event.checkoutBlock.poster.raw
  ) {
    const imageName = `events/${event.id}/assets/${event.name}-poster`;
    const uploadTask = await storage
      .ref(imageName)
      .put(event.checkoutBlock.poster.raw);
    const downloadUrl = await uploadTask.ref.getDownloadURL();
    theUpdatedEvent = {
      ...theUpdatedEvent,
      checkoutBlock: {
        ...event.checkoutBlock,
        poster: downloadUrl
      }
    };
    await eventDoc.update({
      checkoutBlock: {
        ...event.checkoutBlock,
        poster: downloadUrl
      },
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  return theUpdatedEvent;
}
export async function updateEventHeader(event, userId) {
  const eventsCollection = store.collection(EVENTS_COLLECTION);
  const eventDoc = eventsCollection.doc(event.id);

  let eventLogo = event.logoUrl?.url || event.logoUrl || '';
  if (event.logoUrl && event.logoUrl?.raw) {
    const imageName = `events/${event.id}/assets/${event.name}-logo`;
    const uploadTask = await storage.ref(imageName).put(event.logoUrl.raw);
    eventLogo = await uploadTask.ref.getDownloadURL();
  }

  let eventBanner = event.bannerUrl?.url || event.bannerUrl || '';
  if (event.bannerUrl && event.bannerUrl?.raw) {
    const imageName = `events/${event.id}/assets/${event.name}-banner`;
    const uploadTask = await storage.ref(imageName).put(event.bannerUrl.raw);
    eventBanner = await uploadTask.ref.getDownloadURL();
  }

  let eventMetaImage = event.metaImageUrl?.url || event.metaImageUrl || '';
  if (event.metaImageUrl && event.metaImageUrl?.raw) {
    const imageName = `events/${event.id}/assets/${event.name}-metaImage`;
    const uploadTask = await storage.ref(imageName).put(event.metaImageUrl.raw);
    eventMetaImage = await uploadTask.ref.getDownloadURL();
  }
  let eventFavicon = event.faviconUrl?.url || event.faviconUrl || '';
  if (event.faviconUrl && event.faviconUrl?.raw) {
    const imageName = `events/${event.id}/assets/${event.name}-favicon`;
    const uploadTask = await storage.ref(imageName).put(event.faviconUrl.raw);
    eventFavicon = await uploadTask.ref.getDownloadURL();
  }

  const theUpdatedEvent = {
    headline: event.headline || '',
    description: event.description || '',
    showHeaderOverlay: event.showHeaderOverlay,
    modifiedBy: userId,
    logoUrl: eventLogo,
    bannerUrl: eventBanner,
    metaImageUrl: eventMetaImage,
    faviconUrl: eventFavicon
  };

  await eventDoc.update({
    ...theUpdatedEvent,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  return theUpdatedEvent;
}

export async function updateEventTicketBox(event, userId) {
  const eventsCollection = store.collection(EVENTS_COLLECTION);
  const eventDoc = eventsCollection.doc(event.id);
  let theUpdatedEvent = {};
  if (
    event.checkoutBlock &&
    event.checkoutBlock.poster &&
    typeof event.checkoutBlock.poster === 'object'
  ) {
    theUpdatedEvent.checkoutBlock = {
      title: event.checkoutBlock.title || '',
      description: event.checkoutBlock.description || '',
      video: event.checkoutBlock.video || '',
      defaultVideoThumbnail: event.checkoutBlock.defaultVideoThumbnail || '',
      poster:
        event.checkoutBlock.poster.raw === ''
          ? event.checkoutBlock.poster.url
          : '',
      registerFreeButtonLabel: event.checkoutBlock.registerFreeButtonLabel,
      buyTicketButtonLabel: event.checkoutBlock.buyTicketButtonLabel
    };
  }
  if (
    event.checkoutBlock &&
    event.checkoutBlock.poster &&
    event.checkoutBlock.poster.raw
  ) {
    const imageName = `events/${event.id}/assets/${event.name}-poster`;
    const uploadTask = await storage
      .ref(imageName)
      .put(event.checkoutBlock.poster.raw);
    const downloadUrl = await uploadTask.ref.getDownloadURL();
    theUpdatedEvent = {
      ...theUpdatedEvent,
      modifiedBy: userId,
      checkoutBlock: {
        ...event.checkoutBlock,
        poster: downloadUrl
      }
    };
  }
  await eventDoc.update({
    ...theUpdatedEvent,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  return theUpdatedEvent;
}
export async function deleteEvent(eventId) {
  const eventsCollection = store.collection(EVENTS_COLLECTION);
  const eventDoc = eventsCollection.doc(eventId);

  await eventDoc.delete();

  return eventId;
}

export async function archiveEvent(eventId) {
  const eventsCollection = store.collection(EVENTS_COLLECTION);
  const eventDoc = eventsCollection.doc(eventId);

  await eventDoc.update({
    isArchived: true
  });
  return eventId;
}

export async function getUserEvents(userId) {
  const documentReference = store.collection(USERS_COLLECTION).doc(userId);
  let events = [];
  events = documentReference
    .get()
    .then(doc => {
      if (doc.exists) {
        const data = doc.data();
        if (data.events != null) {
          const eventList = [];
          data.events.forEach(event => {
            if (!event.isArchived) {
              const startDate = getTimestamp(event.startDate);
              const endDate = getTimestamp(event.endDate);
              const userEvent = {
                name: event.name,
                startDate,
                endDate,
                eventId: event.eventId,
                status: getStatus(startDate, endDate),
                published: event.published || false,
                timezone: event.timezone
              };

              eventList.push(userEvent);
            }
          });
          return eventList;
        }
      }
    })
    .catch(error => {
      console.log(error);
    });
  return events;
}

export async function getUserEventsWithTickets(userId) {
  const userDoc = await store
    .collection(USERS_COLLECTION)
    .doc(userId)
    .get();
  if (!userDoc.exists) return [];
  const { events } = userDoc.data();
  if (!events || !events.length) return [];
  const queries = events.map(async ({ eventId, name }) => {
    const ticketsQuery = await store
      .collection(EVENTS_COLLECTION)
      .doc(eventId)
      .collection(TICKETS_COLLECTION)
      .get();
    if (ticketsQuery.empty) return { eventId, name, tickets: [] };
    return {
      eventId,
      name,
      tickets: ticketsQuery.docs.map(doc => ({
        name: doc.data().name,
        ticketId: doc.id
      }))
    };
  });
  return await Promise.all(queries);
}

export async function getAllEventIdsByUsers(userIds) {
  const eventsCollectionRef = await store
    .collection(EVENTS_COLLECTION)
    .where('createdBy', 'in', userIds)
    .get();
  let eventIds = [];
  eventsCollectionRef.docs.map(doc => {
    eventIds.push(doc.id);
  });

  return eventIds;
}

const getStatus = (startDate, endDate) => {
  moment.tz.setDefault('UTC');
  startDate = moment(startDate).tz('UTC', true);
  endDate = moment(endDate).tz('UTC', true);

  const currentDate = moment();
  const startDifference = startDate.diff(currentDate, 'minutes');
  const endDifference = endDate.diff(currentDate, 'minutes');

  const status =
    startDifference <= 0
      ? endDifference < 0
        ? { id: 0, name: translate('event.list.past') }
        : { id: 1, name: translate('event.list.live') }
      : { id: 2, name: translate('event.list.upcoming') };

  return status;
};

const getTimestamp = date => {
  const d = date;
  date = new firebase.firestore.Timestamp(d.seconds, d.nanoseconds).toDate();
  return date;
};
