import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import landingPageSectionService from 'src/services/landingPageSectionService';

export const CREATE_SECTION = '@section/create-section';
export const UPDATE_SECTION = '@section/update-section';
export const DELETE_SECTION = '@section/delete-section';
export const GET_SECTION = '@section/get-section';
export const GET_SECTIONS = '@section/get-sections';
export const RESET_SECTIONS = '@section/reset-sections';
export const UPDATE_SECTIONS_ORDER = '@section/update-sections-order';

export const getSections = createAsyncThunk(GET_SECTIONS, async () => {
  const sections = await landingPageSectionService.getSections();
  return sections;
});

export const updateSectionsSort = createAsyncThunk(
  UPDATE_SECTIONS_ORDER,
  async changes => {
    const change = await landingPageSectionService.updateSectionsSort(changes);
    return change;
  }
);

export const updateSection = createAsyncThunk(UPDATE_SECTION, async changes => {
  const change = await landingPageSectionService.updateSection(changes);
  return change;
});

export const createSection = createAsyncThunk(CREATE_SECTION, async section => {
  const newSection = await landingPageSectionService.createSection(section);
  return newSection;
});

export const getSectionById = createAsyncThunk(GET_SECTION, async sectionId => {
  const section = await landingPageSectionService.getSectionById(sectionId);
  return section;
});

export const resetSections = createAction(RESET_SECTIONS);
