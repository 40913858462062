import React from 'react';

function Ticket({ style = { marginRight: '16px', display: 'flex', alignItems: 'center' } }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} width="20" height="20" viewBox="0 0 24 24">
      <path
        d="M9.39,23A.55.55,0,0,1,9,22.85L6.76,20.58a.5.5,0,0,1,0-.73,1.87,1.87,0,0,0,0-2.61,1.9,1.9,0,0,0-2.61,0,.52.52,0,0,1-.73,0L1.15,15a.53.53,0,0,1,0-.74L14.24,1.15a.53.53,0,0,1,.74,0l2.27,2.28a.5.5,0,0,1,0,.71,1.83,1.83,0,0,0,.06,2.53h0a1.83,1.83,0,0,0,2.53.06.51.51,0,0,1,.71,0L22.85,9a.53.53,0,0,1,0,.74L9.76,22.85A.53.53,0,0,1,9.39,23ZM7.82,20.18l1.57,1.57L21.75,9.39,20.16,7.8a2.88,2.88,0,0,1-3.56-.4h0a2.88,2.88,0,0,1-.4-3.56L14.61,2.25,2.25,14.61l1.57,1.57a2.87,2.87,0,0,1,4,4Z"
        fill="currentColor"
      />
      <path
        d="M17.69,14a.52.52,0,0,1-.36-.16L10,6.47a.5.5,0,0,1,0-.73.52.52,0,0,1,.73,0l7.33,7.33a.53.53,0,0,1-.37.89Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Ticket;
