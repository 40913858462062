import categoryService from 'src/services/sponsorCategoryService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const CREATE_CATEGORY = '@category/create-category';
export const UPDATE_CATEGORY = '@category/update-category';
export const UPDATE_CATEGORIES = '@category/update-categories';
export const DELETE_CATEGORY = '@category/delete-category';
export const GET_CATEGORY = '@category/get-category';
export const GET_CATEGORIES = '@category/get-categories';
export const RESET_CATEGORIES = '@category/reset-categories';

export const createCategory = createAsyncThunk(CREATE_CATEGORY, async category => {
  const newCategory = await categoryService.createCategory(category);
  return newCategory;
});

export const updateCategory = createAsyncThunk(UPDATE_CATEGORY, async changes => {
  const change = await categoryService.updateCategory(changes);
  return change;
});

export const updateCategoriesSort = createAsyncThunk(UPDATE_CATEGORIES, async changes => {
  const change = await categoryService.updateCategoriesSort(changes);
  return change;
});

export const deleteCategory = createAsyncThunk(DELETE_CATEGORY, async categoryId => {
  await categoryService.deleteCategory(categoryId);
  return categoryId;
});

export const getCategoryById = createAsyncThunk(GET_CATEGORY, async categoryId => {
  const category = await categoryService.getCategoryById(categoryId);
  return category;
});

export const getCategories = createAsyncThunk(GET_CATEGORIES, async () => {
  const categories = await categoryService.getEventCategories();
  return categories;
});

export const resetCategories = createAction(RESET_CATEGORIES);

