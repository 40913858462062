import React from 'react';
import {
  makeStyles,
  CircularProgress,
  Backdrop,
  Typography,
  Box
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  messageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: 16,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.grey[300]
  },
  typography: {
    marginRight: 16
  }
}));

function CustomLoader({ message }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open>
      {message ? (
        <Box className={classes.messageBox}>
          <Typography className={classes.typography}>{message}</Typography>
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Backdrop>
  );
}

export default CustomLoader;
