import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from 'src/services/authService';
import videoService from 'src/services/videoService';
import {
  ASSIGN_VIDEO_TO_SESSION,
  DELETE_VIDEO,
  GET_VIDEOS,
  GET_VIDEO_BY_ID,
  START_VIDEO_BY_ID_STATUS_LISTENING,
  START_VIDEO_STATUS_LISTENING,
  UPLOAD_VIDEO,
  UPDATE_VIDEO,
  GET_VIDEO_CATEGORIES,
  VIDEO_CATEGORY_CREATED,
  REMOVE_LOBBY_ASSIGNMENT_FROM_VIDEOS
} from './videoAction-types';

export const uploadVideo = createAsyncThunk(
  UPLOAD_VIDEO,
  async (
    { file, videoType, videoName, noCategory, displayOnLobby, loopVideo },
    { dispatch }
  ) => {
    const accessToken = authService.getAccessToken();
    const video = await videoService.uploadVideo(
      file,
      dispatch,
      accessToken,
      videoType,
      videoName,
      noCategory,
      displayOnLobby,
      loopVideo
    );
    return video;
  }
);

export const listenVideoStatusById = createAsyncThunk(
  START_VIDEO_BY_ID_STATUS_LISTENING,
  async (videoId, { dispatch }) => {
    const unsubscribe = await videoService.listenVideoStatusById(
      dispatch,
      videoId
    );
    return unsubscribe;
  }
);

export const getVideos = createAsyncThunk(GET_VIDEOS, async _ => {
  const videos = await videoService.getEventVideos();
  return videos;
});

export const startVideoStatusListening = createAsyncThunk(
  START_VIDEO_STATUS_LISTENING,
  async (_, { dispatch }) => {
    const unsubscribe = await videoService.listeningToCompletedVideos(dispatch);
    return unsubscribe;
  }
);

export const deleteVideo = createAsyncThunk(DELETE_VIDEO, async video => {
  await videoService.deleteVideo(video);
  return video.id;
});

export const getVideoById = createAsyncThunk(GET_VIDEO_BY_ID, async videoId => {
  const video = await videoService.getVideoById(videoId);
  return video;
});

export const assignVideoToSession = createAsyncThunk(
  ASSIGN_VIDEO_TO_SESSION,
  async changes => {
    const change = await videoService.assignVideoToSession(changes);
    return change;
  }
);

export const updateVideo = createAsyncThunk(UPDATE_VIDEO, async video => {
  const change = await videoService.updateVideo(video);
  return change;
});

export const removeLobbyAssignmentFromVideos = createAsyncThunk(
  REMOVE_LOBBY_ASSIGNMENT_FROM_VIDEOS,
  async () => {
    const changes = await videoService.removeLobbyAssignment();
    return changes;
  }
);

export const getVideoCategories = createAsyncThunk(
  GET_VIDEO_CATEGORIES,
  async () => {
    let videoCategories = await videoService.getEventVideoCategories();
    return videoCategories;
  }
);

export const createVideoCategory = createAsyncThunk(
  VIDEO_CATEGORY_CREATED,
  async category => {
    const newCategory = await videoService.createVideoCategory(category);
    return newCategory;
  }
);
