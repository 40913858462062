import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import integrationsService from 'src/services/integrationService';

export const UPDATE_INTEGRATION_TRACKING_CODES =
  '@integrations/update-integration-tracking';
export const GET_INTEGRATION_TRACKING_CODES =
  '@integrations/get-integration-tracking';
export const RESET_INTEGRATION_TRACKING_CODES =
  '@integrations/reset-integration-tracking';

export const updateIntegrationTrackingCodes = createAsyncThunk(
  UPDATE_INTEGRATION_TRACKING_CODES,
  async changes => {
    const change = await integrationsService.updateIntegrationTrackingCodes(
      changes
    );
    return change;
  }
);

export const getIntegrationTrackingCodes = createAsyncThunk(
  GET_INTEGRATION_TRACKING_CODES,
  async () => {
    const info = await integrationsService.getIntegrationTrackingCodes();
    return info;
  }
);

export const resetIntegrationTrackingCodes = createAction(
  RESET_INTEGRATION_TRACKING_CODES
);
