import React from 'react';
import { Dialog as MatDialog } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { dialogSelectors } from 'src/reducers/dialogReducer';
import { closeDialog, removeDialog } from 'src/actions/dialogActions';

function Dialog() {
  const configs = useSelector(dialogSelectors.selectAll);
  const dispatch = useDispatch();
  if (!configs) return null;

  return (
    <>
      {configs.map(({ children, id, onClose, onExited, ...rest }) => (
        <MatDialog
          onClose={e => {
            onClose && onClose(e);
            dispatch(closeDialog({ id }));
          }}
          TransitionProps={{
            onExited: e => {
              onExited && onExited(e);
              dispatch(removeDialog({ id }));
            }
          }}
          key={id}
          {...rest}
        >
          {children}
        </MatDialog>
      ))}
    </>
  );
}

export default Dialog;
