import {
  createBenefit,
  updateBenefit,
  updateBenefitsSort,
  deleteBenefit,
  getBenefitById,
  getBenefits,
  resetBenefits,
} from 'src/actions/benefitActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const benefitEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = benefitEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const benefitReducer = createReducer(initialState, {
  [createBenefit.pending]: (state, _) => {
    state.loading = true;
  },
  [createBenefit.fulfilled]: (state, { payload }) => {
    state.loading = false;
    benefitEntityAdapter.upsertOne(state, payload);
  },
  [createBenefit.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateBenefit.pending]: (state, _) => {
    state.loading = true;
  },
  [updateBenefit.fulfilled]: (state, { payload }) => {
    state.loading = false;
    benefitEntityAdapter.updateOne(state, payload);
  },
  [updateBenefit.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateBenefitsSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateBenefitsSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    benefitEntityAdapter.upsertMany(state, payload);
  },
  [updateBenefitsSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteBenefit.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteBenefit.fulfilled]: (state, { payload }) => {
    state.loading = false;
    benefitEntityAdapter.removeOne(state, payload);
  },
  [deleteBenefit.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getBenefitById.pending]: (state, _) => {
    state.loading = true;
  },
  [getBenefitById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    benefitEntityAdapter.upsertOne(state, payload);
  },
  [getBenefitById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getBenefits.pending]: (state, _) => {
    state.loading = true;
  },
  [getBenefits.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    benefitEntityAdapter.upsertMany(state, payload);
  },
  [getBenefits.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetBenefits.type]: (_, __) => {
    return initialState;
  }
});

export default benefitReducer;
