import firebase from 'firebase';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const LEGAL_INFORMATION_COLLECTION = 'legal-informations';

export async function createLegalInformation(eventId, userId, legalInformation) {
  const legalInformationsCollectionRef = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${LEGAL_INFORMATION_COLLECTION}`
  );
  const legalInformationRef = await legalInformationsCollectionRef.add({
    type: legalInformation.type,
    text: legalInformation.text,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  });

  const doc = await legalInformationRef.get();
  await doc.ref.update({
    id: doc.id
  });

  return {
    ...doc.data(),
    id: doc.id
  };
}

export async function updateLegalInformation(eventId, userId, changes) {
  const legalInformationDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${LEGAL_INFORMATION_COLLECTION}`)
    .doc(changes.id);

  await legalInformationDoc.update({
    text: changes.text,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedBy: userId
  });

  const doc = await legalInformationDoc.get();

  return {
    changes: {
      ...doc.data(),
    },
    id: doc.id
  };
}

export async function getLegalInformations(eventId) {
  const legacies = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(LEGAL_INFORMATION_COLLECTION)
    .get();

  return legacies.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}