import React from 'react';
import { IconButton, SvgIcon, Tooltip, makeStyles } from '@material-ui/core';
import { Bell as BellIcon } from 'react-feather';
import AnnounceKit from 'announcekit-react';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.contrastText
  }
}))

function Notifications() {
  const classes = useStyles()
  return (
    <Tooltip title="What's new">
      <IconButton>
        <AnnounceKit widget="https://announcekit.app/widgets/v2/2cjAas">
          <SvgIcon className={classes.icon} >
            <BellIcon />
          </SvgIcon>
        </AnnounceKit>
      </IconButton>
    </Tooltip>
  );
}

export default Notifications;
