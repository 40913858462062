import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import {
  getEventSettings,
  updateEventChatSettings,
  updateEventQASettings,
  updateEventPeopleSettings,
  updateEventLabelSettings
} from '../core/api/EventSettingsStore';

class EventSettingsService {
  updateEventChatSettings = chatSettings =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateEventChatSettings(chatSettings, eventId)
        .then(newChatSettings => {
          resolve(newChatSettings);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateEventQASettings = qaSettings =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateEventQASettings(qaSettings, eventId)
        .then(newQASettings => {
          resolve(newQASettings);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateEventPeopleSettings = peopleSettings =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateEventPeopleSettings(peopleSettings, eventId)
        .then(newPeopleSettings => {
          resolve(newPeopleSettings);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateEventLabelSettings = labelSettings =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateEventLabelSettings(labelSettings, eventId)
        .then(item => {
          resolve(item);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventSettings = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventSettings(eventId)
        .then(settings => {
          resolve(settings);
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new EventSettingsService();
