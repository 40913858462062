import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import {
  createBooth,
  getEventBooths,
  deleteBooth,
  getBoothById,
  updateBooth,
  updateBoothsSort,
  updateBoothIsPublished
} from '../core/api/BoothStore';
import { translate } from 'i18n-js';
import packageService from './packageService';
import { BOOTH_TYPE } from 'src/constants/packagesConstants';

class BoothService {
  createBooth = booth =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);
      packageService
        .validatePackage(BOOTH_TYPE, {})
        .then(() => {
          createBooth(booth, userId, eventId)
            .then(newBooth => {
              resolve(newBooth);
            })
            .catch(error => {
              reject(error.message);
            });
        })
        .catch(err => reject(err));
    });

  getEventBooths = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventBooths(eventId)
        .then(booths => {
          if (booths != null) {
            resolve(this.sortList(booths));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteBooth = boothId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteBooth(boothId, eventId)
        .then(booth => {
          resolve(booth);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getBoothById = boothId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getBoothById(eventId, boothId)
        .then(booth => {
          if (booth != null) {
            resolve(booth);
          } else {
            reject(translate('booths.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateBooth = booth =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateBooth(booth, eventId)
        .then(newBooth => {
          resolve(newBooth);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateBoothsSort = booths =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateBoothsSort(booths, eventId)
        .then(newBooths => {
          resolve(newBooths);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateBoothIsPublished = (boothId, actionValue) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateBoothIsPublished(boothId, eventId, actionValue)
        .then(booth => {
          resolve(booth);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortList = list => {
    let sorted = list.sort((a, b) =>
      a.hasOwnProperty('sort') && b.hasOwnProperty('sort')
        ? a.sort - b.sort
        : a.createdDate - b.createdDate
    );
    let result = sorted.map((s, index) => (s = { ...s, sort: index }));
    return result;
  };
}

export default new BoothService();
