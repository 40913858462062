import firebase from 'firebase/app';
import 'firebase/analytics';

function logEvent(eventName, eventParams) {
  firebase.analytics().logEvent(eventName, eventParams);
}

export default {
  logEvent
};
