import { store } from './firebase';
import firebase from 'firebase';

const SETTINGS_COLLECTION = 'settings';
const TERMS_CONDITIONS_DOCUMENT = 'terms-conditions';
const BILLING_DOCUMENT = 'billing';
const PACKAGES_COLLECTION = 'packages';
const VAT_DOCUMENT = 'vat-rates';

export async function getTermsAndConditions() {
  const settingsDoc = store
    .collection(SETTINGS_COLLECTION)
    .doc(TERMS_CONDITIONS_DOCUMENT);
  const settings = await settingsDoc.get();
  if (!settings.exists) return null;
  let settingsData = settings.data();
  let date = getTimestamp(settingsData.modifiedDate);
  return {
    modifiedDate: date.toDateString(),
    ...settings.data()
  };
}

export async function getDefaultBillingFees() {
  const billingDoc = store
    .collection(SETTINGS_COLLECTION)
    .doc(BILLING_DOCUMENT);

  const billing = await billingDoc.get();
  if (!billing.exists) return null;
  return {
    ...billing.data()
  };
}

export async function getBillingFeesByPackage(packageId) {
  const billingDoc = store
    .collection(SETTINGS_COLLECTION)
    .doc(BILLING_DOCUMENT)
    .collection(PACKAGES_COLLECTION)
    .doc(packageId);

  const billing = await billingDoc.get();
  if (!billing.exists) return null;
  return {
    ...billing.data()
  };
}

export async function getCountryTaxes() {
  const vatDoc = await store
    .collection(SETTINGS_COLLECTION)
    .doc(VAT_DOCUMENT)
    .get();

  if (!vatDoc.exists) return null;

  return {
    ...vatDoc.data()
  };
}

const getTimestamp = date => {
  const d = date;
  date = new firebase.firestore.Timestamp(d.seconds, d.nanoseconds).toDate();
  return date;
};
