import {
  createSession,
  getSessionById,
  updateSession,
  deleteSession,
  pushOffSessions,
  getEventSessions,
  getAvailableSessions,
  createSessionCategory,
  getEventSessionCategories,
  removeTheOutlawSessions
} from './../core/api/SessionStore';
import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import packageService from './packageService';
import { SPEAKER_TYPE } from 'src/constants/packagesConstants';

class SessionService {
  createSession = session =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      packageService
        .validatePackage(SPEAKER_TYPE, {
          speakersNumber: session.selectedSpeakers.length
        })
        .then(() => {
          createSession(session, userId, eventId)
            .then(newSession => {
              resolve(newSession);
            })
            .catch(error => {
              reject(error.message);
            });
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getSessionById = sessionId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getSessionById(eventId, sessionId)
        .then(session => {
          if (session != null) {
            resolve(session);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateSession = session =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      packageService
        .validatePackage(SPEAKER_TYPE, {
          speakersNumber: session.selectedSpeakers.length
        })
        .then(() => {
          updateSession(session, eventId)
            .then(newSession => {
              resolve(newSession);
            })
            .catch(error => {
              reject(error.message);
            });
        })
        .catch(err => reject(err));
    });

  pushOffSessions = sessions =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      pushOffSessions(sessions, eventId)
        .then(sessionChanges => {
          resolve(sessionChanges);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteSession = sessionId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteSession(sessionId, eventId)
        .then(session => {
          resolve(session);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventSessions = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventSessions(eventId)
        .then(sessions => {
          if (sessions != null) {
            resolve(sessions);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getAvailableSessions = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getAvailableSessions(eventId)
        .then(sessions => {
          if (sessions != null) {
            resolve(sessions);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  createSessionCategory = category =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createSessionCategory(category, userId, eventId)
        .then(newCategory => {
          resolve(newCategory);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventSessionCategories = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventSessionCategories(eventId)
        .then(sessionCategories => {
          if (sessionCategories != null) {
            resolve(sessionCategories);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  removeOutlawSessions = sessions =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      removeTheOutlawSessions(eventId, sessions)
        .then(sessions => {
          if (sessions != null) {
            resolve(sessions);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new SessionService();
