import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, Link, makeStyles } from '@material-ui/core';
import { translate } from 'src/translations/i18n';
import { selectBillingInformation } from 'src/selectors/billingInformationSelectors';
import * as routes from 'src/routeConstants';
import { EVENT } from 'src/routeConstants';
import { selectSettingsLoading } from 'src/selectors/settingsSelectors';
import { getUserPackage } from 'src/actions/packageActions';
import {
  selectPackageLoading,
  selectUserPackage
} from 'src/selectors/packageSelectors';
import {
  FREE_PLAN,
  FREE_ATTENDEES_LIMIT_WARNING
} from 'src/constants/packagesConstants';
import { getQueryParameter } from 'src/utils/queryParameter';
import { checkForFreeAttendeeLimitExceeded } from 'src/utils/planUtils';
const useStyles = makeStyles(() => ({
  root: {
    color: 'rgb(76, 72, 82)',
    textAlign: 'center',
    textTransform: 'none',
    wordBreak: 'break-word',
    backgroundColor: 'rgb(255, 219, 151)',
    padding: '2px 16px',
    position: 'relative',
    zIndex: '0'
  }
}));

function BillingDetailsVerificationBar({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserPackage());
  }, [dispatch]);
  const userPlan = useSelector(selectUserPackage);
  const billingInformation = useSelector(selectBillingInformation);
  const settingsLoading = useSelector(selectSettingsLoading);
  const planLoading = useSelector(selectPackageLoading);
  const usedRegistrations = userPlan?.freeTicketsCount;
  const eventId = getQueryParameter(EVENT);
  const hasFreePlan = userPlan?.type === FREE_PLAN;

  const freeAttendeesLimitExceeded = checkForFreeAttendeeLimitExceeded(
    eventId,
    hasFreePlan,
    usedRegistrations
  );

  const hasCreditCard = !!billingInformation?.paymentCard;
  if (settingsLoading || planLoading) {
    return <></>;
  }
  const showAttendeesLimitExceededBar =
    !hasCreditCard && hasFreePlan && freeAttendeesLimitExceeded;
  return showAttendeesLimitExceededBar ? (
    <div className={clsx(classes.root, className)} {...rest}>
      <Typography variant="body2" color="inherit">
        {translate('topBar.moreThan70FreeRegistrations', {
          registrationLimitWarning: FREE_ATTENDEES_LIMIT_WARNING,
          includedRegistrations: userPlan?.includedFreeRegistrations,
          event: eventId
            ? translate('topBar.thisEvent')
            : translate('topBar.oneOfYourEvents')
        })}
        <Link
          color="inherit"
          underline="always"
          to={`${routes.BILLING}${routes.BILLING_INFORMATION}`}
          component={RouterLink}
        >
          {translate('topBar.addBillingDetails')}
        </Link>
        {translate('topBar.inOrderToAddAttendees', {
          includedRegistrations: userPlan?.includedFreeRegistrations
        })}
      </Typography>
    </div>
  ) : null;
}

BillingDetailsVerificationBar.propTypes = {
  className: PropTypes.string
};

export default BillingDetailsVerificationBar;
