import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as routes from 'src/routeConstants';

function GuestGuard({ children }) {
  const account = useSelector(state => state.account);
  const urlParams = new URLSearchParams(window.location.search);
  const hasToVerify = urlParams.get('verifyEmail');
  if (hasToVerify) {
    return children;
  }
  if (account.user) {
    return <Redirect to={routes.EVENTS} />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
