import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Container,
  Box,
  Divider,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import LinkTabs from 'src/components/LinkTabs/LinkTabs';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';
import { useLocation } from 'react-router';
import BillingDetailsVerificationBar from 'src/layouts/TicketsLayout/BillingDetailsVerificationBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function AttendeesLayout({ children }) {
  const classes = useStyles();
  const { pathname: currentPath } = useLocation();

  return (
    <Page className={classes.root} title={translate('pageTitle.attendees')}>
      <Container maxWidth="lg">
        <Typography variant="h3" color="textPrimary" className="pageTitleStyle">
          {translate(`attendees.pageName`)}
        </Typography>
        <Box mt={3}>
          <LinkTabs
            currentPath={currentPath}
            paths={{
              [`${routes.ATTENDEES}`]: {
                label: translate('attendees.tabs.overview'),
                index: 0
              },
              [`${routes.ATTENDEES}${routes.SCAN_QR}`]: {
                label: translate('attendees.tabs.scanQr'),
                index: 1
              },
            }}
          />
        </Box>
        <Divider />
        {children}
      </Container>
    </Page>
  );
}

AttendeesLayout.propTypes = {
  children: PropTypes.any
};

export default AttendeesLayout;
