import React from 'react';

function Users() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
      <path d="M7.63,13.42A6.61,6.61,0,0,0,1,20a.56.56,0,0,0,.57.57H13.7a.57.57,0,0,0,.57-.57A6.62,6.62,0,0,0,7.63,13.42Zm-5.47,6a5.51,5.51,0,0,1,10.94,0Z" fill="currentColor"/>
      <path d="M16.37,13.42a.57.57,0,0,0-.57.57.56.56,0,0,0,.57.57A5.47,5.47,0,0,1,21.86,20a.57.57,0,0,0,.57.57A.56.56,0,0,0,23,20,6.61,6.61,0,0,0,16.37,13.42Z" fill="currentColor"/>
      <path d="M7.63,11.73A4.15,4.15,0,1,0,3.45,7.59,4.17,4.17,0,0,0,7.63,11.73Zm0-7.16a3,3,0,1,1-3,3A3,3,0,0,1,7.63,4.57Z" fill="currentColor"/>
      <path d="M16.85,11.73a4.15,4.15,0,1,0-4.18-4.14A4.17,4.17,0,0,0,16.85,11.73Zm0-7.16a3,3,0,1,1-3.05,3A3,3,0,0,1,16.85,4.57Z" fill="currentColor"/>
    </svg>
  );
}

export default Users;
