import {
  createSection,
  deleteSection,
  getSections,
  updateSection,
  updateSectionsSort,
  getSectionById
} from 'src/core/api/LandingPageSectionStore';
import * as routes from 'src/routeConstants';
import { getQueryParameter } from 'src/utils/queryParameter';
import authService from './authService';
import { translate } from 'i18n-js'

class LandingPageService {
  createSection = section =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      const userId = authService.getUserUid();
      createSection(eventId, userId, section)
        .then(newSection => {
          resolve(newSection);
        })
        .catch(error => {
          reject(error);
        });
    });

  getSections = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      getSections(eventId)
        .then(sections => {
          resolve(sections);
        })
        .catch(error => {
          reject(error);
        });
    });

  updateSection = change =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      const userId = authService.getUserUid();
      updateSection(eventId, userId, change)
        .then(changes => {
          resolve(changes);
        })
        .catch(error => {
          reject(error);
        });
    });

  updateSectionsSort = changes =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      const userId = authService.getUserUid();
      updateSectionsSort(eventId, userId, changes)
        .then(changesList => {
          resolve(changesList);
        })
        .catch(error => {
          reject(error);
        });
    });

  deleteSection = sectionId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      deleteSection(eventId, sectionId)
        .then(sectionId => {
          resolve(sectionId);
        })
        .catch(error => {
          reject(error);
        });
    });

  getSectionById = sectionId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getSectionById(eventId, sectionId)
        .then(section => {
          if (section != null) {
            resolve(section);
          } else {
            reject(translate('sponsors.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new LandingPageService();
