import { createAction } from '@reduxjs/toolkit';

export const DIALOG_ACTIONS = {
  CLOSE: 'CLOSE',
  OPEN: 'OPEN',
  REMOVE: 'REMOVE'
};

export const openDialog = createAction(DIALOG_ACTIONS.OPEN);
export const closeDialog = createAction(DIALOG_ACTIONS.CLOSE);
export const removeDialog = createAction(DIALOG_ACTIONS.REMOVE);
