import firebase from 'firebase';
import { store } from './firebase';

const EVENTS = 'events';
const TRACKING_CODES_COL = 'tracking-codes';
const TRACKING_CODES_DOC = 'codes';

export async function updateIntegrationTrackingCodes(
  eventId,
  userId,
  integrationTrackingCodes
) {
  const trackingCodesDocRef = store.doc(
    `${EVENTS}/${eventId}/${TRACKING_CODES_COL}/${TRACKING_CODES_DOC}`
  );

  const {
    headTrackingCodes,
    bodyTrackingCodes,
    footerTrackingCodes
  } = integrationTrackingCodes;

  await trackingCodesDocRef.set(
    {
      headTrackingCodes,
      bodyTrackingCodes,
      footerTrackingCodes,
      createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: userId
    },
    { merge: true }
  );

  const doc = await trackingCodesDocRef.get();
  return {
    ...doc.data()
  };
}

export async function getIntegrationTrackingCodes(eventId) {
  const trackingCodesDoc = await store
    .doc(`${EVENTS}/${eventId}/${TRACKING_CODES_COL}/${TRACKING_CODES_DOC}`)
    .get();

  return {
    ...trackingCodesDoc.data()
  };
}
