import { createSelector } from '@reduxjs/toolkit';
import { settingsEntityAdapter } from 'src/reducers/settingsReducer';

export const selectSettingsState = state => state.settings;

export const selectSettingInfo = createSelector(
  selectSettingsState,
  state => state.defaultFees
);

export const selectPackageById = createSelector(
  selectSettingsState,
  state => state.package
);

export const selectCountryTaxes = createSelector(
  selectSettingsState,
  state => state.taxes
);

export const selectSettingsList = settingsEntityAdapter.getSelectors(
  selectSettingsState
).selectAll;

export const selectSettingsLoading = createSelector(
  selectSettingsState,
  state => state.loading
);

export const selectSettingsLoaded = createSelector(
  selectSettingsState,
  state => state.loaded
);

export const selectSettingsById = settingsEntityAdapter.getSelectors(
  selectSettingsState
).selectById;
