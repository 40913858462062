// global colors 

export const primary = '#0BC490';
export const primaryLight = 'rgb(11 196 144 / 20%)';
export const primaryDark = '#229970';
export const secondary = '#E6F4EA';

export const white = '#fff';
export const black = '#000';

export const grey = '#808080';
