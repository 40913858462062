import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  createCustomFormField,
  deleteCustomFormField,
  getCustomFormFieldByFormId,
  resetCustomForm,
  updateCustomFormField,
  updateCustomFormFieldsSort
} from 'src/actions/customFormFieldsActions';

export const customFormEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = customFormEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const customFormReducer = createReducer(initialState, {
  [createCustomFormField.pending]: (state, _) => {
    state.loading = true;
  },
  [createCustomFormField.fulfilled]: (state, { payload }) => {
    state.loading = false;
    customFormEntityAdapter.upsertOne(state, payload);
  },
  [createCustomFormField.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateCustomFormField.pending]: (state, _) => {
    state.loading = true;
  },
  [updateCustomFormField.fulfilled]: (state, { payload }) => {
    state.loading = false;
    customFormEntityAdapter.updateOne(state, payload);
  },
  [updateCustomFormField.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateCustomFormFieldsSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateCustomFormFieldsSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    customFormEntityAdapter.upsertMany(state, payload);
  },
  [updateCustomFormFieldsSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteCustomFormField.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteCustomFormField.fulfilled]: (state, { payload }) => {
    state.loading = false;
    customFormEntityAdapter.removeOne(state, payload);
  },
  [deleteCustomFormField.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getCustomFormFieldByFormId.pending]: (state, _) => {
    state.loading = true;
  },
  [getCustomFormFieldByFormId.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    customFormEntityAdapter.upsertMany(state, payload);
  },
  [getCustomFormFieldByFormId.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetCustomForm.type]: (_, __) => {
    return initialState;
  }
});

export default customFormReducer;
