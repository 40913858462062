import axios from 'axios';
import firebase from 'firebase';
import { DOMAIN_ASSOCIATION_STATUS } from 'src/actions/domainActions';
import { store } from './firebase';

export const ALREADY_REGISTERED_DOMAIN = 'already_registered_domain';
const DOMAINS_COLLECTION = 'domains';
const USERS_COLLECTION = 'users';

export async function getEventDomains(eventId) {
  const documentReference = store.collection(DOMAINS_COLLECTION);
  let domains = [];
  domains = documentReference.get().then(snap => {
    const domainsList = [];
    snap.forEach(doc => {
      if (doc.exists) {
        const data = doc.data();
        if (data && data.events && data.events[eventId]) {
          const newDomain = {
            id: doc.id,
            name: data.name,
            createdDate: data.createdDate,
            status: data.status ? data.status : ''
          };
          domainsList.push(newDomain);
        }
      }
    });
    return domainsList;
  });

  return domains;
}

/**
 * @note Creating a domain: The doc in firebase + trough service
 * @param {*} domain
 * @param {*} userId
 * @param {*} eventId
 */
export async function createDomain(domain, userId, eventId) {
  const domainsCollection = store.collection(DOMAINS_COLLECTION);

  const documentCollection = await store
    .collection(DOMAINS_COLLECTION)
    .where('name', '==', domain.name)
    .get();

  if (documentCollection.size > 0) {
    return ALREADY_REGISTERED_DOMAIN;
  }

  let events = {};
  events[eventId] = true;

  let newDomain = {
    name: domain.name,
    events: events,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId,
    status: DOMAIN_ASSOCIATION_STATUS.SETTINGS_REQUIRED
  };

  const addedDomain = await domainsCollection.add(newDomain);
  return { ...newDomain, id: addedDomain.id };
}

/**
 * @note Verifying a domain's settings: The CNAME or NAMESERVERS should be set (by the client/organizer).
 * @param {*} domain
 */
export async function verifyDomain(domain, accessToken) {
  const domainRef = store.collection(`${DOMAINS_COLLECTION}`).doc(domain.id);

  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    };

    return axios({
      method: 'post',
      headers: headers,
      url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/domains-verifyDomainCnameSettings`,
      data: {
        domain: domain.name
      }
    }).then(
      async () => {
        await domainRef.update({
          status: DOMAIN_ASSOCIATION_STATUS.VERIFIED
        });

        return {
          id: domain.id,
          changes: {
            status: DOMAIN_ASSOCIATION_STATUS.VERIFIED
          }
        };
      },
      error => {
        console.log(error);
        return false;
      }
    );
  } catch (e) {
    console.log("Error: - could not check the domain's CNAME settings");
    return false;
  }
}

/**
 * @Note  Registering the new domain and generating the SSL certificate for it;
 * @param {*} domainId
 * @param {*} accessToken
 */

export async function registerDomain(domain, accessToken) {
  const domainRef = store.collection(`${DOMAINS_COLLECTION}`).doc(domain.id);
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    };

    return axios({
      method: 'post',
      headers: headers,
      url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/domains-createDomainMapping`,
      data: {
        domain: domain.name
      }
    }).then(
      async () => {
        await domainRef.update({
          status: DOMAIN_ASSOCIATION_STATUS.SSL_CERTIFIED
        });

        return {
          id: domain.id,
          changes: {
            status: DOMAIN_ASSOCIATION_STATUS.SSL_CERTIFIED
          }
        };
      },
      async error => {
        console.log(error.message);
        await domainRef.update({
          status: DOMAIN_ASSOCIATION_STATUS.SSL_CERTIFICATION_FAILED
        });
        return {
          id: domain.id,
          changes: {
            status: DOMAIN_ASSOCIATION_STATUS.SSL_CERTIFICATION_FAILED
          }
        };
      }
    );
  } catch (e) {
    await domainRef.update({
      status: DOMAIN_ASSOCIATION_STATUS.SSL_CERTIFICATION_FAILED
    });

    return {
      id: domain.id,
      changes: {
        status: DOMAIN_ASSOCIATION_STATUS.SSL_CERTIFICATION_FAILED
      }
    };
  }
}

/**
 * @note Deleting a domain: The doc in firebase + trough service
 * @param {*} domain
 * @param {*} accessToken
 * @param {*} eventId
 */
export async function deleteDomain(domain, accessToken, eventId) {
  const domainSnap = await store
    .collection(DOMAINS_COLLECTION)
    .doc(domain.id)
    .get();
  let attachedEvents = domainSnap.get('events');
  if (!attachedEvents || Object.keys(attachedEvents).length <= 1) {
    return deleteEntireDomain(domain, accessToken);
  } else {
    return deleteOnlyTheMappingToEvent(domain, eventId);
  }
}

/**
 * @note  Deleting the entire domain: from the domains collection and from the kong;
 * @param {*} domain
 * @param {*} eventId
 */
async function deleteEntireDomain(domain, accessToken) {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    };

    await axios({
      method: 'post',
      headers: headers,
      url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/domains-removeDomainMapping`,
      data: {
        domain: domain.name
      }
    });

    const domainDoc = store.collection(DOMAINS_COLLECTION).doc(domain.id);
    await domainDoc.delete();
  } catch (e) {
    console.log('Error: - could not delete the Domain.');
  }

  return domain;
}

/**
 * @note  Deleting the event mapping only in the domain's doc
 * @param {*} domain
 * @param {*} eventId
 */
async function deleteOnlyTheMappingToEvent(domain, eventId) {
  await store
    .collection(DOMAINS_COLLECTION)
    .doc(domain.id)
    .update({ [`events.${eventId}`]: firebase.firestore.FieldValue.delete() });
  return domain;
}

export async function updateDomain({ id, changes }, userId) {
  const domainDoc = await store
    .collection(DOMAINS_COLLECTION)
    .doc(id)
    .get();

  await domainDoc.ref.update({
    ...changes,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedBy: userId
  });

  return {
    id,
    changes: {
      ...changes,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedBy: userId
    }
  };
}

export async function getDomainById(domainId) {
  const documentReference = store.collection(DOMAINS_COLLECTION).doc(domainId);
  let domain = null;
  domain = documentReference.get().then(doc => {
    if (doc.exists) {
      const data = doc.data();
      if (data != null) {
        let newDomain = {
          id: domainId,
          name: data.name,
          status: data.status
        };
        return newDomain;
      }
    }
  });

  return domain;
}

/**
 * @note  Collect all the Attachable Domains for the current event
 * @param {*} userId
 */
export async function getAttachableDomains(userId) {
  let domainArray = [];

  const allDomainsSnap = await store.collection(DOMAINS_COLLECTION).get();
  const userSnap = await store
    .collection(USERS_COLLECTION)
    .doc(userId)
    .get();

  if (allDomainsSnap && userSnap) {
    const userEvents = userSnap.get('events');

    allDomainsSnap.forEach(domain => {
      const attachedEvents = domain.get('events');

      userEvents.forEach(event => {
        if (attachedEvents && attachedEvents[event.eventId]) {
          domainArray.push({ id: domain.id, name: domain.get('name') });
        }
      });
    });
  }

  /* Let's return only unique elements/domains. We don't want to have duplicates. */
  const result = [];
  const map = new Map();
  for (const item of domainArray) {
    if (!map.has(item.id)) {
      map.set(item.id, true);
      result.push({
        id: item.id,
        name: item.name
      });
    }
  }

  return result;
}

/**
 * @note  Attach an existing domain to a given event
 * @param {*} domainId
 * @param {*} eventId
 */
export async function attachNewDomain(domainId, eventId) {
  const domainSnap = await store
    .collection(DOMAINS_COLLECTION)
    .doc(domainId)
    .get();

  if (domainSnap) {
    let events = domainSnap.get('events');
    events[eventId] = true;

    await store
      .collection(DOMAINS_COLLECTION)
      .doc(domainId)
      .set({ events: events }, { merge: true });

    return events;
  }

  return null;
}
