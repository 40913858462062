import firebase from 'firebase';
import { store } from './firebase';
import moment from 'moment-timezone';
const EVENTS_COLLECTION = 'events';
const PROMO_CODES_COLLECTION = 'promoCodes';

export async function getEventPromoCodes(eventId) {
  const documentReference = store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(PROMO_CODES_COLLECTION);

  let promoCodes = [];
  promoCodes = documentReference.get().then(snap => {
    const promoCodesList = [];
    snap.forEach(doc => {
      if (doc.exists) {
        const data = doc.data();
        const newPromoCode = {
          id: doc.id,
          ...data
        };
        promoCodesList.push(newPromoCode);
      }
    });
    return promoCodesList;
  });

  return promoCodes;
}

export async function createPromoCode(promoCode, userId, eventId, timezone) {
  const promoCodesCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${PROMO_CODES_COLLECTION}`
  );

  moment.tz.setDefault(timezone);
  let startDate = null;
  let endDate = null;

  if (promoCode.startDate && promoCode.endDate) {
    startDate = moment(promoCode.startDate)
      .startOf('day')
      .toDate();
    endDate = moment(promoCode.endDate)
      .endOf('day')
      .toDate();
  }

  let newPromoCode = {
    code: promoCode.code,
    percent: promoCode.percent,
    eventId,
    startDate,
    endDate,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId,
    allTickets: promoCode.allTickets,
    selectedTickets: promoCode.selectedTickets,
    quantity: promoCode.quantity || ''
  };

  let promoCodeId;
  await promoCodesCollection.add(newPromoCode).then(s => (promoCodeId = s.id));

  newPromoCode.id = promoCodeId;
  return newPromoCode;
}

export async function deletePromoCode(promoCodeId, eventId) {
  const promoCodesCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${PROMO_CODES_COLLECTION}`
  );
  const promoCodeDoc = promoCodesCollection.doc(promoCodeId);

  await promoCodeDoc.delete();
  return promoCodeId;
}

export async function updatePromoCode(promoCode, eventId, timezone) {
  const promoCodesCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${PROMO_CODES_COLLECTION}`
  );
  const promoCodeDoc = promoCodesCollection.doc(promoCode.id);

  moment.tz.setDefault(timezone);
  let startDate = null;
  let endDate = null;

  if (promoCode.startDate && promoCode.endDate) {
    startDate = moment(promoCode.startDate)
      .startOf('day')
      .toDate();
    endDate = moment(promoCode.endDate)
      .endOf('day')
      .toDate();
  }

  let updatedPromoCode = {
    id: promoCode.id,
    code: promoCode.code,
    percent: promoCode.percent,
    startDate,
    endDate,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    allTickets: promoCode.allTickets,
    selectedTickets: promoCode.selectedTickets,
    quantity: promoCode.quantity || ''
  };

  await promoCodeDoc.update(updatedPromoCode);
  return updatedPromoCode;
}

export async function getPromoCodeById(promoCodeId, eventId) {
  const documentReference = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${PROMO_CODES_COLLECTION}`)
    .doc(promoCodeId);
  let promoCode = null;
  promoCode = documentReference.get().then(doc => {
    if (doc.exists) {
      const data = doc.data();
      if (data != null) {
        let newPromoCode = {
          id: promoCodeId,
          ...data
        };
        return newPromoCode;
      }
    }
  });

  return promoCode;
}
