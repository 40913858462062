import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container, Box, Divider } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from 'src/layouts/ReportsLayout/Header';
import LinkTabs from 'src/components/LinkTabs/LinkTabs';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';
import { useLocation } from 'react-router';
import { getQueryParameter } from 'src/utils/queryParameter';
import BillingDetailsVerificationBar from 'src/layouts/TicketsLayout/BillingDetailsVerificationBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function ReportsLayout({ children }) {
  const classes = useStyles();
  const { pathname: currentPath } = useLocation();
  const eventId = getQueryParameter(routes.EVENT);

  return (
    <>
      <BillingDetailsVerificationBar />
      <Page className={classes.root} title={translate('pageTitle.reports')}>
        <Container maxWidth="lg">
          <Header />
          <Box mt={3}>
            <LinkTabs
              currentPath={currentPath}
              paths={{
                [`${routes.EVENT}/${eventId}${routes.REPORTS}`]: {
                  label: 'Registrations',
                  index: 0
                },
                [`${routes.EVENT}/${eventId}${routes.REPORTS}/${routes.REPORTS_SESSIONS}`]: {
                  label: 'Sessions',
                  index: 1
                },
                [`${routes.EVENT}/${eventId}${routes.REPORTS}/${routes.REPORTS_NETWORKING}`]: {
                  label: 'Networking',
                  index: 2
                },
                [`${routes.EVENT}/${eventId}${routes.REPORTS}/${routes.REPORTS_EXPO}`]: {
                  label: 'EXPO',
                  index: 3
                },
                [`${routes.EVENT}/${eventId}${routes.REPORTS}${routes.REPORT_DOWNLOADS}`]: {
                  label: 'Downloads',
                  index: 4
                }
              }}
            />
          </Box>
          <Divider />
          {children}
        </Container>
      </Page>
    </>
  );
}

ReportsLayout.propTypes = {
  children: PropTypes.any
};

export default ReportsLayout;
