export const UPLOAD_VIDEO = '@video/upload';
export const GET_VIDEOS = '@video/get-videos';
export const VIDEO_UPLOAD_PAUSED = '@video/upload-paused';
export const VIDEO_UPLOAD_CANCELED = '@video/upload-canceled';
export const VIDEO_UPLOAD_FAILED = '@video/upload-failed';
export const VIDEO_UPLOAD_FINISHING = '@video/upload-finishing';
export const VIDEO_UPLOAD_COMPLETED = '@video/upload-completed';
export const REMOVE_LOBBY_ASSIGNMENT_FROM_VIDEOS =
  '@video/remove-lobby-assignment-from-videos';
export const VIDEO_UPLOAD_MUX_PREPARING = '@video/upload-mux-preparing';
export const START_VIDEO_STATUS_LISTENING = '@video/status-listening';
export const START_VIDEO_BY_ID_STATUS_LISTENING =
  '@video/by-id-status-listening';
export const VIDEO_UPLOAD_IN_PROGRESS = '@video/upload-in-progress';
export const VIDEO_STATUS_CHANGED = '@video/status-changed';
export const VIDEO_BY_ID_STATUS_CHANGED = '@video/by-id-status-changed';
export const ASSIGN_VIDEO_TO_SESSION = '@video/assign-to-session';
export const DELETE_VIDEO = '@video/delete';
export const GET_VIDEO_BY_ID = '@video/get-video-by-id';
export const UPDATE_VIDEO = '@video/update-video';
export const GET_VIDEO_CATEGORIES = '@video/get-video-categories';
export const VIDEO_CATEGORY_CREATED = '@video/create-video-category';

export const VIDEO_UPLOAD_STATUS = {
  STARTED: 'STARTED',
  PAUSED: 'PAUSED',
  CANCELED: 'CANCELED',
  UPLOADING: 'UPLOADING',
  FAILED: 'FAILED',
  FINISHING: 'FINISHING',
  COMPLETED: 'COMPLETED',
  MUX_PREPARING: 'MUX_PREPARING'
};
