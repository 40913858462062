import { createReducer } from '@reduxjs/toolkit';
import {
  getIntegrationTrackingCodes,
  resetIntegrationTrackingCodes,
  updateIntegrationTrackingCodes
} from 'src/actions/integrationsActions';

const initialState = {
  headTrackingCodes: '',
  bodyTrackingCodes: '',
  footerTrackingCodes: '',
  loading: false,
  loaded: false,
  error: null
};

const integrationsSectionReducer = createReducer(initialState, {
  [getIntegrationTrackingCodes.pending]: state => {
    state.loading = true;
  },
  [getIntegrationTrackingCodes.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    state.headTrackingCodes = payload.headTrackingCodes;
    state.bodyTrackingCodes = payload.bodyTrackingCodes;
    state.footerTrackingCodes = payload.footerTrackingCodes;
  },
  [getIntegrationTrackingCodes.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [updateIntegrationTrackingCodes.pending]: (state, _) => {
    state.loading = true;
  },
  [updateIntegrationTrackingCodes.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    state.headTrackingCodes = payload.headTrackingCodes;
    state.bodyTrackingCodes = payload.bodyTrackingCodes;
    state.footerTrackingCodes = payload.footerTrackingCodes;
  },
  [updateIntegrationTrackingCodes.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetIntegrationTrackingCodes.type]: (_, __) => {
    return initialState;
  }
});

export default integrationsSectionReducer;
