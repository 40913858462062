const CUSTOMIZATION_DEFAULTS = {
  fontFamily: 'Roboto',
  colors: {
    topMenuBackground: '#FFFFFF',
    topMenuLinks: '#000000',
    headerText: '#FFFFFF',
    pageBackground: '#F6F6F6',
    alternativeBackground: '#ECECEC',
    containerBackground: '#FFFFFF',
    pageText: '#000000',
    alternativeText: '#000000',
    button: '#1D55E3',
    buttonHover: '#253253',
    buttonText: '#FFFFFF',
    buttonTextHover: '#FFFFFF',
    link: '#1D55E3',
    linkHover: '#253253',
    errorColor: '#FF0000',
    borderColor: '#DDDDDD',
    successColor: '#008000'
  },
  shape: {
    cards: {
      borderRadius: 10
    },
    buttons: {
      borderRadius: 10
    },
    forms: {
      borderRadius: 10
    }
  },
  links: {
    decoration: 'underline',
    hoverDecoration: 'underline'
  }
};

export default CUSTOMIZATION_DEFAULTS;
