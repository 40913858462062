import authService from 'src/services/authService';
import { translate } from 'src/translations/i18n';
import {
  createBillingInformation,
  getBillingInformation,
  updateBillingInformation,
  updateBillingInformationOrganization,
  updatePaymentInformation,
  getUserBillingFlags,
  getUserBillingFlagsFromEvent,
  verifyVatNumber,
  validateCard
} from 'src/core/api/BillingStore';

class BillingInformationService {
  createBillingInformation = info =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();

      createBillingInformation(userId, info)
        .then(newSections => {
          resolve(newSections);
        })
        .catch(error => {
          reject(error);
        });
    });

  updateBillingInformation = changes =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();

      updateBillingInformation(userId, changes)
        .then(changes => {
          resolve(changes);
        })
        .catch(error => {
          reject(error);
        });
    });

  updateBillingInformationOrganization = organization => {
    const userId = authService.getUserUid();
    new Promise((resolve, reject) => {
      updateBillingInformationOrganization(userId, organization)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  updatePaymentInformation = changes =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();

      updatePaymentInformation(userId, changes)
        .then(changes => {
          resolve(changes);
        })
        .catch(error => {
          reject(error);
        });
    });

  getBillingInformation = () =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      getBillingInformation(userId)
        .then(billingInformation => {
          if (billingInformation != null) {
            resolve(billingInformation);
          } else {
            reject(translate('letters.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getUserBillingFlags = async userId => {
    try {
      const userBillingFlags = await getUserBillingFlags(userId);
      return userBillingFlags;
    } catch (error) {
      console.error('[GET-USER-BILLING]', error.message);
      return error;
    }
  };

  getUserBillingFlagsFromEvent = async eventId => {
    try {
      const userBillingFlags = await getUserBillingFlagsFromEvent(eventId);
      return userBillingFlags;
    } catch (error) {
      console.error('[GET-USER-BILLING-FROM-EVENT]', error.message);
      return error;
    }
  };

  verifyVatNumber = (vatNumber, countryCode) =>
    new Promise((resolve, reject) => {
      verifyVatNumber(vatNumber, countryCode)
        .then(validationInfo => {
          resolve(validationInfo);
        })
        .catch(error => {
          reject(error);
        });
    });

  validateCard = (cardElement, stripe) =>
    new Promise((resolve, reject) => {
      validateCard(cardElement, stripe)
        .then(clientSecret => {
          resolve(clientSecret);
        })
        .catch(error => {
          reject(error);
        });
    });
}

export default new BillingInformationService();
