import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';
import { DIALOG_ACTIONS } from 'src/actions/dialogActions';

const dialogAdapter = createEntityAdapter();

const initialState = dialogAdapter.getInitialState();

const dialogReducer = createReducer(initialState, {
  [DIALOG_ACTIONS.OPEN]: (state, action) =>
    dialogAdapter.addOne(state, { ...action.payload, open: true }),
  [DIALOG_ACTIONS.CLOSE]: (state, action) =>
    dialogAdapter.updateOne(state, {
      id: action.payload.id,
      changes: { open: false }
    }),
  [DIALOG_ACTIONS.REMOVE]: (state, action) =>
    dialogAdapter.removeOne(state, action.payload.id)
});

export const dialogSelectors = dialogAdapter.getSelectors(
  state => state.dialog
);

export default dialogReducer;
