import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import { logout } from 'src/actions/accountActions';
import { translate } from 'src/translations/i18n';
import { resetEvents } from 'src/actions/eventActions';
import { Link as RouterLink } from 'react-router-dom';
import * as routes from 'src/routeConstants';
import { getTeamIncomingJoinRequests } from 'src/actions/teamActions';
import {
  selectIncomingJoinRequests,
  selectTeamLoaded
} from 'src/selectors/teamSelectors';
import NotificationBubble from 'src/views/event/components/NotificationBubble';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const pendingRequests = useSelector(selectIncomingJoinRequests).filter(
    s => s.status && s.status.id === 0
  );
  const loaded = useSelector(selectTeamLoaded);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getTeamIncomingJoinRequests());
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={account.user.photo}
        />
        <Hidden smDown>
          <Typography variant="h6">
            {account.user.fullName ? account.user.fullName : account.user.email}
          </Typography>
        </Hidden>
        {loaded && pendingRequests.length !== 0 && (
          <Box mx={1}>
            <NotificationBubble />
          </Box>
        )}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to={routes.PROFILE}>
          {translate('profile.myProfile')}
        </MenuItem>
        <MenuItem component={RouterLink} to={routes.TEAM}>
          {translate('team.pageName')}
          {loaded && pendingRequests.length !== 0 && (
            <Box mx={1}>
              <NotificationBubble />
            </Box>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(`https://support.eventmix.live`, '_blank');
          }}
        >
          {translate('topBar.supportCenter')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(`https://partners.eventmix.live`, '_blank');
          }}
        >
          {translate('topBar.partnerProgram')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{translate('topBar.logOut')}</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
