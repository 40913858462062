import {
  getTermsAndConditions,
  getDefaultBillingFees,
  getBillingFeesByPackage,
  getCountryTaxes
} from 'src/actions/settingsAction';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const settingsEntityAdapter = createEntityAdapter();

const initialState = settingsEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  defaultFees: null,
  package: null,
  taxes: null,
  error: null
});

const settingsReducer = createReducer(initialState, {
  [getTermsAndConditions.pending]: (state, _) => {
    state.loading = true;
  },
  [getTermsAndConditions.fulfilled]: (state, { payload }) => {
    state.loading = false;
    settingsEntityAdapter.upsertOne(state, payload);
  },
  [getTermsAndConditions.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getDefaultBillingFees.pending]: (state, _) => {
    state.loading = true;
  },
  [getDefaultBillingFees.fulfilled]: (state, { payload }) => {
    state.defaultFees = payload;
    state.loading = false;
  },
  [getDefaultBillingFees.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getBillingFeesByPackage.pending]: (state, _) => {
    state.loading = true;
  },
  [getBillingFeesByPackage.fulfilled]: (state, { payload }) => {
    state.package = payload;
    state.loading = false;
  },
  [getBillingFeesByPackage.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getCountryTaxes.pending]: (state, _) => {
    state.loading = true;
  },
  [getCountryTaxes.fulfilled]: (state, { payload }) => {
    state.taxes = payload;
    state.loading = false;
  },
  [getCountryTaxes.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  }
});

export default settingsReducer;
