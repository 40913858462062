import authService from 'src/services/authService';
import {
  getUserPackage,
  getPackages,
  getInvoices,
  downloadInvoice,
  getPackageByName,
  updateUserPackage,
  cancelPackage,
  verifyCouponCode,
  getLimitedUserPlan
} from '../core/api/PackageStore';
import { getEventStages } from '../core/api/StageStore';
import { getEventNetworks } from '../core/api/NetworkStore';
import { getEventBooths } from 'src/core/api/BoothStore';
import { getTeamMembers } from 'src/core/api/TeamStore';
import * as validationTypes from 'src/constants/packagesConstants';
import * as routes from 'src/routeConstants';
import moment from 'moment';
import { getQueryParameter } from 'src/utils/queryParameter';
import eventService from './eventService';
import { getEventById } from 'src/core/api/EventStore';

class PackageService {
  getUserPackage = () =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      getUserPackage(userId)
        .then(p => {
          if (p) {
            resolve(p);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventOwnerPlan = eventId =>
    new Promise(async (resolve, reject) => {
      let id;
      if (!eventId) {
        id = await authService.getUserUid();
      } else {
        const event = await eventService.getEventById(eventId);
        id = event.createdBy;
      }
      try {
        const userPlan = await getLimitedUserPlan(id);
        if (userPlan) {
          resolve(userPlan);
        }
      } catch (error) {
        reject(error.message);
      }
    });

  getPackageByName = selectedPackage =>
    new Promise((resolve, reject) => {
      getPackageByName(selectedPackage)
        .then(p => {
          if (p) {
            resolve(p);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getPackages = () =>
    new Promise((resolve, reject) => {
      getPackages()
        .then(p => {
          if (p) {
            resolve(p);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getInvoices = () =>
    new Promise((resolve, reject) => {
      getInvoices()
        .then(p => resolve(p))
        .catch(error => {
          reject(error.message);
        });
    });

  downloadInvoice = invoiceId =>
    new Promise((resolve, reject) => {
      downloadInvoice(invoiceId)
        .then(p => resolve(p))
        .catch(error => {
          reject(error.message);
        });
    });

  verifyCouponCode = couponCode =>
    new Promise((resolve, reject) => {
      verifyCouponCode(couponCode)
        .then(validCouponCode => {
          resolve(validCouponCode);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateUserPackage = (newPackage, coupon) =>
    new Promise((resolve, reject) => {
      const { email, user_id } = authService.getUserInfo();
      updateUserPackage(newPackage, coupon, email, user_id)
        .then(change => resolve(change))
        .catch(error => {
          reject(error.message);
        });
    });

  cancelPackage = cancelData =>
    new Promise((resolve, reject) => {
      cancelPackage(cancelData)
        .then(p => resolve(p))
        .catch(error => {
          reject(error.message);
        });
    });

  validatePackage = async (
    type,
    { speakersNumber, createdNetworks, newEventInfo }
  ) => {
    const userId = authService.getUserUid();
    let eventOwnerPlan;
    let eventId;
    switch (type) {
      case validationTypes.EVENT_LENGTH_TYPE:
      case validationTypes.TEAM_MEMBERS_TYPE:
        eventOwnerPlan = await getUserPackage(userId);
        break;
      default:
        eventId = getQueryParameter(routes.EVENT);
        let { createdBy } = await getEventById(eventId);
        eventOwnerPlan = await getUserPackage(createdBy);
    }

    switch (type) {
      case validationTypes.STAGE_TYPE:
        const stages = await getEventStages(eventId);
        if (stages.length >= eventOwnerPlan?.stageCount) {
          throw new Error(validationTypes.STAGES_VALIDATION_ERROR);
        }
        break;
      case validationTypes.ROUND_TABLE_TYPE:
        const networks = await getEventNetworks(eventId);
        const totalNetworks = createdNetworks.length + networks.length;
        if (
          totalNetworks > eventOwnerPlan?.roundTablesCount ||
          createdNetworks.length > eventOwnerPlan?.roundTablesCount ||
          networks.length > eventOwnerPlan?.roundTablesCount
        ) {
          throw new Error(validationTypes.ROUND_TABLES_VALIDATION_ERROR);
        }
        break;
      case validationTypes.BOOTH_TYPE:
        const booths = await getEventBooths(eventId);
        if (booths.length >= eventOwnerPlan?.sponsorBoothCount) {
          throw new Error(validationTypes.BOOTHS_VALIDATION_ERROR);
        }
        break;
      case validationTypes.TEAM_MEMBERS_TYPE:
        const teams = await getTeamMembers(userId);
        if (teams.length > eventOwnerPlan?.teamMembersCount) {
          throw new Error(validationTypes.TEAM_MEMBERS_VALIDATION_ERROR);
        }
        break;
      case validationTypes.SPEAKER_TYPE:
        if (speakersNumber > eventOwnerPlan?.speakersPerSession) {
          throw new Error(validationTypes.SPEAKERS_VALIDATION_ERROR);
        }
        break;
      case validationTypes.EVENT_LENGTH_TYPE:
      case validationTypes.EVENT_LENGTH_UPDATE_TYPE:
        if (
          moment
            .duration(
              moment(newEventInfo.endDate).diff(moment(newEventInfo.startDate))
            )
            .asHours() >= eventOwnerPlan?.eventLength
        ) {
          throw new Error(validationTypes.EVENT_LENGTH_VALIDATION_ERROR);
        }
        break;
      default:
        return;
    }
  };
}

export default new PackageService();
