import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import customFormService from 'src/services/customFormFieldsService';

export const GET_CUSTOM_REGISTRATION_FIELDS_BY_FORM_ID =
  '@custom-form-fields/get-custom-form-fields-by-form-id';
export const RESET_CUSTOM_REGISTRATION_FIELDS =
  '@custom-form-fields/reset-custom-form-fields';
export const CREATE_CUSTOM_REGISTRATION_FIELD =
  '@custom-form-fields/create-custom-form-field';
export const UPDATE_CUSTOM_REGISTRATION_FIELD =
  '@custom-form-fields/update-custom-form-field';
export const DELETE_CUSTOM_REGISTRATION_FIELD =
  '@custom-form-fields/delete-custom-form-field';
export const UPDATE_CUSTOM_REGISTRATION_FIELDS_SORT =
  '@custom-form-fields/update-custom-form-fields-sort';

export const createCustomFormField = createAsyncThunk(
  CREATE_CUSTOM_REGISTRATION_FIELD,
  async ({ customFormField, formId }) => {
    const formField = await customFormService.createCustomFormField(
      customFormField,
      formId
    );
    return { ...formField, formId };
  }
);

export const updateCustomFormField = createAsyncThunk(
  UPDATE_CUSTOM_REGISTRATION_FIELD,
  async ({ changes, formId }) => {
    const change = await customFormService.updateCustomFormField(
      changes,
      formId
    );
    return change;
  }
);

export const updateCustomFormFieldsSort = createAsyncThunk(
  UPDATE_CUSTOM_REGISTRATION_FIELDS_SORT,
  async ({ changes, formId }) => {
    const change = await customFormService.updateCustomFormFieldsSort(
      changes,
      formId
    );
    return change;
  }
);

export const deleteCustomFormField = createAsyncThunk(
  DELETE_CUSTOM_REGISTRATION_FIELD,
  async ({ id, formId }) => {
    await customFormService.deleteCustomFormField(id, formId);
    return id;
  }
);

export const getCustomFormFieldByFormId = createAsyncThunk(
  GET_CUSTOM_REGISTRATION_FIELDS_BY_FORM_ID,
  async formId => await customFormService.getCustomFormFieldsByFormId(formId)
);

export const resetCustomForm = createAction(RESET_CUSTOM_REGISTRATION_FIELDS);
