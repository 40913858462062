import { createSelector } from '@reduxjs/toolkit';

export const selectBillingInformationState = state => state.billingInformation;

export const selectBillingInformation = createSelector(
  selectBillingInformationState,
  state => state.billingInformation
);
export const selectBillingInformationLoading = createSelector(
  selectBillingInformationState,
  state => state.loading
);

export const selectUserBillingFlags = createSelector(
  selectBillingInformationState,
  state => state.userBillingFlags
);
export const selectUserBillingFlagsLoading = createSelector(
  selectBillingInformationState,
  state => state.userBillingFlagsLoading
);

export const selectBillingInformationLoaded = createSelector(
  selectBillingInformationState,
  state => state.loaded
);
