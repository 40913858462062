import firebase from 'firebase';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const CATEGORIES_COLLECTION = 'sponsorCategories';
const SPONSORS_COLLECTION = 'sponsors';

export async function createCategory(category, userId, eventId) {
  const categoriesCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${CATEGORIES_COLLECTION}`
  );

  let collectionSize = await categoriesCollection
    .get()
    .then(s => (collectionSize = s.size));

  let newCategory = {
    id: '',
    name: category.name,
    description: category.description,
    sort: collectionSize,
    selectedLogoSize: category.selectedLogoSize,
    showSponsorLogoOnEventHomepage: category.showSponsorLogoOnEventHomepage,
    showSponsorLogoOnConferencePage: category.showSponsorLogoOnConferencePage,
    playVideoAdBetweenSessions: category.playVideoAdBetweenSessions,
    virtualExpoBooth: category.virtualExpoBooth,
    showChatWithAtendees: category.showChatWithAtendees,
    showPrerecordedPromoVideo: category.showPrerecordedPromoVideo,
    showPromoOffer: category.showPromoOffer,
    showBannerAd: category.showBannerAd,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  };

  await categoriesCollection
    .add(newCategory)
    .then(s => (newCategory = { ...newCategory, id: s.id }));

  const categoryDoc = categoriesCollection.doc(newCategory.id);

  await categoryDoc.update({
    id: newCategory.id,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  return newCategory;
}

export async function updateCategory(category, eventId) {
  const categoryDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${CATEGORIES_COLLECTION}`)
    .doc(category.id);

  await categoryDoc.update({
    name: category.name,
    description: category.description,
    selectedLogoSize: category.selectedLogoSize,
    showSponsorLogoOnEventHomepage: category.showSponsorLogoOnEventHomepage,
    showSponsorLogoOnConferencePage: category.showSponsorLogoOnConferencePage,
    playVideoAdBetweenSessions: category.playVideoAdBetweenSessions,
    virtualExpoBooth: category.virtualExpoBooth,
    showChatWithAtendees: category.showChatWithAtendees,
    showPrerecordedPromoVideo: category.showPrerecordedPromoVideo,
    showPromoOffer: category.showPromoOffer,
    showBannerAd: category.showBannerAd,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  const sponsorSnap = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${SPONSORS_COLLECTION}`)
    .get();
  let sponsors = sponsorSnap.docs.map(s => ({ ...s.data() }));

  const batch = store.batch();
  sponsors.forEach(sponsor => {
    if (
      sponsor.selectedCategory &&
      sponsor.selectedCategory.id === category.id
    ) {
      const newSelectedCategory = {
        ...sponsor.selectedCategory,
        name: category.name
      };
      const snap = store
        .collection(`${EVENTS_COLLECTION}/${eventId}/${SPONSORS_COLLECTION}`)
        .doc(sponsor.id);
      batch.update(snap, { ...sponsor, selectedCategory: newSelectedCategory });
    }
  });
  await batch.commit();

  let updatedCategory = {
    id: category.id,
    name: category.name,
    description: category.description,
    selectedLogoSize: category.selectedLogoSize,
    showSponsorLogoOnEventHomepage: category.showSponsorLogoOnEventHomepage,
    showSponsorLogoOnConferencePage: category.showSponsorLogoOnConferencePage,
    playVideoAdBetweenSessions: category.playVideoAdBetweenSessions,
    virtualExpoBooth: category.virtualExpoBooth,
    showChatWithAtendees: category.showChatWithAtendees,
    showPrerecordedPromoVideo: category.showPrerecordedPromoVideo,
    showPromoOffer: category.showPromoOffer,
    showBannerAd: category.showBannerAd
  };

  return updatedCategory;
}

export async function getCategoryById(eventId, categoryId) {
  const categoryDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${CATEGORIES_COLLECTION}`)
    .doc(categoryId);
  const category = await categoryDoc.get();
  if (!category.exists) return null;
  return { id: category.id, ...category.data() };
}

export async function deleteCategory(categoryId, eventId) {
  const categoriesCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${CATEGORIES_COLLECTION}`
  );
  const categoryDoc = categoriesCollection.doc(categoryId);

  await categoryDoc.delete();

  const sponsorSnap = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${SPONSORS_COLLECTION}`)
    .get();
  let sponsors = sponsorSnap.docs.map(s => ({ ...s.data() }));

  const batch = store.batch();
  sponsors.forEach(sponsor => {
    if (
      sponsor.selectedCategory &&
      sponsor.selectedCategory.id === categoryId
    ) {
      const snap = store
        .collection(`${EVENTS_COLLECTION}/${eventId}/${SPONSORS_COLLECTION}`)
        .doc(sponsor.id);
      batch.update(snap, { ...sponsor, selectedCategory: null });
    }
  });
  await batch.commit();

  return categoryId;
}

export async function getEventCategories(eventId) {
  const categoryDocs = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(CATEGORIES_COLLECTION)
    .get();

  return categoryDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export async function updateCategoriesSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  changesList.forEach(async changes => {
    const categoriesRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${CATEGORIES_COLLECTION}/${changes.id}`
    );

    batch.update(categoriesRef, {
      id: changes.id,
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    let result = {
      ...changes
    };

    resultList.push(result);
  });

  batch.commit();

  return resultList;
}
