import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import sessionService from 'src/services/sessionService';

export const CREATE_SESSION = '@session/create-session';
export const UPDATE_SESSION = '@session/update-session';
export const PUSH_OFF_SESSIONS = '@session/push-off-sessions';
export const DELETE_SESSION = '@session/delete-session';
export const GET_AVAILABLE_SESSIONS = '@session/get-available-sessions';
export const GET_SESSION = '@session/get-session';
export const GET_SESSIONS = '@session/get-sessions';
export const RESET_SESSIONS = '@session/reset-sessions';
export const LISTEN_SESSION_STATUS_BY_ID = '@session/listen-status-by-id';
export const SESSION_STATUS_CHANGED = '@session/status-changed';
export const SESSION_CATEGORY_CREATED = '@session/create-session-category';
export const GET_SESSION_CATEGORIES = '@session/get-session-categories';

export const createSession = createAsyncThunk(
  CREATE_SESSION,
  async ({ session }) => {
    const newSession = await sessionService.createSession(session);
    return newSession;
  }
);

export const updateSession = createAsyncThunk(
  UPDATE_SESSION,
  async ({ changes }) => {
    const change = await sessionService.updateSession(changes);
    return change;
  }
);

export const pushOffSessions = createAsyncThunk(
  PUSH_OFF_SESSIONS,
  async ({ sessions }) => {
    const changes = await sessionService.pushOffSessions(sessions);
    return changes;
  }
);

export const deleteSession = createAsyncThunk(
  DELETE_SESSION,
  async sessionId => {
    await sessionService.deleteSession(sessionId);
    return sessionId;
  }
);

export const getSessionById = createAsyncThunk(GET_SESSION, async sessionId => {
  const session = await sessionService.getSessionById(sessionId);
  return session;
});

export const getSessions = createAsyncThunk(GET_SESSIONS, async () => {
  const sessions = await sessionService.getEventSessions();
  return sessions;
});

export const resetSessions = createAction(RESET_SESSIONS);

export const getAvailableSessions = createAsyncThunk(
  GET_AVAILABLE_SESSIONS,
  async () => {
    const sessions = await sessionService.getAvailableSessions();
    return sessions;
  }
);

export const createSessionCategory = createAsyncThunk(
  SESSION_CATEGORY_CREATED,
  async category => {
    const newCategory = await sessionService.createSessionCategory(category);
    return newCategory;
  }
);

export const getSessionCategories = createAsyncThunk(
  GET_SESSION_CATEGORIES,
  async () => {
    let sessionCategories = await sessionService.getEventSessionCategories();
    return sessionCategories;
  }
);

export const getSectionsForAddons = createAsyncThunk(
  GET_SESSION_CATEGORIES,
  async () => {
    let sessionCategories = await sessionService.getEventSessionCategories();
    return sessionCategories;
  }
);
