import firebase from 'firebase';
import authService from 'src/services/authService';
import { auth, authProviders } from 'src/core/api/firebase';
import analytics from 'src/utils/analytics';
import { resetEvents } from './eventActions';
import { resetAttendees } from './attendeesActions';
import { resetBilling } from './billingActions';
import { resetPackages } from './packageActions';
import { resetTeamMembers } from './teamActions';
export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const AUTHENTICATE_WITH_GOOGLE = '@account/authenticate-with-google';
export const AUTHENTICATE_WITH_GOOGLE_SUCCCESS =
  '@account/authenticate-with-google-success';
export const REGISTER_SUCCESS = '@account/register-success';
export const REGISTER_FAILURE = '@account/register-failure';
export const AUTHENTICATE_WITH_GOOGLE_FAILURE =
  '@account/auhenticate-with-google-failure';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_PROFILE_ORGANIZATION =
  '@account/update-profile-organization';
export const UPDATE_PROFILE_PHONE = '@account/update-profile-phone';
export const UPDATE_PROFILE_WEBSITE = '@account/update-profile-website';
export const UPDATE_PROFILE_MESSAGE = '@account/update-profile-message';
export const SET_REGISTRATION_SURVEY = '@account/set-registration-survey';
export const REBUILD_PROVIDER_INFO = '@account/update-profile-organization';
export const CLEAR_USER = '@account/clear-user';
export const RESET_PROVIDER_INFO = '@account/reset-provider-info';

export const DELETE_ACCOUNT = '@account/delete-account';
export const DELETING_ACCOUNT_START = '@account/deleting-account-start';
export const DELETING_ACCOUNT_END = '@account/deleting-account-end';

export const ACCOUNT_DELETE_REQUEST = '@account/account-delete-request';
export const ACCOUNT_DELETE_REQUEST_START =
  '@account/account-delete-request-start';
export const ACCOUNT_DELETE_REQUEST_END = '@account/account-delete-request-end';

export const RESET_PASSWORD_REQUEST = '@account/reset_password';
export const RESET_PASSWORD_SUCCESS = '@account/reset_password-success';
export const RESET_PASSWORD_FAILURE = '@account/reset_password-failure';
export const UPDATE_USER = '@account/update-user';
export const EMAIL_VERIFICATION = '@account/email-verification';
export const EMAIL_VERIFICATION_SUCCESS = '@account/email-verification-success';
export const EMAIL_VERIFICATION_FAILURE = '@account/email-verification-failure';

export const VERIFY_PASSWORD_PENDING = '@account/verify_password_pending';
export const VERIFY_PASSWORD_FAILURE = '@account/verify_password_failure';
export const VERIFY_PASSWORD_SUCCESS = '@account/verify_password_success';

export const RESET_PENDING = '@account/reset_pending';
export const RESET_SUCCESS = '@account/reset_success';
export const RESET_FAILURE = '@account/reset_failure';

export function register(newUser) {
  return async dispatch => {
    try {
      dispatch({ type: REGISTER });
      const response = await authService.registerUser(newUser);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: response
      });

      analytics.logEvent('sign_up_complete');
    } catch (error) {
      if (
        //https://github.com/firebase/firebase-js-sdk/issues/1926
        error.indexOf("Failed to execute 'transaction' on 'IDBDatabase'") > -1
      ) {
        return window.location.reload();
      }
      dispatch({ type: REGISTER_FAILURE });
      throw error;
    }
  };
}
/**
 *
 * @param {'register' | 'login'} authType Signals the type of action.
 * @returns {boolean} Returns true if redirect to register page is needed.
 */
export function authenticateWithGoogle(authType) {
  return async dispatch => {
    try {
      dispatch({ type: AUTHENTICATE_WITH_GOOGLE });

      const googleUserInfo = await auth.signInWithPopup(
        authProviders.google.provider
      );

      if (googleUserInfo) {
        authService.setSession(await auth.currentUser.getIdToken());
        const providerInfo = {
          fullName: googleUserInfo?.user?.displayName,
          email: googleUserInfo?.user?.email,
          photo: googleUserInfo?.user?.photoURL,
          // organization must be set at a later point
          organization: '--not-defined--',
          uid: googleUserInfo?.user?.uid,
          emailVerified: googleUserInfo?.user?.emailVerified
        };

        const registerResult = await authService.registerUserWithProvider(
          providerInfo
        );

        // having no registerResult means that the user already exists
        // so we just login the user
        if (!!registerResult) {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: registerResult
          });
          dispatch({
            type: AUTHENTICATE_WITH_GOOGLE_SUCCCESS,
            payload: providerInfo
          });
          analytics.logEvent('sign_up_complete');
          if (authType === 'login') {
            return true;
          }
          return false;
        }

        if (authType === 'login') {
          await dispatch(loginAfterRegisterWithProvider());
          return false;
        }
        return false;
      } else {
        throw new Error('Could not retrieve data from Google Auth provider');
      }
    } catch (error) {
      dispatch({ type: AUTHENTICATE_WITH_GOOGLE_FAILURE });
      throw error;
    }
  };
}

export function loginAfterRegisterWithProvider() {
  return async dispatch => {
    try {
      const current_user = await authService.loginInWithToken();
      dispatch({
        type: LOGIN_SUCCESS,
        payload: current_user
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function login(user) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const response = await authService.loginWithEmailAndPassword(user);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: response
      });
    } catch (error) {
      if (
        //https://github.com/firebase/firebase-js-sdk/issues/1926
        error.indexOf("Failed to execute 'transaction' on 'IDBDatabase'") > -1
      ) {
        return window.location.reload();
      }
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function zapierLogin(user) {
  return async dispatch => {
    var search = window.location.search.substring(1);
    let searchParams = '';
    try {
      searchParams = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
    } catch (e) {}

    try {
      dispatch({ type: LOGIN_REQUEST });
      const userData = await authService.loginWithEmailAndPassword(user);

      // Check if has events
      const userDoc = await firebase
        .firestore()
        .collection('users')
        .doc(userData.user_id)
        .get();

      if (!userDoc.get('events')) {
        await dispatch(logout());
        dispatch({ type: LOGIN_FAILURE });
        throw 'Not an organizer. Please sign up at: organizer.eventmix.live';
      } else {
        // Progress on Zapier Oauth2 login
        post(
          `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/authentication-oauth-auth`,
          {
            uid: userData.user_id,
            ...searchParams
          }
        );
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function resetPassword(email) {
  return async dispatch => {
    try {
      dispatch({ type: RESET_PASSWORD_REQUEST });

      const response = await authService.resetPassword(email);

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({ type: RESET_PASSWORD_FAILURE });
    }
  };
}

export function logout() {
  return async dispatch => {
    authService.logout();
    dispatch(resetEvents());
    dispatch(resetAttendees());
    dispatch(resetBilling());
    dispatch(resetPackages());
    dispatch(resetTeamMembers());
    dispatch({ type: RESET_PROVIDER_INFO });
    dispatch({
      type: LOGOUT
    });
  };
}

export function setUserData(user) {
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function updateUser(changes) {
  return async dispatch => {
    try {
      await authService.getUserIdToken();
      dispatch({
        type: UPDATE_USER,
        payload: changes // new profile
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateProfile(changes) {
  return async dispatch => {
    try {
      await authService.updateProfile(changes);
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          ...changes,
          photo: changes.photo.url
        }
      });
    } catch (error) {
      throw new Error(error);
    }
  };
}
export function updateUserOrganization(userData) {
  return async dispatch => {
    try {
      await authService.updateUserOrganization(userData);
      dispatch({
        type: UPDATE_PROFILE_ORGANIZATION,
        payload: userData.organization
      });
    } catch (error) {
      throw new Error(error);
    }
  };
}
export function updateUserPhoneNumber(userData) {
  return async dispatch => {
    try {
      await authService.updateUserPhoneNumber(userData);
      dispatch({
        type: UPDATE_PROFILE_PHONE,
        payload: userData.phoneNumber
      });
    } catch (error) {
      throw new Error(error);
    }
  };
}
export function updateUserWebsite(userData) {
  return async dispatch => {
    try {
      await authService.updateUserWebsite(userData);
      dispatch({
        type: UPDATE_PROFILE_WEBSITE,
        payload: userData.website
      });
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function setRegistrationSurvey(userData) {
  return async dispatch => {
    try {
      await authService.setRegistrationSurvey(userData);
      dispatch({
        type: SET_REGISTRATION_SURVEY,
        payload: userData.registrationSurvey
      });
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function deleteFullAccount() {
  return async dispatch => {
    dispatch({
      type: DELETING_ACCOUNT_START
    });
    try {
      await authService.deleteFullAccount();
      dispatch({
        type: DELETING_ACCOUNT_END
      });
    } catch (error) {
      dispatch({
        type: DELETING_ACCOUNT_END
      });
      throw error;
    }
  };
}

export function requestDeleteFullAccount() {
  return async dispatch => {
    dispatch({
      type: ACCOUNT_DELETE_REQUEST_START
    });
    try {
      await authService.requestDeleteFullAccount();
      dispatch({
        type: ACCOUNT_DELETE_REQUEST_END
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_DELETE_REQUEST_END
      });
      throw error;
    }
  };
}

export function verifyPasswordResetCode(code) {
  return async dispatch => {
    dispatch({ type: VERIFY_PASSWORD_PENDING });
    firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(function() {
        dispatch({
          type: VERIFY_PASSWORD_SUCCESS
        });
      })
      .catch(function() {
        dispatch({
          type: VERIFY_PASSWORD_FAILURE
        });
      });
  };
}

export function resetThePassword(newPassword, code) {
  return async dispatch => {
    dispatch({ type: RESET_PENDING });
    firebase
      .auth()
      .confirmPasswordReset(code, newPassword)
      .then(function() {
        dispatch({ type: RESET_SUCCESS });
      })
      .catch(function() {
        dispatch({ type: RESET_SUCCESS });
      });
  };
}

export function requestEmailVerification() {
  return async _dispatch => {
    try {
      await authService.requestEmailVerification();
    } catch (error) {
      console.log(error);
    }
  };
}

export function handleVerifyEmail(actionCode) {
  return async dispatch => {
    dispatch({ type: EMAIL_VERIFICATION });
    return auth
      .applyActionCode(actionCode)
      .then(async () => {
        // Save the email-verified property for the organizer on the firebase doc too;
        const response = await authService.saveEmailVerifiedFlag();
        if (response) {
          dispatch({
            type: EMAIL_VERIFICATION_SUCCESS
          });
          return true;
        }
        else return false;
      })
      .catch(async error => {
        dispatch({ type: EMAIL_VERIFICATION_FAILURE });
        return error;
      });
  };
}

/**
 * Sends a request to the specified url from a form. This will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

function post(path, params, method = 'post') {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}
