import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as routes from 'src/routeConstants';
import { getQueryParameter } from 'src/utils/queryParameter';
import { getAllEventIdsByUsers } from 'src/actions/eventActions';
import CustomLoader from './CustomLoader';

function EventsGuard({ children }) {
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);
  const eventId = getQueryParameter(routes.EVENT);

  const [allEventIds, setAllEventIds] = useState(null);

  useEffect(() => {
    dispatch(getAllEventIdsByUsers()).then(({ payload }) => {
      if (payload) {
        setAllEventIds(payload);
      }
    });
  }, [dispatch]);

  if (!allEventIds) return <CustomLoader />;

  if (account.user && allEventIds.includes(eventId)) {
    return <>{children}</>;
  }
  if (!account.user) {
    return <Redirect to={routes.LOGIN} />;
  }
  return <Redirect to={routes.NOT_FOUND} />;
}

EventsGuard.propTypes = {
  children: PropTypes.any
};

export default EventsGuard;
