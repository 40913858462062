import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';
import {
  createSpeaker,
  updateSpeaker,
  updateSpeakersSort,
  deleteSpeaker,
  getSpeakerById,
  getEventSpeakers
} from '../core/api/SpeakerStore';
import { functions } from 'src/core/api/firebase';
class SpeakerService {
  createSpeaker = (speaker, cleanupId) =>
    new Promise(async (resolve, reject) => {
      try {
        const eventId = getQueryParameter(routes.EVENT);
        const newSpeaker = await createSpeaker(speaker, eventId, cleanupId);
        resolve(newSpeaker);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });

  getSpeakerById = speakerId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getSpeakerById(eventId, speakerId)
        .then(speaker => {
          if (speaker != null) {
            resolve(speaker);
          } else {
            reject(translate('speakers.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateSpeaker = speaker =>
    new Promise(async (resolve, reject) => {
      try {
        const eventId = getQueryParameter(routes.EVENT);
        const newSpeaker = await updateSpeaker(speaker, eventId);
        resolve(newSpeaker);
      } catch (error) {
        console.error(error);
        reject(error.message);
      }
    });

  updateSpeakersSort = speakers =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateSpeakersSort(speakers, eventId)
        .then(newSpeakers => {
          resolve(newSpeakers);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteSpeaker = speakerId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteSpeaker(speakerId, eventId)
        .then(deletedSpeakerId => {
          resolve(deletedSpeakerId);
        })
        .catch(error => {
          console.error(error);
          reject(error.message);
        });
    });

  getEventSpeakers = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventSpeakers(eventId)
        .then(speakers => {
          if (speakers != null) {
            resolve(this.sortSpeakers(speakers));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortSpeakers = speakers => {
    let sortedSpeakers = speakers.sort((a, b) =>
      a.hasOwnProperty('sort') && b.hasOwnProperty('sort')
        ? a.sort - b.sort
        : a.createdDate - b.createdDate
    );
    let newSpeakers = sortedSpeakers.map(
      (s, index) => (s = { ...s, sort: index })
    );
    return newSpeakers;
  };

  inviteSpeaker = (speaker, stageId) => {
    const eventId = getQueryParameter(routes.EVENT);
    return new Promise((resolve, reject) => {
      const sendInvite = functions.httpsCallable(
        'emails-sendInviteSpeakerEmail'
      );
      sendInvite({ speaker, stageId, eventId })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.message);
        });
    });
  };

  generateSpeakerLoginLink = (speaker, stageId) => {
    const eventId = getQueryParameter(routes.EVENT);
    const email = speaker.email;
    return new Promise((resolve, reject) => {
      const createSpeakerLoginLink = functions.httpsCallable(
        'emails-generateSpeakerLoginLink'
      );
      createSpeakerLoginLink({ email, stageId, eventId })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.message);
        });
    });
  };
}

export default new SpeakerService();
