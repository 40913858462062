import {
  createBooth,
  updateBooth,
  updateBoothsSort,
  updateBoothIsPublished,
  deleteBooth,
  getBoothById,
  getBooths,
  resetBooths,
} from 'src/actions/boothActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const boothEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = boothEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const boothReducer = createReducer(initialState, {
  [createBooth.pending]: (state, _) => {
    state.loading = true;
  },
  [createBooth.fulfilled]: (state, { payload }) => {
    state.loading = false;
    boothEntityAdapter.upsertOne(state, payload);
  },
  [createBooth.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateBooth.pending]: (state, _) => {
    state.loading = true;
  },
  [updateBooth.fulfilled]: (state, { payload }) => {
    state.loading = false;
    boothEntityAdapter.updateOne(state, payload);
  },
  [updateBooth.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateBoothsSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateBoothsSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    boothEntityAdapter.upsertMany(state, payload);
  },
  [updateBoothsSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateBoothIsPublished.pending]: (state, _) => {
    state.loading = true;
  },
  [updateBoothIsPublished.fulfilled]: (state, { payload }) => {
    state.loading = false;
    boothEntityAdapter.updateOne(state, payload);
  },
  [updateBoothIsPublished.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteBooth.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteBooth.fulfilled]: (state, { payload }) => {
    state.loading = false;
    boothEntityAdapter.removeOne(state, payload);
  },
  [deleteBooth.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getBoothById.pending]: (state, _) => {
    state.loading = true;
  },
  [getBoothById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    boothEntityAdapter.upsertOne(state, payload);
  },
  [getBoothById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getBooths.pending]: (state, _) => {
    state.loading = true;
  },
  [getBooths.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    boothEntityAdapter.upsertMany(state, payload);
  },
  [getBooths.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetBooths.type]: (_, __) => {
    return initialState;
  }
});

export default boothReducer;
