import firebase from 'firebase';
import authService from 'src/services/authService';
import eventService from 'src/services/eventService';
import { functions, store } from './firebase';

const USERS_COLLECTION = 'users';
const BILLING_INFORMATION = 'billing-informations';
const billingInformationId = 'billingInformation';

const billingInformationDocRef = userId =>
  store
    .collection(`${USERS_COLLECTION}/${userId}/${BILLING_INFORMATION}`)
    .doc(billingInformationId);

export async function createBillingInformation(userId, billingInformation) {
  const billingInformationCollection = store.collection(
    `${USERS_COLLECTION}/${userId}/${BILLING_INFORMATION}`
  );
  const newBillingInfo = {
    ...billingInformation,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId,
    id: billingInformationId
  };

  await billingInformationCollection
    .doc(billingInformationId)
    .set(newBillingInfo, { merge: true });

  return {
    ...billingInformation,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId,
    id: billingInformationId
  };
}

export async function updateBillingInformation(userId, changes) {
  const billingInformationRef = billingInformationDocRef(userId);
  await billingInformationRef.update({
    ...changes
  });

  const billingInformationDoc = await billingInformationRef.get();

  return {
    id: billingInformationDoc.id,
    ...billingInformationDoc.data()
  };
}

export async function updateBillingInformationOrganization(
  userId,
  organization
) {
  const billingInformationRef = billingInformationDocRef(userId);
  await billingInformationRef.set(
    {
      nameOrCompanyName: organization
    },
    { merge: true }
  );
}

export async function updatePaymentInformation(userId, changes) {
  const billingInformationRef = billingInformationDocRef(userId);
  if (changes.paymentMethodId) {
    await billingInformationRef.update({
      ...changes,
      paymentInfo: {
        paymentMethodId: changes.paymentMethodId,
        nameOfCardHolder: changes.nameOfCardHolder,
        paymentCard: changes.paymentCard
      }
    });

    const billingInformationDoc = await billingInformationRef.get();

    return {
      id: billingInformationDoc.id,
      ...billingInformationDoc.data(),
      paymentInfo: {
        paymentMethodId: changes.paymentMethodId,
        nameOfCardHolder: changes.nameOfCardHolder,
        paymentCard: changes.paymentCard
      }
    };
  } else {
    await billingInformationRef.update({
      ...changes
    });

    const billingInformationDoc = await billingInformationRef.get();

    return {
      id: billingInformationDoc.id,
      ...billingInformationDoc.data()
    };
  }
}

export async function getBillingInformation(userId) {
  const billingInfoReference = billingInformationDocRef(userId);
  const billingInfo = await billingInfoReference.get();
  if (!billingInfo.exists) return null;
  return { ...billingInfo.data() };
}
export async function getUserBillingFlagsFromEvent(eventId) {
  try {
    const { user_id, connectedAccountId } = await authService.getUserInfo();
    const currentUserIsStripeConnected = !!connectedAccountId;
    const event = await eventService.getEventById(eventId);
    const eventCreator = event.createdBy;
    const currentUserIsEventCreator = user_id === eventCreator;

    if (currentUserIsEventCreator) {
      return {
        isStripeConnected: currentUserIsStripeConnected,
        isSharedEvent: false
      };
    }

    const userBillingFlagsFunction = functions.httpsCallable(
      'billingInformation-checkForUserBillingInfo'
    );

    const userBillingInfo = await userBillingFlagsFunction({
      userId: eventCreator
    });

    return {
      ...userBillingInfo.data,
      isSharedEvent: true
    };
  } catch (error) {
    console.error(error.message);
    return Promise.reject(error);
  }
}

export async function getUserBillingFlags(userId) {
  try {
    const userBillingFlagsFunction = functions.httpsCallable(
      'billingInformation-checkForUserBillingInfo'
    );
    const userBillingInfo = await userBillingFlagsFunction({ userId: userId });
    return userBillingInfo.data;
  } catch (error) {
    console.error(error.message);
    return Promise.reject(error);
  }
}

export async function verifyVatNumber(vatNumber, countryCode) {
  const verifyVAT = functions.httpsCallable('subscriptions-verifyVatNumber');
  const response = await verifyVAT({
    vatNumber: vatNumber.trim(),
    countryCode: countryCode
  });

  if (response.data.validationInfo) {
    return response.data.validationInfo;
  } else {
    throw new Error(response.data.message);
  }
}

export async function validateCard(
  cardElement,
  stripe /* pass down the stripe instance because you can't use hooks outside of components */
) {
  // generate a setup intent to validate the card
  const { data: clientSecret } = await functions.httpsCallable(
    'payment-setupIntent'
  )();
  // validate the card, with the related card informations
  const { error } = await stripe.confirmCardSetup(clientSecret, {
    payment_method: {
      card: cardElement
    }
  });
  if (error) {
    throw error;
  }
}
