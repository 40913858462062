import {
  createPromoCode,
  getEventPromoCodes,
  deletePromoCode,
  updatePromoCode,
  getPromoCodeById
} from '../core/api/PromoCodeStore';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import authService from 'src/services/authService';
import { translate } from 'src/translations/i18n';

class PromoCodeService {
  createPromoCode = ({ promoCode, timezone }) =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createPromoCode(promoCode, userId, eventId, timezone)
        .then(newPromoCode => {
          resolve(newPromoCode);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventPromoCodes = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventPromoCodes(eventId)
        .then(promoCodes => {
          if (promoCodes != null) {
            resolve(promoCodes);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deletePromoCode = promoCodeId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deletePromoCode(promoCodeId, eventId)
        .then(stage => {
          resolve(stage);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updatePromoCode = ({ promoCode, timezone }) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updatePromoCode(promoCode, eventId, timezone)
        .then(newPromoCode => {
          resolve(newPromoCode);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getPromoCodeById = promoCodeId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getPromoCodeById(promoCodeId, eventId)
        .then(promoCode => {
          if (promoCode != null) {
            resolve(promoCode);
          } else {
            reject(translate('domains.errors.noDomainWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new PromoCodeService();
