import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import {
  getStat,
  getStatsMonthly,
  getStatsTotal
} from '../core/api/StatsStore';

class StatsService {
  getStatsTotal = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getStatsTotal(eventId)
        .then(totalStat => {
          resolve(totalStat);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getStatsMonthly = date =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getStatsMonthly(eventId, date)
        .then(monthlyStats => {
          resolve(monthlyStats);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getStat = (date, period) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getStat(eventId, date, period)
        .then(dayStat => {
          resolve(dayStat);
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new StatsService();
