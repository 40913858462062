import { createReducer } from '@reduxjs/toolkit';
import {
  changeStatPeriod,
  getStatsMonthly,
  getStatsTotal,
  getStat
} from 'src/actions/statsActions';
import { PERIODS } from 'src/constants/stats';

const initialState = {
  period: PERIODS.DAY,
  date: null,
  total: {},
  monthly: [],
  periodic: {},
  totalLoading: false,
  monthlyLoading: false,
  periodicLoading: false,
  errors: {}
};

const statsReducer = createReducer(initialState, {
  [getStatsTotal.pending]: state => {
    state.totalLoading = true;
    delete state.errors.total;
  },
  [getStatsTotal.fulfilled]: (state, { payload }) => {
    state.totalLoading = false;
    state.total = payload;
  },
  [getStatsTotal.rejected]: (state, { error }) => {
    state.totalLoading = false;
    state.total = {};
    state.errors.total = error;
  },
  [getStatsMonthly.pending]: state => {
    state.monthlyLoading = true;
    delete state.errors.monthly;
  },
  [getStatsMonthly.fulfilled]: (state, { payload }) => {
    state.monthlyLoading = false;
    state.monthly = payload;
  },
  [getStatsMonthly.rejected]: (state, { error }) => {
    state.monthlyLoading = false;
    state.monthly = [];
    state.errors.monthly = error;
  },
  [getStat.pending]: state => {
    state.periodicLoading = true;
    delete state.errors.periodic;
  },
  [getStat.fulfilled]: (state, { payload }) => {
    state.periodicLoading = false;
    state.periodic = payload;
  },
  [getStat.rejected]: (state, { error }) => {
    state.periodicLoading = false;
    state.periodic = {};
    state.errors.periodic = error;
  },
  [changeStatPeriod.type]: (state, { payload }) => {
    const { period, date } = payload;
    state.period = period;
    state.date = date;
  }
});

export default statsReducer;
