import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import {
  createTestimonial,
  getEventTestimonials,
  deleteTestimonial,
  getTestimonialById,
  updateTestimonial,
  updateTestimonialsSort
} from '../core/api/TestimonialStore';
import { translate } from 'i18n-js';

class TestimonialService {
  createTestimonial = testimonial =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createTestimonial(testimonial, userId, eventId)
        .then(newTestimonial => {
          resolve(newTestimonial);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventTestimonials = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventTestimonials(eventId)
        .then(testimonials => {
          if (testimonials != null) {
            resolve(this.sortList(testimonials));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteTestimonial = testimonialId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteTestimonial(testimonialId, eventId)
        .then(testimonial => {
          resolve(testimonial);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getTestimonialById = testimonialId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getTestimonialById(eventId, testimonialId)
        .then(testimonial => {
          if (testimonial != null) {
            resolve(testimonial);
          } else {
            reject(translate('sponsors.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateTestimonial = testimonial =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateTestimonial(testimonial, eventId)
        .then(newTestimonial => {
          resolve(newTestimonial);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateTestimonialsSort = (testimonials) => new Promise((resolve, reject) => {
    const eventId = getQueryParameter(routes.EVENT);

    updateTestimonialsSort(testimonials, eventId)
      .then((newTestimonials) => {
        resolve(newTestimonials);
      })
      .catch((error) => {
        reject(error.message);
      });
  });

  sortList = (list) => {
    let sorted = list.sort((a, b) => (a.hasOwnProperty('sort') && b.hasOwnProperty('sort')) ? a.sort - b.sort : a.createdDate - b.createdDate);
    let result = sorted.map((s, index) => (s = { ...s, sort: index }));
    return result;
  }
}

export default new TestimonialService();
