export const STAGE_TYPE = 'stages';
export const ROUND_TABLE_TYPE = 'round tables';
export const SPEAKER_TYPE = 'speakers';
export const BOOTH_TYPE = 'sponsor booths';
export const TEAM_MEMBERS_TYPE = 'team members';
export const QR_TYPE = 'QR codes';
export const EVENT_LENGTH_TYPE = 'event length';
export const EVENT_LENGTH_UPDATE_TYPE = 'event length update';
export const STAGES_VALIDATION_ERROR =
  'Your package does not cover more stages';
export const EVENT_LENGTH_VALIDATION_ERROR =
  'Your package does not cover a longer event';
export const ROUND_TABLES_VALIDATION_ERROR =
  'Your package does not cover more round tables';
export const BOOTHS_VALIDATION_ERROR =
  'Your package does not cover more  expo booths';
export const TEAM_MEMBERS_VALIDATION_ERROR =
  'Your package does not cover more team members';
export const SPEAKERS_VALIDATION_ERROR =
  'Your package does not cover more speakers';

export const FREE_MONTHLY = 'EVENTMIX-FREE-PLAN-USD-Monthly';
export const FREE_YEARLY = 'EVENTMIX-FREE-PLAN-USD-Yearly';

export const START_MONTHLY = 'EVENTMIX-START-PLAN-USD-Monthly';
export const START_YEARLY = 'EVENTMIX-START-PLAN-USD-Yearly';

export const SCALE_MONTHLY = 'EVENTMIX-SCALE-PLAN-USD-Monthly';
export const SCALE_YEARLY = 'EVENTMIX-SCALE-PLAN-USD-Yearly';
export const FREE_TRIAL_MONTHLY = 'PAYG-USD-Monthly';

export const PRO_MONTHLY = 'EVENTMIX-PRO-PLAN-USD-Monthly';
export const PRO_YEARLY = 'EVENTMIX-PRO-PLAN-USD-Yearly';
export const FREE_PLAN = 'Free';
export const START_PLAN = 'Start';
export const SCALE_PLAN = 'Scale';
export const PRO_PLAN = 'Pro';
export const FREE_EARLY_ACCESS = 'Free Early Access';

export const PACKAGES_ORDER = [FREE_PLAN, START_PLAN, SCALE_PLAN, PRO_PLAN];

export const FREE_ATTENDEES_LIMIT_WARNING = 70;
