import {
  updateIntegrationTrackingCodes,
  getIntegrationTrackingCodes
} from 'src/core/api/IntegrationStore';
import * as routes from 'src/routeConstants';
import { getQueryParameter } from 'src/utils/queryParameter';
import authService from './authService';
import { translate } from 'src/translations/i18n';

class IntegrationPageService {
  updateIntegrationTrackingCodes = change =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      const userId = authService.getUserUid();
      updateIntegrationTrackingCodes(eventId, userId, change)
        .then(changes => {
          resolve(changes);
        })
        .catch(error => {
          reject(error);
        });
    });

  getIntegrationTrackingCodes = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      getIntegrationTrackingCodes(eventId)
        .then(trackingCodes => {
          if (trackingCodes != null) {
            resolve(trackingCodes);
          } else {
            reject(translate('letters.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new IntegrationPageService();
