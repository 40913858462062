import * as routes from 'src/routeConstants';
import { getQueryParameter } from 'src/utils/queryParameter';
import {
  getEventCustomRegistrationForms,
  updateCustomRegistrationFormsSort,
  deleteCustomRegistrationForm,
  getEventCustomRegistrationFormById,
  createCustomRegistrationForm,
  updateCustomRegistrationForm
} from '../core/api/CustomRegistrationFormsStore';

class CustomRegistrationFormsService {
  getEventCustomRegistrationForms = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventCustomRegistrationForms(eventId)
        .then(customRegistration => {
          if (customRegistration != null) {
            resolve(this.sortCustomRegistration(customRegistration));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventCustomRegistrationFormById = formId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventCustomRegistrationFormById(eventId, formId)
        .then(resolve)
        .catch(error => {
          reject(error.message);
        });
    });

  updateCustomRegistrationFormsSort = customRegistrationForms =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateCustomRegistrationFormsSort(customRegistrationForms, eventId)
        .then(item => {
          resolve(item);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  createCustomRegistrationForm = data =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      createCustomRegistrationForm(data, eventId)
        .then(item => {
          resolve(item);
        })
        .catch(error => {
          console.log(error);
          reject(error.message);
        });
    });

  updateCustomRegistrationForm = changes =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      updateCustomRegistrationForm(changes, eventId)
        .then(item => {
          resolve(item);
        })
        .catch(error => {
          console.log(error);
          reject(error.message);
        });
    });

  deleteCustomRegistrationForm = id =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteCustomRegistrationForm(id, eventId)
        .then(item => {
          resolve(item);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortCustomRegistration = customRegistration => {
    let sortedCustomRegistration = customRegistration.sort((a, b) =>
      a.hasOwnProperty('sort') && b.hasOwnProperty('sort')
        ? a.sort - b.sort
        : a.createdDate - b.createdDate
    );
    let newCustomRegistration = sortedCustomRegistration.map(
      (s, index) => (s = { ...s, sort: index })
    );
    return newCustomRegistration;
  };
}

export default new CustomRegistrationFormsService();
