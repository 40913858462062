import firebase from 'firebase';
import { storage, store } from './firebase';

const EVENTS_COLLECTION = 'events';
const SPONSORS_COLLECTION = 'sponsors';

export async function createSponsor(sponsor, userId, eventId) {
  const sponsorsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${SPONSORS_COLLECTION}`
  );

  let collectionSize = 0;
  await sponsorsCollection.get().then(s => collectionSize = s.size);

  let newSponsor = {
    link: sponsor.link,
    name: sponsor.name,
    logoUrl: '',
    id: '',
    sort: collectionSize,
    selectedCategory: sponsor.selectedCategory,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  };

  await sponsorsCollection
    .add(newSponsor)
    .then(s => (newSponsor = { ...newSponsor, id: s.id }));

  if (sponsor.logoUrl !== '') {
    const imageName = `events/${eventId}/sponsor/${sponsor.name}`;
    const uploadTask = await storage.ref(imageName).put(sponsor.logoRaw);
    const sponsorDoc = sponsorsCollection.doc(newSponsor.id);
    const downloadUrl = await uploadTask.ref.getDownloadURL();
    newSponsor = { ...newSponsor, logoUrl: downloadUrl };
    await sponsorDoc.update({
      logoUrl: downloadUrl,
      id: newSponsor.id,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  return newSponsor;
}

export async function getEventSponsors(eventId) {
  const sponsorDocs = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(SPONSORS_COLLECTION)
    .get();

  return sponsorDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export async function deleteSponsor(sponsorId, eventId) {
  const sponsorsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${SPONSORS_COLLECTION}`
  );
  const sponsorDoc = sponsorsCollection.doc(sponsorId);

  await sponsorDoc.delete();

  return sponsorId;
}

export async function updateSponsor(sponsor, eventId) {
  const sponsorDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${SPONSORS_COLLECTION}`)
    .doc(sponsor.id);

  await sponsorDoc.update({
    link: sponsor.link,
    name: sponsor.name,
    selectedCategory: sponsor.selectedCategory,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  let updatedSponsor = {
    id: sponsor.id,
    link: sponsor.link,
    name: sponsor.name,
    selectedCategory: sponsor.selectedCategory
  };

  if (sponsor.logoRaw !== '') {
    const imageName = `events/${eventId}/sponsor/${sponsor.name}`;
    const uploadTask = await storage.ref(imageName).put(sponsor.logoRaw);
    const downloadUrl = await uploadTask.ref.getDownloadURL();
    updatedSponsor = {
      ...updatedSponsor,
      logoUrl: downloadUrl
    };
    await sponsorDoc.update({
      logoUrl: downloadUrl,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  return updatedSponsor;
}

export async function updateSponsorsSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  changesList.forEach(async changes => {
    const sponsorRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${SPONSORS_COLLECTION}/${changes.id}`
    );

    batch.update(sponsorRef, {
      id: changes.id,
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    let result = {
      ...changes
    };

    resultList.push(result);
  });

  batch.commit();

  return resultList;
}

export async function getSponsorById(eventId, sponsorId) {
  const sponsorDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${SPONSORS_COLLECTION}`)
    .doc(sponsorId);
  const sponsor = await sponsorDoc.get();
  if (!sponsor.exists) return null;
  return { id: sponsor.id, ...sponsor.data() };
}
