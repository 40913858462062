import promoCodeService from 'src/services/promoCodeService';

export const CREATE_PROMO_CODE = '@promoCode/create-promoCode';
export const CREATE_PROMO_CODE_FAILURE = '@promoCode/create-promoCode-failure';
export const GET_PROMO_CODES = '@promoCode/get-promoCodes';
export const GET_PROMO_CODE_FAILURE = '@promoCode/get-promoCode-failure';
export const DELETE_PROMO_CODE = '@promoCode/delete-promoCode';
export const DELETE_PROMO_CODE_FAILURE = '@promoCode/delete-promoCode-failure';
export const UPDATE_PROMO_CODE = '@promoCode/update-promoCode';
export const UPDATE_PROMO_CODE_FAILURE = '@promoCode/update-promoCode-failure';

export function createPromoCode(newPromoCode) {
  return async dispatch => {
    try {
      dispatch({
        type: CREATE_PROMO_CODE,
        payload: newPromoCode
      });
    } catch (error) {
      dispatch({ type: CREATE_PROMO_CODE_FAILURE });
      throw error;
    }
  };
}

export function getPromoCodes() {
  return async dispatch => {
    try {
      const response = await promoCodeService.getEventPromoCodes();
      dispatch({
        type: GET_PROMO_CODES,
        payload: response
      });
    } catch (error) {
      dispatch({ type: GET_PROMO_CODE_FAILURE });
      throw error;
    }
  };
}

export function deletePromoCode(promoCodeId) {
  return async dispatch => {
    try {
      dispatch({
        type: DELETE_PROMO_CODE,
        payload: promoCodeId
      });
    } catch (error) {
      dispatch({ type: DELETE_PROMO_CODE_FAILURE });
      throw error;
    }
  };
}

export function updatePromoCode(promoCode) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_PROMO_CODE,
        payload: promoCode
      });
    } catch (error) {
      dispatch({ type: UPDATE_PROMO_CODE_FAILURE });
      throw error;
    }
  };
}
