export const LIVE_BACKSTAGE_SESSION_TYPE = 'LIVE_BACKSTAGE_SESSION_TYPE';
export const LIVE_RTMPS_SESSION_TYPE = 'LIVE_RTMPS_SESSION_TYPE';
export const PRE_RECORDED_SESSION_TYPE = 'PRE_RECORDED_SESSION_TYPE';
export const TIMER_UNTIL_NEXT_SESSION = 'TIMER_UNTIL_NEXT_SESSION';

export const sessionTranslateKeys = {
  [LIVE_BACKSTAGE_SESSION_TYPE]: 'stages.type.live-backstage',
  [LIVE_RTMPS_SESSION_TYPE]: 'stages.type.live-rtmps',
  [PRE_RECORDED_SESSION_TYPE]: 'stages.type.recorded',
  [TIMER_UNTIL_NEXT_SESSION]: 'stages.type.timer'
};

export const LIVE_SESSION_TYPES = [
  LIVE_BACKSTAGE_SESSION_TYPE,
  LIVE_RTMPS_SESSION_TYPE
];

export const LECTURE_SESSION_TYPES = [
  LIVE_RTMPS_SESSION_TYPE,
  LIVE_BACKSTAGE_SESSION_TYPE,
  PRE_RECORDED_SESSION_TYPE
];

export const BREAK_SESSION_TYPES = [
  LIVE_RTMPS_SESSION_TYPE,
  LIVE_BACKSTAGE_SESSION_TYPE,
  PRE_RECORDED_SESSION_TYPE,
  TIMER_UNTIL_NEXT_SESSION
];
