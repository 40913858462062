import firebase from 'firebase';
import { LABEL_ID } from 'src/constants/event-settings-constants';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const EVENT_SETTINGS_COLLECTION = 'settings';

export async function updateEventChatSettings(changes, eventId) {
  const chatSettingsRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${EVENT_SETTINGS_COLLECTION}/chat`
  );
  await chatSettingsRef.set({
    ...changes,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  const chatSettingsDoc = await chatSettingsRef.get();
  return {
    id: chatSettingsDoc.id,
    changes: {
      ...chatSettingsDoc.data()
    }
  };
}

export async function updateEventQASettings(changes, eventId) {
  const qaSettingsRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${EVENT_SETTINGS_COLLECTION}/qa`
  );
  await qaSettingsRef.set({
    ...changes,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  const qaSettingsDoc = await qaSettingsRef.get();
  return {
    id: qaSettingsDoc.id,
    changes: {
      ...qaSettingsDoc.data()
    }
  };
}

export async function updateEventPeopleSettings(changes, eventId) {
  const peopleSettingsRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${EVENT_SETTINGS_COLLECTION}/people`
  );
  await peopleSettingsRef.set({
    ...changes,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  const peopleSettingsDoc = await peopleSettingsRef.get();
  return {
    id: peopleSettingsDoc.id,
    changes: {
      ...peopleSettingsDoc.data()
    }
  };
}

export async function updateEventLabelSettings(changes, eventId) {
  const labelSettingsRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${EVENT_SETTINGS_COLLECTION}/${LABEL_ID}`
  );
  await labelSettingsRef.set(
    {
      ...changes,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    },
    { merge: true }
  );

  const labelSettingsDoc = await labelSettingsRef.get();
  return {
    id: labelSettingsDoc.id,
    changes: {
      ...labelSettingsDoc.data()
    }
  };
}

export async function getEventSettings(eventId) {
  const sponsorDocs = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(EVENT_SETTINGS_COLLECTION)
    .get();

  return sponsorDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}
