export const LANDING_PAGE_SECTIONS = {
  HEADER: 'header',
  TICKETS_BOX: 'ticketsBox',
  ABOUT: 'about',
  SPEAKERS: 'speakers',
  LETTER: 'letter',
  SCHEDULE: 'schedule',
  CHECK_LIST: 'checklist',
  BENEFITS: 'benefits',
  TESTIMONIALS: 'testimonials',
  SPONSORS: 'sponsors',
  FOOTER: 'footer'
};

export const LANDING_PAGE_SECTION_NAMES = {
  HEADER: 'Header',
  TICKETS_BOX: 'Tickets and Registration Box',
  ABOUT: 'About',
  SPEAKERS: 'Speakers',
  LETTER: 'Letter',
  SCHEDULE: 'Schedule',
  CHECKLIST: 'Checklist',
  BENEFITS: 'Benefits',
  TESTIMONIALS: 'Testimonials',
  SPONSORS: 'Sponsors',
  FOOTER: 'Footer'
};

export const LANDING_PAGE_CUSTOM_SECTIONS_TYPE = 'customSections';

export const DEFAULTS = {
  [LANDING_PAGE_SECTIONS.HEADER]: {
    sort: 0,
    isSortable: false,
    isDefault: true,
    isActive: true,
    isEditable: true,
    isPersistent: true,
    title: LANDING_PAGE_SECTION_NAMES.HEADER,
    type: LANDING_PAGE_SECTIONS.HEADER
  },
  [LANDING_PAGE_SECTIONS.TICKETS_BOX]: {
    sort: 1,
    isSortable: false,
    isDefault: true,
    isActive: true,
    isEditable: true,
    isPersistent: true,
    title: LANDING_PAGE_SECTION_NAMES.TICKETS_BOX,
    type: LANDING_PAGE_SECTIONS.TICKETS_BOX
  },
  [LANDING_PAGE_SECTIONS.ABOUT]: {
    sort: 2,
    isSortable: false,
    isDefault: true,
    isActive: true,
    isEditable: true,
    isPersistent: true,
    title: LANDING_PAGE_SECTION_NAMES.ABOUT,
    type: LANDING_PAGE_SECTIONS.ABOUT
  },
  [LANDING_PAGE_SECTIONS.LETTER]: {
    sort: 3,
    isSortable: true,
    isDefault: false,
    isActive: true,
    isEditable: true,
    title: LANDING_PAGE_SECTION_NAMES.LETTER,
    type: LANDING_PAGE_SECTIONS.LETTER
  },
  [LANDING_PAGE_SECTIONS.SCHEDULE]: {
    sort: 4,
    isSortable: true,
    isDefault: true,
    isActive: true,
    isEditable: false,
    title: LANDING_PAGE_SECTION_NAMES.SCHEDULE,
    type: LANDING_PAGE_SECTIONS.SCHEDULE
  },
  [LANDING_PAGE_SECTIONS.SPEAKERS]: {
    sort: 5,
    isSortable: true,
    isDefault: true,
    isActive: true,
    isEditable: false,
    title: LANDING_PAGE_SECTION_NAMES.SPEAKERS,
    type: LANDING_PAGE_SECTIONS.SPEAKERS
  },
  [LANDING_PAGE_SECTIONS.CHECK_LIST]: {
    sort: 6,
    isSortable: true,
    isDefault: true,
    isActive: true,
    isEditable: true,
    title: LANDING_PAGE_SECTION_NAMES.CHECKLIST,
    type: LANDING_PAGE_SECTIONS.CHECK_LIST
  },
  [LANDING_PAGE_SECTIONS.BENEFITS]: {
    sort: 7,
    isSortable: true,
    isDefault: true,
    isActive: true,
    isEditable: true,
    title: LANDING_PAGE_SECTION_NAMES.BENEFITS,
    type: LANDING_PAGE_SECTIONS.BENEFITS
  },
  [LANDING_PAGE_SECTIONS.TESTIMONIALS]: {
    sort: 8,
    isSortable: true,
    isDefault: true,
    isActive: true,
    isEditable: true,
    title: LANDING_PAGE_SECTION_NAMES.TESTIMONIALS,
    type: LANDING_PAGE_SECTIONS.TESTIMONIALS
  },
  [LANDING_PAGE_SECTIONS.SPONSORS]: {
    sort: 9,
    isSortable: true,
    isDefault: true,
    isActive: true,
    isEditable: true,
    title: LANDING_PAGE_SECTION_NAMES.SPONSORS,
    type: LANDING_PAGE_SECTIONS.SPONSORS
  },
  [LANDING_PAGE_SECTIONS.FOOTER]: {
    sort: 999999,
    isSortable: false,
    isDefault: true,
    isActive: true,
    isPersistent: true,
    isEditable: true,
    title: LANDING_PAGE_SECTION_NAMES.FOOTER,
    type: LANDING_PAGE_SECTIONS.FOOTER
  }
};
