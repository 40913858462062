import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  getUserPackage,
  getEventOwnerPlan,
  resetPackages,
  getPackages,
  updateUserPackage,
  getInvoices
} from 'src/actions/packageActions';

export const packageEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.monthlyPrice - b.monthlyPrice
});

const initialState = packageEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  userPackage: null,
  eventOwnerPlan: null,
  eventOwnerPlanLoading: false,
  eventOwnerPlanLoaded: false,
  invoices: [],
  invoiceLoading: false,
  invoiceLoaded: false,
  userPlanLoading: false,
  userPlanLoaded: false,
  updatePlanLoading: false,
  updatePlanLoaded: false
});

const packageReducer = createReducer(initialState, {
  [getUserPackage.pending]: (state, _) => {
    state.userPlanLoading = true;
  },
  [getUserPackage.fulfilled]: (state, { payload }) => {
    state.userPlanLoading = false;
    state.userPlanLoaded = true;
    state.userPackage = payload;
  },
  [getUserPackage.rejected]: (state, { error }) => {
    state.userPlanLoading = false;
    state.error = error;
  },
  [getEventOwnerPlan.fulfilled]: (state, { payload }) => {
    state.eventOwnerPlanLoading = false;
    state.eventOwnerPlanLoaded = true;
    state.eventOwnerPlan = payload;
  },
  [getEventOwnerPlan.rejected]: (state, { error }) => {
    state.eventOwnerPlanLoading = false;
    state.error = error;
  },
  [getEventOwnerPlan.pending]: (state, _) => {
    state.eventOwnerPlanLoading = true;
  },
  [getPackages.pending]: (state, _) => {
    state.loading = true;
  },
  [getPackages.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    packageEntityAdapter.upsertMany(state, payload);
  },
  [getPackages.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getInvoices.pending]: (state, _) => {
    state.invoiceLoading = true;
  },
  [getInvoices.fulfilled]: (state, { payload }) => {
    state.invoiceLoading = false;
    state.invoiceLoaded = true;
    state.invoices = payload;
  },
  [getInvoices.rejected]: (state, { error }) => {
    state.invoiceLoading = false;
    state.error = error;
  },
  [updateUserPackage.pending]: (state, _) => {
    state.loading = true;
    state.updatePlanLoading = true;
  },
  [updateUserPackage.rejected]: (state, _) => {
    state.loading = false;
    state.updatePlanLoading = false;
  },
  [updateUserPackage.fulfilled]: (state, { payload }) => {
    state.userPackage = payload;
    state.loading = false;
    state.updatePlanLoading = false;
    state.loaded = true;
    state.updatePlanLoaded = false;
  },
  [resetPackages.type]: (_, __) => {
    return initialState;
  }
});

export default packageReducer;
