import React from 'react';

function Mic() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
      <path d="M20.14,10.64a.56.56,0,1,0-1.12,0,7,7,0,0,1-7,7h0a7,7,0,0,1-7-7,.56.56,0,1,0-1.12,0,8.14,8.14,0,0,0,7.58,8.11v3.13H8.65a.56.56,0,1,0,0,1.12h6.71a.56.56,0,0,0,0-1.12h-2.8V18.75A8.14,8.14,0,0,0,20.14,10.64Z" fill="currentColor"/>
      <path d="M11.78,14.18h.44a3.37,3.37,0,0,0,3.36-3.36V4.36A3.36,3.36,0,0,0,12.22,1h-.44A3.36,3.36,0,0,0,8.42,4.36v6.46A3.37,3.37,0,0,0,11.78,14.18ZM9.55,4.36a2.24,2.24,0,0,1,2.23-2.24h.44a2.24,2.24,0,0,1,2.23,2.24v6.46a2.23,2.23,0,0,1-2.23,2.23h-.44a2.23,2.23,0,0,1-2.23-2.23Z" fill="currentColor"/>
    </svg>
  );
}

export default Mic;
