import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import attendeesService from 'src/services/attendeesService';

export const QUERY_ATTENDEES = '@ticket/query-attendees';
export const GET_ATTENDEES_STATS = '@ticket/get-attendees-stats';
export const RESET_ATTENDEES = '@ticket/reset-attendees';
export const ACTIVATE_USER_TICKET = '@ticket/activate-user-ticket';
export const ACTIVATE_USERS = '@ticket/activate-users';
export const GET_LATEST_ATTENDEES = '@ticket/get-latest-attendees';
export const DELETE_ATTENDEE = '@ticket/delete-attendee';
export const SET_EVENT_FILTER = '@ticket/set-event-filter';
export const SET_TICKET_FILTER = '@ticket/set-ticket-filter';
export const SET_STATUS_FILTER = '@ticket/set-status-filter';
export const SET_ORGANIZATION_USER_FILTER =
  '@ticket/set-organization-user-filter';
export const EXPORT_ATTENDEES_AS_CSV = '@ticket/export-attendees-as-csv';

export const queryAttendees = createAsyncThunk(QUERY_ATTENDEES, async query => {
  const data = await attendeesService.queryAttendees(query);
  return data;
});

export const activateUsers = createAsyncThunk(
  ACTIVATE_USERS,
  async attendees => {
    await attendeesService.activateUsers(attendees);
    return attendees.map(attendee => ({ ...attendee, ticketIsPending: false }));
  }
);

export const getLatestAttendees = createAsyncThunk(
  GET_LATEST_ATTENDEES,
  async () => {
    const attendees = await attendeesService.getLatestAttendees();
    return attendees;
  }
);

export const deleteAttendees = createAsyncThunk(
  DELETE_ATTENDEE,
  async attendees => {
    await attendeesService.deleteAttendees(attendees);
    return attendees.map(({ id }) => id);
  }
);

export const resetAttendees = createAction(RESET_ATTENDEES);

export const getAttendeesStats = createAsyncThunk(
  GET_ATTENDEES_STATS,
  async statId => {
    return await attendeesService.getAttendeeStats(statId);
  }
);

export const setEventFilter = createAction(SET_EVENT_FILTER);
export const setTicketFilter = createAction(SET_TICKET_FILTER);
export const setStatusFilter = createAction(SET_STATUS_FILTER);
export const setOrganizationUserFilter = createAction(
  SET_ORGANIZATION_USER_FILTER
);

export const exportAttendees = createAsyncThunk(
  EXPORT_ATTENDEES_AS_CSV,
  async params => {
    return await attendeesService.exportAttendees(params);
  }
);
