import { PERIODS } from 'src/constants/stats';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const STATS_COLLECTION = 'stats';
const WEEKS_COLLECTION = 'weeks';
const MONTHS_COLLECTION = 'months';
const DAYS_COLLECTION = 'days';
const TOTAL_DOC = '--total--';

/*
  EXAMPLE SHAPE OF THE STAT OBJECT

  ex: 2020 JAN 01

  Each document in the STATS_COLLECTION represents a physical year,
  the id of the doc is the corresponding year. -> 2020
  Each document in the MONTHS_COLLECTION represents a physical month,
  the id of the doc is the corresponding month. -> 1
  Each document in the DAYS_COLLECTION represents a physical day,
  the id of the doc is the corresponding day. -> 1
  ...
*/

function getNow(date) {
  const year = date.format('YYYY');
  const month = date.format('M');
  const day = date.format('D');
  const week = date.format('W');
  return { year, month, day, week };
}

function getPrevious(date, prevBy) {
  const prevDate = date.clone().subtract(1, prevBy);
  const year = prevDate.format('YYYY');
  const month = prevDate.format('M');
  const day = prevDate.format('D');
  const week = prevDate.format('W');
  return { year, month, day, week };
}

const PERIODS_COLLECTION = {
  [PERIODS.DAY]: ({ eventId, year, month, day }) =>
    `${EVENTS_COLLECTION}/${eventId}/${STATS_COLLECTION}/${year}/${MONTHS_COLLECTION}/${month}/${DAYS_COLLECTION}/${day}`,
  [PERIODS.MONTH]: ({ eventId, year, month }) =>
    `${EVENTS_COLLECTION}/${eventId}/${STATS_COLLECTION}/${year}/${MONTHS_COLLECTION}/${month}`,
  [PERIODS.WEEK]: ({ eventId, year, week }) =>
    `${EVENTS_COLLECTION}/${eventId}/${STATS_COLLECTION}/${year}/${WEEKS_COLLECTION}/${week}`,
  [PERIODS.YESTERDAY]: ({ eventId, year, month, day }) =>
    `${EVENTS_COLLECTION}/${eventId}/${STATS_COLLECTION}/${year}/${MONTHS_COLLECTION}/${month}/${DAYS_COLLECTION}/${day}`,
  [PERIODS.LAST_MONTH]: ({ eventId, year, month }) =>
    `${EVENTS_COLLECTION}/${eventId}/${STATS_COLLECTION}/${year}/${MONTHS_COLLECTION}/${month}`,
  [PERIODS.LAST_WEEK]: ({ eventId, year, week }) =>
    `${EVENTS_COLLECTION}/${eventId}/${STATS_COLLECTION}/${year}/${WEEKS_COLLECTION}/${week}`
};

const PERIODS_ID = {
  [PERIODS.DAY]: date => date.format('D'),
  [PERIODS.MONTH]: date => date.format('M'),
  [PERIODS.WEEK]: date => date.format('W'),
  [PERIODS.YESTERDAY]: date => date.format('D'),
  [PERIODS.LAST_MONTH]: date => date.format('M'),
  [PERIODS.LAST_WEEK]: date => date.format('W')
};

const PERIODS_SUBTRACT = {
  [PERIODS.DAY]: 'day',
  [PERIODS.MONTH]: 'month',
  [PERIODS.WEEK]: 'week',
  [PERIODS.YESTERDAY]: 'day',
  [PERIODS.LAST_MONTH]: 'month',
  [PERIODS.LAST_WEEK]: 'week'
};

export async function getStatsTotal(eventId) {
  const doc = await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${STATS_COLLECTION}/${TOTAL_DOC}`)
    .get();

  if (!doc.exists)
    return {
      id: TOTAL_DOC,
      platformExpenses: 0,
      ticketsSold: 0,
      ticketsSoldById: {},
      addonsSoldById: {},
      totalSales: 0
    };

  return { id: doc.id, ...doc.data() };
}

export async function getStatsMonthly(eventId, date) {
  const { year } = getNow(date);
  const { docs } = await store
    .collection(
      `${EVENTS_COLLECTION}/${eventId}/${STATS_COLLECTION}/${year}/${MONTHS_COLLECTION}`
    )
    .get();

  return docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function getStat(eventId, date, period) {
  const [prevDoc, doc] = await Promise.all([
    store
      .doc(
        PERIODS_COLLECTION[period]({
          eventId,
          ...getPrevious(date, PERIODS_SUBTRACT[period])
        })
      )
      .get(),
    store.doc(PERIODS_COLLECTION[period]({ eventId, ...getNow(date) })).get()
  ]);

  const prevStat = prevDoc.exists
    ? { id: prevDoc.id, ...prevDoc.data() }
    : null;

  if (!doc.exists)
    return {
      id: PERIODS_ID[period](date),
      platformExpenses: 0,
      ticketsSold: 0,
      ticketsSoldById: {},
      totalSales: 0,
      prevStat
    };

  return {
    id: doc.id,
    ...doc.data(),
    prevStat
  };
}
