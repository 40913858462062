import {
  createSession,
  updateSession,
  deleteSession,
  pushOffSessions,
  getSessionById,
  getSessions,
  getAvailableSessions,
  resetSessions,
  createSessionCategory,
  getSessionCategories
} from 'src/actions/sessionActions';
import { removeOutlawSessions } from 'src/actions/eventActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const sessionEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.createdDate - b.createdDate
});
//TODO sort by name
export const sessionCategoryEntityAdapter = createEntityAdapter();

const initialState = sessionEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  categories: sessionCategoryEntityAdapter.getInitialState({
    error: null
  })
});

const sessionReducer = createReducer(initialState, {
  [createSession.pending]: (state, _) => {
    state.loading = true;
  },
  [createSession.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sessionEntityAdapter.upsertOne(state, payload);
  },
  [createSession.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateSession.pending]: (state, _) => {
    state.loading = true;
  },
  [updateSession.fulfilled]: (state, { payload }) => {
    state.loading = false;
    if (payload.sessionChanges) {
      sessionEntityAdapter.updateOne(state, payload.sessionChanges);
    }
  },
  [updateSession.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteSession.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteSession.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sessionEntityAdapter.removeOne(state, payload);
  },
  [deleteSession.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [pushOffSessions.pending]: (state, _) => {
    state.loading = true;
  },
  [pushOffSessions.fulfilled]: (state, { payload }) => {
    state.loading = false;
    if (payload.sessionChanges) {
      sessionEntityAdapter.updateMany(state, payload.sessionChanges);
    }
  },
  [pushOffSessions.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getSessionById.pending]: (state, _) => {
    state.loading = true;
  },
  [getSessionById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    sessionEntityAdapter.upsertOne(state, payload);
  },
  [getSessionById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getSessions.pending]: (state, _) => {
    state.loading = true;
  },
  [getSessions.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    sessionEntityAdapter.upsertMany(state, payload);
  },
  [getSessions.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getAvailableSessions.pending]: (state, _) => {
    state.loading = true;
  },
  [getAvailableSessions.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    sessionEntityAdapter.upsertMany(state, payload);
  },
  [getAvailableSessions.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [createSessionCategory.fulfilled]: (state, { payload }) => {
    sessionCategoryEntityAdapter.upsertOne(state.categories, payload);
  },
  [createSessionCategory.rejected]: (state, { error }) => {
    state.categories.error = error;
  },
  [getSessionCategories.fulfilled]: (state, { payload }) => {
    sessionCategoryEntityAdapter.upsertMany(state.categories, payload);
  },
  [getSessionCategories.rejected]: (state, { error }) => {
    state.categories.error = error;
  },

  [removeOutlawSessions.pending]: (state, _) => {
    state.loading = true;
  },
  [removeOutlawSessions.fulfilled]: (state, { payload }) => {
    state.loading = false;
    if (payload) {
      sessionEntityAdapter.removeMany(state, payload);
    }
  },
  [removeOutlawSessions.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },

  [resetSessions.type]: (_, __) => {
    return initialState;
  }
});

export default sessionReducer;
