import testimonialService from 'src/services/testimonialService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const CREATE_TESTIMONIAL = '@testimonial/create-testimonial';
export const UPDATE_TESTIMONIAL = '@testimonial/update-testimonial';
export const UPDATE_TESTIMONIALS = '@testimonial/update-testimonials';
export const DELETE_TESTIMONIAL = '@testimonial/delete-testimonial';
export const GET_TESTIMONIAL = '@testimonial/get-testimonial';
export const GET_TESTIMONIALS = '@testimonial/get-testimonials';
export const RESET_TESTIMONIALS = '@testimonial/reset-testimonials';
export const LISTEN_TESTIMONIAL_STATUS_BY_ID = '@testimonial/listen-status-by-id';
export const TESTIMONIAL_STATUS_CHANGED = '@testimonial/status-changed';

export const createTestimonial = createAsyncThunk(CREATE_TESTIMONIAL, async testimonial => {
  const newTestimonial = await testimonialService.createTestimonial(testimonial);
  return newTestimonial;
});

export const updateTestimonial = createAsyncThunk(UPDATE_TESTIMONIAL, async changes => {
  const change = await testimonialService.updateTestimonial(changes);
  return change;
});

export const updateTestimonialsSort = createAsyncThunk(UPDATE_TESTIMONIALS, async changes => {
  const change = await testimonialService.updateTestimonialsSort(changes);
  return change;
});

export const deleteTestimonial = createAsyncThunk(DELETE_TESTIMONIAL, async testimonialId => {
  await testimonialService.deleteTestimonial(testimonialId);
  return testimonialId;
});

export const getTestimonialById = createAsyncThunk(GET_TESTIMONIAL, async testimonialId => {
  const testimonial = await testimonialService.getTestimonialById(testimonialId);
  return testimonial;
});

export const getTestimonials = createAsyncThunk(GET_TESTIMONIALS, async () => {
  const testimonials = await testimonialService.getEventTestimonials();
  return testimonials;
});

export const resetTestimonials = createAction(RESET_TESTIMONIALS);

