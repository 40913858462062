import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import authService from 'src/services/authService';
import stageService from 'src/services/stageService';

export const CREATE_STAGE = '@stage/create-stage';
export const UPDATE_STAGE = '@stage/update-stage';
export const UPDATE_STAGES = '@stage/update-stages';
export const DELETE_STAGE = '@stage/delete-stage';
export const GET_STAGES = '@stage/get-stages';
export const GET_STAGE = '@stage/get-stage';
export const RESET_STAGES = '@stage/reset-stages';
export const CREATE_STAGE_FEED = '@stage/create-feed';
export const UPDATE_STAGE_FEED = '@stage/update-feed';
export const DELETE_STAGE_FEED = '@stage/delete-feed';
export const LISTEN_STAGE_STATUS_BY_ID = '@stage/listen-status-by-id';
export const STAGE_STATUS_CHANGED = '@stage/status-changed';
export const ADD_INVITED_SPEAKER = '@stage/add-invited-speaker';

export const createStage = createAsyncThunk(CREATE_STAGE, async stage => {
  const accessToken = authService.getAccessToken();
  const newStage = await stageService.createStage(stage, accessToken);
  return newStage;
});

export const updateStage = createAsyncThunk(UPDATE_STAGE, async changes => {
  const change = await stageService.updateStage(changes);
  return change;
});

export const updateStagesSort = createAsyncThunk(
  UPDATE_STAGES,
  async changes => {
    const change = await stageService.updateStagesSort(changes);
    return change;
  }
);

export const deleteStage = createAsyncThunk(DELETE_STAGE, async stageId => {
  const accessToken = authService.getAccessToken();
  await stageService.deleteStage(stageId, accessToken);
  return stageId;
});

export const createFeed = createAsyncThunk(
  CREATE_STAGE_FEED,
  async ({ stageId, feed }) => {
    const accessToken = authService.getAccessToken();
    const change = await stageService.createFeed(stageId, feed, accessToken);
    return change;
  }
);

export const updateFeed = createAsyncThunk(
  UPDATE_STAGE_FEED,
  async ({ stageId, feed }) => {
    const change = await stageService.updateFeed(stageId, feed);
    return change;
  }
);

export const deleteFeed = createAsyncThunk(
  DELETE_STAGE_FEED,
  async ({ stageId, feedId }) => {
    const accessToken = authService.getAccessToken();
    const change = await stageService.deleteFeed(stageId, feedId, accessToken);
    return change;
  }
);

export const getStageById = createAsyncThunk(GET_STAGE, async stageId => {
  const stage = await stageService.getStageById(stageId);
  return stage;
});

export const getStages = createAsyncThunk(GET_STAGES, async () => {
  const accessToken = authService.getAccessToken();
  const stages = await stageService.getEventStages(accessToken);
  return stages;
});

export const resetStages = createAction(RESET_STAGES);

export const stageStatusChanged = createAction(STAGE_STATUS_CHANGED);

export const listenStageStatusById = createAsyncThunk(
  LISTEN_STAGE_STATUS_BY_ID,
  async (stageId, { dispatch }) => {
    const dispatchAction = payload => dispatch(stageStatusChanged(payload));
    const unsubscribe = await stageService.listenStageStatusById(
      dispatchAction,
      stageId
    );
    return () => unsubscribe();
  }
);

export const addInvitedSpeaker = createAsyncThunk(
  ADD_INVITED_SPEAKER,
  async ({ stageId, speakerId }) => {
    const change = await stageService.addInvitedSpeaker(stageId, speakerId);
    return change;
  }
);
