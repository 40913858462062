import firebase from 'firebase';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const EMAIL_TEMPLATES_COLLECTION = 'email-templates';

export async function updateEmailTemplate(eventId, userId, changes) {
  const doc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${EMAIL_TEMPLATES_COLLECTION}`)
    .doc(changes.id);

  await doc.set({
    languageId: changes.languageId,
    text: changes.text,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedBy: userId
  });

  const result = await doc.get();

  return {
    changes: {
      ...result.data()
    },
    id: result.id
  };
}

export async function getEmailTemplates(eventId) {
  const items = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(EMAIL_TEMPLATES_COLLECTION)
    .get();

  return items.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}
