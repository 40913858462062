import authService from 'src/services/authService';
import { translate } from 'src/translations/i18n';
import {
  createTeamMember,
  updateTeamMemberStatus,
  deleteTeamMember,
  getTeamMembers,
  getTeamIncomingJoinRequests,
  getTeamMemberById,
  deleteTeamMemberByOwnerId,
  getTeamMemberByUserId,
  sendEmail,
  getTeamOwnersByUserId
} from '../core/api/TeamStore';
import packageService from './packageService';
import { TEAM_MEMBERS_TYPE } from 'src/constants/packagesConstants';

class TeamMemberService {
  createTeamMember = teamMember =>
    new Promise((resolve, reject) => {
      const teamOwner = authService.getUserInfo();
      packageService
        .validatePackage(TEAM_MEMBERS_TYPE, {})
        .then(() => {
          createTeamMember(teamMember, teamOwner)
            .then(newTeamMember => {
              if (!newTeamMember) {
                reject(translate('team.errors.alreadyExists'));
              }
              resolve(newTeamMember);
            })
            .catch(error => {
              reject(error.message);
            });
        })
        .catch(err => reject(err));
    });

  updateTeamMemberStatus = teamId =>
    new Promise((resolve, reject) => {
      updateTeamMemberStatus(teamId)
        .then(updated => {
          resolve(updated);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteTeamMember = teamMemberId =>
    new Promise((resolve, reject) => {
      deleteTeamMember(teamMemberId)
        .then(id => {
          resolve(id);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getTeamMembers = () =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      getTeamMembers(userId)
        .then(teamMembers => {
          if (teamMembers != null) {
            const sortedTeamMembers = this.sortByCreatedDateAscending(
              teamMembers
            );

            resolve(sortedTeamMembers);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteTeamMemberByOwnerId = userId =>
    new Promise((resolve, reject) => {
      deleteTeamMemberByOwnerId(userId)
        .then(id => {
          resolve(id);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getTeamIncomingJoinRequests = () =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      getTeamIncomingJoinRequests(userId)
        .then(teamMembers => {
          if (teamMembers != null) {
            const sortedTeamMembers = this.sortByCreatedDateAscending(
              teamMembers
            );

            resolve(sortedTeamMembers);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getTeamMemberById = teamMemberId =>
    new Promise((resolve, reject) => {
      getTeamMemberById(teamMemberId)
        .then(teamMember => {
          resolve(teamMember);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getTeamMemberByUserId = () =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();

      getTeamMemberByUserId(userId)
        .then(teams => {
          if (teams.length !== 0) resolve(teams);
          else resolve([]);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getTeamOwnersByUserId = userId =>
    new Promise((resolve, reject) =>
      getTeamOwnersByUserId(userId)
        .then(resolve)
        .catch(error => reject(error.message))
    );

  resendEmail = team =>
    new Promise((resolve, reject) => {
      sendEmail(team)
        .then(status => {
          resolve(status);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortByCreatedDateAscending = teamMembers =>
    teamMembers.sort((a, b) => a.createdDate - b.createdDate);
}

export default new TeamMemberService();
