import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  addInvitedSpeaker,
  createFeed,
  createStage,
  deleteFeed,
  deleteStage,
  getStageById,
  getStages,
  resetStages,
  stageStatusChanged,
  updateFeed,
  updateStage,
  updateStagesSort
} from 'src/actions/stageActions';

export const stageEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = stageEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const stageReducer = createReducer(initialState, {
  [resetStages.type]: (_, __) => {
    return initialState;
  },
  [getStages.pending]: (state, _) => {
    state.loading = true;
  },
  [getStages.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    stageEntityAdapter.upsertMany(state, payload);
  },
  [getStages.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getStageById.pending]: (state, _) => {
    state.loading = true;
  },
  [getStageById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    stageEntityAdapter.upsertOne(state, payload);
  },
  [getStageById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [updateStage.pending]: (state, _) => {
    state.loading = true;
  },
  [updateStage.fulfilled]: (state, { payload }) => {
    state.loading = false;
    stageEntityAdapter.updateOne(state, payload);
  },
  [updateStage.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateStagesSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateStagesSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    stageEntityAdapter.upsertMany(state, payload);
  },
  [updateStagesSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteStage.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteStage.fulfilled]: (state, { payload }) => {
    state.loading = false;
    stageEntityAdapter.removeOne(state, payload);
  },
  [deleteStage.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [createStage.pending]: (state, _) => {
    state.loading = true;
  },
  [createStage.fulfilled]: (state, { payload }) => {
    state.loading = false;
    stageEntityAdapter.upsertOne(state, payload);
  },
  [createStage.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [createFeed.pending]: (state, _) => {
    state.loading = true;
  },
  [createFeed.fulfilled]: (state, { payload }) => {
    stageEntityAdapter.updateOne(state, payload);
    state.loading = false;
  },
  [createFeed.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [updateFeed.pending]: (state, _) => {
    state.loading = true;
  },
  [updateFeed.fulfilled]: (state, { payload }) => {
    stageEntityAdapter.updateOne(state, payload);
    state.loading = false;
  },
  [updateFeed.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [deleteFeed.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteFeed.fulfilled]: (state, { payload }) => {
    stageEntityAdapter.updateOne(state, payload);
    state.loading = false;
  },
  [deleteFeed.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [stageStatusChanged.type]: (state, { payload }) => {
    stageEntityAdapter.updateOne(state, payload);
  },
  [addInvitedSpeaker.pending]: (state, _) => {
    state.loading = true;
  },
  [addInvitedSpeaker.fulfilled]: (state, { payload }) => {
    state.loading = false;
    stageEntityAdapter.updateOne(state, payload);
  },
  [addInvitedSpeaker.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  }
});

export default stageReducer;
