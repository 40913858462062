import eventSettingsService from 'src/services/eventSettingsService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const UPDATE_EVENT_CHAT_SETTINGS =
  '@event-settings/update-chat-settings';
export const UPDATE_EVENT_QA_SETTINGS = '@event-settings/update-qa-settings';
export const UPDATE_EVENT_PEOPLE_SETTINGS =
  '@event-settings/update-people-settings';
export const GET_EVENT_SETTINGS = '@event-settings/get-event-settings';
export const RESET_EVENT_SETTINGS = '@event-settings/reset-event-settings';
export const UPDATE_EVENT_LABEL_SETTINGS =
  '@event-settings/update-label-settings';

export const updateEventChatSettings = createAsyncThunk(
  UPDATE_EVENT_CHAT_SETTINGS,
  async changes => {
    const change = await eventSettingsService.updateEventChatSettings(changes);
    return change;
  }
);

export const updateEventQASettings = createAsyncThunk(
  UPDATE_EVENT_QA_SETTINGS,
  async changes => {
    const change = await eventSettingsService.updateEventQASettings(changes);
    return change;
  }
);

export const updateEventPeopleSettings = createAsyncThunk(
  UPDATE_EVENT_PEOPLE_SETTINGS,
  async changes => {
    const change = await eventSettingsService.updateEventPeopleSettings(
      changes
    );
    return change;
  }
);

export const updateEventLabelSettings = createAsyncThunk(
  UPDATE_EVENT_QA_SETTINGS,
  async changes => {
    const change = await eventSettingsService.updateEventLabelSettings(changes);
    return change;
  }
);

export const getEventSettings = createAsyncThunk(
  GET_EVENT_SETTINGS,
  async eventId => {
    const settings = await eventSettingsService.getEventSettings(eventId);
    return settings;
  }
);

export const resetEventSettings = createAction(RESET_EVENT_SETTINGS);
