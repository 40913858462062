import {
  EVENT_LENGTH_TYPE,
  EVENT_LENGTH_UPDATE_TYPE
} from 'src/constants/packagesConstants';
import authService from 'src/services/authService';
import { translate } from 'src/translations/i18n';
import {
  archiveEvent,
  createEvent,
  getEventById,
  saveSlug,
  verifySlug,
  getEventSlugById,
  getUserEvents,
  updateEvent,
  updateEventHeader,
  updateEventTicketBox,
  updateEventPartial,
  deleteEvent,
  getAllEventIdsByUsers,
  getUserEventsWithTickets
} from '../core/api/EventStore';
import packageService from './packageService';

class EventService {
  createEvent = event =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const connectedAccountId = authService.getConnectedAccountId();
      packageService
        .validatePackage(EVENT_LENGTH_TYPE, { newEventInfo: event })
        .then(() => {
          createEvent(event, userId, connectedAccountId)
            .then(newEvent => {
              if (!newEvent) {
                reject(translate('event.errors.alreadyExists'));
              }
              resolve(newEvent);
            })
            .catch(error => {
              reject(error.message);
            });
        })
        .catch(err => reject(err));
    });

  updateEvent = event =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      packageService
        .validatePackage(EVENT_LENGTH_UPDATE_TYPE, { newEventInfo: event })
        .then(() => {
          updateEvent(event, userId)
            .then(newEvent => {
              resolve(newEvent);
            })
            .catch(error => {
              reject(error.message);
            });
        })
        .catch(err => {
          reject(err.message);
        });
    });
  updateEventHeader = event =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      updateEventHeader(event, userId)
        .then(newEvent => {
          resolve(newEvent);
        })
        .catch(error => {
          reject(error.message);
        });
    });
  updateEventTicketBox = event =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      updateEventTicketBox(event, userId)
        .then(newEvent => {
          resolve(newEvent);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteEvent = event =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      deleteEvent(event, userId)
        .then(id => {
          resolve(id);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateEventPartial = changes =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      updateEventPartial(changes, userId)
        .then(change => {
          resolve(change);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventById = eventId =>
    new Promise((resolve, reject) => {
      getEventById(eventId)
        .then(event => {
          if (event != null) {
            resolve(event);
          } else {
            reject(translate('event.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventSlugById = eventId =>
    new Promise((resolve, reject) => {
      getEventSlugById(eventId)
        .then(eventSlug => {
          resolve(eventSlug);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  saveSlug = (eventId, newSlug) =>
    new Promise((resolve, reject) => {
      saveSlug(eventId, newSlug)
        .then(slug => {
          resolve(slug);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  verifySlug = newSlug =>
    new Promise((resolve, reject) => {
      verifySlug(newSlug)
        .then(valid => {
          resolve(valid);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  archiveEvent = eventId =>
    new Promise((resolve, reject) => {
      archiveEvent(eventId)
        .then(event => {
          resolve(event);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getUserEventsWithTickets = uid =>
    new Promise((resolve, reject) => {
      const userId = uid || authService.getUserUid();
      getUserEventsWithTickets(userId)
        .then(events => {
          resolve(events);
        })
        .catch(error => reject(error.message));
    });

  getUserEvents = userId =>
    new Promise((resolve, reject) => {
      getUserEvents(userId)
        .then(events => {
          if (events != null) {
            const liveEvents = this.sortByStartDateAscending(
              events.filter(event => event.status.id === 1)
            );
            const upcomingEvents = this.sortByStartDateAscending(
              events.filter(event => event.status.id === 2)
            );
            const pastEvents = this.sortByStartDateAscending(
              events.filter(event => event.status.id === 0)
            );

            resolve(liveEvents.concat(upcomingEvents).concat(pastEvents));
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getAllEventIdsByUsers = allUserIds =>
    new Promise((resolve, reject) => {
      getAllEventIdsByUsers(allUserIds)
        .then(events => {
          resolve(events);
        })
        .catch(error => reject(error.message));
    });

  sortByStartDateAscending = events =>
    events.sort((a, b) => a.startDate - b.startDate);
}

export default new EventService();
