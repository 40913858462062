/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  UPDATE_PROFILE_ORGANIZATION,
  UPDATE_PROFILE_PHONE,
  UPDATE_PROFILE_WEBSITE,
  SET_REGISTRATION_SURVEY,
  UPDATE_USER,
  VERIFY_PASSWORD_PENDING,
  VERIFY_PASSWORD_FAILURE,
  VERIFY_PASSWORD_SUCCESS,
  RESET_PENDING,
  RESET_SUCCESS,
  RESET_FAILURE,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAILURE,
  DELETING_ACCOUNT_START,
  DELETING_ACCOUNT_END,
  ACCOUNT_DELETE_REQUEST_START,
  ACCOUNT_DELETE_REQUEST_END,
  AUTHENTICATE_WITH_GOOGLE_SUCCCESS,
  REBUILD_PROVIDER_INFO,
  CLEAR_USER,
  RESET_PROVIDER_INFO
} from 'src/actions/accountActions';

const initialState = {
  user: null,
  resetCodeVerificationPending: false,
  resetCodeVerificationSuccess: false,
  resetOperationPending: false,
  resetOperationSuccess: false,
  isLoading: false,
  providerInfo: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const user = action.payload;

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, draft => {
        draft.user = null;
      });
    }

    case AUTHENTICATE_WITH_GOOGLE_SUCCCESS: {
      const providerInfo = action.payload;

      return produce(state, draft => {
        draft.providerInfo = providerInfo;
      });
    }
    case REBUILD_PROVIDER_INFO: {
      const providerInfo = action.payload;
      return produce(state, draft => {
        draft.providerInfo = providerInfo;
      });
    }

    case CLEAR_USER: {
      return produce(state, draft => {
        draft.user = null;
      });
    }

    case RESET_PROVIDER_INFO: {
      return produce(state, draft => {
        draft.providerInfo = null;
      });
    }

    case DELETING_ACCOUNT_START: {
      return produce(state, draft => {
        draft.isLoading = true;
      });
    }

    case DELETING_ACCOUNT_END: {
      return produce(state, draft => {
        draft.isLoading = false;
      });
    }

    case ACCOUNT_DELETE_REQUEST_START: {
      return produce(state, draft => {
        draft.isLoading = true;
      });
    }

    case ACCOUNT_DELETE_REQUEST_END: {
      return produce(state, draft => {
        draft.isLoading = false;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case UPDATE_PROFILE: {
      const user = action.payload;
      return produce(state, draft => {
        draft.user = user;
      });
    }
    case UPDATE_PROFILE_ORGANIZATION: {
      return produce(state, draft => {
        draft.user = { ...state.user, organization: action.payload };
      });
    }
    case UPDATE_PROFILE_PHONE: {
      return produce(state, draft => {
        draft.user = { ...state.user, phoneNumber: action.payload };
      });
    }
    case UPDATE_PROFILE_WEBSITE: {
      return produce(state, draft => {
        draft.user = { ...state.user, website: action.payload };
      });
    }
    case SET_REGISTRATION_SURVEY: {
      return produce(state, draft => {
        draft.user = { ...state.user, registrationSurvey: action.payload };
      });
    }


    case UPDATE_USER: {
      return produce(state, draft => {
        draft.user = { ...state.user, ...action.payload };
      });
    }
    /* 
    case EMAIL_VERIFICATION: {}
    */

    case EMAIL_VERIFICATION_SUCCESS: {
      return produce(state, draft => {
        draft.user = { ...state.user, email_verified: true };
      });
    }

    case EMAIL_VERIFICATION_FAILURE: {
      return produce(state, draft => {
        draft.user = { ...state.user, email_verified: false };
      });
    }

    case VERIFY_PASSWORD_PENDING: {
      return produce(state, draft => {
        draft.resetCodeVerificationPending = true;
      });
    }

    case VERIFY_PASSWORD_FAILURE: {
      return produce(state, draft => {
        draft.resetCodeVerificationPending = false;
        draft.resetCodeVerificationSuccess = false;
      });
    }

    case VERIFY_PASSWORD_SUCCESS: {
      return produce(state, draft => {
        draft.resetCodeVerificationPending = false;
        draft.resetCodeVerificationSuccess = true;
      });
    }

    case RESET_PENDING: {
      return produce(state, draft => {
        draft.resetOperationPending = true;
      });
    }

    case RESET_FAILURE: {
      return produce(state, draft => {
        draft.resetOperationPending = false;
        draft.resetOperationSuccess = false;
      });
    }

    case RESET_SUCCESS: {
      return produce(state, draft => {
        draft.resetOperationPending = false;
        draft.resetOperationSuccess = true;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
