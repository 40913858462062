import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import {
  ATTACH_DOMAIN,
  CREATE_DOMAIN,
  CREATE_DOMAIN_FAILURE,
  CREATE_DOMAIN_START,
  DELETE_DOMAIN,
  DELETE_DOMAIN_FAILURE,
  DELETE_DOMAIN_START,
  GET_DOMAIN,
  GET_DOMAINS,
  GET_DOMAINS_FAILURE,
  GET_DOMAINS_START,
  GET_DOMAIN_FAILURE,
  GET_DOMAIN_START,
  REGISTER_DOMAIN,
  REGISTER_DOMAIN_FAILURE,
  REGISTER_DOMAIN_START,
  UPDATE_DOMAIN,
  UPDATE_DOMAIN_FAILURE,
  UPDATE_DOMAIN_START,
  VERIFY_DOMAIN,
  VERIFY_DOMAIN_FAILURE,
  VERIFY_DOMAIN_START,
  GET_ATTACHABLE_DOMAINS,
  GET_ATTACHABLE_DOMAINS_START,
  GET_ATTACHABLE_DOMAINS_FAILURE,
  RENEW_DOMAIN_END,
  RENEW_DOMAIN_START,
  RENEW_DOMAIN_FAILURE
} from 'src/actions/domainActions';

export const domainEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.createdDate - b.createdDate
});

const initialState = domainEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  verifying: false,
  attachableDomains: null
});

const domainReducer = createReducer(initialState, {
  [CREATE_DOMAIN_START]: (state, _) => {
    state.loading = true;
  },
  [CREATE_DOMAIN_FAILURE]: (state, _) => {
    state.loading = false;
  },
  [CREATE_DOMAIN]: (state, action) => {
    domainEntityAdapter.addOne(state, action.payload);
    state.loading = false;
  },
  [GET_DOMAINS]: (state, action) => {
    domainEntityAdapter.addMany(state, action.payload);
    state.loading = false;
    state.loaded = true;
  },
  [GET_DOMAINS_START]: (state, _) => {
    domainEntityAdapter.removeAll(state);
    state.loading = true;
  },
  [GET_DOMAINS_FAILURE]: (state, _) => {
    state.loading = false;
  },
  [DELETE_DOMAIN]: (state, action) => {
    domainEntityAdapter.removeOne(state, action.payload);
    state.loading = false;
  },
  [DELETE_DOMAIN_START]: (state, _) => {
    state.loading = true;
  },
  [DELETE_DOMAIN_FAILURE]: (state, _) => {
    state.loading = false;
  },
  [GET_DOMAIN]: (state, action) => {
    domainEntityAdapter.upsertOne(state, action.payload);
    state.loading = false;
  },
  [GET_DOMAIN_START]: (state, _) => {
    state.loading = true;
  },
  [GET_DOMAIN_FAILURE]: (state, _) => {
    state.loading = false;
  },
  [UPDATE_DOMAIN_START]: (state, _) => {
    state.loading = true;
  },
  [UPDATE_DOMAIN_FAILURE]: (state, _) => {
    state.loading = false;
  },
  [UPDATE_DOMAIN]: (state, action) => {
    domainEntityAdapter.updateOne(state, {
      id: action.payload.id,
      changes: { ...action.payload.changes }
    });
    state.loading = false;
  },
  [VERIFY_DOMAIN_START]: (state, _) => {
    state.verifying = true;
  },
  [VERIFY_DOMAIN_FAILURE]: (state, _) => {
    state.verifying = false;
  },
  [VERIFY_DOMAIN]: (state, action) => {
    domainEntityAdapter.updateOne(state, {
      id: action.payload.id,
      changes: { ...action.payload.changes }
    });
    state.verifying = false;
  },
  [REGISTER_DOMAIN_START]: (state, _) => {
    state.verifying = true;
  },
  [REGISTER_DOMAIN_FAILURE]: (state, _) => {
    state.verifying = false;
  },
  [REGISTER_DOMAIN]: (state, action) => {
    domainEntityAdapter.updateOne(state, {
      id: action.payload.id,
      changes: { ...action.payload.changes }
    });
    state.verifying = false;
  },
  [GET_ATTACHABLE_DOMAINS]: (state, action) => {
    state.attachableDomains = action.payload;
    state.loading = false;
    state.loaded = true;
  },
  [GET_ATTACHABLE_DOMAINS_START]: (state, _) => {
    state.loading = true;
  },
  [GET_ATTACHABLE_DOMAINS_FAILURE]: (state, _) => {
    state.attachableDomains = null;
    state.loading = false;
  },
  [ATTACH_DOMAIN]: (state, _) => {
    state.loading = false;
  },
  [RENEW_DOMAIN_START]: (state, _) => {
    state.loading = true;
  },
  [RENEW_DOMAIN_END]: (state, _) => {
    state.loading = false;
  },
  [RENEW_DOMAIN_FAILURE]: (state, _) => {
    state.loading = false;
  }
});

export default domainReducer;
