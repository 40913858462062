import boothService from 'src/services/boothService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const CREATE_BOOTH = '@booth/create-booth';
export const UPDATE_BOOTH = '@booth/update-booth';
export const UPDATE_BOOTH_PUBLISHED = '@booth/update-booth-published';
export const UPDATE_BOOTHS = '@booth/update-booths';
export const DELETE_BOOTH = '@booth/delete-booth';
export const GET_BOOTH = '@booth/get-booth';
export const GET_BOOTHS = '@booth/get-booths';
export const RESET_BOOTHS = '@booth/reset-booths';

export const createBooth = createAsyncThunk(CREATE_BOOTH, async booth => {
  const newBooth = await boothService.createBooth(booth);
  return newBooth;
});

export const updateBooth = createAsyncThunk(UPDATE_BOOTH, async changes => {
  const change = await boothService.updateBooth(changes);
  return change;
});

export const updateBoothsSort = createAsyncThunk(UPDATE_BOOTHS, async changes => {
  const change = await boothService.updateBoothsSort(changes);
  return change;
});

export const updateBoothIsPublished = createAsyncThunk(UPDATE_BOOTH_PUBLISHED, async ({ boothId, actionValue }) => {
  const change = await boothService.updateBoothIsPublished(boothId, actionValue);
  return change;
});

export const deleteBooth = createAsyncThunk(DELETE_BOOTH, async boothId => {
  await boothService.deleteBooth(boothId);
  return boothId;
});

export const getBoothById = createAsyncThunk(GET_BOOTH, async boothId => {
  const booth = await boothService.getBoothById(boothId);
  return booth;
});

export const getBooths = createAsyncThunk(GET_BOOTHS, async () => {
  const booths = await boothService.getEventBooths();
  return booths;
});

export const resetBooths = createAction(RESET_BOOTHS);

