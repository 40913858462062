import axios from 'axios';
import firebase from 'firebase';
import { translate } from 'src/translations/i18n';
import { asyncForEach } from 'src/utils/asyncUtils';
import { store } from './firebase';

const EVENTS_COLLECTION = 'events';
const STAGES_COLLECTION = 'stages';

export async function createStage(stage, userId, eventId, accessToken) {
  const stagesCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}`
  );

  let collectionSize = 0;
  await stagesCollection.get().then(s => (collectionSize = s.size));

  // create doc
  const stageRef = await stagesCollection.add({
    ...stage,
    sort: collectionSize,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  });
  const stageDoc = await stageRef.get();
  const feeds = [];

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  };

  const {
    data: { id, streamingKey, streamingUrl, playbackId }
  } = await axios({
    method: 'post',
    headers: headers,
    url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/stages-createNewFeed`,
    data: {
      passthrough: `${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageDoc.id}`
    }
  });
  feeds.push({
    id,
    streamingKey,
    streamingUrl,
    streamingPageUrl: `https://stream.mux.com/${playbackId}.m3u8`,
    thumbnailImage: `https://image.mux.com/${playbackId}/animated.gif`,
    name: translate('feed.defaultFeedName')
  });

  await stageDoc.ref.update({
    id: stageDoc.id,
    feeds
  });
  return { ...stageDoc.data(), id: stageDoc.id };
}

export async function updateStage(changes, eventId) {
  const stageRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${changes.id}`
  );

  await stageRef.update({
    ...changes,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  const stageDoc = await stageRef.get();

  return { id: stageDoc.id, changes: { ...stageDoc.data() } };
}

export async function updateStagesSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  changesList.forEach(async changes => {
    const stageRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${changes.id}`
    );

    batch.update(stageRef, {
      ...changes,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    let result = {
      ...changes
    };

    resultList.push(result);
  });

  batch.commit();

  return resultList;
}

export async function addInvitedSpeaker(stageId, eventId, speakerId) {
  let stageDoc = await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageId}`)
    .get();
  if (stageDoc.exists) {
    await stageDoc.ref.set(
      {
        invitedSpeakers: firebase.firestore.FieldValue.arrayUnion(
          ...[speakerId]
        )
      },
      { merge: true }
    );
  } else {
    return null;
  }

  stageDoc = await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageId}`)
    .get();

  return { id: stageDoc.id, changes: { ...stageDoc.data() } };
}

export async function getStageById(eventId, stageId) {
  const stageDoc = await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageId}`)
    .get();
  if (!stageDoc.exists) return null;
  return { ...stageDoc.data(), id: stageDoc.id };
}

export async function getStagesByIds(eventId, stageIds) {
  if (!stageIds?.length) {
    return [];
  }
  const stageCollectionRef = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(STAGES_COLLECTION)
    .where('id', 'in', stageIds)
    .get();

  let stages = [];
  stageCollectionRef.docs.map(doc => {
    const stage = {
      id: doc.id,
      ...doc.data()
    };
    stages.push(stage);
  });

  return stages;
}

export async function deleteStage(stageId, eventId, accessToken) {
  const stageDoc = await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageId}`)
    .get();
  const { feeds } = stageDoc.data();
  if (feeds && feeds.length) {
    await asyncForEach(feeds, async feed => {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      };

      await axios({
        method: 'post',
        headers: headers,
        url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/stages-deleteFeed`,
        data: {
          feedId: feed.id
        }
      });
    });
  }
  await stageDoc.ref.delete();
  return stageId;
}

export async function getEventStages(eventId) {
  const stagesCollection = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}`)
    .get();
  return stagesCollection.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export async function createFeed(stageId, feed, eventId, accessToken) {
  const stageDoc = await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageId}`)
    .get();

  if (stageDoc.exists) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    };

    const {
      data: { id, streamingKey, streamingUrl, playbackId }
    } = await axios({
      headers: headers,
      method: 'post',
      url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/stages-createNewFeed`,
      data: {
        passthrough: `${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageId}`
      }
    });
    const feeds = stageDoc.data().feeds || [];
    feeds.push({
      id,
      streamingKey,
      streamingUrl,
      streamingPageUrl: `https://stream.mux.com/${playbackId}.m3u8`,
      thumbnailImage: `https://image.mux.com/${playbackId}/animated.gif`,
      name: feed.name || translate('feed.defaultFeedName')
    });
    await stageDoc.ref.update({
      feeds,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
    return {
      id: stageDoc.id,
      changes: {
        ...stageDoc.data(),
        feeds,
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
      }
    };
  } else {
    return null;
  }
}

export async function deleteFeed(stageId, feedId, eventId, accessToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  };
  await axios({
    headers: headers,
    method: 'post',
    url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/stages-deleteFeed`,
    data: {
      feedId
    }
  });
  const stageDoc = await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageId}`)
    .get();
  if (stageDoc.exists) {
    const feeds = stageDoc.data().feeds || [];
    await stageDoc.ref.update({
      feeds: feeds.filter(feed => feed.id !== feedId),
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    return {
      id: stageDoc.id,
      changes: {
        ...stageDoc.data(),
        feeds: feeds.filter(feed => feed.id !== feedId),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
      }
    };
  }
  return null;
}

export async function updateFeed(stageId, feed, eventId) {
  const stageDoc = await store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageId}`)
    .get();
  if (stageDoc.exists) {
    const feeds = stageDoc.data().feeds || [feed]; // upsert - update or insert
    await stageDoc.ref.update({
      feeds: feeds.map(_feed => (_feed.id === feed.id ? feed : _feed)),
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
    return {
      id: stageDoc.id,
      changes: {
        ...stageDoc.data(),
        feeds: feeds.map(_feed => (_feed.id === feed.id ? feed : _feed)),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
      }
    };
  } else {
    return null;
  }
}

export async function listenStageStatusById(dispatch, stageId, eventId) {
  const unsubscribe = store
    .doc(`${EVENTS_COLLECTION}/${eventId}/${STAGES_COLLECTION}/${stageId}`)
    .onSnapshot(
      querySnapshot => {
        dispatch({
          id: querySnapshot.id,
          changes: { ...querySnapshot.data() }
        });
      },
      error => {
        throw Error(error.message);
      }
    );
  return () => unsubscribe();
}
