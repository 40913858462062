import {
  assignVideoToSession,
  createVideoCategory,
  deleteVideo,
  getEventVideoCategories,
  getEventVideos,
  getVideoById,
  listeningToCompletedVideos,
  listenVideoStatusById,
  updateVideo,
  removeLobbyAssignmentFromVideos,
  uploadVideo
} from 'src/core/api/VideoStore';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';
import { getQueryParameter } from 'src/utils/queryParameter';
import authService from './authService';

class VideoService {
  uploadVideo = (
    file,
    dispatch,
    accessToken,
    videoType,
    videoName,
    noCategory,
    displayOnLobby,
    loopVideo
  ) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      const userId = authService.getUserUid();
      uploadVideo(
        file,
        dispatch,
        eventId,
        userId,
        accessToken,
        videoType,
        videoName,
        noCategory,
        displayOnLobby,
        loopVideo
      )
        .then(video => {
          resolve(video);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventVideos = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      getEventVideos(eventId)
        .then(videos => {
          if (videos != null) {
            resolve(videos);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getVideoById = videoId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getVideoById(eventId, videoId)
        .then(video => {
          if (video != null) {
            resolve(video);
          } else {
            reject(translate('videos.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  listeningToCompletedVideos = dispatch =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      listeningToCompletedVideos(dispatch, eventId)
        .then(unsubscribe => {
          resolve(unsubscribe);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  listenVideoStatusById = (dispatch, videoId) =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      listenVideoStatusById(dispatch, eventId, videoId)
        .then(unsubscribe => {
          resolve(unsubscribe);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  assignVideoToSession = changes =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      assignVideoToSession(eventId, changes)
        .then(change => {
          resolve(change);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteVideo = video =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      deleteVideo(video, eventId)
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateVideo = video =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      updateVideo(video, eventId)
        .then(videoChanges => {
          resolve(videoChanges);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  removeLobbyAssignment = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);
      removeLobbyAssignmentFromVideos(eventId)
        .then(videos => {
          resolve(videos);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventVideoCategories = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventVideoCategories(eventId)
        .then(videoCategories => {
          if (videoCategories != null) {
            resolve(videoCategories);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  createVideoCategory = category =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createVideoCategory(category, userId, eventId)
        .then(newCategory => {
          resolve(newCategory);
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new VideoService();
