import {
  PACKAGES_ORDER,
  FREE_ATTENDEES_LIMIT_WARNING
} from 'src/constants/packagesConstants';
import moment from 'moment';
import { FREE_PLAN } from 'src/constants/packagesConstants';

export const eventRegistrationsLeftPercentage = (total, used) => {
  const result = Math.round((100 * used) / total);
  return result >= 100 ? 100 : result;
};

export const checkIfUserPlan = (plan, planType, yearlyOptionSelected) => {
  return plan?.type === FREE_PLAN
    ? planType === plan?.type
    : ((plan?.isYearly && yearlyOptionSelected) ||
        (!plan?.isYearly && !yearlyOptionSelected)) &&
        planType === plan?.type;
};

export const getPlanRenewDate = (planIsYearly, planStartDate) => {
  const planDurationAddition = planIsYearly ? 'years' : 'months';
  const now = moment();
  let renewDate = moment.unix(planStartDate).add(1, planDurationAddition);
  if (renewDate.isBefore(now)) {
    const diff = now.diff(moment.unix(planStartDate), planDurationAddition);
    const timeToAdd = diff === 0 ? 2 : diff + 1;
    renewDate = moment.unix(planStartDate).add(timeToAdd, planDurationAddition);
  }
  return renewDate;
};

export const isUpgradingPlan = (
  currentPlanPrice,
  currentPlan,
  selectedPlan,
  yearlyOptionSelected
) => {
  const currentPlanIndex = PACKAGES_ORDER.findIndex(
    p => p === currentPlan.type
  );
  const nextPlanIndex = PACKAGES_ORDER.findIndex(p => p === selectedPlan.type);

  if (currentPlanIndex === nextPlanIndex) {
    return yearlyOptionSelected
      ? currentPlanPrice < selectedPlan?.yearlyPrice
      : currentPlanPrice < selectedPlan?.monthlyPrice;
  } else {
    return nextPlanIndex > currentPlanIndex;
  }
};

export const checkForFreeAttendeeLimitExceeded = (
  eventId,
  hasFreePlan,
  usedRegistrations
) => {
  if (!usedRegistrations) return false;
  if (!hasFreePlan) return false;
  if (!usedRegistrations) return false;

  if (eventId) {
    const foundEvent = usedRegistrations?.find(
      event => eventId === event.eventId
    );
    return foundEvent?.attendees?.length >= FREE_ATTENDEES_LIMIT_WARNING;
  }
  return !!usedRegistrations?.find(
    ({ attendees }) => attendees.length >= FREE_ATTENDEES_LIMIT_WARNING
  );
};
