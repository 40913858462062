import { createSelector } from '@reduxjs/toolkit';
import { domainEntityAdapter } from '../reducers/domainReducer';

export const selectDomainState = state => state.domain;

export const selectDomainList = domainEntityAdapter.getSelectors(
  selectDomainState
).selectAll;

export const selectDomainLoading = createSelector(
  selectDomainState,
  state => state.loading
);

export const selectDomainLoaded = createSelector(
  selectDomainState,
  state => state.loaded
);

export const selectDomainById = domainEntityAdapter.getSelectors(
  selectDomainState
).selectById;

export const selectDomainVerifying = createSelector(
  selectDomainState,
  state => state.verifying
);

export const selectAttachableDomains = createSelector(
  selectDomainState,
  state => state.attachableDomains
);
