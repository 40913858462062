import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEvent } from 'src/actions/eventActions';
import TopBar from 'src/layouts/EventLayout/TopBar';
import * as routes from 'src/routeConstants';
import { getQueryParameter } from 'src/utils/queryParameter';
import NavBar from './NavBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  noBanner: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  hasBanner: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 88,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 108
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 128
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    minHeight: '100%',
    overflow: 'auto'
  }
}));

function EventLayout({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const eventId = getQueryParameter(routes.EVENT);
  const account = useSelector(state => state.account);

  useEffect(() => {
    dispatch(setEvent(eventId));
  }, [dispatch]);

  return (
    <>
      <div className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <div
          className={
            account.user !== null && account.user.email_verified
              ? classes.noBanner
              : classes.hasBanner
          }
        >
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

EventLayout.propTypes = {
  children: PropTypes.any
};

export default EventLayout;
