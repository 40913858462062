import sponsorService from 'src/services/sponsorService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const CREATE_SPONSOR = '@sponsor/create-sponsor';
export const UPDATE_SPONSOR = '@sponsor/update-sponsor';
export const UPDATE_SPONSORS = '@sponsor/update-sponsors';
export const DELETE_SPONSOR = '@sponsor/delete-sponsor';
export const GET_SPONSOR = '@sponsor/get-sponsor';
export const GET_SPONSORS = '@sponsor/get-sponsors';
export const RESET_SPONSORS = '@sponsor/reset-sponsors';

export const createSponsor = createAsyncThunk(CREATE_SPONSOR, async sponsor => {
  const newSponsor = await sponsorService.createSponsor(sponsor);
  return newSponsor;
});

export const updateSponsor = createAsyncThunk(UPDATE_SPONSOR, async changes => {
  const change = await sponsorService.updateSponsor(changes);
  return change;
});

export const updateSponsorsSort = createAsyncThunk(UPDATE_SPONSORS, async changes => {
  const change = await sponsorService.updateSponsorsSort(changes);
  return change;
});

export const deleteSponsor = createAsyncThunk(DELETE_SPONSOR, async sponsorId => {
  await sponsorService.deleteSponsor(sponsorId);
  return sponsorId;
});

export const getSponsorById = createAsyncThunk(GET_SPONSOR, async sponsorId => {
  const sponsor = await sponsorService.getSponsorById(sponsorId);
  return sponsor;
});

export const getSponsors = createAsyncThunk(GET_SPONSORS, async () => {
  const sponsors = await sponsorService.getEventSponsors();
  return sponsors;
});

export const resetSponsors = createAction(RESET_SPONSORS);

