import firebase from 'firebase';
import { storage, store } from './firebase';

const EVENTS_COLLECTION = 'events';
const LETTERS_COLLECTION = 'letter';

export async function addLetter(letter, eventId) {
  const letterCollection = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${LETTERS_COLLECTION}`);

  const letterRef = await letterCollection.add({
    name: letter.name,
    position: letter.position,
    organization: letter.organization,
    letter: letter.letter,
    createdDate: firebase.firestore.FieldValue.serverTimestamp()
  });
  const letterDoc = await letterRef.get();
  await letterDoc.ref.update({
    id: letterDoc.id
  });

  let letterPhoto = '';
  if (letter.photo.url !== '') {
    const imageName = `events/${eventId}/letter/${letter.name}`;
    const uploadTask = storage.ref(imageName).put(letter.photo.raw);

    let promise;
    uploadTask.on(
      'state_changed',
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress);
      },
      error => {
        console.log(error);
      },
      () => {
        // complete function ...
        storage
          .ref(imageName)
          .getDownloadURL()
          .then(url => {
            letterPhoto = url;
            promise = letterDoc.ref.update({
              photo: url,
              modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
            });
          });
      }
    );
    await uploadTask.then(() => {
      Promise.resolve(promise);
    });
  }

  return {
    ...letterDoc.data(),
    id: letterDoc.id,
    photo: letterPhoto
  };

}


export async function saveLetter(letter, eventId) {
  const letterRef = store.doc(
    `${EVENTS_COLLECTION}/${eventId}/${LETTERS_COLLECTION}/${letter.id}`
  );

  await letterRef.update({
    name: letter.name,
    position: letter.position,
    organization: letter.organization,
    letter: letter.letter,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  const letterDoc = await letterRef.get();

  let letterPhoto = '';
  if (letter.photo && letter.photo.url === '') {
    await letterRef.update({
      photo: '',
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  } else if (
    letter.photo &&
    letter.photo.url !== '' &&
    letter.photo.raw !== ''
  ) {
    let promise;
    const imageName = `events/${eventId}/letter/${letter.name}`;
    const uploadTask = storage.ref(imageName).put(letter.photo.raw);

    uploadTask.on(
      'state_changed',
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress);
      },
      error => {
        console.log(error);
      },
      () => {
        // complete function ...
        storage
          .ref(imageName)
          .getDownloadURL()
          .then(url => {
            letterPhoto = url;
            promise = letterRef.update({
              photo: url,
              modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
            });
          });
      }
    );
    await uploadTask.then(() => {
      Promise.resolve(promise);
    });
  }
  return {
    id: letterDoc.id,
    ...letterDoc.data(),
    photo: letterPhoto
  };
}

export async function getLetter(eventId) {
  const letterCollection = await store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${LETTERS_COLLECTION}`).limit(1).get();

  let letterDoc = letterCollection.docs;

  if (letterDoc.length === 0) return null;

  let doc = letterDoc[0];
  const data = doc.data();
  if (data != null) {
    const newLetter = {
      id: doc.id,
      name: data.name,
      position: data.position,
      organization: data.organization,
      letter: data.letter,
      photo: data.photo,
    };
    return newLetter;
  }
}
