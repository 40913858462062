import moment from 'moment';
import { getAttendees, getEventAttendees } from './AttendeesStore';
import { getUserById } from './UserStore';
import { getUserEvents } from './EventStore';
import { getPlanRenewDate } from 'src/utils/planUtils';
import { functions, store } from './firebase';
import { FREE_PLAN } from 'src/constants/packagesConstants';
import { createReferral } from 'src/core/api/ReferralStore';
const userBillingInfoDoc = userId =>
  store
    .collection('users')
    .doc(userId)
    .collection('billing-informations')
    .doc('billingInformation');

const packageSettingsCollection = store
  .collection('settings')
  .doc('billing')
  .collection('packages');

export async function getUserPackages(userId) {
  const userPackagesCollection = await userBillingInfoDoc(userId)
    .collection('packages')
    .orderBy('startDate', 'desc')
    .get();
  return userPackagesCollection.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }));
}

export async function getUserPackage(userId) {
  const userPackages = await getUserPackages(userId);
  if (userPackages.length === 0) {
    const p = await getPackageByName(FREE_PLAN);
    const freeTicketsCount = await getFreeTicketsCountForEachEvent(userId);
    const renewDate = moment().add(1, 'months');
    return {
      freeTicketsCount,
      renewDate,
      ...p
    };
  }

  const epoch = Math.round(new Date().getTime() / 1000);
  const currentUserPackage = userPackages.find(s =>
    moment(s.startDate).isBefore(epoch)
  );
  if (!currentUserPackage) {
    const p = await getPackageByName(FREE_PLAN);
    const freeTicketsCount = await getFreeTicketsCountForEachEvent(userId);

    const renewDate = moment().add(1, 'months');
    return {
      freeTicketsCount,
      renewDate,
      ...p
    };
  }
  const renewDate = getPlanRenewDate(
    currentUserPackage.isYearly,
    currentUserPackage.startDate
  );

  const startDate = moment.unix(currentUserPackage.startDate);
  const p = await getPackageById(currentUserPackage.packageId);

  const freeTicketsCount =
    p.type === FREE_PLAN
      ? await getFreeTicketsCountForEachEvent(userId)
      : await getFreeTicketsCountForTheCurrentSubscriptionPeriod(
          userId,
          startDate,
          renewDate
        );
  let np = userPackages.find(
    s => moment.unix(s.startDate).isSameOrAfter(renewDate) && !s.isCanceled
  );
  let nextPlan;
  if (typeof np === 'undefined') {
    nextPlan = null;
  } else {
    nextPlan = await getPackageById(np.packageId);
    nextPlan = {
      ...nextPlan,
      isYearly: np.isYearly,
      startDate: np.startDate
    };
  }

  return {
    renewDate,
    freeTicketsCount,
    nextPlan,
    ...p,
    ...currentUserPackage
  };
}
export async function getLimitedUserPlan(userId) {
  const userPackages = await getUserPackages(userId);
  if (userPackages.length === 0) {
    const p = await getPackageByName(FREE_PLAN);
    const freeTicketsCount = 0;
    const renewDate = moment().add(1, 'months');
    return {
      freeTicketsCount,
      renewDate,
      ...p
    };
  }

  const epoch = Math.round(new Date().getTime() / 1000);
  const currentUserPackage = userPackages.find(s =>
    moment(s.startDate).isBefore(epoch)
  );
  if (!currentUserPackage) {
    const p = await getPackageByName(FREE_PLAN);
    const freeTicketsCount = 0;

    const renewDate = moment().add(1, 'months');
    return {
      freeTicketsCount,
      renewDate,
      ...p
    };
  }
  const renewDate = getPlanRenewDate(
    currentUserPackage.isYearly,
    currentUserPackage.startDate
  );
  const p = await getPackageById(currentUserPackage.packageId);

  const freeTicketsCount = 0;

  return {
    renewDate,
    freeTicketsCount,
    ...p,
    ...currentUserPackage
  };
}

export async function getFreeTicketsCountForTheCurrentSubscriptionPeriod(
  userId,
  startDate,
  endDate
) {
  const result = await getAttendees(userId, false);
  const freeTickets = result.attendees.filter(
    ticket =>
      ticket.ticketPrice === 0 &&
      moment.unix(ticket.purchaseDate.seconds).isBetween(startDate, endDate)
  );
  return freeTickets.length;
}

export async function getFreeTicketsCountForEachEvent(userId) {
  const userEvents = await getUserEvents(userId);
  if (!userEvents) return [];
  const eventAttendees = Promise.all(
    userEvents.map(async ({ eventId }) => {
      const attendees = await getEventAttendees(eventId);
      return {
        eventId,
        attendees
      };
    })
  );
  return eventAttendees;
}
export async function getPreviousPackages(userId) {
  const userPackages = await getUserPackages(userId);
  const formattedUserPackages = userPackages.map(p => {
    return {
      ...p,
      endDate: moment.unix(p.startDate).add(1, p.isYearly ? 'years' : 'months')
    };
  });
  return formattedUserPackages.filter(p => p.endDate.isBefore(moment()));
}

export async function getPackageByName(packageName) {
  const packageDoc = await packageSettingsCollection
    .where('type', '==', packageName)
    .limit(1)
    .get();
  const result = packageDoc.docs.map(doc => doc.data());
  const id = packageDoc.docs.map(doc => doc.id);
  return {
    id: id[0],
    ...result[0]
  };
}

export async function getPackageById(id) {
  const packageDoc = await packageSettingsCollection.doc(id).get();
  return { ...packageDoc.data(), id: packageDoc.id };
}

export async function getPackages() {
  const packageDoc = await packageSettingsCollection.get();
  const data = packageDoc.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return data;
}

export async function updateUserPackage(packageDetail, coupon, email, userId) {
  try {
    await createReferral(userId, email);
    const updateSubscription = functions.httpsCallable(
      'subscriptions-createSubscription'
    );
    await updateSubscription({
      packageId: packageDetail.id,
      isYearly: packageDetail.isYearly,
      coupon: coupon
    });

    await userBillingInfoDoc(userId).set(
      { packageId: packageDetail.id },
      { merge: true }
    );

    return packageDetail;
  } catch (err) {
    console.error(err);
  }
}

export async function getInvoices() {
  const getInvoices = functions.httpsCallable('subscriptions-getInvoices');
  const response = await getInvoices();
  return response.data;
}

export async function downloadInvoice(invoiceId) {
  const getInvoice = functions.httpsCallable('subscriptions-downloadInvoice');
  const response = await getInvoice({
    invoiceId: invoiceId
  });
  return response.data.download_url;
}

export async function cancelPackage(cancelData) {
  const cancelSubscription = functions.httpsCallable(
    'subscriptions-cancelSubscription'
  );
  return await cancelSubscription(cancelData);
}

export async function verifyCouponCode(couponCode) {
  const verifyCoupon = functions.httpsCallable(
    'subscriptions-verifyCouponCode'
  );
  const response = await verifyCoupon({
    couponCode: couponCode
  });
  if (response.data.verifiedCouponCode) {
    return response.data.verifiedCouponCode;
  } else {
    throw new Error(response.data.message);
  }
}
