import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as LogoSVG } from 'src/assets/images/eventmix-logo.svg';

const useStyles = makeStyles(() => ({
  logoImage: {
    width: '170px',
    display: 'flex',
    flexDirection: 'column'
  }
}));
function Logo({ fill = '#FFFFFF', ...props }) {
  const classes = useStyles();
  return (
    <div
      alt="Logo"
      className={classes.logoImage}
      src="/static/eventmix-logo.svg"
      {...props}
    >
      <LogoSVG fill={fill} />
    </div>
  );
}

export default Logo;
