import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import { translate } from 'i18n-js';
import {
  createBenefit,
  getEventBenefits,
  deleteBenefit,
  getBenefitById,
  updateBenefit,
  updateBenefitsSort
} from '../core/api/BenefitStore';

class BenefitService {
  createBenefit = benefit =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);

      createBenefit(benefit, userId, eventId)
        .then(newBenefit => {
          resolve(newBenefit);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getEventBenefits = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventBenefits(eventId)
        .then(benefits => {
          if (benefits != null) {
            resolve(this.sortList(benefits));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteBenefit = benefitId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteBenefit(benefitId, eventId)
        .then(benefit => {
          resolve(benefit);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getBenefitById = benefitId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getBenefitById(eventId, benefitId)
        .then(benefit => {
          if (benefit != null) {
            resolve(benefit);
          } else {
            reject(translate('sponsors.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateBenefit = benefit =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateBenefit(benefit, eventId)
        .then(newBenefit => {
          resolve(newBenefit);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateBenefitsSort = benefits =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateBenefitsSort(benefits, eventId)
        .then(newBenefits => {
          resolve(newBenefits);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortList = list => {
    let sorted = list.sort((a, b) =>
      a.hasOwnProperty('sort') && b.hasOwnProperty('sort')
        ? a.sort - b.sort
        : a.createdDate - b.createdDate
    );
    let result = sorted.map((s, index) => (s = { ...s, sort: index }));
    return result;
  };
}

export default new BenefitService();
