import firebase from 'firebase';
import { store, storage } from './firebase';

const EVENTS_COLLECTION = 'events';
const TESTIMONIALS_COLLECTION = 'testimonials';

export async function createTestimonial(
  { photoUrl, photoRaw, name, text },
  userId,
  eventId
) {
  const testimonialsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${TESTIMONIALS_COLLECTION}`
  );

  let collectionSize = await testimonialsCollection.get().then(s => collectionSize = s.size);

  let newTestimonial = {
    name,
    text,
    photoUrl: '',
    id: '',
    sort: collectionSize,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId
  };

  await testimonialsCollection
    .add(newTestimonial)
    .then(s => (newTestimonial = { ...newTestimonial, id: s.id }));
  const testimonialDoc = testimonialsCollection.doc(newTestimonial.id);

  if (photoUrl !== '') {
    const imageName = `events/${eventId}/testimonials/${name}`;
    const uploadTask = await storage.ref(imageName).put(photoRaw);

    const downloadUrl = await uploadTask.ref.getDownloadURL();
    newTestimonial = { ...newTestimonial, photoUrl: downloadUrl };
    await testimonialDoc.update({
      photoUrl: downloadUrl,
      id: newTestimonial.id,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
    return newTestimonial;
  }
  await testimonialDoc.update({
    id: newTestimonial.id,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });
  return newTestimonial;
}

export async function getEventTestimonials(eventId) {
  const testimonialDocs = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(TESTIMONIALS_COLLECTION)
    .get();

  return testimonialDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export async function deleteTestimonial(testimonialId, eventId) {
  const testimonialsCollection = store.collection(
    `${EVENTS_COLLECTION}/${eventId}/${TESTIMONIALS_COLLECTION}`
  );
  const testimonialDoc = testimonialsCollection.doc(testimonialId);

  await testimonialDoc.delete();

  return testimonialId;
}

export async function getTestimonialById(eventId, testimonialId) {
  const testimonialDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${TESTIMONIALS_COLLECTION}`)
    .doc(testimonialId);
  const testimonial = await testimonialDoc.get();
  if (!testimonial.exists) return null;
  return { id: testimonial.id, ...testimonial.data() };
}

export async function updateTestimonial(
  { id, photoRaw, name, text, photoUrl },
  eventId
) {
  const testimonialDoc = store
    .collection(`${EVENTS_COLLECTION}/${eventId}/${TESTIMONIALS_COLLECTION}`)
    .doc(id);

  await testimonialDoc.update({
    name,
    text,
    photoUrl,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  let updatedTestimonial = {
    id,
    name,
    text
  };
  if (photoRaw !== '') {
    const imageName = `events/${eventId}/testimonials/${name}`;
    const uploadTask = await storage.ref(imageName).put(photoRaw);
    const downloadUrl = await uploadTask.ref.getDownloadURL();
    updatedTestimonial = {
      ...updatedTestimonial,
      photoUrl: downloadUrl
    };
    await testimonialDoc.update({
      photoUrl: downloadUrl,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  return updatedTestimonial;
}

export async function updateTestimonialsSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  changesList.forEach(async changes => {
    const testimonialRef = store.doc(
      `${EVENTS_COLLECTION}/${eventId}/${TESTIMONIALS_COLLECTION}/${changes.id}`
    );

    batch.update(testimonialRef, {
      id: changes.id,
      name: changes.name,
      text: changes.text,
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    let result = {
      ...changes
    };

    resultList.push(result);
  });

  batch.commit();

  return resultList;
}
