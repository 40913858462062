/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const MODE = {
  ADD: 'add',
  EDIT: 'edit'
};

export const AM_PM = 'AM-PM';
export const FULL_24 = '24 hr';
