import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import { translate } from 'src/translations/i18n';
import {
  addLetter,
  saveLetter,
  getLetter
} from '../core/api/LetterStore';

class LetterService {
  getLetter = () => new Promise((resolve, reject) => {
    const eventId = getQueryParameter(routes.EVENT);
    getLetter(eventId)
      .then((letter) => {
        if (letter != null) {
          resolve(letter);
        } else {
          reject(translate('letters.errors.noEventWithId'));
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });

  addLetter = (letter) => new Promise((resolve, reject) => {
    const eventId = getQueryParameter(routes.EVENT);
    addLetter(letter, eventId)
      .then((newLetter) => {
        resolve(newLetter);
      })
      .catch((error) => {
        reject(error.message);
      });
  });

  saveLetter = (letter) => new Promise((resolve, reject) => {
    const eventId = getQueryParameter(routes.EVENT);
    saveLetter(letter, eventId)
      .then((newLetter) => {
        resolve(newLetter);
      })
      .catch((error) => {
        reject(error.message);
      });
  });


}

export default new LetterService();
