import authService from 'src/services/authService';
import { getQueryParameter } from 'src/utils/queryParameter';
import * as routes from 'src/routeConstants';
import {
  createNetworks,
  getEventNetworks,
  deleteNetworks,
  getNetworkById,
  updateNetwork,
  updateNetworksSort
} from '../core/api/NetworkStore';
import { translate } from 'i18n-js';
import { ROUND_TABLE_TYPE } from 'src/constants/packagesConstants';
import packageService from './packageService';

class NetworkService {
  createNetworks = networks =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      const eventId = getQueryParameter(routes.EVENT);
      packageService
        .validatePackage(ROUND_TABLE_TYPE, { createdNetworks: networks })
        .then(() => {
          createNetworks(networks, userId, eventId)
            .then(newNetworks => {
              resolve(newNetworks);
            })
            .catch(error => {
              reject(error.message);
            });
        })
        .catch(err => reject(err));
    });

  getEventNetworks = () =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getEventNetworks(eventId)
        .then(networks => {
          if (networks != null) {
            resolve(networks);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteNetworks = networkIds =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      deleteNetworks(networkIds, eventId)
        .then(networks => {
          resolve(networks);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  getNetworkById = networkId =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      getNetworkById(eventId, networkId)
        .then(network => {
          if (network != null) {
            resolve(network);
          } else {
            reject(translate('networks.errors.noEventWithId'));
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateNetwork = network =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateNetwork(network, eventId)
        .then(newNetwork => {
          resolve(newNetwork);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateNetworksSort = networks =>
    new Promise((resolve, reject) => {
      const eventId = getQueryParameter(routes.EVENT);

      updateNetworksSort(networks, eventId)
        .then(newNetworks => {
          resolve(newNetworks);
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new NetworkService();
