import ticketService from 'src/services/ticketService';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const CREATE_TICKET = '@ticket/create-ticket';
export const UPDATE_TICKET = '@ticket/update-ticket';
export const UPDATE_TICKETS = '@ticket/update-tickets';
export const UPDATE_TICKET_ARCHIVED = '@ticket/update-ticket-archived';
export const UPDATE_TICKET_PUBLISHED = '@ticket/update-ticket-published';
export const DELETE_TICKET = '@ticket/delete-ticket';
export const GET_TICKET = '@ticket/get-ticket';
export const GET_TICKETS = '@ticket/get-tickets';
export const GET_TICKETS_FOR_PROMO_CODES =
  '@ticket/get-tickets-for-promo-codes';
export const GET_TICKETS_FOR_SESSION = '@session/get-tickets-for-session';
export const RESET_TICKETS = '@ticket/reset-tickets';

export const createTicket = createAsyncThunk(
  CREATE_TICKET,
  async ({ ticket, timezone }) => {
    const newTicket = await ticketService.createTicket(ticket, timezone);
    return newTicket;
  }
);

export const updateTicket = createAsyncThunk(
  UPDATE_TICKET,
  async ({ changes, timezone }) => {
    const change = await ticketService.updateTicket(changes, timezone);
    return change;
  }
);

export const updateTicketsSort = createAsyncThunk(
  UPDATE_TICKETS,
  async changes => {
    const change = await ticketService.updateTicketsSort(changes);
    return change;
  }
);

export const updateTicketIsArchived = createAsyncThunk(
  UPDATE_TICKET_ARCHIVED,
  async ({ ticketId, actionValue }) => {
    const change = await ticketService.updateTicketIsArchived(
      ticketId,
      actionValue
    );
    return change;
  }
);

export const updateTicketIsPublished = createAsyncThunk(
  UPDATE_TICKET_PUBLISHED,
  async ({ ticketId, actionValue }) => {
    const change = await ticketService.updateTicketIsPublished(
      ticketId,
      actionValue
    );
    return change;
  }
);

export const deleteTicket = createAsyncThunk(DELETE_TICKET, async ticketId => {
  await ticketService.deleteTicket(ticketId);
  return ticketId;
});

export const getTicketById = createAsyncThunk(GET_TICKET, async ticketId => {
  const ticket = await ticketService.getTicketById(ticketId);
  return ticket;
});

export const getTickets = createAsyncThunk(GET_TICKETS, async () => {
  const tickets = await ticketService.getEventTickets();
  return tickets;
});

export const getTicketsByEvent = createAsyncThunk(
  GET_TICKETS,
  async eventId => {
    const tickets = await ticketService.getEventTickets(eventId);
    return tickets;
  }
);

export const getTicketsForPromoCodes = createAsyncThunk(
  GET_TICKETS_FOR_PROMO_CODES,
  async () => {
    const tickets = await ticketService.getEventTickets();
    return tickets.map(({ id, name }) => ({ id, name }));
  }
);

export const getTicketsForSession = createAsyncThunk(
  GET_TICKETS_FOR_SESSION,
  async ({ oldStageId, newStageId, currentTab }) => {
    const count = await ticketService.getSessionTickets(
      oldStageId,
      newStageId,
      currentTab
    );
    return count;
  }
);

export const resetTickets = createAction(RESET_TICKETS);
