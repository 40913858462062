import firebase from 'firebase';
import { storage, store } from './firebase';
import { asyncForEach } from 'src/utils/asyncUtils';

const EVENTS_COLLECTION = 'events';
const BOOTHS_COLLECTION = 'booths';

export async function createBooth(booth, userId, eventId) {
  const boothsCollection = store.collection(`${EVENTS_COLLECTION}/${eventId}/${BOOTHS_COLLECTION}`);

  let collectionSize = await boothsCollection.get().then(s => (collectionSize = s.size));

  let newBooth = {
    id: '',
    sort: collectionSize,
    selectedSponsorId: booth.selectedSponsorId,
    selectedCategoryId: booth.selectedCategoryId,
    selectedButtonAction: booth.selectedButtonAction,
    headline: booth.headline,
    about: booth.about,
    bannerLink: booth.bannerLink,
    offerDescription: booth.offerDescription,
    offer: booth.offer,
    buttonText: booth.buttonText,
    website: booth.website,
    linkedin: booth.linkedin,
    email: booth.email,
    facebook: booth.facebook,
    twitter: booth.twitter,
    phone: booth.phone,
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: userId,
    isPublished: true
  };

  await boothsCollection.add(newBooth).then(s => (newBooth = { ...newBooth, id: s.id }));

  const boothDoc = boothsCollection.doc(newBooth.id);

  let boothHeader = '';
  if (booth.headerUrl && booth.headerUrl.raw) {
    const imageName = `events/${eventId}/booths/${newBooth.id}/assets/${booth.headline}-header`;
    const uploadTask = await storage.ref(imageName).put(booth.headerUrl.raw);
    boothHeader = await uploadTask.ref.getDownloadURL();
  }

  let boothBanner = '';
  if (booth.bannerUrl && booth.bannerUrl.raw) {
    const imageName = `events/${eventId}/booths/${newBooth.id}/assets/${booth.headline}-banner`;
    const uploadTask = await storage.ref(imageName).put(booth.bannerUrl.raw);
    boothBanner = await uploadTask.ref.getDownloadURL();
  }

  let selectedFiles = [];
  if (booth.files.length > 0) {
    await asyncForEach(booth.files, async file => {
      const fileName = `events/${eventId}/booths/${newBooth.id}/files/${file.name}`;
      const uploadTask = await storage.ref(fileName).put(file);

      const downloadUrl = await uploadTask.ref.getDownloadURL();
      let newFile = { name: file.name, size: file.size, downloadUrl: downloadUrl };
      selectedFiles.push(newFile);
    });
  }

  if (booth.embedded) {
    newBooth.embedded = {
      video: booth.embedded.video || '',
      defaultVideoThumbnail: booth.embedded.defaultVideoThumbnail || '',
      poster: booth.embedded.poster.raw === '' ? booth.embedded.poster.url : ''
    };
  }

  let boothPoster = '';
  if (booth.embedded && booth.embedded.poster && booth.embedded.poster.raw) {
    const imageName = `events/${eventId}/booths/${newBooth.id}/assets/${booth.headline}-poster`;
    const uploadTask = await storage.ref(imageName).put(booth.embedded.poster.raw);
    boothPoster = await uploadTask.ref.getDownloadURL();
  }

  newBooth = {
    ...newBooth,
    embedded: {
      ...booth.embedded,
      poster: boothPoster
    },
    headerUrl: boothHeader,
    bannerUrl: boothBanner,
    files: selectedFiles
  };

  await boothDoc.update({
    id: newBooth.id,
    embedded: {
      ...booth.embedded,
      poster: boothPoster
    },
    headerUrl: boothHeader,
    bannerUrl: boothBanner,
    files: selectedFiles,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  return newBooth;
}

export async function updateBooth(booth, eventId) {
  const boothDoc = store.collection(`${EVENTS_COLLECTION}/${eventId}/${BOOTHS_COLLECTION}`).doc(booth.id);

  const oldBooth = await boothDoc.get();

  await boothDoc.update({
    selectedSponsorId: booth.selectedSponsorId,
    selectedCategoryId: booth.selectedCategoryId,
    selectedButtonAction: booth.selectedButtonAction || '',
    headline: booth.headline,
    about: booth.about,
    bannerLink: booth.bannerLink,
    offerDescription: booth.offerDescription,
    offer: booth.offer,
    buttonText: booth.buttonText,
    website: booth.website,
    linkedin: booth.linkedin,
    email: booth.email,
    facebook: booth.facebook,
    twitter: booth.twitter,
    phone: booth.phone,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  let boothHeader = booth.headerUrl?.url || '';
  if (booth.headerUrl && booth.headerUrl?.raw) {
    const imageName = `events/${eventId}/booths/${booth.id}/assets/${booth.headline}-header`;
    const uploadTask = await storage.ref(imageName).put(booth.headerUrl.raw);
    boothHeader = await uploadTask.ref.getDownloadURL();
  }

  let boothBanner = booth.bannerUrl?.url || '';
  if (booth.bannerUrl && booth.bannerUrl?.raw) {
    const imageName = `events/${eventId}/booths/${booth.id}/assets/${booth.headline}-banner`;
    const uploadTask = await storage.ref(imageName).put(booth.bannerUrl.raw);
    boothBanner = await uploadTask.ref.getDownloadURL();
  }

  let old = oldBooth.data();

  let additions = booth.files.filter(x => !old.files.map(s => s.name).includes(x.name));
  let deletions = old.files.filter(x => !booth.files.map(s => s.name).includes(x.name));

  let selectedFiles = old.files;

  if (deletions.length > 0) {
    await asyncForEach(deletions, async file => {
      const fileName = `events/${eventId}/booths/${booth.id}/files/${file.name}`;
      await storage.ref(fileName).delete();

      selectedFiles = selectedFiles.filter(s => s.name !== file.name);
    });
  }

  if (additions.length > 0) {
    await asyncForEach(additions, async file => {
      const fileName = `events/${eventId}/booths/${booth.id}/files/${file.name}`;
      const uploadTask = await storage.ref(fileName).put(file);

      const downloadUrl = await uploadTask.ref.getDownloadURL();
      let newFile = { name: file.name, downloadUrl: downloadUrl, size: file.size };
      selectedFiles.push(newFile);
    });
  }

  let updatedBooth = {
    ...booth,
    bannerUrl: boothBanner,
    headerUrl: boothHeader,
    files: selectedFiles
  };

  if (booth.embedded) {
    updatedBooth.embedded = {
      video: booth.embedded.video || '',
      defaultVideoThumbnail: booth.embedded.defaultVideoThumbnail || '',
      poster: booth.embedded.poster.raw === '' ? booth.embedded.poster.url : ''
    };
  }

  let boothPoster = booth.poster?.url || booth.poster || '';
  if (booth.embedded && booth.embedded.poster && booth.embedded.poster.raw) {
    const imageName = `events/${eventId}/booths/${booth.id}/assets/${booth.headline}-poster`;
    const uploadTask = await storage.ref(imageName).put(booth.embedded.poster.raw);
    boothPoster = await uploadTask.ref.getDownloadURL();
  }

  await boothDoc.update({
    embedded: {
      ...booth.embedded,
      poster: boothPoster
    },
    bannerUrl: boothBanner,
    headerUrl: boothHeader,
    files: selectedFiles,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  return updatedBooth;
}

export async function getBoothById(eventId, boothId) {
  const boothDoc = store.collection(`${EVENTS_COLLECTION}/${eventId}/${BOOTHS_COLLECTION}`).doc(boothId);
  const booth = await boothDoc.get();
  if (!booth.exists) return null;
  return { id: booth.id, ...booth.data() };
}

export async function deleteBooth(boothId, eventId) {
  const boothsCollection = store.collection(`${EVENTS_COLLECTION}/${eventId}/${BOOTHS_COLLECTION}`);

  const boothDoc = boothsCollection.doc(boothId);
  await boothDoc.delete();

  return boothId;
}

export async function getEventBooths(eventId) {
  const boothDocs = await store
    .collection(EVENTS_COLLECTION)
    .doc(eventId)
    .collection(BOOTHS_COLLECTION)
    .get();

  return boothDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export async function updateBoothsSort(changesList, eventId) {
  let resultList = [];
  const batch = store.batch();

  changesList.forEach(async changes => {
    const boothsRef = store.doc(`${EVENTS_COLLECTION}/${eventId}/${BOOTHS_COLLECTION}/${changes.id}`);

    batch.update(boothsRef, {
      id: changes.id,
      sort: changes.sort,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });

    let result = {
      ...changes
    };

    resultList.push(result);
  });

  batch.commit();

  return resultList;
}

export async function updateBoothIsPublished(boothId, eventId, actionValue) {
  const boothRef = store.doc(`${EVENTS_COLLECTION}/${eventId}/${BOOTHS_COLLECTION}/${boothId}`);

  await boothRef.update({
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    isPublished: actionValue
  });

  const boothDoc = await boothRef.get();
  const changes = boothDoc.data();
  return {
    id: boothDoc.id,
    changes: {
      ...changes
    }
  };
}
