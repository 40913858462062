import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import packageService from 'src/services/packageService';

export const GET_USER_PACKAGE = '@package/get-user-package';
export const GET_EVENT_OWNER_PACKAGE = '@package/get-event-owner-package';
export const GET_PACKAGES = '@package/get-packages';
export const GET_PACKAGE_BY_NAME = '@package/get-package-by-name';
export const RESET_PACKAGES = '@package/reset-packages';
export const UPDATE_USER_PACKAGE = '@package/update-user-package';
export const GET_INVOICES = '@package/get-invoices';
export const DOWNLOAD_INVOICE = '@package/download-invoice';
export const CANCEL_PACKAGE = '@package/cancel-package';
export const VERIFY_COUPON_CODE = '@package/verify-coupon-code';

export const getUserPackage = createAsyncThunk(GET_USER_PACKAGE, async () => {
  return await packageService.getUserPackage();
});

export const getEventOwnerPlan = createAsyncThunk(
  GET_EVENT_OWNER_PACKAGE,
  async eventId => {
    return await packageService.getEventOwnerPlan(eventId);
  }
);

export const getPackages = createAsyncThunk(GET_PACKAGES, async () => {
  return await packageService.getPackages();
});

export const getPackageByName = createAsyncThunk(
  GET_PACKAGE_BY_NAME,
  async packageName => {
    const change = await packageService.getPackageByName(packageName);
    return change;
  }
);

export const getInvoices = createAsyncThunk(GET_INVOICES, async () => {
  return await packageService.getInvoices();
});

export const downloadInvoice = createAsyncThunk(
  DOWNLOAD_INVOICE,
  async invoiceId => {
    const invoiceLink = await packageService.downloadInvoice(invoiceId);
    return invoiceLink;
  }
);

export const updateUserPackage = createAsyncThunk(
  UPDATE_USER_PACKAGE,
  async ({ newPackage, coupon }) => {
    const change = await packageService.updateUserPackage(newPackage, coupon);
    return change;
  }
);

export const verifyCouponCode = createAsyncThunk(
  VERIFY_COUPON_CODE,
  async couponCode => {
    const validCouponCode = await packageService.verifyCouponCode(couponCode);
    return validCouponCode;
  }
);

export const cancelPackage = createAsyncThunk(
  CANCEL_PACKAGE,
  async cancelData => {
    const change = await packageService.cancelPackage(cancelData);
    return change;
  }
);

export const resetPackages = createAction(RESET_PACKAGES);
