import { createSelector } from '@reduxjs/toolkit';
import { sectionEntityAdapter } from '../reducers/landingPageSectionReducer';

export const selectLandingPageSectionState = state => state.section;

export const selectSectionList = sectionEntityAdapter.getSelectors(
  selectLandingPageSectionState
).selectAll;

export const selectSectionLoading = createSelector(
  selectLandingPageSectionState,
  state => state.loading
);

export const selectSectionLoaded = createSelector(
  selectLandingPageSectionState,
  state => state.loaded
);

export const getPageNameById = sectionEntityAdapter.getSelectors(
  selectLandingPageSectionState
).selectById;

export const selectSectionById = sectionEntityAdapter.getSelectors(
  selectLandingPageSectionState
).selectById;

export const selectLastSortBeforeFooter = createSelector(
  selectSectionList,
  sectionList => sectionList[sectionList.length - 2]?.sort
);
