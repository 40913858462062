import firebase from 'firebase';
import { auth, functions, store, storage } from './firebase';

const USERS_COLLECTION = 'users';
const TEAMS_COLLECTION = 'teams';

export async function createUser(newUser) {
  await auth.createUserWithEmailAndPassword(newUser.email, newUser.password);
  sendEmailVerificationRequest();

  const user = auth.currentUser;
  user
    .updateProfile({
      displayName: newUser.fullName
    })
    .then(() => {
      firebase
        .firestore()
        .collection(USERS_COLLECTION)
        .doc(user.uid)
        .set({
          fullName: newUser.fullName,
          organization: newUser.organization,
          phoneNumber: newUser.phoneNumber,
          website: newUser.website,
          email: newUser.email,
          createdDate: firebase.firestore.FieldValue.serverTimestamp()
        });
    })
    .catch(error => {
      console.log(error.message);
    });
}

export async function createUserUsingProvider(newUser) {
  try {
    const user = auth.currentUser;

    await user.updateProfile({
      displayName: newUser.fullName
    });

    await firebase
      .firestore()
      .collection(USERS_COLLECTION)
      .doc(user.uid)
      .set({
        fullName: newUser.fullName,
        organization: newUser.organization,
        email: newUser.email,
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        photo: newUser.photo,
        organizerEmailVerified: !!newUser.emailVerified
      });
  } catch (error) {
    console.error(error.message);
  }
}

export async function signInUser(user) {
  await auth.signInWithEmailAndPassword(user.email, user.password);
  const token = await auth.currentUser.getIdToken();
  return token;
}

export async function sendPasswordResetEmail(email) {
  const sendThePasswordResetEmail = functions.httpsCallable(
    'emails-sendThePasswordResetEmailForOrganizers'
  );
  return await sendThePasswordResetEmail({
    email: email,
    organizerUrl: process.env.REACT_APP_ORGANIZER_URL
  });
}

export function signOut() {
  return auth.signOut();
}

export function getUserIdToken() {
  if (auth.currentUser) {
    return auth.currentUser.getIdToken(true);
  }
  return new Promise((_, reject) => reject('NO_USER'));
}

export async function getUserById(userId) {
  const userDoc = store.collection(`${USERS_COLLECTION}`).doc(userId);
  const user = await userDoc.get();
  if (!user.exists) return null;
  return { id: user.id, ...user.data() };
}

export async function sendEmailVerificationRequest() {
  const user = auth.currentUser;
  const sendEmailConfirmationRequest = functions.httpsCallable(
    'emails-sendEmailConfirmationRequest'
  );
  return await sendEmailConfirmationRequest({
    email: user?.email,
    organizerUrl: process.env.REACT_APP_ORGANIZER_URL
  });
}

export async function createUsers(users, eventId, ticketId) {
  const createUsersFunction = functions.httpsCallable(
    'auth-registration-csvImport'
  );
  return await createUsersFunction({ users, eventId, ticketId });
}

export async function deleteFullAccount() {
  const deleteFullAccountFunction = functions.httpsCallable(
    'auth-account-deleteFullUserAccount'
  );
  return await deleteFullAccountFunction();
}

export async function requestDeleteFullAccount() {
  const requestDeleteFullAccountFunction = functions.httpsCallable(
    'auth-account-requestDeleteFullAccount'
  );
  return await requestDeleteFullAccountFunction();
}

export async function updateProfile(userData) {
  const userId = userData.user_id;
  const userRef = store.doc(`${USERS_COLLECTION}/${userId}`);
  const batch = store.batch();
  const userDoc = await userRef.get();
  batch.update(userRef, {
    fullName: userData.fullName,
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
  });

  let profileImage = '';
  if (userData.photo && userData.photo.url === '') {
    batch.update(userRef, {
      photo: '',
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  } else if (
    userData.photo.url !== '' &&
    userData.photo.raw !== '' &&
    userData.photo.raw !== undefined
  ) {
    const imageName = `users/${userId}/avatar`;
    const uploadTask = await storage.ref(imageName).put(userData.photo.raw);
    const downloadUrl = await uploadTask.ref.getDownloadURL();

    batch.update(userRef, {
      photo: downloadUrl,
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  const teamsQuery = await store
    .collection(TEAMS_COLLECTION)
    .where('teamOwnerId', '==', userId)
    .get();

  teamsQuery.docs.forEach(doc => {
    batch.update(doc.ref, {
      teamOwnerName: userData.fullName
    });
  });

  await batch.commit();

  return {
    id: userId,
    changes: {
      ...userDoc.data(),
      photo: profileImage
    }
  };
}

export async function updateUserOrganization(userData) {
  const userRef = store.doc(`${USERS_COLLECTION}/${userData.id}`);
  const userDoc = await userRef.get();
  await userRef.update({
    organization: userData.organization
  });
  return {
    id: userData.id,
    changes: {
      ...userDoc.data(),
      organization: userData.organization
    }
  };
}
export async function updateUserPhoneNumber(userData) {
  const userRef = store.doc(`${USERS_COLLECTION}/${userData.user_id}`);
  const userDoc = await userRef.get();
  await userRef.update(
    {
      phoneNumber: userData.phoneNumber
    },
    { merge: true }
  );
  return {
    id: userData.user_id,
    changes: {
      ...userDoc.data(),
      phoneNumber: userData.phoneNumber
    }
  };
}
export async function updateUserWebsite(userData) {
  const userRef = store.doc(`${USERS_COLLECTION}/${userData.user_id}`);
  const userDoc = await userRef.get();
  await userRef.update({
    website: userData.website
  });
  return {
    id: userData.user_id,
    changes: {
      ...userDoc.data(),
      website: userData.website
    }
  };
}
export async function setRegistrationSurvey(userData) {
  const userRef = store.doc(`${USERS_COLLECTION}/${userData.user_id}`);
  const userDoc = await userRef.get();
  await userRef.update({
    registrationSurvey: userData.registrationSurvey
  });
  return {
    id: userData.id,
    changes: {
      ...userDoc.data(),
      registrationSurvey: userData.registrationSurvey
    }
  };
}

/**
 * @note Save a flag when the email is verified for the organizers => used for triggering Hubspot events
 * @returns
 */
export async function saveEmailVerifiedFlag() {
  const user = auth.currentUser;
  await store.doc(`${USERS_COLLECTION}/${user.uid}`).update({
    organizerEmailVerified: true
  });
  return true;
}

export async function generateHubspotToken(email, fullName) {
  try {
    const response = await functions.httpsCallable(
      'hubspot-generateHubspotToken'
    )({
      email: email,
      fullName: fullName
    });
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}
