import { packageEntityAdapter } from '../reducers/packageReducer';
import { createSelector } from '@reduxjs/toolkit';
import { FREE_PLAN } from 'src/constants/packagesConstants';
export const selectPackageState = state => state.package;

export const selectPackageList = packageEntityAdapter.getSelectors(
  selectPackageState
).selectAll;

export const selectUserPackage = createSelector(
  selectPackageState,
  state => state.userPackage
);

export const selectEventOwnerPlan = createSelector(
  selectPackageState,
  state => state.eventOwnerPlan
);

export const selectEventOwnerPlanLoading = createSelector(
  selectPackageState,
  state => state.eventOwnerPlanLoading
);

export const selectPackageLoading = createSelector(
  selectPackageState,
  state => state.userPlanLoading
);

export const selectPackageLoaded = createSelector(
  selectPackageState,
  state => state.userPlanLoaded
);
export const selectUpdatePlanLoading = createSelector(
  selectPackageState,
  state => state.updatePlanLoading
);

export const selectInvoices = createSelector(
  selectPackageState,
  state => state.invoices
);

export const selectInvoiceLoading = createSelector(
  selectPackageState,
  state => state.invoiceLoading
);
export const selectDefaultPackage = createSelector(
  selectPackageList,
  packageList => packageList.find(p => p.type === FREE_PLAN)
);
