import React from 'react';

function Pen() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
      <path d="M21.33,7.17l-4.49-4.5a.59.59,0,0,0-.19-.13l-.1,0-.11,0-.12,0-.09,0a.5.5,0,0,0-.19.13L2.67,16a.56.56,0,0,0-.17.4v4.5a.56.56,0,0,0,.56.56h4.5a.56.56,0,0,0,.4-.17L21.33,8A.55.55,0,0,0,21.33,7.17Zm-14,13.2H3.63v-3.7l9.62-9.62L17,10.75ZM17.75,10l-3.7-3.7,2.39-2.39,3.7,3.7Z" fill="currentColor"/>
      <path d="M21.45,20.37H12.58a.57.57,0,0,0-.56.57.56.56,0,0,0,.56.56h8.87a.57.57,0,0,0,.57-.56A.58.58,0,0,0,21.45,20.37Z" fill="currentColor"/>
    </svg>
  );
}

export default Pen;
