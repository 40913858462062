import React from 'react';

function Display() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }} width="20" height="20" viewBox="0 0 24 24">
      <path d="M21.65,2.5H2.35a.56.56,0,0,0-.56.56V16.8a.56.56,0,0,0,.56.56h9.23v3H6.91a.56.56,0,1,0,0,1.12H17.09a.56.56,0,1,0,0-1.12H12.7v-3h9a.56.56,0,0,0,.56-.56V3.06A.56.56,0,0,0,21.65,2.5Zm-.56,13.74H2.91V3.62H21.09Z" fill="currentColor"/>
      <path d="M9.84,13.38a.6.6,0,0,0,.28.08.54.54,0,0,0,.28-.08l5.14-3a.55.55,0,0,0,.28-.48.58.58,0,0,0-.28-.49l-5.14-3A.56.56,0,0,0,9.56,7v5.93A.58.58,0,0,0,9.84,13.38Zm.84-5.44,3.46,2-3.46,2Z" fill="currentColor"/>
    </svg>
  );
}

export default Display;
