/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
import { THEMES } from '../constants';
import * as colorsCustom from './colors';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      },
      MuiButton: {
        contained: {
          textTransform: 'capitalize',
          borderRadius: 50,
          padding: '.73em 1.56em',
          fontSize: '0.875rem',
          fontWeight: 700,
          '&:hover': {
            backgroundColor: colorsCustom.primary
          }
        },
        outlined: {
          borderRadius: 50,
          padding: '.73em 1.56em',
          fontSize: '0.875rem',
          fontWeight: 700
        },
        containedSecondary: {
          padding: '0.605em 1.4375em',
          backgroundColor: 'transparent',
          border: '2px solid',
          borderColor: colorsCustom.primary,
          color: colorsCustom.primary,
          '&:hover': {
            border: '2px solid',
            borderColor: colorsCustom.primaryDark,
            color: colorsCustom.primaryDark,
            backgroundColor: colorsCustom.primaryLight
          }
        }
      },
      MuiCard: {
        root: {
          backgroundColor: colorsCustom.white,
          borderRadius: '10px',
          boxShadow: 'none'
        }
      },
      MuiAutocomplete: {
        popper: {
          border: '1px solid',
          backgroundColor: colorsCustom.white
        }
      },
      MuiPaper: {
        rounded: {
          borderRadius: '10px',
          boxShadow: 'none'
        }
      },
      MuiCardContent: {
        root: {
          borderRadius: '10px',
          boxShadow: 'none'
        }
      },
      MuiTableCell: {
        head: {
          padding: 10,
          fontSize: '0.875rem !important',
          color: colorsCustom.grey
        },
        body: {
          padding: 10,
          fontSize: '0.875rem !important',
          '& a, p, span': {
            fontSize: 'inherit !important'
          }
        }
      },
      MuiTableRow: {
        root: {
          '&:last-of-type td': {
            borderBottom: 0
          }
        }
      },
      MuiDialog: {
        container: {
          '& .MuiTabs-indicator': {
            backgroundColor: colorsCustom.black
          }
        }
      },
      MuiSwitch: {
        colorSecondary: {
          '&.Mui-checked': {
            color: colorsCustom.primary
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: colorsCustom.primary
          }
        }
      },
      MuiRadio: {
        colorSecondary: {
          '&.Mui-checked': {
            color: colorsCustom.primary
          }
        }
      }
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colorsCustom.white,
        dark: '#f4f5f7',
        blue: '#1d55e3',
        grey: '#adadad',
        paper: '#F8F9FA',
        main: '#2A2F33'
      },
      primary: {
        main: colorsCustom.primary,
        contrastText: colorsCustom.white,
        light: colorsCustom.secondary
      },
      secondary: {
        main: colorsCustom.secondary,
        contrastText: colorsCustom.primaryDark
      },
      lightColors: {
        green: 'lightgreen',
        red: 'salmon'
      },
      primaryLight: {
        main: colorsCustom.primaryLight
      },
      focusBorder: `2.1px solid #229970`,
      littleFocusBorder: `2px solid #adadad`,
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      },
      grey: {
        main: '#80838a',
        [300]: colorsCustom.black,
        ['A100']: 'rgb(20, 59, 158)',
        light: 'lightgrey',
        bluegrey: '#546E7A'
      },
      nonColors: {
        black: colorsCustom.black,
        white: colorsCustom.white
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      focusBorder: `2px solid #8a85ff`,
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      focusBorder: `2px solid #a67dff`,
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find(theme => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction })
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
