import {
  createTicket,
  updateTicket,
  updateTicketIsPublished,
  updateTicketIsArchived,
  updateTicketsSort,
  deleteTicket,
  getTicketById,
  getTickets,
  getTicketsForPromoCodes,
  resetTickets
} from 'src/actions/ticketActions';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export const ticketEntityAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = ticketEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

const ticketReducer = createReducer(initialState, {
  [createTicket.pending]: (state, _) => {
    state.loading = true;
  },
  [createTicket.fulfilled]: (state, { payload }) => {
    state.loading = false;
    ticketEntityAdapter.upsertOne(state, payload);
  },
  [createTicket.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateTicket.pending]: (state, _) => {
    state.loading = true;
  },
  [updateTicket.fulfilled]: (state, { payload }) => {
    state.loading = false;
    ticketEntityAdapter.updateOne(state, payload);
  },
  [updateTicket.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateTicketIsPublished.pending]: (state, _) => {
    state.loading = true;
  },
  [updateTicketIsPublished.fulfilled]: (state, { payload }) => {
    state.loading = false;
    ticketEntityAdapter.updateOne(state, payload);
  },
  [updateTicketIsPublished.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateTicketIsArchived.pending]: (state, _) => {
    state.loading = true;
  },
  [updateTicketIsArchived.fulfilled]: (state, { payload }) => {
    state.loading = false;
    ticketEntityAdapter.updateOne(state, payload);
  },
  [updateTicketIsArchived.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [updateTicketsSort.pending]: (state, _) => {
    state.loading = true;
  },
  [updateTicketsSort.fulfilled]: (state, { payload }) => {
    state.loading = false;
    ticketEntityAdapter.upsertMany(state, payload);
  },
  [updateTicketsSort.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [deleteTicket.pending]: (state, _) => {
    state.loading = true;
  },
  [deleteTicket.fulfilled]: (state, { payload }) => {
    state.loading = false;
    ticketEntityAdapter.removeOne(state, payload);
  },
  [deleteTicket.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getTicketById.pending]: (state, _) => {
    state.loading = true;
  },
  [getTicketById.fulfilled]: (state, { payload }) => {
    state.loading = false;
    ticketEntityAdapter.upsertOne(state, payload);
  },
  [getTicketById.rejected]: (state, { error }) => {
    state.error = error;
    state.loading = false;
  },
  [getTickets.pending]: (state, _) => {
    state.loading = true;
  },
  [getTickets.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    ticketEntityAdapter.upsertMany(state, payload);
  },
  [getTickets.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [getTicketsForPromoCodes.pending]: (state, _) => {
    state.loading = true;
  },
  [getTicketsForPromoCodes.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.loaded = true;
    ticketEntityAdapter.upsertMany(state, payload);
  },
  [getTicketsForPromoCodes.rejected]: (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
  [resetTickets.type]: (_, __) => {
    return initialState;
  }
});

export default ticketReducer;
