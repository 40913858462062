import {
  getAddons,
  deleteAddon,
  createAddon,
  updateAddon
} from '../core/api/AddonStore';
import authService from './authService';
import { updateAddonSort } from 'src/core/api/AddonStore';

class AddonService {
  getAddons = eventId =>
    new Promise((resolve, reject) => {
      getAddons(eventId)
        .then(payload => {
          const orderedAddons = this.sortList(payload);
          resolve({ addons: orderedAddons });
        })
        .catch(error => {
          reject(error.message);
        });
    });

  sortList = list => {
    let sortedAddons = list.sort(
      (a, b) => a.sort - b.sort || a.createdDate.seconds - b.createdDate.seconds
    );
    return sortedAddons;
  };

  createAddon = (addon, eventId) =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      createAddon(addon, userId, eventId)
        .then(payload => {
          resolve(payload);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateAddon = (addon, eventId) =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      updateAddon(addon, userId, eventId)
        .then(payload => {
          resolve(payload);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  updateAddonsSort = (addons, eventId) =>
    new Promise((resolve, reject) => {
      const userId = authService.getUserUid();
      updateAddonSort(addons, userId, eventId)
        .then(newAddons => {
          resolve(newAddons);
        })
        .catch(error => {
          reject(error.message);
        });
    });

  deleteAddon = (addonId, eventId) =>
    new Promise((resolve, reject) => {
      deleteAddon(addonId, eventId)
        .then(addon => {
          resolve(addon);
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

export default new AddonService();
